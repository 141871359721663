<app-page-header title="ClaimInvitation" icon="mail"></app-page-header>
<div [@fadeInOut] class="page-content" fxLayoutGap="space-evenly center">
  <!--<div fxLayout="row" class="mat-small" *ngIf="!claimExists || claimExpired || claimClaimed">
    <div fxLayout="row">
      Code: {{ code }}
    </div>
    <div fxLayout="row">
      Result: {{ inviteClaimJSON }}
    </div>
    <div fxLayout="row">
      Exists: {{ claimExists }}
    </div>
    <div fxLayout="row">
      Expired: {{ claimExpired }}
    </div>
    <div fxLayout="row">
      Claimed: {{ claimClaimed }}
    </div>
  </div>-->

  <div fxLayout="column" *ngIf="showLoginOptions" fxLayoutGap="space-evenly center">
    <div fxFlex="100%" *ngIf="showLoginButton" fxLayout="column">
      <div>
        {{ 'invitations.claim.LoginScript' | translate }}
      </div>
      <div>&nbsp;</div>
      <div>
        <button mat-raised-button fxFlex="100%" (click)="showLoginDialog();" color="primary">{{'invitations.claim.Login' | translate}}</button>
      </div>
    </div>
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <div fxFlex="100%" *ngIf="showRegistrationButton" fxLayout="column">
      <div>
        {{ 'invitations.claim.RegisterScript' | translate }}
      </div>
      <div>&nbsp;</div>
      <div>
        <button mat-raised-button fxFlex="100%" (click)="showRegisterDialog();" color="default">{{'invitations.claim.Register' | translate}}</button>
      </div>
    </div>
  </div>

  <div *ngIf="showClaimOptions" fxLayoutGap="space-evenly center">
    <div fxFlex="100%" fxLayout="column">
      <div>
        {{ 'invitations.claim.ClaimScript' | translate }}
      </div>
      <div>&nbsp;</div>
      <div>
        <button mat-raised-button fxFlex="100%" (click)="claimInvitation();" color="primary">{{'invitations.claim.Claim' | translate}}</button>
      </div>
    </div>
  </div>

  <div fxLayout="row" *ngIf="showClaimDoesNotExist">
    <div fxFlex="100%">
      <div fxLayout="row">
        {{ 'invitations.claim.ClaimDoesNotExist' | translate }}
      </div>
    </div>
  </div>

  <div fxLayout="row" *ngIf="showClaimExpired">
    <div fxFlex="100%">
      <div fxLayout="row">
        {{ 'invitations.claim.ClaimExpired' | translate }}
      </div>
    </div>
  </div>

  <div fxLayout="row" *ngIf="showClaimClaimed">
    <div fxFlex="100%">
      <div fxLayout="row">
        {{ 'invitations.claim.ClaimClaimed' | translate }}
      </div>
    </div>
  </div>
</div>
