import { Component, OnInit, Input } from '@angular/core';
import { Material } from 'src/app/models/material.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Test } from 'src/app/models/test.model';
import { XrLanguageCode } from 'src/app/models/xr-language-code.model';
import { XrService } from 'src/app/services/xr.service';

@Component({
  selector: 'app-material-header-control',
  templateUrl: './material-header-control.component.html',
  styleUrls: ['./material-header-control.component.scss']
})
export class MaterialHeaderControlComponent implements OnInit {
  @Input() material: Material = new Material(-1, "");
  @Input() designation: string;


  get nameText(): string {
    if (this.designation == "aptitude")
      return this.translationService.getTranslation("materialHeaderControl.MaterialAptitude");
    else if (this.designation == "assessment")
      return this.translationService.getTranslation("materialHeaderControl.MaterialAssessment");
    else if (this.designation == "survey")
      return this.translationService.getTranslation("materialHeaderControl.MaterialSurvey");
    else
      return this.translationService.getTranslation("materialHeaderControl.MaterialName");
  }

  get editObj(): Material {
    if (this.material)
      return this.material;
    return new Material(-1, "");
  }

  editTest: Test = new Test();

  get materialName(): string {
    return this.editObj.materialName;
  }
  set materialName(value: string) {
    this.editObj.materialName = value;
    this.test.testName = value;
  }

  get template(): string {
    return (this.editObj.template == true ? "true" : "false")
  }
  set template(value: string) {
    this.editObj.template = value == "true";
  }

  get languageCode(): string {
    return this.editObj.languageCode;
  }
  set languageCode(value: string) {
    this.editObj.languageCode = value;
    this.test.languageCode = value;
  }


  get test(): Test {
    if (!this.editTest || !this.editTest.testId) {
      if (["aptitude","assessment", "survey"].filter(x => x == this.designation).length > 0) {
        if (this.material) {
          if (this.material.materialOrder) {
            var test = this.material.materialOrder.filter(x => x.test).map(x => x.test);
            if (test) {
              if (test.length > 0) {
                this.editTest = test[0]; //there should only be one test, if any connected to a single material
              }
            }
          }
        }
      }
    }

    return this.editTest;
  }

  get languages(): XrLanguageCode[] {
    return this.xrService.getLanguageCodes();
  }

  constructor(
    private xrService: XrService,
    private translationService: AppTranslationService
  ) {
    this.xrService.getLanguageCodes(); //primes the request...
  }

  ngOnInit() {
  }

}
