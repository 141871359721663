import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LanguageManager } from 'src/app/shared/language-picker/language-manager';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AppLanguage } from 'src/app/models/AppLanguage';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit, AfterViewInit {

  selectedValue: AppLanguage;
  isLoading: boolean = false;

  constructor(
    public languageManager: LanguageManager,
    private configuration: ConfigurationService,
    private authService: AuthService
  ) {
    this.configuration.configurationImported$.subscribe(
      () => {
        if (!this.currentLanguage) return;
        this.installLanguage(this.currentLanguage);
      });
  }

  ngOnInit() {
    if (this.currentLanguage) {
      this.installLanguage(this.currentLanguage);
    }
  }

  ngAfterViewInit(): void {
    this.isLoading = false;
    if (this.selectedValue != this.currentLanguage)
      this.installLanguage(this.currentLanguage);
  }

  installLanguage(language: AppLanguage) {
    this.selectedValue = language;
    this.languageManager.installLanguage(language);
  }

  get profileReady(): boolean {
    if (!this.authService) return false;
    if (this.authService.isSessionExpired) return false;
    return this.authService.isLoggedIn;
  }

  get currentLanguage(): AppLanguage {
    return this.languageManager.getLanguageByLocale(this.configuration.language);
  }

  setLanguage(language: AppLanguage) {
    if (language && this.profileReady && !this.isLoading) {
      this.languageManager.installLanguage(language);
      this.configuration.language = language.locale;
    }
  }

  selectionMade() {
    this.setLanguage(this.selectedValue);
  }
}
