import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';

import { UserEditorComponent } from '../user-editor/user-editor.component';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { Utilities } from '../../services/utilities';

@Component({
  selector: 'app-edit-user-dialog',
  templateUrl: 'edit-user-dialog.component.html',
  styleUrls: ['edit-user-dialog.component.scss']
})
export class EditUserDialogComponent {
  @ViewChild(UserEditorComponent)
  editUser: UserEditorComponent;
  loadingIndicator: boolean;

  get userName(): any {
    return this.data.user ? { name: this.data.user.userName } : null;
  }

  constructor(
    private alertService: AlertService,
    private snackBar: MatSnackBar,
    private accountService: AccountService,
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User, roles: Role[] }) {
  }

  ngAfterViewInit() {
    this.editUser.userSaved$.subscribe(user => this.dialogRef.close(user));
  }

  sendTestEmail() {
    this.snackBar.open(`Send test email to ${this.data.user.userName}?`, 'SEND', { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;

        this.accountService.sendTestEmail(this.data.user)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage('Send Test Email Error', `An error occured whilst deleting the user.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
                MessageSeverity.error, error);
            });
      });
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
