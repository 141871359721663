<div [@fadeInOut] class="page-content" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
  <div>
    <mat-card>
      <mat-card-header>
        <mat-icon mat-card-avatar>vpn_key</mat-icon>
        <mat-card-title>{{'login.Title' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'login.SubTitle' | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <app-login-control [isModal]="false" (enterKeyPress)="loginControl.login()"></app-login-control>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button [routerLink]="'/registration'">{{'mainMenu.Registration' | translate }}</button>
        <div fxFlex="auto"></div>
        <button mat-button [routerLink]="'/forgot-password'">{{'mainMenu.ForgotPassword' | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="loginControl.isLoading" (click)="loginControl.login()">
          <mat-spinner *ngIf="loginControl.isLoading" [diameter]="20"></mat-spinner>{{'mainMenu.Login' | translate | uppercase}}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
