import { Component, OnInit, Input, ViewChild, OnDestroy, OnChanges } from '@angular/core';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-contact-editor-control',
  templateUrl: './contact-editor-control.component.html',
  styleUrls: ['./contact-editor-control.component.scss']
})
export class ContactEditorControlComponent implements OnChanges {

  @Input() contact: InvitationContact = new InvitationContact("", "", "");
  @Input() isEditMode: boolean = false;

  @ViewChild('form') private form: NgForm;

  editorForm: FormGroup;

  isNew: boolean = false;
  private isSaving: boolean = false;

  private onContactSaved: Subject<InvitationContact> = new Subject<InvitationContact>();
  contactSaved$: Observable<InvitationContact> = this.onContactSaved.asObservable();

  get firstName() {
    return this.editorForm.get('firstName');
  }
  get lastName() {
    return this.editorForm.get('lastName');
  }
  get email() {
    return this.editorForm.get('email');
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
  }
  ngOnChanges(): void {
    if (this.contact) {
      this.isNew = (this.contact.email == "");
    }
    else {
      this.isNew = true;
      this.createNewObject();
    }

    this.resetForm();
  }

  private buildForm() {
    this.editorForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: '',
      lastName: '',
      success: false,
      error: ''
    })
  }

  private createNewObject() {
    if (this.contact)
      return;

    this.contact = new InvitationContact("", "", "");
  }

  private resetForm(stopEditing: boolean = false) {
    if (stopEditing)
      this.isEditMode = false;

    if (!this.contact || this.isNew) {
      this.isNew = true;
      this.createNewObject();
    }

    if (this.isNew) {
      //no special logic, yet.
    }

    this.editorForm.reset({
      email: this.contact.email || '',
      firstName: this.contact.firstName || '',
      lastName: this.contact.lastName || '',
      success: this.contact.success || false,
      error: this.contact.error || ''
    });
  }

  public beginEdit() {
    this.isEditMode = true;
  }

  save() {
    if (!this.form.submitted) {
      // Causes validation to update.
      this.form.onSubmit(null);
      return;
    }

    if (!this.editorForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    this.alertService.startLoadingMessage('Adding contact...');

    const editedContact = this.getEditedObject();
    this.saveCompleted(editedContact);
  }

  private getEditedObject(): InvitationContact {
    const formModel = this.editorForm.value;

    return {
      email: formModel.email,
      firstName: formModel.firstName,
      lastName: formModel.lastName,
      success: formModel.success,
      error: formModel.error
    };
  }

  private saveCompleted(contact?: InvitationContact) {
    if (contact) {
      //add downstream event calls here, if needed.
      this.contact = contact;
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.resetForm(true);
    this.onContactSaved.next(this.contact);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  cancel() {
    this.resetForm();
    this.isEditMode = false;
    this.alertService.resetStickyMessage();
  }
}
