import { Injectable } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { InvitationSender } from '../models/invitation-sender.model';
import { InvitationClaim } from '../models/invitation-claim.model';
import { AuthService } from './auth.service';
import { Invitation } from '../models/invitation.model';
import { Task } from '../models/task.model';

@Injectable({
  providedIn: 'root'
})
export class InvitationsService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint,
    private authService: AuthService
  ) { }

  sendInvitations(info: InvitationSender) {
    if (info) {
      return this.applicationEndpoint.sendInvitations(info);
    }
  }

  checkInvitation(code: string) {
    if (code) {
      return this.applicationEndpoint.checkInvitationClaim<InvitationClaim>(code);
    }
  }

  claimInvitation(code: string) {
    if (code) {
      return this.applicationEndpoint.claimInvitation<Task>(this.authService.currentUserProfile.userId, code);
    }
  }
}
