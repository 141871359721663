import { Component, OnInit } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { Company } from 'src/app/models/company';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-company-view',
  templateUrl: './company-view.component.html',
  styleUrls: ['./company-view.component.scss'],
  animations: [fadeInOut]
})
export class CompanyViewComponent implements OnInit {
  companyId: number = -1;
  company: Company;

  get companyName() {
    if (!this.company) return "";
    return this.company.companyName;
  }

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private companyService: CompaniesService,
    private authService: AuthService
  ) {
    route.params.subscribe(params => { this.parseParams(params); });
  }

  parseParams(params: Params) {
    this.companyId = params['companyId'];
    if (!this.companyId) {
      var uc = this.authService.currentUserCompany;
      if (uc)
        this.companyId = uc.companyId;
    }
  }

  ngOnInit() {
    this.alertService.startLoadingMessage();
    if (this.companyId >= 0)
      this.companyService.getCompany(this.companyId).subscribe(company => { this.companyFound(company); });
    else
      this.authService.companySelected$.subscribe(result => { this.companyFound(result) });
  }

  companyFound(company: Company) {
    this.alertService.stopLoadingMessage();
    this.company = company;
  }
}
