import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MaterialContent } from 'src/app/models/material-content.model';
import { MatDialog } from '@angular/material';
import { RichTextDialogComponent } from 'src/app/components/dialogs/rich-text-dialog/rich-text-dialog.component';


@Component({
  selector: 'app-material-content-control',
  templateUrl: './material-content-control.component.html',
  styleUrls: ['./material-content-control.component.scss']
})
export class MaterialContentControlComponent implements OnInit {
  @Input() materialContent: MaterialContent = new MaterialContent;
  @Input() position: string = "";

  @Output() onDelete = new EventEmitter<MaterialContent>();
  @Output() onMoveUp = new EventEmitter<MaterialContent>();
  @Output() onMoveDown = new EventEmitter<MaterialContent>();

  get editObj(): MaterialContent {
    if (this.materialContent)
      return this.materialContent;
    return new MaterialContent;
  }

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {

  }

  launchContentEditor() {
    var content: string = (' ' + this.editObj.content).trimLeft();

    const dialogRef = this.dialog.open(RichTextDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { title: this.editObj.title, content: content }
      });
    dialogRef.afterClosed().subscribe(c => {
      if (c) {
        this.updateContent(c);
      }
    });
  }

  updateContent(c: string) {
    this.editObj.content = c;
  }

  delete() {
    this.onDelete.emit(this.materialContent);
  }

  moveUp() {
    this.onMoveUp.emit(this.materialContent);
  }

  moveDown() {
    this.onMoveDown.emit(this.materialContent);
  }
}
