import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyReportListControlComponent } from '../controls/company-report-list-control/company-report-list-control.component';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { fadeInOut } from 'src/app/services/animations';

@Component({
  selector: 'app-company-report-list',
  templateUrl: './company-report-list.component.html',
  styleUrls: ['./company-report-list.component.scss'],
  animations: [fadeInOut]
})
export class CompanyReportListComponent implements OnInit {
  @ViewChild(CompanyReportListControlComponent) list: CompanyReportListControlComponent;

  companyId: number;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

  ngOnInit() {
  }

}
