<div fxFlex="100%" fxLayout="row" fxLayoutGap="16px" class="content-area">
  <div class="buttons">
    <button mat-icon-button (click)="launchEditor()" matTooltip="{{'commands.Edit' | translate}}">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div fxFlex="100%" fxLayout="column" class="simple-border content-container">
    <div fxFlex="100%" fxLayout="row">
      <div fxFlex="100%" class="float-container">
        <label>{{ 'answerControl.Title' | translate }}</label>
        <span>{{ editObj.title || '...' }}</span>
      </div>
      <div class="buttons" fxLayout="row">
        <button mat-icon-button *ngIf="!(position == 'first' || position == 'single')" (click)="moveUp()" matTooltip="{{'commands.Up' | translate}}">
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button mat-icon-button *ngIf="!(position == 'last' || position == 'single')" (click)="moveDown()" matTooltip="{{'commands.Down' | translate}}">
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button mat-icon-button *ngIf="position != 'single'" color="warn" (click)="delete()" matTooltip="{{'commands.Delete' | translate}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <br />
    <div fxLayout="column" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px" class="keep-top">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100%">
        <div fxFlex="50%" class="float-container">
          <label>{{'answerControl.Type' | translate}}</label>
          <span>{{answerType.title}}</span>
        </div>
        <div *ngIf="useFreeTextLimit" fxFlex="50%" class="float-container">
          <label>{{'answerControl.TextLimit' | translate}}</label>
          <span>{{editObj.freeTextCharacterLimit}}</span>
        </div>
      </div>
      <div fxLayout="column" fxLayoutGap="8px" *ngIf="useAnswerValues" fxFlex="100%" class="simple-border content-area">
        <div fxFlex="100%" *ngFor="let av of answerValues" class="content-container keep-top" fxLayout="column">
          <div fxFlex="100%" *ngIf="valuePosition(av) == 'first' || valuePosition(av) == 'single'" class="float-container-header">
            <div fxFlex="15%">
              <label>{{'answerValueControl.Code' | translate}}</label>
            </div>
            <div fxFlex="100%">
              <label>{{'answerValueControl.Title' | translate}}</label>
            </div>
            <div fxFlex="15%">
              <label>{{'answerValueControl.Points' | translate}}</label>
            </div>
          </div>
          <div fxFlex="100%">
            <div fxFlex="15%">
              {{av.code}}
            </div>
            <div fxFlex="100%">
              {{av.title}}
            </div>
            <div fxFlex="15%">
              {{av.pointValue}} pts.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
