import { IAuditableEntity } from "./IAuditableEntry.interface";
import { MaterialContent } from "./material-content.model";
import { Test } from "./test.model";

export class MaterialOrder implements IAuditableEntity {
  public materialOrderId: number;
  public materialId: number;
  public materialContentId: number;
  public testId: number;
  public ordinal: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public deleted: boolean;

  public materialContent: MaterialContent;
  public test: Test;
}
