<div fxLayout="row">
  <div class="name" fxFlex="35%">
    <mat-checkbox [(ngModel)]="benchmark.active">{{benchmark.name}}</mat-checkbox>
  </div>
  <div fxFlex="65%">
    <div class="range-control" fxFlex="15%">
      <input matInput [(ngModel)]="benchmark.from" type="number" [disabled]="!benchmark.active"/>
    </div>
    <div class="range" fxFlex="70%">
      <ng5-slider [(value)]="benchmark.from"
                  [(highValue)]="benchmark.to"
                  [manualRefresh]="slidersRefresh"
                  [options]="{
          floor: 0,
          ceil: 15,
          disabled: !benchmark.active,
          hideLimitLabels: true,
          hidePointerLabels: true
        }">
      </ng5-slider>

    </div>
    <div class="range-control" fxFlex="15%">
      <input matInput [(ngModel)]="benchmark.to" type="number" [disabled]="!benchmark.active"/>
    </div>
  </div>
</div>
