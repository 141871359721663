// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, ViewChild } from '@angular/core';

import { LoginControlComponent } from './login-control.component';

import { fadeInOut } from '../../services/animations';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [fadeInOut]
})
export class LoginComponent {
    @ViewChild(LoginControlComponent)
    loginControl: LoginControlComponent;
}
