<form #form="ngForm" [formGroup]="editorForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'invitations.contactEditorControl.Email' | translate}}</mat-label>
      <input matInput formControlName="email" [readonly]="!isEditMode" />
      <mat-error *ngIf="email.hasError('required')">
        {{'invitations.contactEditorControl.EmailRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
        {{'invitations.contactEditorControl.InvalidEmail' | translate}}
      </mat-error>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'invitations.contactEditorControl.FirstName' | translate}}</mat-label>
      <input matInput formControlName="firstName" [readonly]="!isEditMode" required />
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'invitations.contactEditorControl.LastName' | translate}}</mat-label>
      <input matInput formControlName="lastName" [readonly]="!isEditMode" required />
    </mat-form-field>
  </div>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</form>
