<div fxLayout="row">
  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'invitations.contactTableControl.Search' | translate}}">
    </mat-form-field>
  </div>
  <div fxFlex="auto"></div>
  <div *ngIf="this.dataSource.data.length <= 0" fxLayoutGap="8px">
    <button mat-raised-button matTooltip="{{'invitations.contactTableControl.SelectTeam' | translate}}" (click)="launchTeamSelect()">
      <mat-icon>people_outline</mat-icon>&nbsp;&nbsp;{{'invitations.contactTableControl.SelectTeam' | translate}}
    </button>
    <button mat-raised-button matTooltip="{{'invitations.contactTableControl.NewContact' | translate}}" (click)="editContact()">
      <mat-icon>person_add</mat-icon>&nbsp;&nbsp;{{'invitations.contactTableControl.NewContact' | translate}}
    </button>
    <button mat-raised-button matTooltip="{{'invitations.contactTableControl.Import' | translate}}" (click)="launchImport()">
      <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;{{'invitations.contactTableControl.Import' | translate}}
    </button>
  </div>
</div>

<mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
<mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="email">
    <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'invitations.contactTableControl.Email' | translate}} </mat-header-cell>
    <mat-cell fxFlex="40%" *matCellDef="let row"> {{row.email}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="firstName">
    <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header> {{'invitations.contactTableControl.FirstName' | translate}} </mat-header-cell>
    <mat-cell fxFlex="25%" *matCellDef="let row"> {{row.firstName}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="lastName">
    <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header> {{'invitations.contactTableControl.LastName' | translate}} </mat-header-cell>
    <mat-cell fxFlex="25%" *matCellDef="let row"> {{row.lastName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef fxFlex="140px" fxFlexOffset="30px">
      <button mat-icon-button color="primary" matTooltip="{{'invitations.contactTableControl.SelectTeam' | translate}}" (click)="launchTeamSelect()">
        <mat-icon>people_outline</mat-icon>
      </button>
      <button mat-icon-button color="primary" matTooltip="{{'invitations.contactTableControl.NewContact' | translate}}" (click)="editContact()">
        <mat-icon>person_add</mat-icon>
      </button>
      <button mat-icon-button color="primary" matTooltip="{{'invitations.contactTableControl.Import' | translate}}" (click)="launchImport()">
        <mat-icon>cloud_upload</mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let contact" fxFlex="140px" fxFlexOffset="30px">
      <button mat-icon-button matTooltip="{{'invitations.contactTableControl.Edit' | translate}}" (click)="editContact(contact)">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button matTooltip="{{'invitations.contactTableControl.Delete' | translate}}" (click)="confirmDelete(contact)">
        <mat-icon color="warn">delete</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;">
  </mat-row>
</mat-table>

<mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
