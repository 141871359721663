<div fxFlex="auto" fxLayout="column">
  <div class="search-box" fxLayout="row" fxFlex="auto">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'individualsSelectionControl.Search' | translate}}" />
    </mat-form-field>
  </div>
  <div fxFlex="auto">
    <div class="simple-border" fxFlex="auto">
      <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="selected">
          <mat-header-cell fxFlex="60px" *matHeaderCellDef mat-sort-header>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell fxFlex="60px" *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="lastName">
          <mat-header-cell fxFlex="40%" fxFlex.lt-lg="55%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matHeaderCellDef mat-sort-header>
            <div fxHide.sm fxHide.lt-sm>{{'individualsControl.LastName' | translate}}</div>
            <div fxHide.gt-sm>{{'individualsControl.Name' | translate}}</div>
          </mat-header-cell>
          <mat-cell fxFlex="40%" fxFlex.lt-lg="55%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matCellDef="let row">
            <span fxHide.sm fxHide.lt-sm>{{row.lastName}}</span>
            <span fxHide.gt-sm>
              {{row.lastName}}, {{row.firstName}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <mat-header-cell fxFlex="35%" fxFlex.lt-lg="45%" *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'individualsSelectionControl.FirstName' | translate}} </mat-header-cell>
          <mat-cell fxFlex="35%" fxFlex.lt-lg="45%" *matCellDef="let row" fxHide.sm fxHide.lt-sm> {{row.firstName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header fxHide.lt-lg> {{'individualsSelectionControl.Email' | translate}} </mat-header-cell>
          <mat-cell fxFlex="25%" *matCellDef="let row" fxHide.lt-lg> {{row.email}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
        </mat-row>
      </mat-table>

      <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </div>
  </div>
</div>
