import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Profile } from 'src/app/models/profile';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { Router } from '@angular/router';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { ContactEditorDialogComponent } from 'src/app/components/invitation/dialogs/contact-editor-dialog/contact-editor-dialog.component';
import { ProfileService } from 'src/app/services/profile.service';
import { Utilities } from 'src/app/services/utilities';
import { ContactUploadDialogComponent } from 'src/app/components/dialogs/contact-upload-dialog/contact-upload-dialog.component';
import { CompanyRoleAssignmentDialogComponent } from '../../dialogs/company-role-assignment-dialog/company-role-assignment-dialog.component';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-company-privileged-users-control',
  templateUrl: './company-privileged-users-control.component.html',
  styleUrls: ['./company-privileged-users-control.component.scss']
})
export class CompanyPrivilegedUsersControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'lastName',
    'firstName',
    'role',
    'actions'
  ];
  dataSource: MatTableDataSource<Profile>;
  loadingIndicator: boolean;

  @Input() companyId; number;

  get canEditRoles(): boolean {
    return this.authService.userIsCompanyAdmin;
  }

  constructor(
    private alertService: AlertService,
    private companiesService: CompaniesService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: AppTranslationService,
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
    this.authService.companySelected$.subscribe(
      result => { this.companyFound(result); }
    );
  }
  
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public companyFound(company: Company) {
    this.companyId = company.companyId;
    this.loadData();
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    if (!this.loadingIndicator) {
      this.loadingIndicator = true;
      this.companiesService
        .privilegedUsers(this.companyId)
        .subscribe(entries =>
          this.onDataLoadSuccessful(entries),
          error => this.onDataLoadFailed(error));
    }
  }

  private onDataLoadSuccessful(entries: Profile[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  roleName(entry: Profile): string {
    var role = "User";

    if (entry.userCompany) {
      if (entry.userCompany.length > 0) {
        role = entry.userCompany[0].role;
      }
    }

    role = this.translationService.getTranslation(`roles.labels.${role}`);

    return role;
  }
  roleDescription(entry: Profile): string {
    var role = "User";

    if (entry.userCompany) {
      if (entry.userCompany.length > 0) {
        role = entry.userCompany[0].role;
      }
    }

    role = this.translationService.getTranslation(`roles.labels.${role}Description`);

    return role;
  }

  launch(entry: Profile) {
    this.router.navigateByUrl(`/profile/${entry.userId}`);
  }

  newIndividual() {
    const contact = new InvitationContact("", "", "");

    const dialogRef = this.dialog.open(ContactEditorDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { contact: contact }
      });
    dialogRef.afterClosed().subscribe(contact => {
      if (contact) {
        this.addContact(contact);
      }
    });
  }

  private addContact(contact: InvitationContact) {
    const companyid: number = this.authService.companyId;

    //add logic to create new profile object...
    var profile = new Profile();
    profile.email = contact.email;
    profile.firstName = contact.firstName;
    profile.lastName = contact.lastName;
    profile.userName = contact.email;
    profile.preferredName = contact.firstName;
    profile.educationCredentials = "";
    profile.middleName = "";
    profile.phoneNumber = "";
    profile.photoBase64 = "";
    profile.salutation = "";
    profile.suffix = "";
    this.profileService.newProfile(profile).subscribe(
      p => {
        //connect to profile to company...
        this.profileService.connectToCompany(p.userId, companyid, "Agent").subscribe(
          pc => {
            //push new profile to the list after profile object created...
            p.userCompany.push(pc);
            this.addProfileToList(p);
          },
          error => {
            this.alertService.showStickyMessage('Load Error', `Unable to connect profile to company.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
              MessageSeverity.error, error);
          }
        );
      },
      error => {
        this.alertService.showStickyMessage('Load Error', `Unable to create profile.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);
      }
    )
  }

  private addProfileToList(profile: Profile) {
    if (this.dataSource.data.filter(p => p.userId == profile.userId).length > 0)
      this.dataSource.data.filter(p => p.userId == profile.userId)[0] = profile;
    else 
      this.dataSource.data.push(profile);
    this.refresh();
  }

  private changeRole(profile: Profile) {
    const dialogRef = this.dialog.open(CompanyRoleAssignmentDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { profile: profile, companyId: this.companyId }
      });
    dialogRef.afterClosed().subscribe(profile => {
      if (profile) {
        this.addProfileToList(profile);
      }
    });
  }

}
