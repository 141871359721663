<div *ngIf="itemType == 'material'">
  <div fxLayout="row">{{item.material ? item.material.intro : ''}}</div>
</div>
<div *ngIf="itemType == 'content'">
  <div fxLayout="row">{{item.materialContent ? item.materialContent.description : ''}}</div>
  <p><mat-divider *ngIf="item.materialContent && item.materialContent.content"></mat-divider></p>
  <div fxLayout="column" *ngIf="item.materialContent && item.materialContent.content" [innerHTML]="(item.materialContent ? item.materialContent.content : '') | dompurify"></div>
</div>
<div *ngIf="itemType == 'test'">
  <div fxLayout="row">{{item.test ? item.test.intro : ''}}</div>
</div>
<div *ngIf="itemType == 'question'">
  <form #form=ngForm [formGroup]="answerForm" autocomplete="off">
    <div *ngIf="!continuing" [@fadeThrough]>
      <div fxLayout="row">{{item.question ? item.question.title : ''}}</div>
      <p><mat-divider></mat-divider></p>
      <div fxLayout="row" *ngIf="answerType.code == 'TXT'">
        <mat-form-field [floatLabel]="auto" fxFlex="100%">
          <mat-label>{{item.answer ? item.answer.title : ''}}</mat-label>
          <textarea matInput formControlName="answer" [readonly]="false" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutGap="16px" *ngIf="useSelectionAnswer">
        <mat-label>{{item.answer ? item.answer.title : ''}}</mat-label>
        <mat-radio-group class="radio-group" formControlName="selectionAnswer">
          <mat-radio-button class="radio-button" *ngFor="let val of selectionValues" [value]="val">
            {{val}}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxLayout="row" *ngIf="useCheckboxAnswer">
        <mat-checkbox formControlName="checkboxAnswer">{{item.answer ? item.answer.title : ''}}</mat-checkbox>
      </div>
      <div fxLayout="row" *ngIf="useMultiselectAnswer">
        <mat-form-field [floatLabel]="auto" fxFlex="100%">
          <mat-label>{{item.answer ? item.answer.title : ''}}</mat-label>
          <mat-select formControlName="multiselectAnswer" multiple>
            <mat-option *ngFor="let val of selectionValues" [value]="val">{{val}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutGap="16px" *ngIf="useSelectionButtons">
        <div fxFlex="100%">
          <mat-label>{{item.answer ? item.answer.title : ''}}</mat-label>
        </div>
        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="16px">
          <button mat-raised-button class="sel-button" fxFlex="100%" fxFlex.xs="100%" *ngFor="let val of selectionSet" (click)="setAnswer(val.code, true);" [disabled]="continuing" color="{{itemType == 'question' && val.code == answerControl.value ? 'accent' : 'default'}}">
            {{val.title}}
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
<br />
<div fxLayout="row" *ngIf="!continuing" [@fadeThrough]>
  <div>
    <button mat-button type="button" *ngIf="showBack" (click)="moveBack()">{{'invitations.commands.Back' | translate}}</button>
    <span *ngIf="showBack && showNext">|</span>
    <button mat-button type="button" *ngIf="showNext" (click)="moveForward()">{{'invitations.commands.Next' | translate}}</button>
  </div>
  <div fxFlex="auto"></div>
  <div>
    <button mat-raised-button *ngIf="!useSelectionButtons" (click)="continue();" [disabled]="continuing" color="{{itemType == 'question' && isDirty == true ? 'primary' : 'default'}}">{{itemType == 'question' && isDirty == true ? ('taskItemControl.Save' | translate) : ('taskItemControl.Okay' | translate)}}</button>
  </div>
</div>
