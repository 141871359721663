<div id="app_container" [class.is-mobile]="mobileQuery.matches" class="nav-container">

  <mat-sidenav-container class="app-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">

    <mat-sidenav #mainNav class="app-sidenav-nav border rounded"
                 [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches"
                 fixedTopGap="56"
                 [opened]="!mobileQuery.matches && isUserLoggedIn">
      <mat-nav-list *ngIf="isUserLoggedIn">
        <a href="http://www.360solutions.com">
          <img src="{{'./assets/images/360-Solutions-Logo-White-Outline.png'}}" class="logo-img" />
        </a>
        <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink=""><mat-icon>dashboard</mat-icon>{{'mainMenu.Home' | translate}}</a>
        <a mat-list-item *ngIf="!isCompanyAgent" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/company"><mat-icon>business_center</mat-icon>{{'mainMenu.CompanyHome' | translate}}</a>
        <mat-expansion-panel *ngIf="canAccessOrgTools" #orgTools [class.mat-elevation-z0]="!orgTools.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>people</mat-icon>{{'mainMenu.Organization' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/company/individuals"><mat-icon>people</mat-icon>{{'mainMenu.Individuals' | translate}}</a>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/company/teams"><mat-icon>people_outline</mat-icon>{{'mainMenu.Teams' | translate}}</a>

        </mat-expansion-panel>


        <mat-expansion-panel *ngIf="canAccessInviteTools" #sendTools [class.mat-elevation-z0]="!sendTools.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>send</mat-icon>{{'mainMenu.Invitations' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/invitation/send"><mat-icon>mail_outline</mat-icon>{{'mainMenu.SendInvite' | translate}}</a>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/invitations"><mat-icon>send</mat-icon>{{'mainMenu.Sent' | translate}}</a>
          <a mat-list-item (click)="sendP60()"><mat-icon>contact_mail</mat-icon>{{'mainMenu.SendP60' | translate}}</a> <!--*ngIf="canSendP60Link"-->

        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="canAccessReportTools" #reportTools [class.mat-elevation-z0]="!reportTools.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>description</mat-icon>{{'mainMenu.ReportTools' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/company/reports"><mat-icon>description</mat-icon>{{'mainMenu.Reports' | translate}}</a>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/company/benchmarklist"><mat-icon>graphic_eq</mat-icon>{{'mainMenu.Benchmarks' | translate}}</a>
          <a *ngIf="is360" mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/company/p60optionlist"><mat-icon>widgets</mat-icon>{{'mainMenu.P60Options' | translate}}</a>

        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="canAccessCreatorTools" #creatorTools [class.mat-elevation-z0]="!creatorTools.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>group_work</mat-icon>{{'mainMenu.CreatorTools' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <a *ngIf="is360" mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/materials/aptitudes"><mat-icon>ballot</mat-icon>{{'mainMenu.AptitudeBuilder' | translate}}</a>
          <a *ngIf="is360" mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/materials/assessments"><mat-icon>assessment</mat-icon>{{'mainMenu.AssessmentBuilder' | translate}}</a>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/materials/surveys"><mat-icon>list</mat-icon>{{'mainMenu.SurveyBuilder' | translate}}</a>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/materials/materials"><mat-icon>chrome_reader_mode</mat-icon>{{'mainMenu.MaterialBuilder' | translate}}</a>
          <a mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/courses"><mat-icon>group_work</mat-icon>{{'mainMenu.CourseBuilder' | translate}}</a>

        </mat-expansion-panel>


        <mat-expansion-panel *ngIf="canViewUsers || canViewRoles || isAdmin" #admin [class.mat-elevation-z0]="!admin.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>security</mat-icon>Admin
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a mat-list-item routerLinkActive="active" *ngIf="canViewUsers" routerLink="/admin/users"><mat-icon>people</mat-icon>Users</a>
          <a mat-list-item routerLinkActive="active" *ngIf="canViewRoles" routerLink="/admin/roles"><mat-icon>assignment_ind</mat-icon>Roles</a>
          <a mat-list-item routerLinkActive="active" routerLink="/companies"><mat-icon>business_center</mat-icon>{{'mainMenu.Companies' | translate}}</a>
          <a mat-list-item routerLinkActive="active" routerLink="/legacy/clients"><mat-icon>eco</mat-icon>{{'mainMenu.LegacyClients' | translate}}</a>

        </mat-expansion-panel>

        <!--<a mat-list-item routerLinkActive="active" *ngIf="canViewCustomers" routerLink="/customers"><mat-icon>contacts</mat-icon>{{'mainMenu.Customers' | translate}}</a>
  <a mat-list-item routerLinkActive="active" *ngIf="canViewProducts" routerLink="/products"><mat-icon>local_shipping</mat-icon>{{'mainMenu.Products' | translate}}</a>
  <a mat-list-item routerLinkActive="active" *ngIf="canViewOrders" routerLink="/orders"><mat-icon>shopping_cart</mat-icon>{{'mainMenu.Orders' | translate}}</a>-->

      </mat-nav-list>
      <mat-nav-list>
        <a mat-list-item routerLinkActive="active" routerLink="/about"><mat-icon>info</mat-icon>{{'mainMenu.About' | translate}}</a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="app-main-content-area">
      <mat-toolbar ngPreserveWhitespaces color="{{(isOnline$ | async) ? 'primary' : 'warn'}}" class="app-toolbar">
        <button mat-icon-button (click)="mainNav.toggle()" *ngIf="isUserLoggedIn"><mat-icon>menu</mat-icon></button>
        <img src="{{companyLogo}}" class="logo-img" (click)="gotoCompany()" />
        <h1 class="app-name"><a routerLink="/" class="appTitle">{{appTitle}}</a></h1>
        <div *ngIf="!(isOnline$ | async)" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%" matTooltip="{{ 'status.NoConnection' | translate}}">
          <mat-icon>signal_wifi_off</mat-icon>
        </div>
        <span class="app-toolbar-spacer"></span>
        <!--<app-theme-picker tooltip="{{'mainMenu.Theme' | translate}}"></app-theme-picker>-->

        <div fxLayout="row" fxLayoutGap="4px">
          <button mat-mini-fab color="default" *ngIf="isUserLoggedIn && isCompanyAgent" (click)="sendP60()" matTooltip="{{'mainMenu.SendP60' | translate}}">
            <mat-icon>contact_mail</mat-icon>
          </button>
          <button *ngIf="!isUserLoggedIn" mat-button (click)="router.navigateByUrl('/login')">
            <mat-icon>lock_open</mat-icon>
            <span>{{'mainMenu.Login' | translate}}</span>
          </button>
          <button *ngIf="isUserLoggedIn"
                  color="default"
                  mat-mini-fab
                  class="fab-image"
                  [matMenuTriggerFor]="accountMenu"
                  matTooltip="{{'mainMenu.UserAccount' | translate}}"
                  [style.backgroundImage]="'url(' + (photoBase64)  + ')'">
            <mat-icon *ngIf="!photoBase64" class="menu-button">account_circle</mat-icon>
          </button>
        </div>
        <mat-menu #accountMenu="matMenu">
          <button mat-menu-item (click)="gotoProfile()">
            <mat-icon>account_circle</mat-icon>
            <span>{{'mainMenu.MyProfile' | translate}}</span>
          </button>
          <div mat-menu-item>
            <app-company-selector #selectProfileCompany></app-company-selector>
          </div>
          <!--<div mat-menu-item>
    <app-theme-selector></app-theme-selector>
  </div>-->
          <div mat-menu-item>
            <app-language-selector></app-language-selector>
          </div>
          <button mat-menu-item (click)="resetPassword()">
            <mat-icon>settings</mat-icon><span>{{'mainMenu.ResetPassword' | translate}}</span>
          </button>
          <!--
    TODO: Add a control to launch a password change form...
    TODO: Add a language dropdown...


  <button mat-menu-item (click)="router.navigateByUrl('/settings')">
    <mat-icon>person</mat-icon><span>{{'mainMenu.Profile' | translate}}</span>
  </button>
  <button mat-menu-item (click)="router.navigateByUrl('/settings#preferences')">
    <mat-icon>settings</mat-icon><span>{{'mainMenu.Settings' | translate}}</span>
  </button>-->
          <button mat-menu-item (click)="logout()">
            <mat-icon>lock</mat-icon><span>{{'mainMenu.Logout' | translate}}</span>
          </button>
        </mat-menu>

      </mat-toolbar>

      <div fxLayout="column" fxFill>
        <div id="mainContent" fxFlex="auto">
          <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>

        <!--<div fxLayout="row" fxLayoutGap="2px" *ngIf="showSizeIndicators">
          <div fxFlex="100%" fxHide fxShow.lt-xs>lt-xs</div>
          <div fxFlex="100%" fxHide fxShow.xs>xs</div>
          <div fxFlex="100%" fxHide fxShow.gt-xs>gt-xs</div>
          <div fxFlex="100%" fxHide fxShow.lt-xs>lt-sm</div>
          <div fxFlex="100%" fxHide fxShow.sm>sm</div>
          <div fxFlex="100%" fxHide fxShow.gt-sm>gt-sm</div>
          <div fxFlex="100%" fxHide fxShow.lt-xs>lt-md</div>
          <div fxFlex="100%" fxHide fxShow.md>md</div>
          <div fxFlex="100%" fxHide fxShow.gt-md>gt-md</div>
          <div fxFlex="100%" fxHide fxShow.lt-xs>lt-lg</div>
          <div fxFlex="100%" fxHide fxShow.lg>lg</div>
          <div fxFlex="100%" fxHide fxShow.gt-lg>gt-lg</div>
          <div fxFlex="100%" fxHide fxShow.lt-xs>lt-xl</div>
          <div fxFlex="100%" fxHide fxShow.xl>xl</div>
          <div fxFlex="100%" fxHide fxShow.gt-xl>gt-xl</div>
          <div fxFlex="100%">mode: {{mode}}</div>
        </div>-->
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>

<ngx-toasta></ngx-toasta>
