<app-page-header title="TaskView" icon="play_arrow"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div fxLayout="column" fxLayoutGap="8px">
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px" fxFlex="100%">
        <div fxFlex="45px">
          <circle-progress [percent]="percent"
                           [radius]="16"
                           [outerStrokeWidth]="2"
                           [innerStrokeWidth]="1"
                           [outerStrokeColor]="'#78C000'"
                           [innerStrokeColor]="'#C7E596'"
                           [animation]="true"
                           [animationDuration]="300"
                           [space]="0"
                           [titleFontSize]="10"
                           [unitsFontSize]="10"
                           [showSubtitle]="false"
                           [startFromZero]="false"
                           [responsive]="false"
                           [backgroundColor]="'#FFFFFF'"></circle-progress>
        </div>
        <div fxFlex="100%">
          <h4>{{ taskName }}</h4>
        </div>
      </div>
      <div fxFlex="25%" fxLayout="row" fxLayoutGap="8px" fxFlex.lt-lg="auto">
        <div fxFlex="50%" class="mat-small" fxLayout="column">
          <div>{{ 'taskView.Created' | translate }}</div>
          <div>{{ created }}</div>
        </div>
        <div fxFlex="50%" class="mat-small" *ngIf="dueDate" fxLayout="column">
          <div>{{ 'taskView.DueDate' | translate }}</div>
          <div>{{ dueDate }}</div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div fxLayout="column">
      <div fxLayout="row">
        <h3>{{indexItem.taskItemName}}</h3>
      </div>
      <div fxLayout="row" fxLayoutGap="4px">
        <div fxFlex="100%" fxLayout="column">
          <div fxFlex="100%" fxLayoutAlign="center center">
            <app-task-item-control fxFlex="auto" *ngIf="indexItem.taskItemId" [taskItemId]="indexItem.taskItemId" [index]="indexPosition" [showBack]="showBack" [showNext]="showNext" (progressSaved)="progressSaved($event)" (answerChanged)="answerChanged($event)" (back)="moveBack()" (forward)="moveForward()"></app-task-item-control>
          </div>
        </div>
      </div>
      <!--<div fxLayout="row">
        <button mat-button type="button" *ngIf="showBack" (click)="moveBack()">{{'invitations.commands.Back' | translate}}</button>
        <div fxFlex="auto"></div>
        <button mat-button type="button" *ngIf="showNext" (click)="moveForward()">{{'invitations.commands.Next' | translate}}</button>
      </div>-->
    </div>
  </div>
</div>
