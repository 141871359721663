<div fxFlex="100%" fxLayout="row" fxLayoutGap="8px" class="keep-top">
  <div fxFlex="100%" fxLayout="row" fxLayoutGap="8px">
    <mat-form-field [hideRequiredMarker]="true" floatLabel="auto" fxFlex="25%">
      <mat-label>{{'answerValueControl.Code' | translate}}</mat-label>
      <input matInput type="text" [(ngModel)]="editObj.code" maxlength="25" required [readonly]="!canEditCode" />
    </mat-form-field>
    <mat-form-field [hideRequiredMarker]="true" floatLabel="auto" fxFlex="100%">
      <mat-label>{{'answerValueControl.Title' | translate}}</mat-label>
      <textarea matInput type="text" [(ngModel)]="editObj.title" required [readonly]="!canEditTitle" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
    </mat-form-field>
    <mat-form-field [hideRequiredMarker]="true" floatLabel="auto" fxFlex="15%">
      <mat-label>{{'answerValueControl.Points' | translate}}</mat-label>
      <input matInput type="number" min="0" max="100" step=".25" [(ngModel)]="editObj.pointValue" required />
    </mat-form-field>
  </div>
  <div *ngIf="answerType.code != 'T/F'" fxFlex="120px" class="align-right">
    <button mat-icon-button *ngIf="!(position == 'first' || position == 'single')" (click)="moveUp()" matTooltip="{{'commands.Up' | translate}}">
      <mat-icon>arrow_upward</mat-icon>
    </button>
    <button mat-icon-button *ngIf="!(position == 'last' || position == 'single')" (click)="moveDown()" matTooltip="{{'commands.Down' | translate}}">
      <mat-icon>arrow_downward</mat-icon>
    </button>
    <button mat-icon-button *ngIf="position != 'single'" color="warn" (click)="delete()" matTooltip="{{'commands.Delete' | translate}}">
      <mat-icon>delete</mat-icon>
    </button>
  </div>
</div>
