<h1 mat-dialog-title>
  {{ dialogTitle }}
</h1>
<div mat-dialog-content fxLayout="column">

  <form #form="ngForm" [formGroup]="editorForm" novalidate (ngSubmit)="continue()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

    <div fxLayout="column" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'p60OptionEditor.Title' | translate}}</mat-label>
        <input matInput formControlName="optionTitle" [readonly]="!isEditMode" />
        <mat-error *ngIf="optionTitle.hasError('required')">
          {{'p60OptionEditor.Required' | translate}}
        </mat-error>
      </mat-form-field>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
        <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
          <mat-label>{{'p60OptionEditor.Code' | translate}}</mat-label>
          <input matInput formControlName="code" [readonly]="!isEditMode" maxlength="25" />
        </mat-form-field>

        <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
          <mat-label>{{'p60OptionEditor.Language' | translate}}</mat-label>
          <mat-select formControlName="languageCode" [disabled]="!isEditMode">
            <mat-option *ngFor="let lc of languages" [value]="lc.code">
              <span matTooltip="{{lc.title}}">{{lc.title + ' (' + lc.code + ')'}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="languageCode.hasError('required')">
            {{'p60OptionEditor.Required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxFlex="100%">
        <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
          <mat-label>{{'materialHeaderControl.Template' | translate}}</mat-label>
          <mat-select formControlName="template" [disabled]="!isEditMode">
            <mat-option [value]="false">{{'materialHeaderControl.TemplateNo' | translate}}</mat-option>
            <mat-option [value]="true">{{'materialHeaderControl.TemplateYes' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- https://github.com/angular/material2/issues/8798 -->
    <div>&nbsp;</div>
  </form>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="continue();" color="primary">{{'commands.Continue' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
