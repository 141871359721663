<form #form="ngForm" [formGroup]="roleForm" novalidate (ngSubmit)="save()" fxLayout="column" fxLayoutGap="1em" autocomplete="off">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
        <mat-form-field fxFlex="100%">
            <mat-label>{{'roles.editor.Name' | translate}}</mat-label>
            <input matInput formControlName="name" [readonly]="!canManageRoles" />
            <mat-error *ngIf="name.hasError('required')">
                {{'roles.editor.RoleNameRequired' | translate}}
            </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex="100%">
            <mat-label>{{'roles.editor.Description' | translate}}</mat-label>
            <input matInput formControlName="description" [readonly]="!canManageRoles" />
        </mat-form-field>
    </div>

    <mat-expansion-panel *ngFor="let permissionGroup of allPermissions | groupBy: 'groupName'" class="simple-border">
        <mat-expansion-panel-header color="primary">
            <mat-panel-title>
                {{permissionGroup.key}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-slide-toggle [disabled]="!canManageRoles" *ngFor="let permission of permissionGroup.value"
                          matTooltip="{{permission.description}}"
                          (click)="$event.stopPropagation()"
                          (change)="$event ? selectedPermissions.toggle(permission) : null"
                          [checked]="selectedPermissions.isSelected(permission)">
            {{permission.name}}
        </mat-slide-toggle>
    </mat-expansion-panel>
    <br />
</form>
