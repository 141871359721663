import { Component, OnInit, ViewChild, Input, OnChanges, OnDestroy, Inject, AfterViewInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { P60option } from 'src/app/models/p60option.model';
import { XrLanguageCode } from 'src/app/models/xr-language-code.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { XrService } from 'src/app/services/xr.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { P60OptionValueSetControlComponent } from '../../controls/p60-option-value-set-control/p60-option-value-set-control.component';

@Component({
  selector: 'app-p60-option-editor-dialog',
  templateUrl: './p60-option-editor-dialog.component.html',
  styleUrls: ['./p60-option-editor-dialog.component.scss']
})
export class P60OptionEditorDialogComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChild('form') private form: NgForm;

  isNew = false;
  private isSaving = false;
  private onOptionSetSaved = new Subject<P60option>();

  @Input() option: P60option = new P60option(0, "(new)");
  @Input() isEditMode = false;

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  editorForm: FormGroup;
  optionSetSaved$ = this.onOptionSetSaved.asObservable();

  //add field properties...
  get dialogTitle() {
    if (this.isEditMode) 
      return this.editorForm.get('optionTitle').value;
    else
      return this.translationService.getTranslation("p60OptionEditor.NewTitle");
  }
  get optionTitle() {
    return this.editorForm.get('optionTitle');
  }
  get code() {
    return this.editorForm.get('code');
  }
  get languageCode() {
    return this.editorForm.get('languageCode');
  }
  get template() {
    return this.editorForm.get('template');
  }

  get languages() {
    return this.xrService.getLanguageCodes();
  }

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private xrService: XrService,
    private companiesService: CompaniesService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<P60OptionEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { option: P60option, isEditMode: boolean }) {

    this.option = this.data.option;
    this.isEditMode = this.data.isEditMode;
  }

  ngOnInit() {
    this.xrService.getLanguageCodes(); //primes the request...

    this.buildForm();
    this.handleChange();
  }

  ngOnChanges() {
    this.handleChange();
  }

  ngOnDestroy() { }

  ngAfterViewInit() {
    
  }

  private handleChange() {
    if (this.option) {
      if (this.option.p60optionId > 0)
        this.isNew = false;
      else
        this.isNew = true;
    } else {
      this.isNew = true;
      //create new p60option object?
    }

    this.resetForm();
  }

  private buildForm() {
    this.editorForm =
      this.formBuilder.group({
        optionTitle: ['', Validators.required],
        code: ['', Validators.maxLength(25)],
        languageCode: ['en', Validators.required],
        companyId: 0,
        template: false
      });
  }

  private resetForm(stopEditing: boolean = false) {
    if (stopEditing)
      this.isEditMode = false;

    if (!this.option || this.isNew) {
      this.isNew = true;
      //create new p60option object?
    }

    if (this.isNew) {
      //no special logic, yet.
    }

    this.editorForm.reset({
      optionTitle: this.option.optionTitle || '',
      code: this.option.code || '',
      languageCode: this.option.languageCode,
      companyId: this.option.companyId,
      template: this.option.template,
      isNew: this.isNew
    });
  }

  public beginEdit() {
    this.isEditMode = true;
  }

  private getEditedOption(): P60option {
    var formModel = this.editorForm.value;
    formModel.p60optionId = this.option.p60optionId;
    formModel.companyID = this.option.companyId;

    return (JSON.parse(JSON.stringify(formModel))); //the JSOn presents a clone...
  }

  public save() {
    if (!this.form.submitted) {
      // Causes validation to update.
      this.form.onSubmit(null);
      return;
    }

    if (!this.editorForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    const editedOption = this.getEditedOption();

    this.companiesService.patchRecordP60Option(editedOption)
      .subscribe(
        option => this.saveCompleted(option),
        error => this.saveFailed(error)
      );
  }

  private saveCompleted(option?: P60option) {
    if (option) {
      //add downstream event calls here, if needed.
      this.option = option;
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.resetForm(true);
    this.onOptionSetSaved.next(this.option);

    this.dialogRef.close(this.option);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);   
  }

  continue() {
    this.save();
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
