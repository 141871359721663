import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { AnswerValue } from 'src/app/models/answer-value.model';
import { XrService } from 'src/app/services/xr.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { XrAnswerType } from 'src/app/models/xr-answer-type.model';
import { AnswerEditorDialogComponent } from '../../dialogs/answer-editor-dialog/answer-editor-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-answer-view-control',
  templateUrl: './answer-view-control.component.html',
  styleUrls: ['./answer-view-control.component.scss']
})
export class AnswerViewControlComponent implements OnInit {
  @Input() answer: Answer = new Answer;
  @Input() position: string = "";

  @Output() onDelete = new EventEmitter<Answer>();
  @Output() onMoveUp = new EventEmitter<Answer>();
  @Output() onMoveDown = new EventEmitter<Answer>();

  @Output() onValueDelete = new EventEmitter<AnswerValue>();

  get editObj(): Answer {
    if (this.answer)
      return this.answer;
    return new Answer;
  }

  get answerTypes(): XrAnswerType[] {
    const ats = this.xrService.getAnswerTypes();
    if (!ats) return []
    return this.xrService.getAnswerTypes().filter(x => x.code != 'XR');
  }

  get answerType(): XrAnswerType {
    if (this.answer) {
      if (!this.answer.answerType || this.answer.answerTypeId != this.answer.answerType.answerTypeId) {
        this.answer.answerType = this.answerTypes.find(x => x.answerTypeId == this.answer.answerTypeId);
      }

      return this.answer.answerType || new XrAnswerType();
    }

    return <XrAnswerType>({ code: "???", title: "guess" }); //TXT
  }

  get useFreeTextLimit(): boolean {
    if (!this.answerType) return false;
    return this.answerType.code == "TXT";
  }

  get answerValues(): AnswerValue[] {
    if (!this.editObj.answerValue || this.editObj.answerValue.length <= 0) {
      this.editObj.answerValue = [];
    }

    if (this.answerType) {
      if (this.answerType.code == "T/F")
        this.prepAnswersTF();
      else if (this.editObj.answerValue.length <= 0 && (this.answerType.code != "TXT" && this.answerType.code != "CHK")) {
        const t = <AnswerValue>({
          answerValueId: this.newId,
          answerId: this.editObj.answerId,
          code: '1',
          title: this.translateService.getTranslation("testEditor.NewAnswer"),
          pointValue: 1.0,
          ordinal: 100,
          deleted: false
        });
        this.editObj.answerValue.push(t);
      }
    }

    this.editObj.answerValue = this.editObj.answerValue.filter(av => av.deleted == false);

    return this.editObj.answerValue;
  }

  get useAnswerValues(): boolean {
    if (!this.answerValues) return false;
    return this.answerValues.length > 0 || (this.answerType && !["CHK", "TXT"].find(x => x == this.answerType.code));
  }

  get canAddAnswerValue(): boolean {
    if (!this.useAnswerValues) return false;
    return this.answerType.code != 'T/F';
  }

  constructor(
    private xrService: XrService,
    private translateService: AppTranslationService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  delete() {
    this.onDelete.emit(this.answer);
  }
  moveUp() {
    this.onMoveUp.emit(this.answer);
  }
  moveDown() {
    this.onMoveDown.emit(this.answer);
  }

  valuePosition(av: AnswerValue) {
    var index = this.answerValues.indexOf(av);

    var first: boolean = index == 0;
    var last: boolean = index == (this.answerValues.length - 1);

    return (first == true && last == true ? "single" : first == true ? "first" : last == true ? "last" : "");
  }

  get newId(): number {
    var minid = 0;
    if (this.editObj.answerValue)
      if (this.editObj.answerValue.length > 0)
        minid = this.editObj.answerValue.map(x => x.answerValueId).sort((a, b) => a > b ? 1 : -1)[0];

    minid = (minid <= 0 ? minid - 1 : -1);

    return minid;
  }

  private prepAnswersTF() {

    const t = <AnswerValue>({
      answerValueId: this.newId,
      answerId: this.editObj.answerId,
      code: 'true',
      title: this.translateService.getTranslation("answerControl.True"),
      pointValue: 1.0,
      ordinal: 100,
      deleted: false
    });
    const texists = this.editObj.answerValue.filter(av => av.code == 'true').length > 0;
    if (!texists)
      this.editObj.answerValue.push(t);

    const f = <AnswerValue>({
      answerValueId: this.newId,
      answerId: this.editObj.answerId,
      code: 'false',
      title: this.translateService.getTranslation("answerControl.False"),
      pointValue: 0.0,
      ordinal: 200,
      deleted: false
    });
    const fexists = this.editObj.answerValue.filter(av => av.code == 'false').length > 0;
    if (!fexists)
      this.editObj.answerValue.push(f);

    var extra = this.editObj.answerValue.filter(x => !(x.code == 'true' || x.code == 'false'));
    extra.forEach(av => {
      var index = this.editObj.answerValue.findIndex(x => x.answerValueId == av.answerValueId);
      if (index >= 0)
        this.onValueDelete.emit(this.editObj.answerValue.splice(index, 1)[0]);
    });
  }

  launchEditor() {
    const dialogRef = this.dialog.open(AnswerEditorDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: {
          title: this.editObj.title,
          answer: this.answer,
          onValueDelete: this.onValueDelete
        }
      });
    dialogRef.afterClosed().subscribe(a => { });
  }
}
