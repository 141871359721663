// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Component, NgModule, Input, AfterViewInit, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, AfterViewInit {
  @Input()
  title: string;

  @Input()
  icon: string;

  constructor(
    private translator: AppTranslationService
  ) {

  }

  ngOnInit() {
    this.title = this.translator.getTranslation('pageHeader.' + this.title);
    this.title = this.title.replace('pageHeader.', '');
  }

  ngAfterViewInit() {
  }
}
