import { Component, OnInit } from '@angular/core';
import { P60option } from 'src/app/models/p60option.model';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { MessageSeverity, AlertService } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';
import { Company } from 'src/app/models/company';
import { ConfigurationService } from '../../../../services/configuration.service';

@Component({
  selector: 'app-company-p60-option-selector-control',
  templateUrl: './company-p60-option-selector-control.component.html',
  styleUrls: ['./company-p60-option-selector-control.component.scss']
})
export class CompanyP60OptionSelectorControlComponent implements OnInit {

  dataSource: P60option[];
  selectedValue: P60option;

  loadingIndicator: boolean = false;

  private onOptionSelected = new Subject<P60option>();
  optionSelected$ = this.onOptionSelected.asObservable();

  public nooption = new P60option(0, this.translateService.getTranslation("companyReports.options.NoOption"));

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private translateService: AppTranslationService,
    private alertService: AlertService,
    private configuration: ConfigurationService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loadingIndicator = true;

    this.authService.companySelected$.subscribe(
      company => this.companyChanged(company)
    );

    if (this.authService.currentUserCompany && this.authService.currentUserCompany != null)
      this.makeCall(this.authService.companyId);
  }

  companyChanged(company: Company) {
    if (!company) return;
    this.makeCall(company.companyId);
  }
  
  makeCall(companyId: number) {
    if (companyId < 0) return;

    this.companiesService
      .getP60Options(companyId)
      .subscribe(options =>
        this.onDataLoadSuccessful(options),
        error => this.onDataLoadFailed(error)
      );
  }

  onDataLoadSuccessful(options: P60option[]): void {
    this.loadingIndicator = false;
    this.dataSource = [this.nooption];
    if (options) {
      //add the standard format for the selected language...
      var stdcode = 'STD-' + this.configuration.language.toUpperCase();
      this.dataSource = options.filter(x => x.code == stdcode);

      //add the standard format for English (if not already added...)
      this.dataSource =
        this.dataSource.concat(options.filter(x => x.code == 'STD-EN' && this.dataSource.indexOf(x) < 0));

      //add other formats for the selected language...
      this.dataSource = this.dataSource.concat(
        options
          .filter(x =>
            x.languageCode == this.configuration.language
            && this.dataSource.indexOf(x) < 0)
          .sort((a, b) => a.optionTitle > b.optionTitle ? 1 : -1));

      //add formats for all other languages...
      this.dataSource = this.dataSource.concat(
        options
          .filter(x =>
            x.languageCode != this.configuration.language
            && this.dataSource.indexOf(x) < 0)
          .sort((a, b) => a.optionTitle > b.optionTitle ? 1 : -1));
    }

    this.selectedValue = this.dataSource[0];
    this.selectionMade();
  }

  onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve options from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  selectionMade() {
    this.onOptionSelected.next(this.selectedValue);
  }
}
