import { Injectable } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { Course } from '../models/course.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint,
    private authService: AuthService
  ) { }

  //getCourses() {
  //  const companyId: number = this.authService.companyId;
  //  return this.getCoursesByCompanyId(companyId);
  //}

  getCourse(id: number, includeall: boolean = false) {
    return this.applicationEndpoint.getCourseEndpoint<Course>(id, includeall);
  }

  getCoursesByCompanyId(companyId: number) {
    return this.applicationEndpoint.getCoursesByCompanyIdEndpoint<Course[]>(companyId);
  }

  getCourses(companyId: number, designation: string = "") {
    return this.applicationEndpoint.getCoursesEndpoint<Course[]>(companyId, designation);
  }

  copyCourse(courseId: number, toCompanyId: number = -1) {
    return this.applicationEndpoint.copyCourseEndpoint<Course>(courseId, toCompanyId);
  }

  deleteCourse(courseId: number) {
    return this.applicationEndpoint.deleteCourseEndpoint<Course>(courseId);
  }

  createCourse(designation: string, course: Course) {
    return this.applicationEndpoint.createCourseEndpoint<Course>(designation, course);
  }

  patchCourse(course: Course) {
    return this.applicationEndpoint.patchCourseEndpoint<Course>(course);
  }
}
