import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';
import { CampaignService } from 'src/app/services/campaign.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CampaignAnalysis } from '../../../models/campaign-analysis.model';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss'],
  animations: [fadeInOut]
})
export class InvitationsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'campaignName',
    'openDate',
    'participation',
    'progress',
    'closeDate',
    'actions'
  ];

  dataSource: MatTableDataSource<CampaignAnalysis>;
  sourceObject: CampaignAnalysis;
  loadingIndicator: boolean;

  get companyId(): number {
    if (!this.authService) return 0;
    if (!this.authService.currentUserCompany) return 0;
    return this.authService.companyId;
  }

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private campaignService: CampaignService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.campaignService.getCompanyCampaigns(this.companyId).subscribe(
      results => this.onDataLoadSuccessful(results),
      error => this.onDataLoadFailed(error));
  }


  private onDataLoadSuccessful(campaigns: CampaignAnalysis[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = campaigns;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve campaigns from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private showObject(campaign?: CampaignAnalysis) {
    if (!campaign) {
      campaign = new CampaignAnalysis();
    }

    this.sourceObject = campaign;

    this.router.navigateByUrl(`/campaign/analysis/${campaign.campaignId}`);

    //const dialogRef = this.dialog.open(CampaignViewDialogComponent,
    //  {
    //    panelClass: 'mat-dialog-lg',
    //    data: { campaign: campaign }
    //  });
    //dialogRef.afterClosed().subscribe(campaign => {
    //  if (campaign) {
    //    //this.updateCampaigns(campaign);
    //  }
    //});
  }
}
