import { IAuditableEntity } from "./IAuditableEntry.interface";
import { Profile } from "../models/profile";

export class UserTeam implements IAuditableEntity {
  public userTeamId: number;
  public userId: number;
  public companyId: number;
  public teamId: number;
  public initiated: Date;
  public dissolved: Date;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;

  public user: Profile;
}

export class Team implements IAuditableEntity {
  public teamId: number;
  public teamName: string;
  public companyId: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;

  public userTeam: UserTeam[];
}
