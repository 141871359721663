import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApplicationEndpoint } from './application-endpoint.service';
import { Company } from '../models/company';
import { CompanyEdit } from '../models/company-edit.model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ReportEntry } from '../models/report-entry.model';
import { P60benchmark } from '../models/p60benchmark';
import { P60option } from '../models/p60option.model';
import { P60optionValue } from '../models/p60option-value.model';
import { Profile } from '../models/profile';
import { CompanyCredit } from '../models/company-credit';
import { CompanyUsage } from '../models/company-usage.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private authService: AuthService,
    private applicationEndpoint: ApplicationEndpoint
  ) { }

  getCompanies() {
    return this.applicationEndpoint.getCompaniesEndpoint<Company[]>();
  }

  getCompany(companyId: number, includeCredits: boolean = false) {
    return this.applicationEndpoint.getCompanyEndpoint<Company>(companyId, includeCredits);
  }

  updateCompany(company: CompanyEdit) {
    if (company.companyId || company.companyId >= 0) {
      return this.applicationEndpoint.getUpdateCompanyEndpoint(company, company.companyId);
    }
    else {
      return this.newCompany(company);
    }
  }

  newCompany(company: CompanyEdit) {
    return this.applicationEndpoint.getNewCompanyEndpoint<Company>(company);
  }

  deleteCompany(companyOrCompanyId: number | Company): Observable<Company> {
    if (typeof companyOrCompanyId === 'number' || companyOrCompanyId instanceof Number) {
      return this.applicationEndpoint.getDeleteCompanyEndpoint<Company>(<number>companyOrCompanyId).pipe<Company>(
        tap(data => companyOrCompanyId));
    } else {
      return this.deleteCompany(companyOrCompanyId.companyId);
    }
  }

  getReports(companyId: number, days?: number) {
    return this.applicationEndpoint.getCompanyReportsEndpoint<ReportEntry[]>(companyId, days);
  }

  releaseReport(userTestId: number) {
    return this.applicationEndpoint.putReleaseReportEndpoint(userTestId);
  }

  getP60Benchmarks(companyId: number) {
    return this.applicationEndpoint.getP60BenchmarksEndpoint<P60benchmark[]>(companyId);
  }

  getP60Options(companyId: number) {
    return this.applicationEndpoint.getP60OptionsEndpoint<P60option[]>(companyId);
  }

  getP60Option(p60OptionId: number) {
    return this.applicationEndpoint.getP60OptionEndpoint<P60option>(p60OptionId);
  }

  patchRecordBenchmark(benchmark: P60benchmark) {
    return this.applicationEndpoint.patchRecordBenchmarkEndpoint<P60benchmark>(benchmark);
  }

  patchRecordP60Option(option: P60option) {
    return this.applicationEndpoint.patchRecordP60OptionEndpoint<P60option>(option);
  }

  patchRecordP60OptionValue(option: P60optionValue) {
    return this.applicationEndpoint.patchRecordP60OptionValueEndpoint<P60optionValue>(option);
  }

  deleteBenchmark(benchmarkId: number): Observable<P60benchmark> {
    return this.applicationEndpoint.deleteBenchmarkEndpoint(benchmarkId);
  }

  deleteP60Option(p60OptionId: number): Observable<P60option> {
    return this.applicationEndpoint.deleteP60OptionEndpoint(p60OptionId);
  }

  deleteP60OptionValue(p60OptionValueId: number): Observable<P60optionValue> {
    return this.applicationEndpoint.deleteP60OptionValueEndpoint(p60OptionValueId);
  }

  copyP60OptionValues(fromId: number, toId: number) {
    return this.applicationEndpoint.copyP60OptionValuesEndpoint(fromId, toId);
  }

  getProfilesByCompany(companyId: number) {
    return this.applicationEndpoint.getUsersByCompany<Profile[]>(companyId);
  }

  addCredits(companyId: number, credits: number) {
    return this.applicationEndpoint.addCreditsEndpoint<CompanyCredit[]>(companyId, credits);
  }

  setUnlimitedCreditStatus(companyId: number, unlimited: boolean) {
    return this.applicationEndpoint.setUnlimitedCreditStatusEndpoint<CompanyCredit[]>(companyId, unlimited);
  }

  getClientCompanies(companyId: number) {
    return this.applicationEndpoint.getClientCompaniesEndpoint<Company[]>(companyId);
  }

  connectCompany(companyId: number, clientCompanyId: number, partner: boolean = false) {
    return this.applicationEndpoint.connectCompanyEndpoint<Company>(companyId, clientCompanyId, partner);
  }

  disconnectCompany(companyId: number, clientCompanyId: number) {
    return this.applicationEndpoint.disconnectCompanyEndpoint<number>(companyId, clientCompanyId);
  }

  privilegedUsers(companyId: number) {
    return this.applicationEndpoint.privilegedUsers<Profile[]>(companyId);
  }

  getCredits(companyId: number) {
    return this.applicationEndpoint.getCreditsByCompany<CompanyCredit[]>(companyId);
  }

  getCompanyUsage(companyId: number): Observable<CompanyUsage[]> {
    return this.applicationEndpoint.getCompanyUsage(companyId);
  }
}
