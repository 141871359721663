import { Component, OnInit, Input } from '@angular/core';
import { Course } from 'src/app/models/course.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { XrLanguageCode } from 'src/app/models/xr-language-code.model';
import { XrService } from 'src/app/services/xr.service';

@Component({
  selector: 'app-course-header-control',
  templateUrl: './course-header-control.component.html',
  styleUrls: ['./course-header-control.component.scss']
})
export class CourseHeaderControlComponent implements OnInit {
  @Input() course: Course = new Course(-1, "");
  @Input() designation: string;

  get nameText(): string {
    if (this.designation == "course")
      return this.translationService.getTranslation("courseHeaderControl.CourseName");
    else 
      return this.translationService.getTranslation("courseHeaderControl.CourseName");
  }

  get editObj(): Course {
    if (this.course)
      return this.course;
    return new Course(-1, "");
  }

  get courseName(): string {
    return this.editObj.courseName;
  }
  set courseName(value: string) {
    this.editObj.courseName = value;
  }

  get template(): string {
    return (this.editObj.template == true ? "true" : "false")
  }
  set template(value: string) {
    this.editObj.template = value == "true";
  }

  get languageCode(): string {
    return this.editObj.languageCode;
  }
  set languageCode(value: string) {
    this.editObj.languageCode = value;
  }

  get languages(): XrLanguageCode[] {
    return this.xrService.getLanguageCodes();
  }

  constructor(
    private translationService: AppTranslationService,
    private xrService: XrService
  ) {
    this.xrService.getLanguageCodes(); //primes the request...
  }

  ngOnInit() {
  }

}
