import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { P60optionValue } from 'src/app/models/p60option-value.model';
import { KeyValueControlComponent, KeyValuePair } from 'src/app/components/controls/key-value-control/key-value-control.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CompaniesService } from 'src/app/services/companies.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-p60-option-value-editor-dialog',
  templateUrl: './p60-option-value-editor-dialog.component.html',
  styleUrls: ['./p60-option-value-editor-dialog.component.scss']
})
export class P60OptionValueEditorDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(KeyValueControlComponent) kvpcontrol: KeyValueControlComponent;

  option: P60optionValue;
  
  constructor(
    public companyService: CompaniesService,
    public alertService: AlertService,
    public dialogRef: MatDialogRef<P60OptionValueEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { option: P60optionValue }) {

    this.option = this.data.option;
  }

  ngOnInit() {
    this.kvpcontrol.loadPair(this.option.keyText, this.option.valueText);
  }

  ngAfterViewInit(): void {
    this.kvpcontrol.pairChanged$.subscribe(
      kvp => this.handleKVPChange(kvp)
    );
  }

  handleKVPChange(pair: KeyValuePair) {
    this.option.valueText = pair.value;
  }

  save() {
    this.alertService.startLoadingMessage("Saving...");

    this.companyService.patchRecordP60OptionValue(this.option).subscribe(
      result => this.onSaveSuccessful(result),
      error => this.onSaveFail(error)
    );
  }

  onSaveSuccessful(option: P60optionValue) {
    this.dialogRef.close(option);
  }
   
  onSaveFail(error: any) {
    this.alertService.stopLoadingMessage();
    
    this.alertService.showStickyMessage('Save Error', `Unable to save the new value.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
