import { IAuditableEntity } from "./IAuditableEntry.interface";
import { P60optionValue } from "./p60option-value.model";

export class P60option implements IAuditableEntity {
  constructor(id: number, name: string) {
    this.p60optionId = id;
    this.optionTitle = name;
  }  

  public p60optionId: number;
  public companyId: number;
  public template: boolean;
  public code: string;
  public optionTitle: string;
  public languageCode: string;
  public createdBy: string;
  public updatedBy: string;
  public createdDate: Date;
  public updatedDate: Date;

  public p60optionValue: P60optionValue[];
}
