
<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="12px">
    <div fxFlex="100%">
      <app-profile-card-control [userId]="userId" [getDefaultProfile]="useDefaultProfile"></app-profile-card-control>
    </div>
    <!--<div fxHide.lt-lg>
      <app-p60-result-control [userId]="userId" [getDefaultProfile]="useDefaultProfile"></app-p60-result-control>
    </div>-->
  </div>
  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="12px" fxFlex="100%">
    <app-task-list-control [userId]="userId" [getDefaultProfile]="useDefaultProfile" fxFlex="50%"></app-task-list-control>
    <app-profile-open-invitations-control [userId]="userId" [getDefaultProfile]="useDefaultProfile" fxFlex="50%"></app-profile-open-invitations-control>
  </div>
  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="12px" fxFlex="100%">
    <app-profile-report-list-control [userId]="userId" [getDefaultProfile]="useDefaultProfile" fxFlex="100%"></app-profile-report-list-control>
  </div>

</div>
