import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { NgForm, FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { InvitationContact, InvitationSender } from 'src/app/models/invitation-sender.model';
import { ContactTableControlComponent } from '../controls/contact-table-control/contact-table-control.component';
import { InvitationsService } from 'src/app/services/invitations.service';
import { AuthService } from 'src/app/services/auth.service';
import { Utilities } from 'src/app/services/utilities';
import { CoursesService } from 'src/app/services/courses.service';
import { MaterialsService } from 'src/app/services/materials.service';
import { Material } from 'src/app/models/material.model';
import { Course } from 'src/app/models/course.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
import { CampaignBuilder } from 'src/app/models/campaign-builder.model';
import { Team } from '../../../models/team.model';
import { CompaniesService } from '../../../services/companies.service';
import { TeamsService } from '../../../services/teams.service';

@Component({
  selector: 'app-send-invitation',
  templateUrl: './send-invitation.component.html',
  styleUrls: ['./send-invitation.component.scss'],
  animations: [fadeInOut]
})
export class SendInvitationComponent implements OnInit,AfterViewInit {
  @ViewChild('form')
  private form: NgForm;

  teamId: number;
  team: Team;

  formSubject: FormGroup;
  formOptions: FormGroup;

  @ViewChild(ContactTableControlComponent)
  editContacts: ContactTableControlComponent;

  displayedColumns = [
    'email',
    'firstName',
    'lastName'
  ];

  //TODO: Invitation Send - courses and materials may be impacted by company changes.  Subscribe to changes through app.component?

  private isCoursesLoading: boolean = false;
  private listCourses: Course[];
  get courseList() {
    if (!this.listCourses && this.isCoursesLoading == false) {
      this.isCoursesLoading = true;
      this.courseService.getCourses(this.authService.companyId, "")
        .subscribe(
          data => this.courseListLoaded(data));
    }

    return this.listCourses;
  }

  private isMaterialsLoading: boolean = false;
  private listMaterials: Material[];
  get materialList() {
    if (!this.listMaterials && this.isMaterialsLoading == false) {
      this.isMaterialsLoading = true;
      this.materialService.getTests(this.authService.companyId, "")
        .subscribe(
          data => this.materialListLoaded(data));
    }

    return this.listMaterials;
  }

  contactData: InvitationContact[] = [];

  get contactstring(): string {
    return this.contactData.map(function (c) { return `${c.firstName} ${c.lastName} <${c.email}>`; }).join("; ");
  }

  get subject() {
    return this.formSubject.get('subject');
  }
  get body() {
    return this.formSubject.get('body');
  }

  get bodyhtml() {
    return this.body.value;
  }

  get validContacts(): boolean {
    if (!this.contactData) return false;
    if (!this.contactData.length) return false;
    return this.contactData.length > 0;
  }

  get validSubjectAndBody(): boolean {
    if (!this.subject || !this.body)
      return false;

    return this.subject.valid && this.body.valid;
  }

  get validOptions(): boolean {
    if (!this.expirationDate)
      return false;
    if (!this.courseId.value && !this.materialId.value) return false;
       
    return this.expirationDate.valid && (this.courseId.value > 0 || this.materialId.value > 0);
  }

  minExp: Date = new Date(new Date().toLocaleDateString());

  get expirationDate() {
    return this.formOptions.get('expirationDate');
  }

  get expirationDateValue() {
    if (!this.expirationDate)
      return null;

    if (this.expirationDate.value == null)
      return null;

    var expDate: Date = null;
    if (!isNaN(Date.parse(this.expirationDate.value.toString()))) {
      expDate = new Date(this.expirationDate.value.toString());
      expDate.setDate(expDate.getDate() + 1); //add a day...
      expDate.setTime(expDate.getTime() - 1000); //remove a second...
    }

    return expDate;
  }

  get expirationDateValuePreview() {
    const expDate = this.expirationDateValue;

    enum dow { "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" };

    return dow[expDate.getDay()] + ', ' + expDate.toLocaleDateString() + ' @ ' + expDate.toLocaleTimeString();
  }

  get courseId() {
    return this.formOptions.get('courseId');
  }

  get materialId() {
    return this.formOptions.get('materialId');
  }

  get courseName() {
    if (!this.courseId)
      return "";
    else if (!this.courseId.value)
      return "";
    else if (this.courseId.value <= 0)
      return "";

    var course = this.courseList.filter(c => c.courseId == this.courseId.value)[0];
    return course.courseName;
  }

  get materialName() {
    if (!this.materialId)
      return "";
    else if (!this.materialId.value)
      return "";
    else if (this.materialId.value <= 0)
      return "";
    var material = this.materialList.filter(c => c.materialId == this.materialId.value)[0];
    return material.materialName;
  }

  get isCourseSelected() {
    if (!this.courseId)
      return false;
    else if (!this.courseId.value)
      return false;
    else if (this.courseId.value <= 0)
      return false;
    else
      return true;
  }

  get isMaterialSelected() {
    if (!this.materialId)
      return false;
    else if (!this.materialId.value)
      return false;
    else if (this.materialId.value <= 0)
      return false;
    else
      return true;
  }

  constructor(
    private authService: AuthService,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private inviteService: InvitationsService, 
    private formBuilder: FormBuilder,
    private courseService: CoursesService,
    private materialService: MaterialsService,
    private campaignService: CampaignService,
    private teamsService: TeamsService,
    public router: Router,
    private route: ActivatedRoute
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
    this.buildForm();
    if (this.teamId) {
      this.teamsService.getTeam(this.teamId, true).subscribe(result => {
        this.parseTeam(result);
      });
    }
  }

  ngAfterViewInit() {
    this.editContacts.contactsChanged$.subscribe(contacts => this.contactsChanged(contacts));
  }

  parseParams(params: Params) {
    this.teamId = params['teamId'];
  }

  parseTeam(team: Team) {
    var contacts: InvitationContact[] = [];
    team.userTeam.forEach(function (value) {
      contacts.push(new InvitationContact(value.user.email, value.user.firstName, value.user.lastName));
    });
    if (contacts.length > 0)
      this.contactsChanged(contacts);
  }

  private materialListLoaded(data: Material[]) {
    this.isMaterialsLoading = false;
    this.listMaterials = data;
    this.listMaterials.unshift(new Material(0, ""));
  }

  private courseListLoaded(data: Course[]) {
    this.isCoursesLoading = false;
    this.listCourses = data;
    this.listCourses.unshift(new Course(0, ""));
  }

  buildForm() {
    this.formSubject =
      this.formBuilder.group({
        subject: ['New Invite', Validators.required],
        body: ''
      });

    this.formOptions =
      this.formBuilder.group({
        expirationDate: null,
        courseId: null,
        materialId: null
      });
  }
  
  contactsChanged(contacts: InvitationContact[]) {
    this.contactData = contacts;
  }

  send() {
    var build = CampaignBuilder.Create(
      this.subject.value,
      'invitation',
      '',
      this.authService.companyId,
      null,
      this.expirationDateValue,
      (this.courseId.value ? [this.courseId.value] : []),
      (this.materialId.value ? [this.materialId.value] : [])
    );

    this.campaignService.buildCampaign(build).subscribe(
      (newbuild: any) => this.sendInvitation(newbuild.campaignId),
      error => this.invitesFailed(error)
    )
  }

  sendInvitation(campaignId?: number) {
    var message = this.body.value;
    if (message != '')
      message = Utilities.convertStringToHTML(message);

    var link = Utilities.baseUrl() + '/invitation/claim/';

    var invite = InvitationSender.Create(
      this.authService.currentUserProfile.userId,
      this.authService.companyId,
      this.subject.value,
      message,
      this.courseId.value,
      this.materialId.value,
      this.translationService.getCurrentLanguage(),
      link,
      this.expirationDateValue,
      this.contactData
    );

    if (campaignId)
      invite.campaignId = campaignId;

    this.alertService.startLoadingMessage('Sending...');

    this.inviteService.sendInvitations(invite).subscribe(
      () => this.invitesSent(invite),
      error => this.invitesFailed(error)
    );
  }

  invitesSent(invite: InvitationSender) {
    if (invite) {
      //add downstream event calls here, if needed.
      this.contactData = invite.contacts;
    }

    //this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.alertService.showMessage('Success', `Invitation sent!`, MessageSeverity.success);

    this.router.navigateByUrl('/invitations');

    //this.resetForm(true);
    //this.onCompanySaved.next(this.company);
  }

  invitesFailed(error: string) {
    //this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Sending Error', 'One or more errors occured whilst sending the invites:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);   
  }

  pauseSelected: boolean = false;


  //TODO: Invitation - Send - Figure out course/material exclusive selection.
  courseSelected() {
    //if (this.pauseSelected)
    //  return;

    //if (!this.courseId)
    //  return;
    //else if (!this.courseId.value)
    //  return;
    //else if (this.courseId.value <= 0)
    //  return;

    //if (!this.materialId)
    //  return;

    //this.pauseSelected = true;

    //this.materialId.patchValue(null);

    //this.pauseSelected = false;
  }

  materialSelected() {
    //if (this.pauseSelected)
    //  return;

    //if (!this.materialId)
    //  return;
    //else if (!this.materialId.value)
    //  return;
    //else if (this.materialId.value <= 0)
    //  return;

    //if (!this.courseId)
    //  return;

    //this.pauseSelected = true;

    //this.courseId.patchValue(null);

    //this.pauseSelected = false;
  }
}
