import { IAuditableEntity } from "./IAuditableEntry.interface";

export class XrAnswerType implements IAuditableEntity {
  public answerTypeId: number;
  public code: string;
  public title: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
}
