<div fxLayout="column" fxLayoutGap="8px">
  <div fxFlex="100%">
    <mat-form-field floatLabel="auto" fxFlex="100%">
      <mat-label>{{nameText}}</mat-label>
      <input matInput [(ngModel)]="materialName" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
    <div fxLayout="column" fxFlex="100%">
      <mat-form-field floatLabel="auto" fxFlex="100%">
        <mat-label>{{'materialHeaderControl.Template' | translate}}</mat-label>
        <mat-select [(ngModel)]="template">
          <mat-option value="false">{{'materialHeaderControl.TemplateNo' | translate}}</mat-option>
          <mat-option value="true">{{'materialHeaderControl.TemplateYes' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100%">
      <mat-form-field floatLabel="auto" fxFlex="100%">
        <mat-label>{{'materialHeaderControl.Language' | translate}}</mat-label>
        <mat-select [(ngModel)]="languageCode">
          <mat-option *ngFor="let lc of languages" [value]="lc.code">
            <span matTooltip="{{lc.title}}">{{lc.title + ' (' + lc.code + ')'}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div fxFlex="100%">
    <mat-form-field floatLabel="auto" fxFlex="100%">
      <mat-label>{{'materialHeaderControl.Intro' | translate}}</mat-label>
      <textarea matInput [(ngModel)]="editObj.intro" [readonly]="false" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
    </mat-form-field>
  </div>
</div>
