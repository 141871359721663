import { IAuditableEntity } from "./IAuditableEntry.interface";
import { CourseMaterial } from "./course-material.model";
import { Company } from "./company";

export class Course implements IAuditableEntity {
  constructor(id: number, name: string) {
    this.courseId = id;
    this.courseName = name;
    this.companyId = 0;
    this.designation = "";
    this.deleted = false;
    this.languageCode = "en";
    this.template = false;
  }

  public courseId: number;
  public courseName: string;
  public companyId: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public designation: string;
  public deleted: boolean;
  public languageCode: string;
  public template: boolean;

  public company: Company;
  public courseMaterial: CourseMaterial[];
}

export class CourseDisplay implements IAuditableEntity {
  public courseId: number;
  public courseName: string;
  public companyId: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public designation: string;
  public deleted: boolean;
  public languageCode: string;
  public template: boolean;

  public company: Company;
  public courseMaterial: CourseMaterial[];

  public companyName: string;
}
