import { Component, OnInit, Inject } from '@angular/core';
import { Course } from 'src/app/models/course.model';
import { XrLanguageCode } from 'src/app/models/xr-language-code.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CoursesService } from 'src/app/services/courses.service';
import { XrService } from 'src/app/services/xr.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-course-new-dialog',
  templateUrl: './course-new-dialog.component.html',
  styleUrls: ['./course-new-dialog.component.scss']
})
export class CourseNewDialogComponent implements OnInit {

  course: Course;
  companyId: number;
  designation: string;

  get languages(): XrLanguageCode[] {
    return this.xrService.getLanguageCodes();
  }

  get title(): string {
    if (this.designation == "course")
      return this.translationService.getTranslation("courseNewDialog.NewCourse");
    else
      return this.translationService.getTranslation("commands.New");
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { course: Course, companyId: number, designation: string },
    private courseService: CoursesService,
    private xrService: XrService,
    private translationService: AppTranslationService,
    private dialogRef: MatDialogRef<CourseNewDialogComponent>,
    private router: Router
  ) {
    this.course = this.data.course;
    this.companyId = this.data.companyId;
    this.designation = this.data.designation;
  }

  ngOnInit() {
    this.xrService.getLanguageCodes(); //primes the request...
  }

  cancel() {
    this.dialogRef.close(null);
  }

  continue() {
    //if (this.designation == "course") {
      this.courseService.createCourse(this.designation, this.course)
        .subscribe(
          result => this.courseCreated(result),
          error => this.handleError(error)
        );
    //}
    //else {
    //  this.courseService.createCourse(this.designation, this.course)
    //    .subscribe(
    //      result => this.testCreated(result),
    //      error => this.handleError(error)
    //    );
    //}
  }

  courseCreated(object: Course) {
    this.router.navigateByUrl(`/course/${object.courseId}`);
    this.dialogRef.close(null);
  }

  handleError(error: any) {

  }


}
