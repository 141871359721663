import { Component, OnInit, Inject } from '@angular/core';
import { Material } from 'src/app/models/material.model';
import { XrLanguageCode } from 'src/app/models/xr-language-code.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { XrService } from 'src/app/services/xr.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AlertService } from 'src/app/services/alert.service';
import { MaterialsService } from 'src/app/services/materials.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test-new-dialog',
  templateUrl: './test-new-dialog.component.html',
  styleUrls: ['./test-new-dialog.component.scss']
})
export class TestNewDialogComponent implements OnInit {

  material: Material;
  companyId: number;
  designation: string;

  get languages(): XrLanguageCode[] {
    return this.xrService.getLanguageCodes();
  }

  get title(): string {
    if (this.designation == "aptitude")
      return this.translationService.getTranslation("testNewDialog.NewAptitude");
    else if (this.designation == "assessment")
      return this.translationService.getTranslation("testNewDialog.NewAssessment");
    else if (this.designation == "survey")
      return this.translationService.getTranslation("testNewDialog.NewSurvey");
    else if (this.designation == "material")
      return this.translationService.getTranslation("testNewDialog.NewMaterial");
    else
      return this.translationService.getTranslation("commands.New");
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { material: Material, companyId: number, designation: string },
    private materialService: MaterialsService,
    private xrService: XrService,
    private translationService: AppTranslationService,
    private dialogRef: MatDialogRef<TestNewDialogComponent>,
    private router: Router
  ) {
    this.material = this.data.material;
    this.companyId = this.data.companyId;
    this.designation = this.data.designation;
  }

  ngOnInit() {
    this.xrService.getLanguageCodes(); //primes the request...
  }

  cancel() {
    this.dialogRef.close(null);
  }

  continue() {
    if (["aptitude","assessment","survey"].indexOf(this.designation) >= 0) {
      this.materialService.createTest(this.designation, this.material)
        .subscribe(
          result => this.testCreated(result),
          error => this.handleError(error)
        );
    }
    else {
      this.materialService.createMaterial(this.designation, this.material)
        .subscribe(
          result => this.testCreated(result),
          error => this.handleError(error)
        );
    }
  }

  testCreated(object: Material) {
    this.router.navigateByUrl(`/materials/test/${object.materialId}`);
    this.dialogRef.close(null);
  }

  handleError(error: any) {

  }

}
