<div class="search-box" fxLayout="row" fxLayout.lt-sm="column">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'individualsControl.SearchPrivileged' | translate}}">
  </mat-form-field>
  <div fxFlex="auto" fxHide.lt-sm></div>
  <div *ngIf="canEditRoles" fxLayoutGap="8px" fxLayout="column">
    <div fxFlex="auto" fxHide.lt-sm></div>
    <button mat-raised-button matTooltip="{{'individualsControl.NewAccount' | translate}}" (click)="newIndividual()">
      <mat-icon>person_add</mat-icon>&nbsp;&nbsp;{{'individualsControl.NewAccount' | translate}}
    </button>
    <div fxFlex="auto"></div>
  </div>
</div>

<div class="simple-border">
  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="lastName">
      <mat-header-cell fxFlex="40%" fxFlex.lt-sm="50%" *matHeaderCellDef mat-sort-header>
        <div fxHide.lt-sm>{{'individualsControl.LastName' | translate}}</div>
        <div fxHide.sm fxHide.gt-sm>{{'individualsControl.Name' | translate}}</div>
      </mat-header-cell>
      <mat-cell fxFlex="40%" fxFlex.lt-sm="50%" *matCellDef="let row">
        {{row.lastName}}
        <div fxHide.sm fxHide.gt-sm>, {{row.firstName}}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell fxFlex="35%" fxHide.lt-sm *matHeaderCellDef mat-sort-header> {{'individualsControl.FirstName' | translate}} </mat-header-cell>
      <mat-cell fxFlex="35%" *matCellDef="let row" fxHide.lt-sm> {{row.firstName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell fxFlex="25%" fxFlex.lt-sm="50%" *matHeaderCellDef mat-sort-header> {{'individualsControl.Role' | translate}} </mat-header-cell>
      <mat-cell fxFlex="25%" fxFlex.lt-sm="50%" *matCellDef="let row" matTooltip="{{roleDescription(row)}}">
        <span *ngIf="!canEditRoles">{{roleName(row)}}</span>
        <button *ngIf="canEditRoles" mat-raised-button (click)="changeRole(row)" color="default" fxFlex="100%">
          {{roleName(row)}}
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.xs="40px" fxFlexOffset="30px" fxFlexOffset.xs="2px">

      </mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.xs="40px" fxFlexOffset="30px" fxFlexOffset.xs="2px">
        <button mat-icon-button matTooltip="{{'individualsControl.DisplayProfile' | translate}}" (click)="launch(entry)">
          <mat-icon>visibility</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>


