<h1 mat-dialog-title>
  {{data.title || ('individualsSelectionDialog.Title' | translate)}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <app-individuals-selection-control #selectControl
                                     [companyId]="data.companyId"
                                     [displayProfiles]="data.displayProfiles"
                                     [pageSizeIncrements]="[5,10,25]">

  </app-individuals-selection-control>
  <div fxFlex="auto"></div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="select();" color="primary">{{'commands.Select' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
