import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Material } from '../../../../models/material.model';
import { MaterialsService } from '../../../../services/materials.service';
import { CompaniesService } from '../../../../services/companies.service';
import { Company } from '../../../../models/company';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { Utilities } from '../../../../services/utilities';

@Component({
  selector: 'app-material-share-dialog',
  templateUrl: './material-share-dialog.component.html',
  styleUrls: ['./material-share-dialog.component.css']
})
export class MaterialShareDialogComponent implements OnInit {

  material: Material;
  companyId: number;
  shareWithId: number = -1;

  private companyList: Company[] = [];
  get companies(): Company[] {
    return this.companyList;
  }

  get title(): string {
    return this.translationService.getTranslation("commands.Share");
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { material: Material, companyId: number },
    private materialService: MaterialsService,
    private companyService: CompaniesService,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<MaterialShareDialogComponent>
  ) {
    this.material = this.data.material;
    this.companyId = this.data.companyId;
  }

  ngOnInit() {
    this.companyService.getCompanies()
      .subscribe(
        results => this.companiesLoaded(results),
        error => this.handleError(error)
      );
  }

  companiesLoaded(list: Company[]) {
    this.companyList = list.filter(x => x.companyId != this.companyId).sort((a, b) => a.companyName > b.companyName ? 1 : -1);
  }

  handleError(error: any) {
    this.alertService.showStickyMessage('Error', `Error: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  materialShared(sharedMat: Material) {
    var msgtitle = this.translationService.getTranslation('materialShare.SharedSuccessfulTitle');
    var msg = this.translationService.getTranslation('materialShare.SharedSuccessfulMsg');
    var sharedWith = this.companyList.find(x => x.companyId == this.shareWithId);
    this.alertService.showMessage(msgtitle, `${sharedWith.companyName} ${msg} ${this.material.materialName}`, MessageSeverity.success);
    this.dialogRef.close();
  }

  continue() {
    if (this.shareWithId < 0) return;

    this.materialService.copyMaterial(this.material.materialId, this.shareWithId)
      .subscribe(
        result => this.materialShared(result),
        error => this.handleError(error)
      );
  }

  cancel() {
    this.dialogRef.close();

  }

}
