import { Injectable } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { Team, UserTeam } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class TeamsService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint
  ) { }

  getTeamsByCompany(companyId: number, includeUsers: boolean) {
    return this.applicationEndpoint.getTeamsByCompanyEndpoint <Team[]>(companyId, includeUsers);
  }

  getTeam(teamId: number, includeUsers: boolean) {
    return this.applicationEndpoint.getTeamEndpoint<Team>(teamId, includeUsers);
  }

  createTeam(team: Team) {
    return this.applicationEndpoint.postTeamEndpoint<Team>(team);
  }

  updateTeam(team: Team) {
    return this.applicationEndpoint.putTeamEndpoint<any>(team);
  }

  deleteTeam(teamId: number) {
    return this.applicationEndpoint.deleteTeamEndpoint<any>(teamId);
  }

  connectUserTeam(userId: number, teamId: number) {
    return this.applicationEndpoint.connectUserTeamEndpoint<UserTeam>(userId, teamId);
  }

  disconnectUserTeam(userId: number, teamId: number) {
    return this.applicationEndpoint.disconnectUserTeamEndpoint<UserTeam>(userId, teamId);
  }
}
