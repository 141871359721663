<h1 mat-dialog-title>
  {{'imageUpload.Title' | translate}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <div>
    {{'imageUpload.Instructions' | translate}}
  </div>
  <app-upload-file-control #imageUpload
                           fxFlex="100%"
                           accept=".png,.jpg,.jpeg,.gif,.bmp"
                           [base64Image]="true"
                           (onBase64ImageReady)="ready($event)">

  </app-upload-file-control>
  <div fxLayout="row" class="photo simple-border">
    <img src="{{ image || '../../../assets/images/usericon.png'}}" />
  </div>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="import();" color="primary">{{'commands.Import' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
