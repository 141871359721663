import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-upload-file-control',
  templateUrl: './upload-file-control.component.html',
  styleUrls: ['./upload-file-control.component.scss']
})
export class UploadFileControlComponent implements OnInit {
  @Input() singleFile: boolean = true;
  @Input() accept: string = "";
  @Input() base64Image: boolean = false;
  @Input() maxImageFileSize: number = (.5 * 1024 * 1024); //.5 MB
  
  @Output() onFilesReady = new EventEmitter<any>();
  @Output() onBase64ImageReady = new EventEmitter<string>();

  constructor(
    private alertService: AlertService,
    private translateService: AppTranslationService
  ) { }

  ngOnInit() {
  }

  files: any = [];

  incomingFile(event) {
    this.uploadFile(event.target.files);
  }

  uploadFile(event) {
    if (this.singleFile)
      this.files = [];

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
      if (this.singleFile)
        break;
    }

    if (this.base64Image && this.files.length == 1) {
      if (this.maxImageFileSize < this.files[0].size) {
        this.files = [];
        const errmsg: string = this.translateService.getTranslation('imageUpload.FileTooLarge');
        this.alertService.showMessage(errmsg);
      }
      else {
        let reader = new FileReader();
        reader.readAsDataURL(this.files[0]);
        reader.onload = this.readerLoaded.bind(this);
      }
    }
    else {
      this.onFilesReady.emit(this.files);
    }
  }

  readerLoaded(e: any) {
    var base64 = e.target.result.toString();
    this.callImageReady(base64);
  }

  callImageReady(result: string) {
    this.onBase64ImageReady.emit(result);
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);

    this.onFilesReady.emit(this.files);
  }

}
