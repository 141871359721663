import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
    selector: 'about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
    animations: [fadeInOut]
})
export class AboutComponent {
  public logs: Array<ChangeLogEntry> = [
    {
      title: "2021.09.06",
      items: [
        "Self-serve password reset added to profile menu.",
        "Company Selector - deleted companies will no longer appear.",
        "Group 'Compose' button added.",
        "Improved loading time.",
        "Report title styling corrected.",
        "P60 pronoun usage improved."
      ]
    },
    {
      title: "2021.06.27",
      items: [
        "Added report confirmation email functionality.",
        "Modified database access for performance.",
        "Corrected rapid answer entry skipping questions.",
        "Added gender selection to account registration."
      ]
    },
    {
      title: "2021.05.11",
      items: [
        "Corrected company information editing.",
        "Modified the report styles to handle large logo sizing.",
        "Removed P60 Options from general availability.",
        "Added forgot password reset redundancy."
      ]
    },
    {
      title: "2021.05.10",
      items: [
        "Improved query response times.",
        "Corrected profile selection on login."
      ]
    },
    {
      title: "2021.05.08",
      items: [
        "Added test email feature for User Accounts.",
        "Updated label text on Compose Invitation.",
        "Corrected user creation during Quick Link.",
        "Improved slow user selection queries."
      ]
    },
  ];

}
export class ChangeLogEntry {
  public title: string = "";
  public items: Array<string> = [];
}
