<h1 mat-dialog-title>
  {{'companyCreditDialog.Title' | translate}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <div fxFlex="100%">
    {{'companyCreditDialog.Instructions' | translate}}
  </div>

  <div fxLayout="row">
    <button mat-raised-button fxFlex="100%" *ngIf="!company.unlimitedCredits" (click)="setUnlimited();" color="primary">{{'companyCreditDialog.UnlimitedCredits' | translate}}</button>
    <button mat-raised-button fxFlex="100%" *ngIf="company.unlimitedCredits" (click)="setCreditsOnly();" color="primary">{{'companyCreditDialog.CreditsOnly' | translate}}</button>
  </div>
  <div fxLayout="row" fxLayout.lt-sm="column" *ngIf="!company.unlimitedCredits" fxLayoutGap="8px">
    <button mat-raised-button fxFlex="100%" (click)="addCredits(-99999);" color="warn">{{'companyCreditDialog.RemoveAll' | translate}}</button>
    <button mat-raised-button fxFlex="100%" (click)="addCredits(01);" color="default">{{'companyCreditDialog.Add' | translate}} 1 {{'companyCreditDialog.Credit' | translate}}</button>
    <button mat-raised-button fxFlex="100%" (click)="addCredits(05);" color="default">{{'companyCreditDialog.Add' | translate}} 5 {{'companyCreditDialog.Credits' | translate}}</button>
    <button mat-raised-button fxFlex="100%" (click)="addCredits(10);" color="default">{{'companyCreditDialog.Add' | translate}} 10 {{'companyCreditDialog.Credits' | translate}}</button>
  </div>
  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</div>
<div mat-dialog-actions fxLayoutGap="8px" fxLayout="row" fxLayout.lt-sm="column">
  <div>{{'companyCreditDialog.Remaining' | translate}}: {{remainingCredits}}</div>
  <div fxFlex="auto" fxHide.lt-sm></div>
  <div *ngIf="!company.unlimitedCredits" >{{'companyCreditDialog.CreditsAdded' | translate}}:&nbsp; <b>{{credits}}</b></div>
  <button mat-raised-button (click)="close();" color="default">{{'commands.Done' | translate}}</button>
</div>
