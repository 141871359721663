import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { Company } from '../../../models/company';
import { CompanyEditorComponent } from '../company-editor/company-editor.component';

@Component({
  selector: 'app-company-view-dialog',
  templateUrl: './company-view-dialog.component.html',
  styleUrls: ['./company-view-dialog.component.scss']
})
export class CompanyViewDialogComponent {
  @ViewChild('cec') editCompany: CompanyEditorComponent;

  get company(): Company {
    return this.data.company;
  }
  
  constructor(
    public dialogRef: MatDialogRef<CompanyViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company }) {
  }

  ngAfterViewInit() {
    this.editCompany.companySaved$.subscribe(company => this.dialogRef.close(company));
  }

  save() {
    this.editCompany.save();
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
