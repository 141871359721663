import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { KeyValueObject } from '../../../models/key-value-object.model';
import { LegacyService } from '../../../services/legacy.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Utilities } from '../../../services/utilities';
import { fadeInOut } from '../../../services/animations';
import { Company } from '../../../models/company';

@Component({
  selector: 'app-legacy-clients',
  templateUrl: './legacy-clients.component.html',
  styleUrls: ['./legacy-clients.component.css'],
  animations: [fadeInOut]
})
export class LegacyClientsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'value',
    'note',
    'key',
    'actions'
  ];
  dataSource: MatTableDataSource<KeyValueObject>;
  loadingIndicator: boolean;

  constructor(
    private legacyService: LegacyService,
    private alertService: AlertService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.legacyService
      .getClients()
      .subscribe(entries =>
        this.onDataLoadSuccessful(entries),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: KeyValueObject[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  transfer(kvo: KeyValueObject) {
    this.alertService.startLoadingMessage("Transferring");
    this.loadingIndicator = true;
    this.legacyService
      .transferClient(kvo)
      .subscribe(company =>
        this.onTransferSuccessful(company),
        error => this.onDataLoadFailed(error));
  }

  onTransferSuccessful(company: Company) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    var row = this.dataSource.data.filter(x => x.clientid == company.companyId)[0];
    row.transferred = true;

    this.alertService.showStickyMessage("Transfer complete!");
  }

}
