<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content fxLayout="column">

  <div fxFlex="100%">
    <h2>{{material.materialName}}</h2>
  </div>

  <div fxFlex="100%">
    <i>{{'materialShare.Instructions' | translate}}</i>
  </div>

  <div fxFlex="100%">
    <mat-form-field floatLabel="auto" fxFlex="100%">
      <mat-label>{{'materialShare.Company' | translate}}</mat-label>
      <mat-select [(ngModel)]="shareWithId">
        <mat-option *ngFor="let c of companies" [value]="c.companyId">
          <span matTooltip="{{c.companyName}}">{{c.companyName}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="continue();" color="primary">{{'commands.Continue' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>

