// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { Profile } from './profile';
import { Company } from './company';
import { XrGender } from './xr-gender';

export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(id?: string, userName?: string, fullName?: string, email?: string, jobTitle?: string, phoneNumber?: string, roles?: string[], userId?: string, genderId?: number) {

    this.id = id;
    this.userName = userName;
    this.fullName = fullName;
    this.email = email;
    this.jobTitle = jobTitle;
    this.phoneNumber = phoneNumber;
    this.roles = roles;
    this.genderId = genderId;

    if (userId)
      this.profileId = +userId;
  }

  get friendlyName(): string {
    let name = this.fullName || this.userName;

    if (this.jobTitle) {
      name = this.jobTitle + ' ' + name;
    }

    return name;
  }

  //setProfile(profile: Profile) {
  //  this.profile = profile;
  //  this.profileId = profile.userId;
  //}
  //setProfileId(profileId: number) {
  //  this.profileId = profileId;
  //}

  public id: string;
  public userName: string;
  public fullName: string;
  public email: string;
  public jobTitle: string;
  public phoneNumber: string;
  public isEnabled: boolean;
  public isLockedOut: boolean;
  public roles: string[];
  public profileId: number;
  public genderId: number;

  public profile?: Profile;
  public company?: Company;
  public gender?: XrGender;
}
