<app-page-header title="{{title}}" icon="{{icon}}"></app-page-header>
<div [@fadeInOut] class="page-content" fxLayout="column">
  <div fxFlex="100%">
    <app-material-header-control [material]="material" designation="{{designation}}"></app-material-header-control>
  </div>
  
  <div fxLayout="column" fxLayoutGap="8px">
    <div fxFlex="100%" fxLayout="row" *ngIf="noContent">
      <div fxFlex="auto"></div>
      <button mat-icon-button color="primary" (click)="addContentAt(100, true)" matTooltip="{{'testEditor.NewContent' | translate}}">
        <mat-icon>library_add</mat-icon>
      </button>
      <div fxFlex="auto"></div>
    </div>
    <div fxFlex="100%" *ngFor="let o of orders">
      <div fxFlex="100%" *ngIf="o.materialContent" fxLayout="column" fxLayoutGap="8px">
        <app-material-content-control [materialContent]="o.materialContent"
                                      [position]="contentPosition(o)"
                                      (onDelete)="contentDelete($event)"
                                      (onMoveUp)="contentMoveUp($event)"
                                      (onMoveDown)="contentMoveDown($event)">
        </app-material-content-control>
        <div fxFlex="100%" fxLayout="row">
          <div fxFlex="auto"></div>
          <button mat-icon-button color="primary" (click)="addContentAt(o.ordinal)" matTooltip="{{'testEditor.NewContent' | translate}}">
            <mat-icon>library_add</mat-icon>
          </button>
          <div fxFlex="auto"></div>
        </div>
      </div>

      <div fxFlex="100%" *ngIf="o.test" fxLayout="column" fxLayoutGap="8px">
        <div fxFlex="100%" *ngFor="let q of questions" fxLayout="column" fxLayoutGap="8px">

          <app-question-control [question]="q"
                                [position]="questionPosition(q)"
                                (onDelete)="questionDelete($event)"
                                (onMoveUp)="questionMoveUp($event)"
                                (onMoveDown)="questionMoveDown($event)"
                                (onAnswerDelete)="answerDelete($event)"
                                (onValueDelete)="valueDelete($event)">
          </app-question-control>
          <div fxFlex="100%" fxLayout="row">
            <div fxFlex="auto"></div>
            <button mat-icon-button color="primary" (click)="addQuestionAt(q.ordinal)" matTooltip="{{'testEditor.NewQuestion' | translate}}">
              <mat-icon>add_to_queue</mat-icon>
            </button>
            <div fxFlex="auto"></div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="100%" fxLayout="row" *ngIf="noQuestion">
      <div fxFlex="auto"></div>
      <button mat-icon-button color="primary" (click)="addQuestionAt(100, true)" matTooltip="{{'testEditor.NewQuestion' | translate}}">
        <mat-icon>add_to_queue</mat-icon>
      </button>
      <div fxFlex="auto"></div>
    </div>
  </div>
  <mat-divider></mat-divider>

  <!--<div>

    <pre fxFlex="100%">
      {{jsonData}}
    </pre>

  </div>-->

  <div class="fab-container" *ngIf="!(loadingIndicator == true)">
    <button mat-fab color="primary" class="fab-action" (click)="save()" matTooltip="{{'commands.Save'|translate}}">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-mini-fab color="warn" class="fab-action" (click)="reload()" matTooltip="{{'commands.Reload'|translate}}">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>


</div>

<div class="{{loadingIndicator == true ? 'editor-hold' : ''}}"></div>
