import { Component, OnInit, Input } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService } from 'src/app/services/alert.service';
import { fadeInOut } from 'src/app/services/animations';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss'],
  animations: [fadeInOut]
})
export class ProfileViewComponent implements OnInit {
  userId: number;
  profile: Profile;

  get fullName() {
    if (!this.profile) return "";
    var fn = this.profile.firstName;
    if (this.profile.firstName != this.profile.preferredName && this.profile.preferredName != "")
      fn = this.profile.preferredName;
    fn += " " + this.profile.lastName;
    return fn;
  }

  constructor(
    private route: ActivatedRoute,
    private alertService: AlertService,
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    route.params.subscribe(params => { this.parseParams(params); });
  }

  parseParams(params: Params) {
    this.userId = params['userId'];
    if (!this.userId) {
      var up = this.authService.currentUserProfile;
      if (up)
        this.userId = up.userId;
    }
  }

  ngOnInit() {
    this.alertService.startLoadingMessage();
    this.profileService.getProfile(this.userId).subscribe(profile => { this.profileFound(profile); });
  }

  profileFound(profile: Profile) {
    this.profile = profile;
  }

}
