import { IAuditableEntity } from "./IAuditableEntry.interface";
import { Company } from "./company";
import { MaterialOrder } from "./material-order.model";
import { Test } from "./test.model";

export class Material implements IAuditableEntity {
  constructor(id: number, name: string) {
    this.materialId = id;
    this.materialName = name;
    this.companyId = 0;
    this.intro = "";
    this.outtro = "";
    this.languageCode = "en";
    this.template = false;
  }

  public materialId: number;
  public materialName: string;
  public companyId: number;
  public intro: string;
  public outtro: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public languageCode: string;
  public template: boolean;
  public deleted: boolean;

  public company: Company;

  public materialOrder: MaterialOrder[];
  
}


export class MaterialDisplay implements Material {
  public materialId: number;
  public materialName: string;
  public companyId: number;
  public intro: string;
  public outtro: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public languageCode: string;
  public template: boolean;
  public deleted: boolean;
  public company: Company;
  public companyName: string;

  public materialOrder: MaterialOrder[];
  public test: Test[];
}
