<app-page-header title="CampaignAnalysis" icon="bubble_chart"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
    <div fxFlex="100%">
      <h4 fxLayout="row">{{ campaignName }}</h4>
    </div>
    <div fxFlex="100%">
      <div fxFlex="100%" fxHide.lt-xl>
        <div fxLayout="row" class="mat-small">&nbsp;</div>
        <div fxLayout="row" class="mat-small">&nbsp;<!--{{ designation }}--></div>
      </div>
      <div fxFlex="100%">
        <div fxLayout="row" class="mat-small">{{ 'campaignAnalysis.OpenDate' | translate }}</div>
        <div fxLayout="row">{{ openDate }}</div>
      </div>
      <div fxFlex="100%">
        <div fxLayout="row" class="mat-small">{{ 'campaignAnalysis.CloseDate' | translate }}</div>
        <div fxLayout="row">{{ closeDate }}</div>
      </div>
    </div>
    <div fxFlex="100%" fxShow.lt-lg>&nbsp;</div>
  </div>
  <div fxLayout="row" fxLayoutGap="8px" fxLayout.lt-lg="column">
    <div fxLayoutGap="16px"
         fxLayout.lt-sm="column" fxFlex.lt-sm="100%"
         fxLayout.md="row" fxFlex.md="100%"
         fxLayout.gt-lg="column" fxFlex.gt-lg="40%">
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div fxFlex="94px">
          <circle-progress [percent]="participationPercent"
                           [radius]="cirpro.radius"
                           [outerStrokeWidth]="cirpro.outerStrokeWidth"
                           [innerStrokeWidth]="cirpro.innerStrokeWidth"
                           [outerStrokeColor]="cirpro.outerStrokeColor"
                           [innerStrokeColor]="cirpro.innerStrokeColor"
                           [animation]="cirpro.animation"
                           [animationDuration]="cirpro.animationDuration"
                           [space]="cirpro.space"
                           [showTitle]="cirpro.showTitle"
                           [title]="participationValue"
                           [titleFontSize]="cirpro.titleFontSize"
                           [unitsFontSize]="cirpro.unitsFontSize"
                           [showSubtitle]="cirpro.showSubtitle"
                           [subtitle]="participationText"
                           [startFromZero]="cirpro.startFromZero"
                           [responsive]="cirpro.responsive"
                           [backgroundColor]="'#FFFFFF'"></circle-progress>
        </div>
        <div fxFlex="100%">
          {{ 'campaignAnalysis.Participation' | translate}}
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
        <div fxFlex="94px">
          <circle-progress [percent]="progressPercent"
                           [radius]="cirpro.radius"
                           [outerStrokeWidth]="cirpro.outerStrokeWidth"
                           [innerStrokeWidth]="cirpro.innerStrokeWidth"
                           [outerStrokeColor]="cirpro.outerStrokeColor"
                           [innerStrokeColor]="cirpro.innerStrokeColor"
                           [animation]="cirpro.animation"
                           [animationDuration]="cirpro.animationDuration"
                           [space]="cirpro.space"
                           [showTitle]="cirpro.showTitle"
                           [title]="progressValue"
                           [titleFontSize]="cirpro.titleFontSize"
                           [unitsFontSize]="cirpro.unitsFontSize"
                           [showSubtitle]="cirpro.hideSubtitle"
                           [subtitle]="participationText"
                           [startFromZero]="cirpro.startFromZero"
                           [responsive]="cirpro.responsive"
                           [backgroundColor]="'#FFFFFF'"></circle-progress>
        </div>
        <div fxFlex="100%">
          {{ 'campaignAnalysis.Completion' | translate}}
        </div>
      </div>
    </div>
    <div fxFlex="100%" fxLayout="column">
      <div fxLayout="row" class="mat-small">{{ 'campaignAnalysis.Invitation' | translate}}</div>
      <div fxFlex="100%" innerHTML="{{invitationTemplate}}">

      </div>
    </div>
  </div>
  <br />
  <mat-divider></mat-divider>
  <br />
  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
    <div fxFlex="50%" fxFlex.lt-lg="100%" fxLayout="column">
      <div class="search-box" fxLayout="row" fxLayout.lt-lg="column">
        <mat-form-field>
          <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'campaigns.management.Search' | translate}}">
        </mat-form-field>
        <div fxFlex="auto" fxHide.sm fxHide.lt-sm></div>
        <div fxLayoutGap="8px" fxLayout="column">
          <div fxFlex="auto" fxHide.lt-sm></div>
          <div fxLayoutGap="8px" fxLayout="row" fxLayout.sm="column" fxLayout.lt-sm="column">
            <button fxFlex="100%" mat-raised-button matTooltip="{{'individualsControl.NewIndividual' | translate}}" (click)="newIndividual()">
              <mat-icon>person_add</mat-icon>&nbsp;&nbsp;{{'individualsControl.NewIndividual' | translate}}
            </button>
          </div>
          <div fxFlex="auto"></div>
        </div>
      </div>
      <div class="simple-border">
        <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
        <mat-table [dataSource]="dsParticipants" matSort>

          <ng-container matColumnDef="lastName">
            <mat-header-cell fxFlex="35%" fxFlex.lt-md="65%" fxFlex.lt-sm="80%" *matHeaderCellDef mat-sort-header> {{'individualsControl.Name' | translate}} </mat-header-cell>
            <mat-cell fxFlex="35%" fxFlex.lt-md="65%" fxFlex.lt-sm="80%" *matCellDef="let row">
              <span fxHide.lt-md>{{row.lastName}}, {{row.firstName}}</span>
              <span fxHide fxShow.lt-md fxHide.lt-sm>
                {{row.lastName}}, {{row.firstName}}
                <br />{{row.email}}
              </span>
              <span fxHide fxShow.lt-sm>
                {{row.lastName}}, {{row.firstName}}
                <br />{{row.email}}
                <br />{{'campaignAnalysis.participants.Status' | translate}}: {{'campaignAnalysis.participants.statusValues.' + row.status | translate }}
              </span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell fxFlex="30%" fxHide.lt-md *matHeaderCellDef mat-sort-header> {{'campaignAnalysis.participants.Email' | translate}} </mat-header-cell>
            <mat-cell fxFlex="30%" fxHide.lt-md *matCellDef="let row"> {{row.email}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell fxFlex="20%" fxHide.lt-sm *matHeaderCellDef mat-sort-header> {{'campaignAnalysis.participants.Status' | translate}} </mat-header-cell>
            <mat-cell fxFlex="20%" fxHide.lt-sm *matCellDef="let row"> {{'campaignAnalysis.participants.statusValues.' + row.status | translate }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="progress">
            <mat-header-cell fxFlex="15%" fxFlex.lt-sm="20%" *matHeaderCellDef mat-sort-header> {{'campaignAnalysis.participants.Progress' | translate}} </mat-header-cell>
            <mat-cell fxFlex="15%" fxFlex.lt-sm="20%" *matCellDef="let row">
              <span *ngIf="row.status != 'emailsent'">{{formatPercent(row.progress)}}</span>
              <button *ngIf="row.status == 'emailsent'" mat-icon-button matTooltip="{{'commands.Send' | translate}}" (click)="resendInvite(row)">
                <mat-icon>send</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;">
          </mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
    <div fxFlex="50%" fxFlex.lt-lg="100%" fxLayout="column">
      <app-campaign-reports-control [campaignId]="campaignId"></app-campaign-reports-control>
    </div>
  </div>
</div>
