<app-page-header title="Benchmarks" icon="graphic_eq"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'benchmarkList.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="benchmarkName">
        <mat-header-cell fxFlex="80%" *matHeaderCellDef mat-sort-header> {{'benchmarkList.Name' | translate}} </mat-header-cell>
        <mat-cell fxFlex="80%" *matCellDef="let row"> {{row.benchmarkName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="template">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header> {{'benchmarkList.Template' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row">
          <mat-icon *ngIf="row.template">check</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="200px">
          <button mat-button color="primary" matTooltip="{{'benchmarkList.New' | translate}}" (click)="newBenchmark()">
            <mat-icon>add_circle_outline</mat-icon>&nbsp;{{'benchmarkList.New' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let benchmark" fxFlex="170px" fxFlexOffset="30px">
          <button mat-icon-button matTooltip="{{'benchmarkList.Copy' | translate}}" (click)="copy(benchmark)">
            <mat-icon>file_copy</mat-icon>
          </button>
          <button *ngIf="benchmark.companyId == companyId" mat-icon-button matTooltip="{{'benchmarkList.Edit' | translate}}" (click)="edit(benchmark)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="benchmark.companyId == companyId" mat-icon-button matTooltip="{{'benchmarkList.Delete' | translate}}" (click)="confirmDelete(benchmark)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
