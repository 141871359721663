import { Component, OnInit } from '@angular/core';
import { MaterialsService } from 'src/app/services/materials.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlertService, MessageSeverity, DialogType } from 'src/app/services/alert.service';
import { Material } from 'src/app/models/material.model';
import { Utilities } from 'src/app/services/utilities';
import { fadeInOut } from 'src/app/services/animations';
import { Test } from 'src/app/models/test.model';
import { MaterialContent } from 'src/app/models/material-content.model';
import { MaterialOrder } from 'src/app/models/material-order.model';
import { MatSnackBar } from '@angular/material';
import { Question } from 'src/app/models/question.model';
import { Answer } from 'src/app/models/answer.model';
import { AnswerValue } from 'src/app/models/answer-value.model';

@Component({
  selector: 'app-test-editor',
  templateUrl: './test-editor.component.html',
  styleUrls: ['./test-editor.component.scss'],
  animations: [fadeInOut]
})
export class TestEditorComponent implements OnInit {

  materialId: number;

  material: Material = new Material(-1, "");

  loadingIndicator: boolean;

  deletedOrds: MaterialOrder[] = [];
  deletedQs: Question[] = [];
  deletedAs: Answer[] = [];
  deletedVs: AnswerValue[] = [];

  get designation(): string {
    return this.test.designation || "material";
  }

  get icon(): string {
    switch (this.designation) {
      case "aptitude": {
        return "ballot";
        break;
      }
      case "assessment": {
        return "assessment";
        break;
      }
      case "survey": {
        return "list";
        break;
      }
      default: {
        return "chrome_reader_mode";
        break;
      }
    }
  }

  get title(): string {
    if (this.material)
      return this.material.materialName;
    return "";
  }
  
  editTest: Test = new Test();
  get test(): Test {
    if (!this.editTest || !this.editTest.testId) {
      if (this.material) {
        if (this.material.materialOrder) {
          var t = this.material.materialOrder.filter(x => x.test).map(x => x.test);
          if (t) {
            if (t.length > 0) {
              this.editTest = t[0]; //there should only be one test, if any connected to a single material
            }
          }
        }
      }
    }

    return this.editTest;
  }

  get questions(): Question[] {
    if (!this.test) return [];
    if (!this.test.question || this.test.question.length <= 0) {
      this.test.question = [];
    }

    this.test.question = this.test.question.filter(a => a.deleted == false);

    return this.test.question;
  }

  get jsonData(): string {
    if (!this.material) return "no data";
    return JSON.stringify(this.material, null, 2);
  }

  get noContent(): boolean {
    if (!this.material) return true;
    if (!this.material.materialOrder) return true;
    return this.material.materialOrder.filter(x => (x.materialContentId || 0) != 0).length <= 0;
  }

  get noQuestion(): boolean {
    if (!this.questions) return true;
    if (this.designation == "material") return false;
    return this.questions.length <= 0; 
  }

  get orders(): MaterialOrder[] {
    if (!this.material) return [];
    if (!this.material.materialOrder || this.material.materialOrder.length <= 0) {
      this.material.materialOrder = [];
    }

    this.material.materialOrder = this.material.materialOrder.filter(x => x.deleted == false);

    return this.material.materialOrder;
  }

  constructor(
    private materialService: MaterialsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: AppTranslationService,
    private snackBar: MatSnackBar
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.materialId = params['materialId'];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.materialService.getMaterial(this.materialId, true)
      .subscribe(
        result => this.onDataLoadSuccessful(result),
        error => this.onDataLoadFailed(error)
      );
  }

  private onDataLoadSuccessful(material: Material) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    if (this.material)
      Object.assign(this.material, material);
    else
      this.material = material;
    this.resetOrdinals();

    window.scroll(0, 0);
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private contentPosition(matord: MaterialOrder): string {
    var index = this.material.materialOrder.indexOf(matord);

    var first: boolean = index == 0;
    var last: boolean = index == (this.material.materialOrder.length - 1);

    var testindex = this.material.materialOrder.findIndex(x => x.testId > 0);
    if (testindex >= 0 && index == (testindex - 1)) last = true;

    return (first == true && last == true ? "single" : first == true ? "first" : last == true ? "last" : "");
  }

  private questionPosition(q: Question): string {
    var index = this.questions.indexOf(q);

    var first: boolean = index == 0;
    var last: boolean = index == (this.questions.length - 1);

    return (first == true && last == true ? "single" : first == true ? "first" : last == true ? "last" : "");
  }

  private resetOrdinals() {
    this.material.materialOrder.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);

    var ordinal: number = 0;
    this.material.materialOrder.forEach(x => {
      ordinal += 100;
      x.ordinal = ordinal;
    });

    this.questions.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);

    var ordinal: number = 0;
    this.questions.forEach(x => {
      ordinal += 100;
      x.ordinal = ordinal;
    });   
  }

  private contentDelete(matcon: MaterialContent) {
    var index = this.material.materialOrder.findIndex(x => x.materialContentId == matcon.materialContentId);
    if (index >= 0)
      this.deletedOrds.push(this.material.materialOrder.splice(index, 1)[0]);
    this.resetOrdinals();
  }

  private questionDelete(q: Question) {
    var index = this.questions.findIndex(x => x.questionId == q.questionId);
    if (index >= 0)
      this.deletedQs.push(this.questions.splice(index, 1)[0]);
    this.resetOrdinals();
  }

  private answerDelete(a: Answer) {
    this.deletedAs.push(a); 
  }
   
  private valueDelete(av: AnswerValue) {
    this.deletedVs.push(av);
  }

  private contentMoveUp(matcon: MaterialContent) {
    var ord = this.material.materialOrder.find(x => x.materialContentId == matcon.materialContentId);
    ord.ordinal -= 150;
    this.resetOrdinals();
  }

  private questionMoveUp(q: Question) {
    var ord = this.questions.find(x => x.questionId == q.questionId);
    ord.ordinal -= 150;
    this.resetOrdinals();
  }

  private contentMoveDown(matcon: MaterialContent) {
    var ord = this.material.materialOrder.find(x => x.materialContentId == matcon.materialContentId);
    ord.ordinal += 150;
    this.resetOrdinals();
  }

  private questionMoveDown(q: Question) {
    var ord = this.questions.find(x => x.questionId == q.questionId);
    ord.ordinal += 150;
    this.resetOrdinals();
  }

  private addContentAt(ordinal: number, before: boolean = false) {
    var minid = 0;
    if (this.material.materialOrder)
      if (this.material.materialOrder.length > 0)
        minid = this.material.materialOrder.map(x => x.materialOrderId).sort((a, b) => a > b ? 1 : -1)[0];

    minid = (minid <= 0 ? minid - 1 : -1);

    var matcontent = new MaterialContent();
    matcontent.materialContentId = minid;
    matcontent.materialId = this.material.materialId;
    matcontent.title = this.translateService.getTranslation("testEditor.NewContentTitle");
    matcontent.content = this.translateService.getTranslation("testEditor.NewContentPrompt");

    var matord = new MaterialOrder();
    matord.materialOrderId = minid;
    matord.materialContentId = minid;
    matord.materialId = this.material.materialId;
    matord.materialContent = matcontent;
    matord.ordinal = ordinal + (before == true ? -50 : 50);
    matord.deleted = false;

    this.material.materialOrder.push(matord);
    this.resetOrdinals();
  }

  private addQuestionAt(ordinal: number, before: boolean = false) {
    var minid = 0;
    if (this.questions)
      if (this.questions.length > 0)
        minid = this.questions.map(x => x.questionId).sort((a, b) => a > b ? 1 : -1)[0];

    minid = (minid <= 0 ? minid - 1 : -1);

    var answer = new Answer();
    answer.answerId = minid * 1000 - 1;
    answer.testId = this.test.testId;
    answer.questionId = minid;
    answer.answerTypeId = 4; //TXT
    answer.code = `A${answer.answerId}`;
    answer.title = this.translateService.getTranslation("testEditor.NewAnswerTitle");
    answer.weight = 1;
    answer.pointValue = 1;
    answer.freeTextCharacterLimit = 1000;
    answer.ordinal = 100;
    answer.xrType = "";
    answer.deleted = false;

    var question = new Question();
    question.questionId = minid;
    question.testId = this.test.testId;
    question.code = "";
    question.title = this.translateService.getTranslation("testEditor.NewQuestion");
    question.weight = 1;
    question.pointValue = 1;
    question.ordinal = ordinal + (before == true ? -50 : 50);
    question.deleted = false;
    question.answer = [answer];

    this.questions.push(question);
    this.resetOrdinals();
  }

  private reload() {
    this.snackBar.open(this.translateService.getTranslation("testEditor.ConfirmReload"), this.translateService.getTranslation("commands.Reload"), { duration: 5000 })
      .onAction().subscribe(() => {
        window.scroll(0, 0);
        this.loadData();
      });
  }

  private save() {
    var msg = this.translateService.getTranslation("testEditor.ConfirmSave");
    var ttl = this.translateService.getTranslation("commands.Save");
    var yes = this.translateService.getTranslation("commands.Yes");
    var no = this.translateService.getTranslation("commands.No");
    this.alertService.showDialog(ttl, msg, DialogType.confirm,
      (val) => this.processSave(),
      () => { },
      yes, no);
  }

  private processSave() {
    this.loadingIndicator = true;
    this.alertService.startLoadingMessage("Saving...");

    this.deletedOrds.forEach(x => {
      if (x.materialOrderId > 0) {
        x.deleted = true;
        this.material.materialOrder.push(x);
      }
    });
    this.deletedQs.forEach(q => {
      if (q.questionId > 0) {
        q.deleted = true;
        this.test.question.push(q);
      }
    });
    this.deletedAs.forEach(a => {
      if (a.answerId > 0) {
        a.deleted = true;
        var q = this.test.question.find(q => q.questionId == a.questionId);
        q.answer.push(a);
      }
    });
    this.deletedVs.forEach(v => {
      if (v.answerValueId > 0) {
        v.deleted = true;
        var q = this.test.question.find(q => q.answer.filter(a => a.answerId == v.answerId).length > 0);
        var a = q.answer.find(a => a.questionId == q.questionId && a.answerId == v.answerId);
        a.answerValue.push(v);
      }
    });

    this.materialService.patchMaterial(this.material).subscribe(
      result => this.onDataLoadSuccessful(result),
      error => this.onDataLoadFailed(error)
    );
  }
}
