<div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="1em">
  <div class="photo simple-border" fxFlex="15%" fxHide.lt-md>
    <img src="{{ photoBase64 || '../../../assets/images/usericon.png'}}" (click)="launchImageImport()" />
  </div>

  <form #form="ngForm" [formGroup]="profileForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off" fxFlex="100%">

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.FirstName' | translate}}</mat-label>
        <input matInput formControlName="firstName" [readonly]="!isEditMode" />
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.LastName' | translate}}</mat-label>
        <input matInput formControlName="lastName" [readonly]="!isEditMode" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.Preferred' | translate}}</mat-label>
        <input matInput formControlName="preferredName" [readonly]="!isEditMode" />
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.Credentials' | translate}}</mat-label>
        <input matInput formControlName="educationCredentials" [readonly]="!isEditMode" />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.Email' | translate}}</mat-label>
        <input matInput formControlName="email" [readonly]="!isEditMode" />
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.Phone' | translate}}</mat-label>
        <input matInput formControlName="phoneNumber" [readonly]="!isEditMode" />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'profile.editor.Gender' | translate}}</mat-label>
        <mat-select formControlName="genderId">
          <mat-option *ngFor="let g of genders" [value]="g.genderId">
            {{g.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- https://github.com/angular/material2/issues/8798 -->
    <div>&nbsp;</div>
  </form>

</div>

