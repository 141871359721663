import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { Company } from 'src/app/models/company';
import { Utilities } from 'src/app/services/utilities';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Subject } from 'rxjs';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  
  dataSource: Company[];
  selectedValue: Company;

  loadingIndicator: boolean = false;
  
  private onCompanySelected = new Subject<Company>();
  companySelected$ = this.onCompanySelected.asObservable();

  get allowUserSelection(): boolean {
    if (this.authService.userIsAdmin == true)
      return true;
    else if (!this.dataSource)
      return false;
    else if (this.dataSource.length == 5)
      return false;
    else
      return false;
  }

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private profileService: ProfileService,
    private alertService: AlertService,
    private translateService: AppTranslationService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.authService.getLoginStatusEvent().subscribe(status => this.userStatusChanged(status));
    this.authService.profileSelected$.subscribe(profile => this.loadData());
  }

  userStatusChanged(loggedIn: boolean) {
    if (loggedIn)
      this.loadData();
    else
      this.selectedValue = null;
  }

  ngOnInit() {
    this.authService.companySelected$.subscribe(
      company => this.switchToCompany(company)
    );
  }

  switchToCompany(company: Company) {
    if (this.dataSource.filter(x => x.companyId == company.companyId).length <= 0)
      this.dataSource.push(company);
    this.dataSource.filter(x => x.companyId == company.companyId)[0] = company;
    this.selectedValue = this.dataSource.filter(x => x.companyId == company.companyId)[0];
  }

  loadData() {
    this.loadingIndicator = true;

    if (this.authService.userIsAdmin == true) {
      this.companiesService
        .getCompanies()
        .subscribe(companies =>
          this.onDataLoadSuccessful(companies), //.sort((a,b) => a.companyId = 0 ? 100 : a.companyName > b.companyName ? 1 : -1)),
          error => this.onDataLoadFailed(error)
        );
    } else if (this.authService.currentUserProfile) {
      this.profileService
        .companySelection(this.authService.currentUserProfile.userId)
        .subscribe(companies =>
          this.onDataLoadSuccessful(companies),
          error => this.onDataLoadFailed(error)
        );
    } else {
      this.getInitiativeCompany();
    }
  }
  getInitiativeCompany() {
    this.companiesService
      .getCompany(0)
      .subscribe(company =>
        this.onDataLoadSuccessful([company]),
        error => this.onDataLoadFailed(error)
      );
  }

  onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve companies from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }
  onDataLoadSuccessful(companies: Company[]): void {
    if (!companies) {
      this.getInitiativeCompany();
    } else if (companies.length <= 0) {
      this.getInitiativeCompany();
    } else {
      this.alertService.stopLoadingMessage();
      this.loadingIndicator = false;
      this.dataSource = companies;
    }

    if (companies.length >= 1) {
      var choice = companies[0];
      var storeValue = true;

      if (this.authService.currentUserCompany && this.dataSource.filter(c => c.companyId == this.authService.companyId).length > 0) {
        choice = this.dataSource.find(c => c.companyId == this.authService.companyId);
        storeValue = false;
      }
      this.selectedValue = choice;
      this.selectionMade(storeValue);
    }

  }

  selectionMade(storeValue: boolean = true) {
    if (this.selectedValue && this.authService.currentUserCompany)
      if (this.selectedValue.companyId == this.authService.companyId)
        return;

    if (storeValue == true)
      this.authService.currentUserCompany = this.selectedValue;
    this.onCompanySelected.next(this.selectedValue);

    var path = this.router.url;
    if (path[0] == '/' && path.length > 1)
      path = path.substr(1);
    var config = this.router.config.filter(p => p.path.startsWith(path));
    if (!config || config.length <= 0) {
      if (path.indexOf('/') > 0)
        path = path.substring(0, this.router.url.lastIndexOf("/"));
      config = this.router.config.filter(p => p.path.startsWith(path));
    }
    if (config.length > 0 && !config[0].data['navigateOnCompanyChange'])
      this.router.navigateByUrl("/");
  }
}
