import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Company } from 'src/app/models/company';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Router } from '@angular/router';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from 'src/app/services/utilities';
import { CompanyViewDialogComponent } from '../../company-view-dialog/company-view-dialog.component';

@Component({
  selector: 'app-company-clients-control',
  templateUrl: './company-clients-control.component.html',
  styleUrls: ['./company-clients-control.component.scss']
})
export class CompanyClientsControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  get canEditClients(): boolean {
    return this.authService.userIsCompanyAdmin;
  }

  get displayedColumns(): string[] {
    if (this.canEditClients) {
      return [
        'companyName',
        'contactName',
        'contactEmailAddress',
        'actions'
      ];      
    }

    return [
      'companyName',
      'contactName',
      'contactEmailAddress'
    ];
  };
  dataSource: MatTableDataSource<Company>;
  loadingIndicator: boolean;

  @Input() companyId: number;

  constructor(
    private alertService: AlertService,
    private companiesService: CompaniesService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: AppTranslationService,
    private authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public companyFound(company: Company) {
    this.companyId = company.companyId;
    this.loadData();
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService
      .getClientCompanies(this.companyId)
      .subscribe(entries =>
        this.onDataLoadSuccessful(entries),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: Company[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private launch(entry: Company) {
    this.authService.currentUserCompany = entry;
    //this.router.navigateByUrl(`/company/${entry.companyId}`);
  }

  private newClient() {
    var client = new Company();

    const dialogRef = this.dialog.open(CompanyViewDialogComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { company: client }
      });
    dialogRef.afterClosed().subscribe(client => {
      if (client) {
        this.connectClient(client);
      }
    });
  }

  private connectClient(client: Company) {
    this.companiesService.connectCompany(this.companyId, client.companyId, false)
      .subscribe(
        c => this.addClientToList(c),
        error => this.onDataLoadFailed(error)
      );
  }

  private addClientToList(client: Company) {
    if (this.dataSource.data.filter(c => c.companyId == client.companyId).length > 0)
      return;

    this.dataSource.data.push(client);
  }

  private telLink(phone: string): string {
    return `tel:${phone.replace(/\D/g, '')}`;
  }

  private mailLink(email: string): string {
    return `mailto:${email}`;
  }
}
