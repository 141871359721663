import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MaterialListControlComponent } from '../controls/material-list-control/material-list-control.component';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { fadeInOut } from 'src/app/services/animations';

@Component({
  selector: 'app-materials',
  templateUrl: './materials.component.html',
  styleUrls: ['./materials.component.scss'],
  animations: [fadeInOut]
})
export class MaterialsComponent implements OnInit, AfterViewInit {
  @ViewChild(MaterialListControlComponent) list: MaterialListControlComponent;

  companyId: number;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.list.companyIdFound(this.companyId);
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

}
