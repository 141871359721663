import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { CourseListControlComponent } from '../controls/course-list-control/course-list-control.component';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
  animations: [fadeInOut]
})
export class CoursesComponent implements OnInit, AfterViewInit {
  @ViewChild(CourseListControlComponent) list: CourseListControlComponent;

  companyId: number;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.list.companyIdFound(this.companyId);
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

}
