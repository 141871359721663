<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <app-answer-control [answer]="answer"
                      position="single"
                      (onValueDelete)="valueDelete($event)">

  </app-answer-control>
  <div fxFlex="auto"></div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="done();" color="primary">{{'commands.Done' | translate}}</button>
</div>
