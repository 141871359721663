import { IAuditableEntity } from "./IAuditableEntry.interface";
import { XrAnswerType } from "./xr-answer-type.model";
import { AnswerValue } from "./answer-value.model";

export class Answer implements IAuditableEntity {
  public answerId: number;
  public testId: number;
  public questionId: number;
  public answerTypeId: number;
  public code: string;
  public title: string;
  public weight: number;
  public pointValue: number;
  public freeTextCharacterLimit: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public ordinal: number;
  public xrType: string;
  public deleted: boolean;

  public answerType: XrAnswerType;
  public answerValue: AnswerValue[];
}
