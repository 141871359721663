import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Profile } from 'src/app/models/profile';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';
import { Router } from '@angular/router';
import { ContactEditorDialogComponent } from 'src/app/components/invitation/dialogs/contact-editor-dialog/contact-editor-dialog.component';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { ContactUploadDialogComponent } from 'src/app/components/dialogs/contact-upload-dialog/contact-upload-dialog.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ProfileService } from 'src/app/services/profile.service';
import { AuthService } from 'src/app/services/auth.service';

import { SelectionModel } from '@angular/cdk/collections';

class SelectableProfile extends Profile {
  public checked: boolean;
}

@Component({
  selector: 'app-individuals-selection-control',
  templateUrl: './individuals-selection-control.component.html',
  styleUrls: ['./individuals-selection-control.component.scss']
})
export class IndividualsSelectionControlComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() displayProfiles: Profile[];
  @Input() companyId: number;
  @Input() teamId: number;

  @Input() pageSizeIncrements: number[] = [25, 50, 100];

  displayedColumns = [
    'selected',
    'lastName',
    'firstName',
    'email'
  ];
  dataSource: MatTableDataSource<Profile>;
  loadingIndicator: boolean;
  selection = new SelectionModel<Profile>(true, []);

  public get selectedProfiles(): Profile[] {
    return this.selection.selected;
  }

  public get pageSizeOptions(): number[] {
    if (!this.pageSizeIncrements || this.pageSizeIncrements.length <= 0)
      return [25, 50, 100];
    return this.pageSizeIncrements;
  }

  constructor(
    private alertService: AlertService,
    private companiesService: CompaniesService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: AppTranslationService,
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  public refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  public reset(entries: Profile[]) {
    this.dataSource.data = entries;
    this.refresh();
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    if (this.displayProfiles) {
      this.onDataLoadSuccessful(this.displayProfiles);
    }
    else {
      this.companiesService
        .getProfilesByCompany(this.companyId)
        .subscribe(entries =>
          this.onDataLoadSuccessful(entries),
          error => this.onDataLoadFailed(error));
    }
  }

  private onDataLoadSuccessful(entries: Profile[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Profile): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.dataSource.filteredData.indexOf(row) + 1}`;
  }

}
