import { IAuditableEntity } from "./IAuditableEntry.interface";

export class MaterialContent implements IAuditableEntity {
  public materialContentId: number;
  public materialId: number;
  public title: string;
  public description: string;
  public imageFileObjectId: number;
  public mediaFileObjectId: number;
  public applicationType: string;
  public content: string;
  public contentUrl: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
}
