import { IAuditableEntity } from "./IAuditableEntry.interface";
import { XrGender } from "./xr-gender";
import { ProfileCompany } from "./profile-company";

export class Profile implements IAuditableEntity {
  constructor() {

  }

  public userId: number;
  public userName: string;
  public email: string;
  public firstName: string;
  public preferredName: string;
  public middleName: string;
  public lastName: string;
  public suffix: string;
  public salutation: string;
  public educationCredentials: string;
  public genderId: number;
  public phoneNumber: string;
  public photoBase64: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;

  public gender: XrGender;
  public userCompany: ProfileCompany[];
}
