import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import * as XLSX from 'xlsx';
import { InvitationContact } from 'src/app/models/invitation-sender.model';

@Component({
  selector: 'app-user-upload-control',
  templateUrl: './user-upload-control.component.html',
  styleUrls: ['./user-upload-control.component.scss']
})
export class UserUploadControlComponent implements OnInit {
  @Input() showButton: boolean = true;

  @Output() onContactsReady = new EventEmitter<InvitationContact[]>();

  constructor() { }

  ngOnInit() {
  }

  arrayBuffer: any;
  file: File;
  filesReady(files: any) {
    if ((files || []).length <= 0)
      return;

    this.file = files[0];
  }

  public Upload() {
    if (!this.file)
      return;

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.FileLoaded(fileReader);

    }
    fileReader.readAsArrayBuffer(this.file);
  }

  FileLoaded(fileReader: FileReader) {
    this.arrayBuffer = fileReader.result;
    var data = new Uint8Array(this.arrayBuffer);
    var arr = new Array();
    for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    var bstr = arr.join("");
    var workbook = XLSX.read(bstr, { type: "binary" });
    var first_sheet_name = workbook.SheetNames[0];
    var worksheet = workbook.Sheets[first_sheet_name];
    var json = JSON.stringify(XLSX.utils.sheet_to_json(worksheet, { raw: true }));

    var copy: InvitationContact[] = JSON.parse(json);
    copy.forEach(c => {
      if (!c.email) {
        const pemails: string[] = ["Email", "EmailAddress", "Email_Address", "emailaddress", "email_address"];
        pemails.forEach(f => { if (f in c) { c.email = c[f]; } });
      }
      if (!c.firstName) {
        const pfirst: string[] = ["First", "FirstName", "First_Name", "first", "first_name"];
        pfirst.forEach(f => { if (f in c) { c.firstName = c[f]; } });
      }
      if (!c.lastName) {
        const plast: string[] = ["Last", "LastName", "Last_Name", "last", "last_name"];
        plast.forEach(f => { if (f in c) { c.lastName = c[f]; } });
      }
      if (!c.firstName && !c.lastName) {
        const pname: string[] = ["Name", "name"];
        pname.forEach(f => {
          if (f in c) {
            var names = c[f].toString().split(" ");
            c.firstName = names[0];
            names.splice(0, 1);
            c.lastName = names.join(" ");
          }
        })
      }
    });

    copy.forEach(c => {
      if (c.email == "") return;
      if (c.lastName == "") c.lastName = c.email.split('@')[0];
    });

    var results = copy.filter(c => c.email && c.email != "");
    this.onContactsReady.emit(results);
  }
}
