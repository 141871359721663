import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { TaskItem } from 'src/app/models/task-item.model';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { TasksService } from 'src/app/services/tasks.service';
import { XrService } from 'src/app/services/xr.service';
import { XrAnswerType } from 'src/app/models/xr-answer-type.model';
import { TaskItemProgress } from 'src/app/models/task-item-progress.model';
import { Task } from 'src/app/models/task.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { UserTestQuestionAnswer } from 'src/app/models/user-test-question-answer.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AnswerChangeEventArgs } from 'src/app/models/answer-change-event-args.model';
import { ProgressSavedEventArgs } from 'src/app/models/progress-saved-event-args.model';
import { XrGender } from 'src/app/models/xr-gender';
import { MAT_CHECKBOX_CLICK_ACTION } from '@angular/material';
import { AnswerValue } from 'src/app/models/answer-value.model';
import { fadeInOut, fadeThrough, flyInOut } from '../../../../services/animations';

@Component({
  selector: 'app-task-item-control',
  templateUrl: './task-item-control.component.html',
  styleUrls: ['./task-item-control.component.scss'],
  providers: [
    { provide: MAT_CHECKBOX_CLICK_ACTION, useValue: 'check' }
  ],
  animations: [ flyInOut, fadeThrough ]
})
export class TaskItemControlComponent implements OnInit {

  id: number;

  @Input() set taskItemId(value: number) {
    this.id = value;
    this.prepControl(this.id);
  };
  get taskItemId(): number {
    return this.id;
  };
  @Input() index: number;
  @Input() isEditMode = false;
  @Input() showBack: boolean;
  @Input() showNext: boolean;

  @ViewChild('form') private form: NgForm;
  answerForm: FormGroup;

  private item: TaskItem = new TaskItem();
  private answerValue: string = "";
  private multiAnswerValue: string[] = [];

  public continuing: boolean = false;

  private onTaskItemSaved = new Subject<TaskItem>();
  taskItemSaved$ = this.onTaskItemSaved.asObservable();

  @Output() progressSaved: EventEmitter<ProgressSavedEventArgs> = new EventEmitter<ProgressSavedEventArgs>();
  @Output() answerChanged: EventEmitter<AnswerChangeEventArgs> = new EventEmitter<AnswerChangeEventArgs>();

  @Output() back: EventEmitter<void> = new EventEmitter();
  @Output() forward: EventEmitter<void> = new EventEmitter();


  selectionValues: string[];
  selectionSet: AnswerValue[];

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  get itemType(): string {
    if (!this.item) return 'n/a';

    if ((this.item.questionId | 0) != 0) return 'question';
    if ((this.item.testId | 0) != 0) return 'test';
    if ((this.item.materialContentId | 0) != 0) return 'content';
    if ((this.item.materialId | 0) != 0) return 'material';

    return 'n/a';
  }

  get labelText(): string {
    if (this.itemType == 'question')
      return this.translationService.getTranslation('taskItemControl.Question');
    else
      return this.item.taskItemName;
  }

  get answerType(): XrAnswerType {
    var anstype: XrAnswerType = this.xrService.getAnswerType(4); //free-text is the default;

    if (this.item) {
      if (this.item.answer) {
        anstype = this.item.answer.answerType;
      }
    }

    return anstype;
  }

  get answer() {
    return this.answerForm.get('answer');
  }
  get selectionAnswer() {
    return this.answerForm.get('selectionAnswer');
  }
  get checkboxAnswer() {
    return this.answerForm.get('checkboxAnswer');
  }
  get multiselectAnswer() {
    return this.answerForm.get('multiselectAnswer');
  }
  get selectionButtonAnswer() {
    return this.answerForm.get('selectionButtonAnswer');
  }

  get answerControl() {
    if (this.useSelectionAnswer) {
      return this.selectionAnswer;
    } else if (this.useCheckboxAnswer) {
      return this.checkboxAnswer;
    } else if (this.useMultiselectAnswer) {
      return this.multiselectAnswer;
    } else if (this.useSelectionButtons) {
      return this.selectionButtonAnswer;
    } else {
      return this.answer;
    }
  }

  get isDirty() {
    return this.answerControl.dirty;
  }

  get valid() {
    return this.answerControl.valid;
  }

  get taskItemName() {
    if (!this.item)
      return this.translationService.getTranslation('taskItemControl.NA');;

    return this.item.taskItemName;
  }

  get useSelectionAnswer() {
    return (this.answerType.code == 'T/F' || this.answerType.code == '1S' || this.answerType.code == 'XR');
  }
  get useCheckboxAnswer() {
    return (this.answerType.code == 'CHK');
  }
  get useMultiselectAnswer() {
    return (this.answerType.code == 'MS');
  }
  get useSelectionButtons() {
    return (this.answerType.code == "1SB");
  }

  constructor(
    private alertService: AlertService,
    private taskService: TasksService,
    private xrService: XrService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService
  ) { }

  ngOnInit() {
    this.xrService.getGenders(); //preps the data, just in case.
    this.xrService.getAnswerTypes(); //preps the data, just in case.
    
    this.prepControl(this.taskItemId);
  }

  prepControl(id: number) {
    this.taskService.getTaskItem(this.taskItemId).subscribe(data => this.parseTaskItem(data));

    this.buildForm();
  }

  parseTaskItem(data: TaskItem) {
    this.item = data;
    
    if (this.item.answer) {
      this.item.answer.answerType = this.xrService.getAnswerType(this.item.answer.answerTypeId);
      if (this.item.answer.answerType.code == "T/F") {
        this.selectionValues = [
          this.translationService.getTranslation("taskItemControl.True"),
          this.translationService.getTranslation("taskItemControl.False")
        ];
      }
      else if (["1S","MS"].includes(this.item.answer.answerType.code)) {
        this.selectionValues = this.item.answer.answerValue.filter(x => x.deleted == false).sort((a, b) => a.ordinal > b.ordinal ? 1 : -1).map(val => val.title);
      }
      else if (this.item.answer.answerType.code == "1SB") {
        this.selectionSet = this.item.answer.answerValue.filter(x => x.deleted == false).sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);
      }
      else if (this.item.answer.answerType.code == "XR") {
        switch (this.item.answer.xrType) {
          case "xrGender": {
            this.selectionValues = this.xrService.getGenders().map(val => val.title);
            break;
          }
        }
      }
    }

    this.answerValue = this.item.userTestQuestionAnswer.comment;
    if (this.useSelectionAnswer) {
      var mav = this.answerValue.split(',');
      if (this.answerType.code == "XR") {
        switch (this.item.answer.xrType) {
          case "xrGender": {
            this.xrService.getGenders().forEach(x => {
              if (x.code == this.answerValue)
                this.answerValue = x.title;
            });
            break;
          }
        }
      }
      else if (this.item.answer.answerType.code == "T/F") {
        if (this.answerValue == "true")
          this.answerValue = this.translationService.getTranslation("taskItemControl.True");
        else
          this.answerValue = this.translationService.getTranslation("taskItemControl.False");
      }
      else if (this.item.answer.answerType.code == "1S") {
        this.item.answer.answerValue.forEach(x => {
          if (x.code == this.answerValue)
            this.answerValue = x.title;
        });
      }
    }
    this.answerControl.patchValue(this.answerValue);
    //this.answer.patchValue(this.answerValue);
    //this.selectionAnswer.patchValue(this.answerValue);
    if (this.useCheckboxAnswer && this.answerValue != "")
      this.checkboxAnswer.patchValue(JSON.parse(this.answerValue));
    //else
    //  this.checkboxAnswer.patchValue(this.answerValue);
    else if (this.useMultiselectAnswer) {
      this.multiAnswerValue = this.answerValue.split(',');
      this.item.answer.answerValue.forEach(x => {
        var mi = this.multiAnswerValue.indexOf(x.code);
        if (mi >= 0)
          this.multiAnswerValue[mi] = x.title;
      });
      this.multiselectAnswer.patchValue(this.multiAnswerValue);
    }
    //this.selectionButtonAnswer.patchValue(this.answerValue);
  }

  buildForm() {
    this.answerForm = this.formBuilder.group({
      answer: [this.answerValue, Validators.required],
      selectionAnswer: [this.answerValue, Validators.required],
      checkboxAnswer: this.answerValue,
      multiselectAnswer: [this.multiAnswerValue, Validators.required],
      selectionButtonAnswer: [this.answerValue, Validators.required]
    });

    this.answerControl.valueChanges.subscribe(value => this.emitAnswerChanged(value));
    this.answerControl.statusChanges.subscribe(value => this.checkError(value));
  }

  resetForm() {
    this.answerForm.reset({
      answer: this.answerValue || '',
      selectionAnswer: this.answerValue || '',
      checkboxAnswer: this.answerValue || '',
      multiselectAnswer: this.multiAnswerValue || '',
      selectionButtonAnswer: this.answerValue || ''
    });
    this.continuing = false;
  }

  translate(key: string) {
    return this.translationService.getTranslation(`taskItemControl.${key}`);
  }

  setAnswer(answer: string, moveOn: boolean = false) {
    if (this.continuing)
      return;

    this.answerControl.patchValue(answer);
    this.answerControl.markAsDirty();
    if (moveOn == true)
      this.continue();
  }

  continue() {
    if (this.continuing)
      return;

    if (this.itemType == 'question' && (!this.valid)) {
      this.alertService.showMessage(this.translate("InvalidAnswerTitle"), this.translate("InvalidAnswer"), MessageSeverity.warn);
      return;
    }

    this.continuing = true;

    var newanswer = this.answerControl.value;
    if (this.useMultiselectAnswer == true)
      newanswer = this.multiselectAnswer.value.toString();

    var tip: TaskItemProgress = new TaskItemProgress();
    tip.taskItemId = this.item.taskItemId;
    tip.progressInfo = '';
    tip.answer = this.convertAnswerValue(newanswer);
    tip.by = ''; //this will be filled in by the API...

    this.taskService.patchRecordTaskItemProgress(tip).subscribe(
      data => {
        setTimeout(() => {
          this.parseProgressResult(data);
        }, 250);
      },
      error => {
        setTimeout(() => {
          this.checkError(error);
        }, 250);
      });
  }

  convertAnswerValue(value: string): string {
    var av = (JSON.parse(JSON.stringify(value)));

    if (this.useSelectionAnswer) {
      if (this.answerType.code == "XR") {
        switch (this.item.answer.xrType) {
          case "xrGender": {
            this.xrService.getGenders().forEach(x => {
              av = av.replace(x.title, x.code);
            });
            break;
          }
        }
      }
      else if (this.item.answer.answerType.code == "T/F") {
        if (av == this.translationService.getTranslation("taskItemControl.True"))
          av = "true";
        else
          av = "false";
      }
      else if (this.item.answer.answerType.code == "1S") {
        this.item.answer.answerValue.forEach(x => {
          av = av.replace(x.title, x.code);
        });
      }
    } else if (this.useMultiselectAnswer) {
      this.item.answer.answerValue.forEach(x => {
        av = av.replace(x.title, x.code);
      });
    }

    return av;
  }

  get convertedAnswerValue(): string {
    this.answerValue = this.answerControl.value;
    if (this.useMultiselectAnswer == true)
      this.answerValue = this.multiselectAnswer.value.toString();

    this.multiAnswerValue = this.useMultiselectAnswer ? this.answerValue.split(',') : [this.answerValue];

    return this.convertAnswerValue(this.answerValue);
  }

  parseProgressResult(data: Task) {
    this.item.userTestQuestionAnswer.comment = this.convertedAnswerValue;
    this.progressSaved.emit(new ProgressSavedEventArgs(this.index, this.taskItemId, data));
    this.resetForm();
  }

  checkError(error: string) {
    this.continuing = false;

    if (error != "INVALID")
      return;

    var noanswer = this.answerControl.value;
    if (this.useMultiselectAnswer == true)
      noanswer = this.multiselectAnswer.value.toString();

    this.emitAnswerChanged(noanswer);
  }

  emitAnswerChanged(value: any) {
    if (this.itemType != "question")
      return;

    var testvalue = value.toString();
    var ansvalue = this.answerValue.toString();

    if (this.useCheckboxAnswer == true) {
      testvalue = JSON.parse(testvalue == "true" ? "true" : "false");
      ansvalue = JSON.parse(ansvalue == "true" ? "true" : "false");
    }
    
    if (ansvalue == testvalue)
      return;

    testvalue = this.convertAnswerValue(testvalue);

    this.answerChanged.emit(new AnswerChangeEventArgs(this.index, this.taskItemId, testvalue, this.itemType));
  }

  moveForward() {
    this.forward.emit();
  }
  moveBack() {
    this.back.emit();
  }
}
