import { Component, OnInit, ViewChild, ViewChildren, QueryList, ApplicationRef, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatDialog, MatAccordion, MatSnackBar, MatExpansionPanel } from '@angular/material';
import { IndividualsSelectionDialogComponent } from '../dialogs/individuals-selection-dialog/individuals-selection-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Team, UserTeam } from 'src/app/models/team.model';
import { TeamsService } from 'src/app/services/teams.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Profile } from 'src/app/models/profile';
import { Utilities } from 'src/app/services/utilities';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { IndividualsSelectionControlComponent } from '../controls/individuals-selection-control/individuals-selection-control.component';
import { fadeInOut } from 'src/app/services/animations';


@Component({
  selector: 'app-company-teams',
  templateUrl: './company-teams.component.html',
  styleUrls: ['./company-teams.component.scss'],
  animations: [fadeInOut]
})
export class CompanyTeamsComponent implements OnInit, AfterViewInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChildren(MatExpansionPanel) matExpansionPanelQueryList: QueryList<MatExpansionPanel>;
  @ViewChildren('teamList') teamLists: QueryList<IndividualsSelectionControlComponent>;

  loadingIndicator: boolean;

  companyId: number;
  teams: Team[];

  expandedTeamId: number;

  get currentProfileSelection(): Profile[] {
    if (this.expandedTeamId == 0 || !this.teamLists)
      return [];
    if (this.teamLists.length <= 0)
      return [];
    if (this.teamLists.filter(c => c.teamId == this.expandedTeamId).length <= 0) 
      return [];

    return this.teamLists.filter(c => c.teamId == this.expandedTeamId)[0].selectedProfiles;
  }

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private teamService: TeamsService,
    private translationService: AppTranslationService,
    private snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params)
      });
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {

  }

  loadData() {
    this.loadingIndicator = true;
    this.alertService.startLoadingMessage();
    this.teamService.getTeamsByCompany(this.companyId, true)
      .subscribe(
        teams => this.onDataLoadSuccessful(teams),
        error => this.onDataLoadFailed(error)
      );
  }

  private getTeamProfiles(team: Team): Profile[] {
    var plist = [];
    team.userTeam.forEach(x => plist.push(x.user));
    return plist;
  }

  private onDataLoadSuccessful(entries: Team[]) {
    this.matExpansionPanelQueryList.changes.subscribe(
      change => {
        if (!change) return;
        if (!change.last) return;
        change.last.open();
        this.cd.detectChanges();
      }
    );

    this.alertService.stopLoadingMessage();
    this.teams = entries;
    this.loadingIndicator = false;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private updateTeam(team: Team) {
    this.teamService.updateTeam(team).subscribe();
  }

  private deleteUsers(team: Team) {
    var selection = this.currentProfileSelection;

    //prompt, locate user selection, and remove...
    var msg = this.translationService.getTranslation('companyTeams.DeleteUsersPrompt');
    var act = this.translationService.getTranslation('companyTeams.Delete');
       
    var listControl: IndividualsSelectionControlComponent =
      this.teamLists.filter(c => c.teamId == this.expandedTeamId)[0];

    var profiles = listControl.selectedProfiles;

    this.snackBar.open(msg, act, { duration: 5000 })
      .onAction().subscribe(() => {
        this.removeProfilesFromTeam(profiles);
      });
  }

  private removeProfilesFromTeam(profiles: Profile[]) {
    for (var p of profiles) {
      this.teamService.disconnectUserTeam(p.userId, this.expandedTeamId)
        .subscribe(
          ut => this.removeUserTeamFromTeam(ut),
          error => { }
        );
    }
  }

  private removeUserTeamFromTeam(ut: UserTeam) {
    var team = this.teams.filter(x => x.teamId == this.expandedTeamId)[0];
    team.userTeam = team.userTeam.filter(x => x.userId != ut.userId);

    var listControl: IndividualsSelectionControlComponent =
      this.teamLists.filter(c => c.teamId == this.expandedTeamId)[0];

    listControl.reset(this.getTeamProfiles(team));
  }

  private deleteTeam(team: Team) {
    //prompt, and remove...

    var msg = this.translationService.getTranslation('companyTeams.DeleteTeamPrompt');
    var act = this.translationService.getTranslation('companyTeams.Delete');
       
    this.snackBar.open(msg, act, { duration: 5000 })
      .onAction().subscribe(() => {
        this.teamService.deleteTeam(team.teamId)
          .subscribe(
            () => this.removeTeamFromList(team),
            error => { }
          );
      });
  }

  private removeTeamFromList(team: Team) {
    this.teams = this.teams.filter(t => t.teamId != team.teamId);
  }

  select(team: Team) {
    const dialogRef = this.dialog.open(IndividualsSelectionDialogComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: {companyId: this.companyId}
      });
    dialogRef.afterClosed().subscribe(profiles => {
      if (profiles) {
        this.addProfilesToTeam(profiles);
      }
    });
  }

  compose(team: Team) {
    this.router.navigateByUrl(`/invitation/send/team/${team.teamId}`);
  }

  private addProfilesToTeam(profiles: Profile[]) {
    for (var p of profiles) {
      this.teamService.connectUserTeam(p.userId, this.expandedTeamId)
        .subscribe(
          ut => this.addUserTeamToTeam(ut),
          error => { }
        );
    }
  }

  private addUserTeamToTeam(ut: UserTeam) {
    var team = this.teams.filter(x => x.teamId == this.expandedTeamId)[0];
    var userteams = team.userTeam;
    if (userteams.filter(x => x.userId == ut.userId).length <= 0)
      userteams.push(ut);

    var listControl: IndividualsSelectionControlComponent =
      this.teamLists.filter(c => c.teamId == this.expandedTeamId)[0];

    listControl.reset(this.getTeamProfiles(team));
  }

  newTeam() {
    var team = new Team();
    team.companyId = this.companyId;
    team.teamName = this.translationService.getTranslation("companyTeams.NewTeamTitle");

    this.teamService.createTeam(team).subscribe(
      result => this.addNewTeam(result),
      error => this.onDataLoadFailed(error)
    );
  }
  private addNewTeam(team: Team) {
    this.teams.push(team);
    this.expandedTeamId = team.teamId;
  }

  private setExpandedTeamId(teamId: number) {
    this.expandedTeamId = teamId;
  }
}
