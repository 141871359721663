import { IAuditableEntity } from "./IAuditableEntry.interface";
import { CompanyCredit } from "./company-credit";
import { Client } from "./client.model";

export class Company implements IAuditableEntity {
  constructor() {

  }
  public companyId: number;
  public companyName: string;
  public companyPhoneNumber: string;
  public address: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public website: string;
  public contactName: string;
  public contactPhoneNumber: string;
  public contactEmailAddress: string;
  public logoFullBase64: string;
  public logoSquareBase64: string;
  public unlimitedCredits: boolean;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;

  public locked: boolean;

  public deletedBy: string;
  public deletedDate: Date;

  public notifyEmailAddresses: string;

  public clientCompanyId2Navigation: Client[];

  public companyCredits: CompanyCredit[];

  public get creditsRemaining(): number {
    if (this.unlimitedCredits == true)
      return 999999999;

    if (!this.companyCredits)
      return 0;

    return this.companyCredits.filter(c => (c.userTestId || 0) <= 0).length;
  }

  public get deleted(): boolean {
    return this.deletedDate != null;
  }
}
