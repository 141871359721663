import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { XrService } from 'src/app/services/xr.service';
import { Question } from 'src/app/models/question.model';
import { MatDialog } from '@angular/material';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AnswerValue } from 'src/app/models/answer-value.model';

@Component({
  selector: 'app-question-control',
  templateUrl: './question-control.component.html',
  styleUrls: ['./question-control.component.scss']
})
export class QuestionControlComponent implements OnInit {
  @Input() question: Question = new Question;
  @Input() position: string = "";

  @Output() onDelete = new EventEmitter<Question>();
  @Output() onMoveUp = new EventEmitter<Question>();
  @Output() onMoveDown = new EventEmitter<Question>();

  @Output() onAnswerDelete = new EventEmitter<Answer>();
  @Output() onValueDelete = new EventEmitter<AnswerValue>();

  get editObj(): Question {
    if (this.question)
      return this.question;
    return new Question;
  }

  get noAnswer(): boolean {
    if (!this.question) return true;
    if (!this.answers) return true;
    return this.answers.length <= 0;
  }

  get answers(): Answer[] {
    if (!this.editObj.answer || this.editObj.answer.length <= 0) {
      this.editObj.answer = [];
    }

    this.editObj.answer = this.editObj.answer.filter(a => a.deleted == false);

    return this.editObj.answer;
  }

  constructor(
    private xrService: XrService,
    private translateService: AppTranslationService,
    private dialog: MatDialog
  ) {  }

  ngOnInit() {
  }

  launchAnswerEditor(a: Answer) {

  }

  delete() {
    this.onDelete.emit(this.question);
  }

  moveUp() {
    this.onMoveUp.emit(this.question);
  }

  moveDown() {
    this.onMoveDown.emit(this.question);
  }

  private resetOrdinals() {
    this.answers.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);

    var ordinal: number = 0;
    this.answers.forEach(x => {
      ordinal += 100;
      x.ordinal = ordinal;
    });   
  }

  private answerPosition(a: Answer): string {
    var index = this.answers.indexOf(a);

    var first: boolean = index == 0;
    var last: boolean = index == (this.answers.length - 1);

    return (first == true && last == true ? "single" : first == true ? "first" : last == true ? "last" : "");
  }

  private answerDelete(a: Answer) {
    var index = this.answers.findIndex(x => x.answerId == a.answerId);
    if (index >= 0) 
      this.onAnswerDelete.emit(this.answers.splice(index, 1)[0]);
    this.resetOrdinals();
  }
   
  private answerMoveUp(a: Answer) {
    var ord = this.answers.find(x => x.answerId == a.answerId);
    ord.ordinal -= 150;
    this.resetOrdinals();
  }

  private answerMoveDown(a: Answer) {
    var ord = this.answers.find(x => x.answerId == a.answerId);
    ord.ordinal += 150;
    this.resetOrdinals();
  }

  private addAnswerAt(ordinal: number, before: boolean = false) {
    var minid = 0;
    if (this.question)
      if (this.answers.length > 0)
        minid = this.answers.map(x => x.answerId).sort((a, b) => a > b ? 1 : -1)[0];

    minid = (minid <= 0 ? minid - 1 : -1);

    var answer = new Answer();
    answer.answerId = minid * 1000 - 1;
    answer.testId = this.question.testId;
    answer.questionId = minid;
    answer.answerTypeId = 4; //TXT
    answer.code = `A${answer.answerId}`;
    answer.title = this.translateService.getTranslation("testEditor.NewResponseTitle");
    answer.weight = 1;
    answer.pointValue = 1;
    answer.freeTextCharacterLimit = 1000;
    answer.ordinal = ordinal + (before == true ? -50 : 50);
    answer.xrType = "";
    answer.deleted = false;

    this.answers.push(answer);
    this.resetOrdinals();
  }
   
  private valueDelete(av: AnswerValue) {
    this.onValueDelete.emit(av);
  }
}
