<div fxLayout="column" fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutGap="12px">
    <div fxFlex="auto">
      <app-company-card-control [companyId]="companyId" [getDefaultCompany]="useDefaultCompany"></app-company-card-control>
    </div>
  </div>
  <div *ngIf="canViewPriviligedUsers || canViewClients" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="12px">
    <div *ngIf="canViewPriviligedUsers" fxFlex="auto">
      <app-company-privileged-users-control [companyId]="companyId"></app-company-privileged-users-control>
    </div>
    <div *ngIf="canViewClients" fxFlex="auto">
      <app-company-clients-control [companyId]="companyId"></app-company-clients-control>
    </div>
  </div>
  <div *ngIf="canViewPriviligedUsers" fxLayout="auto">
    <app-company-usage-control fxFlex="auto" [companyId]="companyId"></app-company-usage-control>
  </div>
</div>
