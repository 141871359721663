<h1 mat-dialog-title>
  {{data.title || ('teamSelectionDialog.Title' | translate)}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">

  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="selected">
      <mat-header-cell fxFlex="60px" *matHeaderCellDef mat-sort-header>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()"
                      [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell fxFlex="60px" *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)"
                      [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="teamName">
      <mat-header-cell fxFlex="75%" *matHeaderCellDef mat-sort-header> {{'teamSelectionDialog.TeamName' | translate}} </mat-header-cell>
      <mat-cell fxFlex="75%" fxFlex.lt-sm="100%" *matCellDef="let row"> {{row.teamName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="members">
      <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header> {{'teamSelectionDialog.Members' | translate}} </mat-header-cell>
      <mat-cell fxFlex="25%" *matCellDef="let row" fxHide.lt-sm> {{row.userTeam.length}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="select();" color="primary">{{'commands.Select' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
