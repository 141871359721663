import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CourseMaterial } from 'src/app/models/course-material.model';
import { Material } from 'src/app/models/material.model';

@Component({
  selector: 'app-course-material-control',
  templateUrl: './course-material-control.component.html',
  styleUrls: ['./course-material-control.component.scss']
})
export class CourseMaterialControlComponent implements OnInit {
  @Input() courseMaterial: CourseMaterial = new CourseMaterial;
  @Input() position: string = "";

  @Output() onDelete = new EventEmitter<CourseMaterial>();
  @Output() onMoveUp = new EventEmitter<CourseMaterial>();
  @Output() onMoveDown = new EventEmitter<CourseMaterial>();
   
  get editObj(): CourseMaterial {
    if (this.courseMaterial)
      return this.courseMaterial;
    return new CourseMaterial;
  }

  get material(): Material {
    return this.editObj.material || new Material(-1, "");
  }

  get icon(): string {
    if (!this.editObj.material) return "";

    return "description";
  }

  constructor() { }

  ngOnInit() {
  }

  delete() {
    this.onDelete.emit(this.courseMaterial);
  }
  moveUp() {
    this.onMoveUp.emit(this.courseMaterial);
  }
  moveDown() {
    this.onMoveDown.emit(this.courseMaterial);
  }

}
