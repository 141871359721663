<div class="search-box" fxLayout="row" fxLayout.lt-lg="column">
  <mat-form-field fxFlex="50%">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'companyReports.list.Search' | translate}}">
  </mat-form-field>
  <div fxFlex="auto" fxHide.lt-sm></div>
  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutAlign="center center" fxHide>
    <app-company-p60-option-selector-control></app-company-p60-option-selector-control>
    <app-company-p60-benchmark-selector-control></app-company-p60-benchmark-selector-control>
  </div>
</div>

<div class="simple-border">
  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="testName">
      <mat-header-cell fxFlex="70%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matHeaderCellDef mat-sort-header> {{'companyReports.list.TestName' | translate}} </mat-header-cell>
      <mat-cell fxFlex="70%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matCellDef="let row">
        <span fxHide.sm fxHide.lt-sm>
          {{row.testName}}
        </span>
        <span fxHide.gt-sm>
          {{row.testName}}
          <br />{{'companyReports.list.Completed' | translate}}: {{row.completed | date:'short'}}
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="completed">
      <mat-header-cell fxFlex="30%" *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'companyReports.list.Completed' | translate}} </mat-header-cell>
      <mat-cell fxFlex="30%" *matCellDef="let row" fxHide.sm fxHide.lt-sm> {{row.completed | date:'short'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="release">
      <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button mat-icon-button color="primary" matTooltip="{{'companyReports.list.ReleaseAll' | translate}}" (click)="releaseAll()">
          <mat-icon>lock_open</mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button *ngIf="!entry.released" mat-icon-button matTooltip="{{'companyReports.list.Release' | translate}}" (click)="release(entry)">
          <mat-icon *ngIf="entry.hasCredit">lock_open</mat-icon>
          <mat-icon *ngIf="!entry.hasCredit" color="warn">error_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="download">
      <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button mat-icon-button color="primary" matTooltip="{{'companyReports.list.DownloadAll' | translate}}" (click)="downloadAll()">
          <mat-icon>cloud_download</mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button mat-icon-button matTooltip="{{'companyReports.list.Download' | translate}}" (click)="download(entry)">
          <mat-icon *ngIf="entry.hasCredit">cloud_download</mat-icon>
          <mat-icon *ngIf="!entry.hasCredit" color="warn">error_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="launch">
      <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button mat-icon-button color="primary" matTooltip="{{'companyReports.list.LaunchAll' | translate}}" (click)="launchAll()">
          <mat-icon>launch</mat-icon>
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button mat-icon-button matTooltip="{{'companyReports.list.Launch' | translate}}" (click)="launch(entry)">
          <mat-icon *ngIf="entry.hasCredit">launch</mat-icon>
          <mat-icon *ngIf="!entry.hasCredit" color="warn">error_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
</div>
