import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Profile } from 'src/app/models/profile';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';
import { Router } from '@angular/router';
import { ContactEditorDialogComponent } from 'src/app/components/invitation/dialogs/contact-editor-dialog/contact-editor-dialog.component';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { ContactUploadDialogComponent } from 'src/app/components/dialogs/contact-upload-dialog/contact-upload-dialog.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { ProfileService } from 'src/app/services/profile.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-individuals-control',
  templateUrl: './individuals-control.component.html',
  styleUrls: ['./individuals-control.component.scss']
})
export class IndividualsControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'lastName',
    'firstName',
    'email',
    'actions'
  ];
  dataSource: MatTableDataSource<Profile>;
  loadingIndicator: boolean;

  @Input() companyId: number;

  constructor(
    private alertService: AlertService,
    private companiesService: CompaniesService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: AppTranslationService,
    private profileService: ProfileService,
    private authService: AuthService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService
      .getProfilesByCompany(this.companyId)
      .subscribe(entries =>
        this.onDataLoadSuccessful(entries),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: Profile[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  launch(entry: Profile) {
    this.router.navigateByUrl(`/profile/${entry.userId}`);
  }

  newIndividual() {
    const contact = new InvitationContact("","","");

    const dialogRef = this.dialog.open(ContactEditorDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { contact: contact }
      });
    dialogRef.afterClosed().subscribe(contact => {
      if (contact) {
        this.addContact(contact);
      }
    });
  }

  private addContact(contact: InvitationContact) {
    const companyid: number = this.authService.companyId;

    //add logic to create new profile object...
    var profile = new Profile();
    profile.email = contact.email;
    profile.firstName = contact.firstName;
    profile.lastName = contact.lastName;
    profile.userName = contact.email;
    profile.preferredName = contact.firstName;
    profile.educationCredentials = "";
    profile.middleName = "";
    profile.phoneNumber = "";
    profile.photoBase64 = "";
    profile.salutation = "";
    profile.suffix = "";
    this.profileService.newProfile(profile).subscribe(
      p => {
        //connect to profile to company...
        this.profileService.connectToCompany(p.userId, companyid).subscribe(
          pc => {
            //push new profile to the list after profile object created...
            this.addProfileToList(p);
          },
          error => {
            this.alertService.showStickyMessage('Load Error', `Unable to connect profile to company.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
              MessageSeverity.error, error);            
          }
        );
      },
      error => {
        this.alertService.showStickyMessage('Load Error', `Unable to create profile.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
          MessageSeverity.error, error);   
      }
    )
  }

  private addProfileToList(profile: Profile) {
    this.dataSource.data.push(profile);
    this.refresh();
  }

  launchImport() {
    const dialogRef = this.dialog.open(ContactUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: {title: this.translationService.getTranslation("commands.Import")}
      });
    dialogRef.afterClosed().subscribe(contacts => {
      if (contacts)
        this.addContacts(contacts);
    });
  }

  private addContacts(contacts: InvitationContact[]) {
    contacts = contacts.filter(c => this.dataSource.data.filter(d => c.email == d.email).length <= 0); //no duplicates

    contacts.forEach(c => this.addContact(c));

    var addtext = this.translationService.getTranslation("individualsControl.NoContacts");
    if (contacts.length > 1)
      addtext = contacts.length + " " + this.translationService.getTranslation("individualsControl.ContactsAdded");
    else if (contacts.length == 1)
      addtext = this.translationService.getTranslation("individualsControl.ContactAdded");

    this.alertService.showMessage(addtext);
  }
}
