<app-page-header title="Invitations" icon="send"></app-page-header>
<div [@fadeInOut] class="page-content">
  <!--<a routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/invitation/send"><mat-icon>send</mat-icon>{{'invitations.SendInvitation' | translate}}</a>
  <br />
  <mat-divider></mat-divider>
  <br />-->
  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'campaigns.management.Search' | translate}}">
    </mat-form-field>
  </div>
  <div class="simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="campaignName">
        <mat-header-cell fxFlex="60%" fxFlex.lt-lg="80%" fxFlex.lt-md="90%" fxFlex.sm="85%" fxFlex.lt-sm="85%" *matHeaderCellDef mat-sort-header> {{'campaigns.management.Name' | translate}} </mat-header-cell>
        <mat-cell fxFlex="60%" fxFlex.lt-lg="80%" fxFlex.lt-md="90%" fxFlex.sm="85%" fxFlex.lt-sm="85%" *matCellDef="let row">
          <span fxHide.lt-lg>{{row.campaign.campaignName}}</span>
          <span fxHide.lg fxHide.gt-lg>
            {{row.campaign.campaignName}}
            <br />{{row.participation | percent}} {{'campaignAnalysis.Participation' | translate}}
            <br />{{row.overallProgress | percent}} {{'campaignAnalysis.Completion' | translate}}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="openDate">
        <mat-header-cell fxFlex="10%" fxFlex.sm="15%" fxFlex.lt-sm="15%" *matHeaderCellDef mat-sort-header> {{'campaigns.management.OpenDate' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" fxFlex.sm="15%" fxFlex.lt-sm="15%" *matCellDef="let row">
          <span fxHide.lt-md>{{row.campaign.openDate | date:'shortDate'}}</span>
          <span fxHide.md fxHide.gt-md>
            {{row.campaign.openDate | date:'shortDate'}}
            <br />{{ row.campaign.closeDate ? ' - ' + (row.campaign.closeDate | date:'shortDate') : ''}}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="participation">
        <mat-header-cell fxFlex="10%" fxHide.lt-lg *matHeaderCellDef mat-sort-header> {{'campaignAnalysis.Participation' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" fxHide.lt-lg *matCellDef="let row"> {{row.participation | percent}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="progress">
        <mat-header-cell fxFlex="10%" fxHide.lt-lg *matHeaderCellDef mat-sort-header> {{'campaignAnalysis.Completion' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" fxHide.lt-lg *matCellDef="let row"> {{row.overallProgress | percent}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="closeDate">
        <mat-header-cell fxFlex="10%" fxHide.lt-md *matHeaderCellDef mat-sort-header> {{'campaigns.management.CloseDate' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" fxHide.lt-md *matCellDef="let row"> {{row.campaign.closeDate | date:'shortDate'}} </mat-cell>
      </ng-container>
      <!--<mat-header-cell *matHeaderCellDef fxFlex="170px">
    <button mat-button color="primary" matTooltip="{{'companies.management.NewCompany' | translate}}" (click)="editCompany()">
      <mat-icon>add_circle_outline</mat-icon>&nbsp;{{'companies.management.NewCompany' | translate}}
    </button>
  </mat-header-cell>-->
      <!--<button mat-icon-button matTooltip="{{'companies.management.Delete' | translate}}" (click)="confirmDelete(company)">
    <mat-icon color="warn">delete</mat-icon>
  </button>-->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="80px">
          <!--<button mat-button color="primary" matTooltip="{{'companies.management.NewCompany' | translate}}" (click)="editCompany()">
        <mat-icon>add_circle_outline</mat-icon>&nbsp;{{'companies.management.NewCompany' | translate}}
      </button>-->
        </mat-header-cell>
        <mat-cell *matCellDef="let campaign" fxFlex="50px" fxFlexOffset="30px">
          <button mat-icon-button matTooltip="{{'campaigns.management.View' | translate}}" (click)="showObject(campaign)">
            <mat-icon>visibility</mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>
</div>
