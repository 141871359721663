import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { IndividualsSelectionControlComponent } from '../../controls/individuals-selection-control/individuals-selection-control.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Profile } from 'src/app/models/profile';

@Component({
  selector: 'app-individuals-selection-dialog',
  templateUrl: './individuals-selection-dialog.component.html',
  styleUrls: ['./individuals-selection-dialog.component.scss']
})
export class IndividualsSelectionDialogComponent implements OnInit {
  @ViewChild(IndividualsSelectionControlComponent) selectControl: IndividualsSelectionControlComponent;

  constructor(
    private dialogRef: MatDialogRef<IndividualsSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, companyId: number, displayProfiles: Profile[] }
  ) { }

  ngOnInit() {
   
  }

  select() {
    this.dialogRef.close(this.selectControl.selectedProfiles);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
