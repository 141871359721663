import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';

import { fadeInOut } from 'src/app/services/animations';
import { AlertService, DialogType, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from 'src/app/services/utilities';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { P60option } from 'src/app/models/p60option.model';
import { P60OptionEditorDialogComponent } from '../dialogs/p60-option-editor-dialog/p60-option-editor-dialog.component';

import exportFromJSON from 'export-from-json'


@Component({
  selector: 'app-p60-option-list',
  templateUrl: './p60-option-list.component.html',
  styleUrls: ['./p60-option-list.component.scss'],
  animations: [fadeInOut]
})
export class P60OptionListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  companyId: number;

  dataSource: MatTableDataSource<P60option>;
  sourceObject: P60option;
  loadingIndicator: boolean;

  copyFromObject: P60option;

  get teststring() {
    if (!this.sourceObject)
      return "n/a";

    return JSON.stringify(this.sourceObject, null, 2);
  }

  get role(): string {
    if (!this.authService) return "User";
    if (!this.authService.companyRole) return "User";
    return this.authService.companyRole;
  }

  get canEdit(): boolean {
    return this.role != "User" && this.companyId == 0;
  }

  get displayedColumns(): string[] {
    if (this.canEdit) {
      return [
        'optionTitle',
        'template',
        'download',
        'actions'
      ];
    }

    return [
      'optionTitle',
      'template',
      'download'
    ];
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router) {

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();

    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService
      .getP60Options(this.companyId)
      .subscribe(list =>
        this.onDataLoadSuccessful(list),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(list: P60option[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = list;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve the option sets from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private newOption() {
    this.companiesService.getP60Option(-1)
      .subscribe(
        result => this.startNewOption(result),
        error => this.onDataLoadFailed(error)
      );
  }

  private startNewOption(copy: P60option) {
    copy.companyId = this.authService.companyId;

    const dialogref = this.dialog.open(P60OptionEditorDialogComponent, { data: { option: copy, isEditMode: true } });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource.data = this.dataSource.data.sort((a, b) => (a.optionTitle > b.optionTitle) ? 1 : -1);
        this.router.navigateByUrl(`/company/p60option/${result.p60optionId}`);
      }
    });
  }

  private copy(object: P60option) {
    this.copyFromObject = object;

    var copy: P60option = (JSON.parse(JSON.stringify(object))); //the JSOn presents a clone...
    copy.optionTitle += " (Copy)";
    copy.p60optionId = 0;
    copy.template = false;
    copy.companyId = this.authService.companyId;

    const dialogref = this.dialog.open(P60OptionEditorDialogComponent, { data: { option: copy, isEditMode: true } });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource.data = this.dataSource.data.sort((a, b) => (a.optionTitle > b.optionTitle) ? 1 : -1);
        this.copyValues(result);
      }
    });
  }

  private copyValues(toObject: P60option) {
    this.alertService.startLoadingMessage("Copying values...");
    var fromObjectId: number = 0;
    if (this.copyFromObject)
      fromObjectId = this.copyFromObject.p60optionId;

    this.companiesService.copyP60OptionValues(fromObjectId, toObject.p60optionId).subscribe(result=> this.onCopyComplete(result), error => this.onDataLoadFailed(error));
    this.copyFromObject = null;
  }

  private onCopyComplete(optionId: any) {
    this.alertService.stopLoadingMessage();
    this.router.navigateByUrl(`/company/p60option/${optionId}`);
  }

  private edit(object: P60option) {
    //if (object.template) return;
    const dialogref = this.dialog.open(P60OptionEditorDialogComponent, { data: { option: object, isEditMode: true } });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
        this.router.navigateByUrl(`/company/p60option/${result.p60optionId}`);
      }
    });
  }


  private confirmDelete(object: P60option) {
    //if (object.template) return;

    this.snackBar.open(`Delete ${object.optionTitle} option set?`, 'DELETE', { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;
        this.delete(object.p60optionId);

      });
  }

  delete(p60optionId: number) {
    this.companiesService.deleteP60Option(p60optionId)
      .subscribe(
        results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.loadData();
        },
        error => {
          this.checkError(error);
        });
  }

  checkError(error: string) {
    if (error != "INVALID")
      return;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the option set.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  download(option: P60option) {
    this.alertService.startLoadingMessage("Downloading");
    this.loadingIndicator = true;
    this.loadValues(option);
  }

  loadValues(option: P60option) {
    this.companiesService.getP60Option(option.p60optionId).subscribe(
      o => this.onValuesDataLoadSuccessful(o),
      error => this.onDataLoadFailed(error)
    );
  }

  private onValuesDataLoadSuccessful(option: P60option) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    const data = JSON.stringify(option.p60optionValue);
    const fileName = option.optionTitle;
    const exportType = 'xls';

    exportFromJSON({ data, fileName, exportType });
  }

}
