import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { observe, Observables } from 'rxjs-observe';
import { Subject } from 'rxjs';

export class KeyValuePair {
  key: string;
  value: string;
}

@Component({
  selector: 'app-key-value-control',
  templateUrl: './key-value-control.component.html',
  styleUrls: ['./key-value-control.component.scss']
})
export class KeyValueControlComponent implements OnInit, AfterViewInit {

  @Input() key: string = '';
  @Input() value: string = '';

  private onPairChanged = new Subject<KeyValuePair>();
  pairChanged$ = this.onPairChanged.asObservable();

  instance: KeyValuePair;
  pair: KeyValuePair;

  displayedColumns: string[] = ['key', 'value'];
   
  constructor() {
    this.instance = new KeyValuePair();
    this.pair = new KeyValuePair();
  }

  ngOnInit() {
    this.loadPair(this.key, this.value);
  }

  ngAfterViewInit(): void {
  }

  public loadPair(key: string, value: string) {
    this.key = key;
    this.value = value;

    this.instance = { key: this.key, value: this.value };
    this.pair = this.instance;


    const { observables, proxy } = observe(this.instance);

    this.pair = proxy;

    observables.key.subscribe(b => { this.emitPair(); });
    observables.value.subscribe(b => { this.emitPair(); });
  }

  emitPair() {
    this.onPairChanged.next(this.pair);
  }

}
