<h1 mat-dialog-title>
  {{data.contact.email}}
</h1>
<div mat-dialog-content>
  <app-contact-editor-control #coned [contact]="contact" isEditMode="true"></app-contact-editor-control>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="save()" color="primary">{{'commands.Save' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel'|translate}}</button>
</div>
