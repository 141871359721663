<div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px">
  <div fxLayoutGap="20px" fxFlex="auto">
    <div fxLayout="column" class="square-logo simple-border" (click)="launchSquareImageImport()">
      <!--<div fxFlex="auto"></div>-->
      <!--<div>-->
      <img src="{{ logoSquare || logoFullBase64 || '../../../assets/images/assessment_background_1.jpg' }}" />
      <!--</div>-->
      <!--<div fxFlex="auto"></div>-->
    </div>
    <div fxLayout="column" fxLayout.lt-md="column" fxFlex="auto" fxLayoutGap="20px">
      <div fxLayout="row" fxFlex="auto">
        <div fxLayout="column" fxFlex="auto">
          <h3><a href="{{website}}">{{companyName}}</a></h3>
          <div>{{address}}</div>
          <div><a href="{{companyPhoneUrl}}">{{companyPhone}}</a></div>
        </div>
        <div fxLayout="column" fxFlex="auto">
          <h4>{{contact}}</h4>
          <a href="{{emailurl}}">{{email}}</a>
          <a href="{{phoneurl}}">{{phone}}</a>
        </div>
      </div>
      <div *ngIf="company?.notifyEmailAddresses && (authService?.userIsCompanyAdmin || authService?.userIsCompanyAgent)" fxLayout="row" fxFlex="auto" fxLayoutGap="8px">
        {{'companies.management.Notifications' | translate}} {{company?.notifyEmailAddresses}}
      </div>
    </div>
  </div>
  <div fxLayout="column" fxFlex="25%" fxFlex.lt-lg="auto" fxLayoutGap="8px">
    <div fxLayout="column" class="logo simple-border" (click)="launchFullImageImport()">
      <img src="{{ logoFullBase64 || '../../../assets/images/LoginLogo.png'}}" />
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column" fxFlex="auto" fxLayoutGap="8px">
      <button *ngIf="canEditCompany" fxFlex="auto" mat-raised-button (click)="launchCompanyEditor();" color="primary">{{'companyCard.EditCompany' | translate}}</button>
      <button *ngIf="canEditCompany" fxFlex="auto" mat-raised-button (click)="launchCreditEditor();" [color]="creditButtonColor">{{'companyCard.Credits' | translate}}: {{remainingCredits}}</button>
    </div>
  </div>
</div>
