<h1 mat-dialog-title>
  {{('roleAssignmentDialog.Title' | translate)}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <button mat-raised-button fxFlex="100%" (click)='applyAdministrator()' color="primary" matTooltip="{{roleDescription('Administrator')}}">{{roleName('Administrator')}}</button>
  <button mat-raised-button fxFlex="100%" (click)='applyAgent()' color="primary" matTooltip="{{roleDescription('Agent')}}">{{roleName('Agent')}}</button>
  <button mat-raised-button fxFlex="100%" (click)='applyCreator()' color="primary" matTooltip="{{roleDescription('Creator')}}">{{roleName('Creator')}}</button>
  <button mat-raised-button fxFlex="100%" (click)='applyUser()' color="primary" matTooltip="{{roleDescription('User')}}">{{roleName('User')}}</button>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
