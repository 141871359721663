import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { XrAnswerType } from 'src/app/models/xr-answer-type.model';
import { AnswerValue } from 'src/app/models/answer-value.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { XrService } from 'src/app/services/xr.service';
import { prepareProfile } from 'selenium-webdriver/firefox';

@Component({
  selector: 'app-answer-control',
  templateUrl: './answer-control.component.html',
  styleUrls: ['./answer-control.component.scss']
})
export class AnswerControlComponent implements OnInit {
  @Input() answer: Answer = new Answer;
  @Input() position: string = "";

  @Output() onDelete = new EventEmitter<Answer>();
  @Output() onMoveUp = new EventEmitter<Answer>();
  @Output() onMoveDown = new EventEmitter<Answer>();

  @Output() onValueDelete = new EventEmitter<AnswerValue>();

  get editObj(): Answer {
    if (this.answer)
      return this.answer;
    return new Answer;
  }

  get answerTypes(): XrAnswerType[] {
    const ats = this.xrService.getAnswerTypes();
    if (!ats) return []
    return this.xrService.getAnswerTypes().filter(x => x.code != 'XR');
  }

  get answerType(): XrAnswerType {
    if (this.answer) {
      if (!this.answer.answerType || this.answer.answerTypeId != this.answer.answerType.answerTypeId) {
        this.answer.answerType = this.answerTypes.find(x => x.answerTypeId == this.answer.answerTypeId);
      }

      return this.answer.answerType || new XrAnswerType();
    }

    return <XrAnswerType>({ code:"???", title:"guess" }); //TXT
  }

  get useFreeTextLimit(): boolean {
    if (!this.answerType) return false;
    return this.answerType.code == "TXT";
  }

  get answerValues(): AnswerValue[] {
    if (!this.editObj.answerValue || this.editObj.answerValue.length <= 0) {
      this.editObj.answerValue = [];
    }

    if (this.answerType) {
      if (this.answerType.code == "T/F")
        this.prepAnswersTF();
      else if (this.editObj.answerValue.length <= 0 && (this.answerType.code != "TXT" && this.answerType.code != "CHK")) {
        const t = <AnswerValue>({
          answerValueId: this.newId,
          answerId: this.editObj.answerId,
          code: '1',
          title: this.translateService.getTranslation("testEditor.NewAnswer"),
          pointValue: 1.0,
          ordinal: 100,
          deleted: false
        });
        this.editObj.answerValue.push(t);
      }
    }

    this.editObj.answerValue = this.editObj.answerValue.filter(av=>av.deleted == false);

    return this.editObj.answerValue;
  }

  get useAnswerValues(): boolean {
    if (!this.answerValues) return false;
    return this.answerValues.length > 0 || (this.answerType && !["CHK", "TXT"].find(x => x == this.answerType.code));
  }

  get canAddAnswerValue(): boolean {
    if (!this.useAnswerValues) return false;
    return this.answerType.code != 'T/F';
  }

  get positionValue(): string {
    if (!this.position) return "single";
    return this.position;
  }

  constructor(
    private xrService: XrService,
    private translateService: AppTranslationService
  ) {  }

  ngOnInit() {
  }

  delete() {
    this.onDelete.emit(this.answer);
  }
  moveUp() {
    this.onMoveUp.emit(this.answer);
  }
  moveDown() {
    this.onMoveDown.emit(this.answer);
  }

  private resetOrdinals() {
    this.answerValues.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);

    var ordinal: number = 0;
    this.answerValues.forEach(x => {
      ordinal += 100;
      x.ordinal = ordinal;
    });
  }

  valuePosition(av: AnswerValue) {
    var index = this.answerValues.indexOf(av);

    var first: boolean = index == 0;
    var last: boolean = index == (this.answerValues.length - 1);

    return (first == true && last == true ? "single" : first == true ? "first" : last == true ? "last" : "");
  }

  valueDelete(av: AnswerValue) {
    var index = this.answerValues.findIndex(x => x.answerValueId == av.answerValueId);
    if (index >= 0)
      this.onValueDelete.emit(this.answerValues.splice(index, 1)[0]);
    this.resetOrdinals();
  }

  private valueMoveUp(av: AnswerValue) {
    var ord = this.answerValues.find(x => x.answerValueId == av.answerValueId);
    ord.ordinal -= 150;
    this.resetOrdinals();
  }

  private valueMoveDown(av: AnswerValue) {
    var ord = this.answerValues.find(x => x.answerValueId == av.answerValueId);
    ord.ordinal += 150;
    this.resetOrdinals();
  }

  private addValueAt(ordinal: number, before: boolean = false) {
    var minid = 0;
    if (this.answerValues)
      if (this.answerValues.length > 0)
        minid = this.answerValues.map(x => x.answerValueId).sort((a, b) => a > b ? 1 : -1)[0];

    minid = (minid <= 0 ? minid - 1 : -1);

    var value = new AnswerValue();
    value.answerValueId = minid * 1000 - 1;
    value.answerId = this.answer.answerId;
    value.code = (this.answerValues.length + 1).toString();
    value.title = this.translateService.getTranslation("testEditor.NewAnswerTitle");
    value.pointValue = this.answerValues.length > 0 ? 0 : 1;
    value.ordinal = ordinal + (before == true ? -50 : 50);
    value.deleted = false;

    this.answerValues.push(value);
    this.resetOrdinals();
  }

  get newId(): number {
    var minid = 0;
    if (this.editObj.answerValue)
      if (this.editObj.answerValue.length > 0)
        minid = this.editObj.answerValue.map(x => x.answerValueId).sort((a, b) => a > b ? 1 : -1)[0];

    minid = (minid <= 0 ? minid - 1 : -1);

    return minid;
  }

  private prepAnswersTF() {

    const t = <AnswerValue>({
      answerValueId: this.newId,
      answerId: this.editObj.answerId,
      code: 'true',
      title: this.translateService.getTranslation("answerControl.True"),
      pointValue: 1.0,
      ordinal: 100,
      deleted: false
    });
    const texists = this.editObj.answerValue.filter(av => av.code == 'true').length > 0;
    if (!texists)
      this.editObj.answerValue.push(t);

    const f = <AnswerValue>({
      answerValueId: this.newId,
      answerId: this.editObj.answerId,
      code: 'false',
      title: this.translateService.getTranslation("answerControl.False"),
      pointValue: 0.0,
      ordinal: 200,
      deleted: false
    });
    const fexists = this.editObj.answerValue.filter(av => av.code == 'false').length > 0;
    if (!fexists)
      this.editObj.answerValue.push(f);

    var extra = this.editObj.answerValue.filter(x => !(x.code == 'true' || x.code == 'false'));
    extra.forEach(av => {
      var index = this.editObj.answerValue.findIndex(x => x.answerValueId == av.answerValueId);
      if (index >= 0)
        this.onValueDelete.emit(this.editObj.answerValue.splice(index, 1)[0]);
    });
  }
}
