import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ApplicationEndpoint } from './application-endpoint.service';
import { Profile } from '../models/profile';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ProfileCompany } from '../models/profile-company';
import { PersonalityScoring } from '../models/personality-scoring.model';
import { RenderRequest } from '../models/render-request.model';
import { ReportEntry } from '../models/report-entry.model';
import { Invitation } from '../models/invitation.model';
import { IQScoring } from '../models/iq-scoring.model';
import { CTScoring } from '../models/ct-scoring.model';
import { Scoring } from '../models/scoring.model';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private authService: AuthService,
    private applicationEndpoint: ApplicationEndpoint
  ) { }

  getProfiles() {
    return this.applicationEndpoint.getUsersEndpoint<Profile[]>();
  }

  getProfile(userId: number) {
    return this.applicationEndpoint.getUserByIdEndpoint<Profile>(userId);
  }

  getProfileByEmail(email: string) {
    return this.applicationEndpoint.getUserByEmailEndpoint<Profile>(email);
  }

  newProfile(user: Profile) {
    return this.applicationEndpoint.getNewUserEndpoint<Profile>(user);
  }

  updateProfile(user: Profile) {
    if (user.userId) {
      return this.applicationEndpoint.getUpdateUserEndpoint<Profile>(user, user.userId);
    }
  }

  deleteProfile(userOrUserId: number | Profile): Observable<Profile> {
    if (typeof userOrUserId === 'number' || userOrUserId instanceof Number) {
      return this.applicationEndpoint.getDeleteCompanyEndpoint<Profile>(<number>userOrUserId).pipe<Profile>(
        tap(data => userOrUserId));
    } else {
      return this.deleteProfile(userOrUserId.userId);
    }   
  }

  connectToCompany(userId: number, companyId: number, role: string = ""): Observable<ProfileCompany> {
    return this.applicationEndpoint.getConnectUserCompanyEndpoint<ProfileCompany>(userId, companyId, role);
  }

  disconnectFromCompany(userId: number, companyId: number): Observable<ProfileCompany> {
    return this.applicationEndpoint.getDisconnectUserCompanyEndpoint<ProfileCompany>(userId, companyId);
  }

  getP60Result(userId: number, companyId: number, language: string, userTestId?: number, benchmarkId?: number, optionId?: number) {
    return this.applicationEndpoint.getP60ResultEndpoint<PersonalityScoring>(userId, companyId, language, userTestId, benchmarkId, optionId);
  }

  getP60Download(userId: number, companyId: number, language: string, userTestId?: number, benchmarkId?: number, optionId?: number) {
    return this.applicationEndpoint.getP60DownloadEndpoint<RenderRequest>(userId, companyId, language, userTestId, benchmarkId, optionId);
  }

  getIQResult(userId: number, companyId: number, language: string, userTestId?: number, optionId?: number) {
    return this.applicationEndpoint.getIQResultEndpoint<IQScoring>(userId, companyId, language, userTestId, optionId);
  }

  getIQDownload(userId: number, companyId: number, language: string, userTestId?: number, optionId?: number) {
    return this.applicationEndpoint.getIQDownloadEndpoint<RenderRequest>(userId, companyId, language, userTestId, optionId);
  }

  getCTResult(userId: number, companyId: number, language: string, userTestId: number, optionId?: number) {
    return this.applicationEndpoint.getCTResultEndpoint<CTScoring>(userId, companyId, language, userTestId, optionId);
  }

  getCTDownload(userId: number, companyId: number, language: string, userTestId?: number, optionId?: number) {
    return this.applicationEndpoint.getCTDownloadEndpoint<RenderRequest>(userId, companyId, language, userTestId, optionId);
  }

  getCSResult(userId: number, companyId: number, language: string, userTestId: number, optionId?: number, summary?: boolean) {
    return this.applicationEndpoint.getCSResultEndpoint<Scoring>(userId, companyId, language, userTestId, optionId, summary);
  }

  getCSDownload(userId: number, companyId: number, language: string, userTestId?: number, optionId?: number, summary?: boolean) {
    return this.applicationEndpoint.getCSDownloadEndpoint<RenderRequest>(userId, companyId, language, userTestId, optionId, summary);
  }

  getReports(userId: number, days?: number, all?: boolean) {
    return this.applicationEndpoint.getUserReportsEndpoint<ReportEntry[]>(userId, days, all);
  }

  getOpenInvitations(userId: number) {
    return this.applicationEndpoint.getOpenInvitationsEndpoint<Invitation[]>(userId);
  }

  companyRole(userId: number, companyId: number) {
    return this.applicationEndpoint.companyRoleEndpoint<ProfileCompany>(userId, companyId);
  }

  companySelection(userId: number) {
    return this.applicationEndpoint.companySelectionEndpoint<Company[]>(userId);
  }
}
