import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';

import { fadeInOut } from '../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from '../../services/app-translation.service';
import { Utilities } from '../../services/utilities';
import { Company } from 'src/app/models/company';
import { CompaniesService } from 'src/app/services/companies.service';
import { CompanyViewDialogComponent } from './company-view-dialog/company-view-dialog.component';


@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
  animations: [fadeInOut]
})
export class CompaniesComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'companyName',
    'contactName',
    'contactEmailAddress',
    'contactPhoneNumber',
    'actions'];
  dataSource: MatTableDataSource<Company>;
  sourceCompany: Company;
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
    
    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService
      .getCompanies()
      .subscribe(companies =>
        this.onDataLoadSuccessful(companies),
        error => this.onDataLoadFailed(error));
  }
   
  private onDataLoadSuccessful(companies: Company[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = companies;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve companies from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private editCompany(company?: Company) {
    if (!company) {
      company = new Company();
    }

    this.sourceCompany = company;

    const dialogRef = this.dialog.open(CompanyViewDialogComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { company: company }
      });
    dialogRef.afterClosed().subscribe(company => {
      if (company) {
        this.updateCompanies(company);
      }
    });

  }

  private updateCompanies(company: Company) {
    var newcompany = false;

    if (!this.sourceCompany) {
      newcompany = true;
    } else if ((this.sourceCompany.companyId || 0) <= 0) {
      newcompany = true;
    }

    if (!newcompany) {
      Object.assign(this.sourceCompany, company);
      this.alertService.showMessage('Success', `Changes to company \"${company.companyName}\" was saved successfully`, MessageSeverity.success);
      this.sourceCompany = null;
    } else {
      this.dataSource.data.push(company);
      this.refresh();
      this.alertService.showMessage('Success', `Company \"${company.companyName}\" was created successfully`, MessageSeverity.success);
    }
  }

  private confirmDelete(company: Company) {
    this.snackBar.open(`Delete ${company.companyName}?`, 'DELETE', { duration: 2000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;

        this.companiesService.deleteCompany(company)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== company);
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the company.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
                MessageSeverity.error, error);
            });
      });
  }
}
