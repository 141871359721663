import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatSnackBar, MatDialog } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Task } from 'src/app/models/task.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { LocalStoreManager } from 'src/app/services/local-store-manager.service';
import { AuthService } from 'src/app/services/auth.service';
import { TasksService } from 'src/app/services/tasks.service';
import { Utilities } from 'src/app/services/utilities';
import { Profile } from 'src/app/models/profile';
import { Router } from '@angular/router';

@Component({
  selector: 'app-task-list-control',
  templateUrl: './task-list-control.component.html',
  styleUrls: ['./task-list-control.component.scss']
})
export class TaskListControlComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() userId: number;
  @Input() getDefaultProfile: boolean = true;

  displayedColumns = ['select', 'taskName', 'completionValue', 'createdDate', 'dueDate'];
  dataSource: MatTableDataSource<Task>;
  completedTasks: SelectionModel<Task>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  taskEdit: Task;
  isDataLoaded = false;
  loadingIndicator = true;
  formResetToggle = true;
  _currentUserId: string;
  _hideCompletedTasks = false;

  profile: Profile;

  get currentUserId() {
    if (this.authService.currentUser) {
      this._currentUserId = this.authService.currentUser.id;
    }

    return this._currentUserId;
  }

  get areAllTasksComplete(): boolean {
    return this.completedTasks.selected.length == this.dataSource.data.length;
  }

  get hideCompletedTasks() {
    return this._hideCompletedTasks;
  }
  set hideCompletedTasks(value: boolean) {
    this._hideCompletedTasks = value;
  }


  get isUser(): boolean {
    if (!this.profile || !this.authService) return false;
    return this.profile.userId == this.authService.currentUserProfile.userId;
  }

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private localStorage: LocalStoreManager,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private tasksService: TasksService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource<Task>();
    this.completedTasks = new SelectionModel<Task>(true, []);
  }

  ngOnInit() {
    this.loadingIndicator = true;
    //if (!this.authService.currentUserProfile)
    //  this.authService.profileSelected$.subscribe(results => this.userProfileSelected(results));
    //else
    //  this.userProfileSelected(this.authService.currentUserProfile);

    if (this.getDefaultProfile == true) {
      this.authService.profileSelected$.subscribe(
        result => { this.profileFound(result); }
      );
    }

    this.waitForUserId(this.userId);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
  }
  ngOnDestroy() {

  }

  public profileFound(profile: Profile) {
    this.profile = profile;

    var isuser = this.isUser;
    if (!isuser) {
      this.displayedColumns = ['taskName', 'completionValue', 'createdDate', 'dueDate'];
    }
    return this.makeCall(profile.userId);
  }

  waitForUserId(id: number) {
    if (!id) {
      setTimeout(() => { this.waitForUserId(id); }, 500);
      return;
    }

    if (id <= 0) {
      setTimeout(() => { this.waitForUserId(id); }, 500);
      return;
    }

    this.makeCall(id);
  }

  makeCall(id: number) {
    this.userId = id;
    
    if (!this.profile && this.authService.currentUserProfile)
      this.profile = this.authService.currentUserProfile;

    if (this.profile) {
      this.tasksService.getTasksForUser(this.userId).subscribe(
        results => this.onDataLoadSuccessful(results)
      );
    }
  }

  //userProfileSelected(profile: Profile) {
  //  this.userId = profile.userId;
  //  this.tasksService.getTasksForUser(profile.userId).subscribe(results => this.onDataLoadSuccessful(results));
  //}

  toggleCompletedTasks() {
    this.hideCompletedTasks = !this.hideCompletedTasks;
    this.refresh();
  }

  refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  applyFilter(filterValue: string) {
    if (filterValue.length < 1) {
      filterValue = ' ';
    }

    this.dataSource.filter = filterValue;
  }

  onDataLoadSuccessful(tasks: Task[]) {
    tasks.forEach(t => t.createdDate = Utilities.utcDateToLocal(new Date(t.createdDate)));

    //tasks = tasks.sort((a, b) => (a.createdDate < b.createdDate) ? 1 : -1);

    this.dataSource.data = tasks;
    this.completedTasks = new SelectionModel<Task>(true, tasks.filter(x => x.completionValue >= 1));
    this.isDataLoaded = true;

    setTimeout(() => {
      this.loadingIndicator = false;
    }, 1500);
  }

  showErrorAlert(caption: string, message: string) {
    this.alertService.showMessage(caption, message, MessageSeverity.error);
  }

  private filterData(task: Task, filter: string): boolean {
    return !(task.completionValue == 1.0 && this.hideCompletedTasks) && Utilities.searchArray(filter, false, task.taskName);
  }

  formatPercent(value: number): string {
    const perc = (value * 100).toFixed(2);
    return `${perc}%`;
  }

  localDate(utcDate: Date) {
    if (utcDate !== undefined)
      return Utilities.utcDateToLocal(utcDate);
    else
      return utcDate;
  }

  isToday(dateValue: Date) {
    if (!Utilities)
      return false;
    if (!Utilities.isToday)
      return false;
    if (!dateValue)
      return false;

    const testDate = Utilities.utcDateToLocal(new Date(dateValue));

    return Utilities.isToday(testDate);
  }

  launch(task: Task) {
    var url = `/home`;
    if (task.taskId)
      url = `/task/${task.taskId}`;
    this.router.navigateByUrl(url);
  }


  canLaunch(task: Task) {
    const localDue = !task.dueDate ? new Date(3000, 1) : this.localDate(task.dueDate);
    return task.completionValue < 1 && localDue.toISOString().substr(0, 10) >= new Date().toISOString().substr(0, 10);
  }
}
