
import { Component, ChangeDetectorRef, ViewChild, OnInit, AfterViewInit, isDevMode } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationStart } from '@angular/router';
import { MatExpansionPanel, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';

import { AlertService, AlertCommand, AlertMessage, MessageSeverity } from './services/alert.service';
import { NotificationService } from './services/notification.service';
import { AppTranslationService } from './services/app-translation.service';
import { AccountService } from './services/account.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { AppTitleService } from './services/app-title.service';
import { AuthService } from './services/auth.service';
import { ConfigurationService } from './services/configuration.service';
import { Permission } from './models/permission.model';
import { LoginDialogComponent } from './components/login/login-dialog.component';
import { AppDialogComponent } from './shared/app-dialog/app-dialog.component';
import { CompaniesService } from './services/companies.service';
import { ProfileService } from './services/profile.service';
import { ProfileInitialDialogComponent } from './components/profile/profile-initial-dialog/profile-initial-dialog.component';

import { Profile } from './models/profile';

import { CompanySelectorComponent } from './components/controls/company-selector/company-selector.component';
import { Company } from './models/company';
import { P60InviteComponent } from './components/invitation/dialogs/p60-invite/p60-invite.component';
import { ProfileCompany } from './models/profile-company';


import { Store } from '@ngrx/store';
import * as fromRoot from "./store/reducers";
import { StartOnlineOfflineCheck } from "./store/actions/network.actions";
import { Observable } from 'rxjs';
import { PasswordResetComponent } from './admin/password-reset/password-reset.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  @ViewChild('admin') adminExpander: MatExpansionPanel;
  @ViewChild('reportTools') reportToolsExpander: MatExpansionPanel;
  @ViewChild('sendTools') sendToolsExpander: MatExpansionPanel;
  @ViewChild('orgTools') orgToolsExpander: MatExpansionPanel;
  @ViewChild('creatorTools') creatorToolsExpander: MatExpansionPanel;
  @ViewChild(CompanySelectorComponent) selectProfileCompany: CompanySelectorComponent;

  private _mobileQueryListener: () => void;
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  isSendExpanded = false;
  isReportToolsExpanded = false;
  isAdminExpanded = false;
  isOrgToolsExpanded = false;
  isCreatorToolsExpanded = false;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = '';
  appLogo = require('./assets/images/LoginLogo.png');

  isConnected: boolean;
  isOnline$: Observable<boolean>;

  mobileQuery: MediaQueryList;
  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  gT = (key: string | Array<string>, interpolateParams?: Object) => this.translationService.getTranslation(key, interpolateParams);

  accountName = function (): string {
    if (this.authService.currentUser)
      if (this.authService.currentUserProfile)
        return `${this.authService.currentUserProfile.preferredName} ${this.authService.currentUserProfile.lastName}`;
      else
        return this.authService.currentUser.email;
    else
      return this.translationService.getTranslation("mainMenu.Account");
  }
  get photoBase64(): string {
    if (this.authService.currentUser)
      if (this.authService.currentUserProfile)
        return this.authService.currentUserProfile.photoBase64;

    return "";
  }

  get showSizeIndicators(): boolean {
    if (!this.authService) return false;
    if (!this.authService.currentUser) return false;
    return this.authService.currentUser.email == 'billy.neagle@gmail.com';
  }

  selectedCompany: Company;

  companyName = function (): string {
    if (this.selectedCompany)
      return this.selectedCompany.companyName;
    else
      return '';
  }

  get is360(): boolean {
    return this.authService.companyId == 0;
  }

  get notificationsTitle() {
    if (this.newNotificationCount) {
      return `${this.gT('app.Notifications')} (${this.newNotificationCount} ${this.gT('app.New')})`;
    } else {
      return this.gT('app.Notifications');
    }
  }

  get isAdmin(): boolean {
    if (!this.authService) return false;
    return this.authService.userIsAdmin;
  }
  get isCompanyAdmin(): boolean {
    if (!this.authService) return false;
    return this.authService.userIsCompanyAdmin;
  }
  get isCompanyAgent(): boolean {
    if (!this.authService) return false;
    return this.authService.userIsCompanyAgent;
  }
  get isCompanyCreator(): boolean {
    if (!this.authService) return false;
    return this.authService.userIsCompanyCreator;
  }

  get squareLogo(): string {
    if (!this.authService) return this.appLogo;
    if (!this.authService.currentUserCompany) return this.appLogo;
    return this.authService.currentUserCompany.logoSquareBase64 || this.authService.currentUserCompany.logoFullBase64 || this.appLogo;
  }

  get companyLogo(): string {
    if (!this.authService) return this.appLogo;
    if (!this.authService.currentUserCompany) return this.appLogo;
    return this.authService.currentUserCompany.logoFullBase64 || this.appLogo;
  }

  get mode(): string {
    if (!isDevMode) return 'n/a';
    return isDevMode() == true ? 'dev' : 'prod'; 
  }

  constructor(
    storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private accountService: AccountService,
    private alertService: AlertService,
    private notificationService: NotificationService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private profileService: ProfileService,
    public configurations: ConfigurationService,
    public router: Router,
    public dialog: MatDialog,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private store: Store<fromRoot.State>
  )
  {
 
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    storageManager.initialiseStorageSyncListener();

    this.toastaConfig.theme = 'material';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;

    this.appTitleService.appName = this.appTitle;
  }

  ngOnInit() {
    this.isOnline$ = this.store.select(fromRoot.getIsOnline);
    this.store.dispatch(new StartOnlineOfflineCheck());

    this.isOnline$.subscribe(status => {
      this.isConnected = status;
    });

    this.isUserLoggedIn = this.authService.isLoggedIn;

    // 0.5 extra sec to display preboot/loader information. Preboot screen is removed 0.5 sec later
    setTimeout(() => {
      this.isAppLoaded = true;
      this.removePrebootScreen = true;
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();
        this.alertService.showMessage('Login', `Welcome back ${this.userName}!`, MessageSeverity.default);
      }
    }, 250);

    this.alertService.getDialogEvent().subscribe(alert => this.dialog.open(AppDialogComponent,
      {
        data: alert,
        panelClass: 'mat-dialog-sm'
      }));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message));

    this.authService.reLoginDelegate = () => this.showLoginDialog();

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;


      if (this.isUserLoggedIn) {
        this.initNotificationsLoading();
        this.initCompany();
      } else {
        this.unsubscribeNotifications();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage('Session Ended!', '', MessageSeverity.default);
        }
        else {
          this.initProfile();
        }
      }, 500);
    });

    this.refreshAdminExpanderState(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.refreshAdminExpanderState((<NavigationStart>event).url);
      }
    });

    this.refreshReportToolsExpanderState(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.refreshReportToolsExpanderState((<NavigationStart>event).url);
      }
    });

    this.refreshSendToolsExpanderState(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.refreshSendToolsExpanderState((<NavigationStart>event).url);
      }
    });

    this.refreshOrgToolsExpanderState(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.refreshOrgToolsExpanderState((<NavigationStart>event).url);
      }
    });

    this.refreshCreatorToolsExpanderState(this.router.url);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.refreshCreatorToolsExpanderState((<NavigationStart>event).url);
      }
    });
  }

  ngAfterViewInit(): void {
    this.selectProfileCompany.companySelected$.subscribe(company => { this.setSelectedCompany(company); });
    this.authService.companySelected$.subscribe(company => { this.setSelectedCompany(company); });
  }

  setSelectedCompany(company: Company) {
    this.selectedCompany = company;
    this.authService.currentUserCompany = company;

    this.findCompanyRole();
  }

  findCompanyRole() {
    if (!this.authService) return;
    if (!this.isAppLoaded || !this.isUserLoggedIn) return;
    if (this.authService.currentUserProfile && this.authService.currentUserCompany)
      this.profileService.companyRole(this.authService.currentUserProfile.userId, this.authService.companyId)
        .subscribe(
          role => this.setCompanyRole(role),
          error => this.parseError(error)
        );
  }

  setCompanyRole(role: ProfileCompany) {
    if (this.authService.userIsAdmin)
      role.role = "Administrator";
    this.authService.companyRole = role.role;
  }

  parseError(error: any) {

  }

  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.unsubscribeNotifications();
  }

  private unsubscribeNotifications() {
    if (this.notificationsLoadingSubscription) {
      this.notificationsLoadingSubscription.unsubscribe();
    }
  }

  private refreshAdminExpanderState(currentUrl: string) {
    setTimeout(() => {
      if (this.adminExpander && currentUrl.toLowerCase().indexOf('admin') > 0) {
        this.adminExpander.open();
      }
    }, 200);
  }

  private refreshReportToolsExpanderState(currentUrl: string) {
    setTimeout(() => {
      if (this.reportToolsExpander && currentUrl.toLowerCase().indexOf('reportTools') > 0) {
        this.reportToolsExpander.open();
      }
    }, 200);
  }

  private refreshSendToolsExpanderState(currentUrl: string) {
    setTimeout(() => {
      if (this.sendToolsExpander && currentUrl.toLowerCase().indexOf('sendTools') > 0) {
        this.sendToolsExpander.open();
      }
    }, 200);
  }

  private refreshOrgToolsExpanderState(currentUrl: string) {
    setTimeout(() => {
      if (this.orgToolsExpander && currentUrl.toLowerCase().indexOf('orgTools') > 0) {
        this.orgToolsExpander.open();
      }
    }, 200);
  }

  private refreshCreatorToolsExpanderState(currentUrl: string) {
    setTimeout(() => {
      if (this.creatorToolsExpander && currentUrl.toLowerCase().indexOf('creatorTools') > 0) {
        this.creatorToolsExpander.open();
      }
    }, 200);
  }

  initNotificationsLoading() {
    this.notificationsLoadingSubscription = this.notificationService.getNewNotificationsPeriodically()
      .subscribe(notifications => {
        this.dataLoadingConsecutiveFailures = 0;
        this.newNotificationCount = notifications.filter(n => !n.isRead).length;
      },
        error => {
          this.alertService.logError(error);

          if (this.dataLoadingConsecutiveFailures++ < 20) {
            setTimeout(() => this.initNotificationsLoading(), 5000);
          } else {
            this.alertService.showStickyMessage('Load Error', 'Loading new notifications from the server failed!', MessageSeverity.error);
          }
        });
  }

  initProfile() {

    this.profileService
      .getProfileByEmail(this.authService.currentUser.email)
      .subscribe(data =>
        this.checkProfile(data),
        error => this.checkProfile()
      );

  }

  setCurrentProfile(profile: Profile) {
      this.authService.currentUserProfile = profile;
      this.findCompanyRole();
  }

  private checkingProfile: boolean;

  checkProfile(profile?: Profile) {
    if (this.checkingProfile) return false;

    if (!this.isAppLoaded || !this.isUserLoggedIn) return;
    if (!this.authService) return;
    if (!this.authService.currentUser) return;

    if (profile) {
      this.setCurrentProfile(profile);
      return;
    }

    this.checkingProfile = true;

    const dialogRef = this.dialog.open(ProfileInitialDialogComponent,
      {
        disableClose: true,
        data: {
          user: this.authService.currentUser,
          profile: this.authService.currentUser.profile,
          isInitial: true
        }
      });

    dialogRef.afterClosed().subscribe(result => {
      this.checkingProfile = false;
      if (result) {
        this.setCurrentProfile(result);
      }
    });
  }

  resetPassword() {
    const dialogRef = this.dialog.open(PasswordResetComponent,
      {
        data: {
          user: this.authService.currentUser
        }
      }
    );
  }

  initCompany() {
    if (this.authService.currentUser.company)
      return;

    //TODO: select the company...
  }

  markNotificationsAsRead() {
    const recentNotifications = this.notificationService.recentNotifications;

    if (recentNotifications.length) {
      this.notificationService.readUnreadNotification(recentNotifications.map(n => n.id), true)
        .subscribe(response => {
          for (const n of recentNotifications) {
            n.isRead = true;
          }

          this.newNotificationCount = recentNotifications.filter(n => !n.isRead).length;
        },
          error => {
            this.alertService.logError(error);
            this.alertService.showMessage('Notification Error', 'Marking read notifications failed', MessageSeverity.error);

          });
    }
  }

  showLoginDialog(): void {
    this.isUserLoggedIn = false;

    this.alertService.showStickyMessage('Session Expired', 'Your Session has expired. Please log in again', MessageSeverity.info);

    const dialogRef = this.dialog.open(LoginDialogComponent, { minWidth: 600 });

    dialogRef.afterClosed().subscribe(result => {
      this.alertService.resetStickyMessage();

      if (!result || this.authService.isSessionExpired) {
        this.authService.logout();
        this.router.navigateByUrl('/login');
        this.alertService.showStickyMessage('Session Expired', 'Your Session has expired. Please log in again to renew your session', MessageSeverity.warn);
      }
    });
  }

  showToast(alert: AlertCommand) {

    if (alert.operation == 'clear') {
      for (const id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    const toastOptions: ToastOptions = {
      title: alert.message.summary,
      msg: alert.message.detail,
    };


    if (alert.operation == 'add_sticky') {
      toastOptions.timeout = 0;

      toastOptions.onAdd = (toast: ToastData) => {
        this.stickyToasties.push(toast.id);
      };

      toastOptions.onRemove = (toast: ToastData) => {
        const index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        if (alert.onRemove) {
          alert.onRemove();
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    } else {
      toastOptions.timeout = 4000;
    }


    switch (alert.message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break;
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break;
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  logout() {
    this.selectedCompany = null;
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : '';
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : '';
  }

  get canViewCustomers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewProducts() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewOrders() {
    return true;
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get canSendP60Link() {
    return this.isCompanyAgent;
  }

  get canAccessReportTools() {
    return this.isCompanyAgent;
  }

  get canAccessCreatorTools() {
    return this.isCompanyCreator;
  }

  get canAccessOrgTools() {
    return this.isCompanyAgent;
  }

  get canAccessInviteTools() {
    return this.isCompanyAgent;
  }

  sendP60() {
    this.dialog.open(P60InviteComponent,
      {
        data: alert,
        panelClass: 'mat-dialog-sm'
      });
  }

  gotoProfile() {
    if (!this.authService) return;
    if (!this.authService.currentUserProfile) return;
    this.router.navigateByUrl(`/profile`);
  }

  gotoCompany() {
    if (!this.authService) return;
    if (!this.authService.currentUserCompany) return;
    this.router.navigateByUrl(`/company`);
  }
}
