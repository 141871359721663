<div fxLayout="column" fxLayoutGap="8px">
  <div fxFlex="100%">
    <mat-form-field floatLabel="auto" fxFlex="100%">
      <mat-label>{{nameText}}</mat-label>
      <input matInput [(ngModel)]="courseName" />
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px">
    <div fxLayout="column" fxFlex="100%">
      <mat-form-field floatLabel="auto" fxFlex="100%">
        <mat-label>{{'courseHeaderControl.Template' | translate}}</mat-label>
        <mat-select [(ngModel)]="template">
          <mat-option value="false">{{'courseHeaderControl.TemplateNo' | translate}}</mat-option>
          <mat-option value="true">{{'courseHeaderControl.TemplateYes' | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxFlex="100%">
      <mat-form-field floatLabel="auto" fxFlex="100%">
        <mat-label>{{'courseHeaderControl.Language' | translate}}</mat-label>
        <mat-select [(ngModel)]="languageCode">
          <mat-option *ngFor="let lc of languages" [value]="lc.code">
            <span matTooltip="{{lc.title}}">{{lc.title + ' (' + lc.code + ')'}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
