import { Component, OnInit, Input, ViewChild, OnChanges } from '@angular/core';
import { Company } from 'src/app/models/company';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { CompanyCardControlComponent } from 'src/app/components/controls/company-card-control/company-card-control.component';
import { CompanyPrivilegedUsersControlComponent } from '../company-privileged-users-control/company-privileged-users-control.component';
import { CompanyClientsControlComponent } from '../company-clients-control/company-clients-control.component';

@Component({
  selector: 'app-company-view-control',
  templateUrl: './company-view-control.component.html',
  styleUrls: ['./company-view-control.component.scss']
})
export class CompanyViewControlComponent implements OnInit, OnChanges {
  @Input() useDefaultCompany: boolean = true;
  @Input() company: Company;

  @ViewChild(CompanyCardControlComponent) card: CompanyCardControlComponent;
  @ViewChild(CompanyPrivilegedUsersControlComponent) users: CompanyPrivilegedUsersControlComponent;
  @ViewChild(CompanyClientsControlComponent) clients: CompanyClientsControlComponent;

  get companyId(): number {
    var companyId: number = 0;
    if (this.company)
      companyId = this.company.companyId;
    return companyId;
  }

  get companyRole(): string {
    return this.authService.companyRole;
  }

  get canViewPriviligedUsers(): boolean {
    return this.authService.userIsCompanyAdmin || this.authService.userIsCompanyAgent;
  }

  get canViewClients(): boolean {
    return this.authService.userIsCompanyAdmin || this.authService.userIsCompanyAgent;
  }

  get canViewUsage(): boolean {
    return this.authService.userIsCompanyAdmin || this.authService.userIsCompanyAgent;
  }

  constructor(
    private authService: AuthService,
    private companyService: CompaniesService
  ) { }

  ngOnInit() {
    if (this.useDefaultCompany == true) {
      var companyId = this.authService.companyId;
      this.companyService.getCompany(companyId).subscribe(company => { this.companyFound(company); });
    }

    this.authService.companySelected$.subscribe(entry => this.companyFound(entry));

  }

  ngOnChanges() {
    this.companyFound(this.company);
  }

  companyFound(company: Company) {
    this.company = company;

    if (this.company) {
      //reset child controls...
      if (this.card) this.card.companyFound(this.company);
      if (this.users) this.users.companyFound(this.company);
      if (this.clients) this.clients.companyFound(this.company);
    }
  }

}
