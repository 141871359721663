import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export class Base64ImageUploadAdapter {
  private loader: any;
  private reader: FileReader;

  constructor(loader) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return new Promise((resolve, reject) => {
      const reader = this.reader = new FileReader();

      reader.addEventListener('load', () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener('error', err => {
        reject(err);
      });

      reader.addEventListener('abort', () => {
        reject();
      });

      this.loader.file.then(file => {
        reader.readAsDataURL(file);
      });
    });
  }

  // Aborts the upload process.
  abort() {
    // Reject the promise returned from the upload() method.
    this.reader.abort();
  }
}

function Base64ImageUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter =
    (loader) => {
      return new Base64ImageUploadAdapter(loader);
    };
}

@Component({
  selector: 'app-rich-text-dialog',
  templateUrl: './rich-text-dialog.component.html',
  styleUrls: ['./rich-text-dialog.component.scss']
})
export class RichTextDialogComponent implements OnInit {

  public Editor = ClassicEditor;
  public config = {
    extraPlugins: [
      Base64ImageUploadAdapterPlugin
    ],
    mediaEmbed: {
      previewsInData: true
    }
  };

  get title(): string {
    if (!this.data) return "";
    return this.data.title;
  }

  get content(): string {
    if (!this.data) return "";
    return this.data.content;
  }
  set content(value: string) {
    this.data.content = value;
  }

  constructor(
    private dialogRef: MatDialogRef<RichTextDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      content: string
    }) {
  }

  ngOnInit() {

  }

  import() {
    this.dialogRef.close(this.data.content);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
