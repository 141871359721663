import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { TeamsService } from 'src/app/services/teams.service';
import { Team, UserTeam } from 'src/app/models/team.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';
import { SelectionModel } from '@angular/cdk/collections';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-team-selection-dialog',
  templateUrl: './team-selection-dialog.component.html',
  styleUrls: ['./team-selection-dialog.component.scss']
})
export class TeamSelectionDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'selected',
    'teamName',
    'members'
  ];
  dataSource: MatTableDataSource<Team>;
  loadingIndicator: boolean;
  selection = new SelectionModel<Team>(true, []);

  constructor(
    private teamService: TeamsService,
    private authService: AuthService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<TeamSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { companyId: number, title: string },
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  loadData() {
    this.loadingIndicator = true;
    this.alertService.startLoadingMessage();

    if (!this.data.companyId || this.data.companyId < 0)
      this.data.companyId = this.authService.companyId;

    this.teamService.getTeamsByCompany(this.data.companyId, true).subscribe(teams => this.onDataLoadSuccessful(teams),
      error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: Team[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  select() {
    var profiles: Profile[] = [];
    for (var t of this.selection.selected) {
      for (var ut of t.userTeam) {
        if (profiles.filter(p=>p.userId == ut.userId).length <= 0)
          profiles.push(ut.user);
      }
    }
    this.dialogRef.close(profiles);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.filteredData.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Team): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }

    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${this.dataSource.filteredData.indexOf(row) + 1}`;
  }
}
