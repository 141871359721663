export class CampaignBuilder {
  public campaignName: string;
  public designation: string;
  public invitationContent: string;
  public adminCompanyId: number;
  public openDate: Date;
  public closeDate: Date;
  public courseIds: number[];
  public materialIds: number[];
  public by: string;
  public campaignId: number;

  public static Create(
    campaignName: string,
    designation: string,
    invitationContent: string,
    adminCompanyId: number,
    openDate: Date,
    closeDate: Date,
    courseIds: number[],
    materialIds: number[]
  ) {
    var build = new CampaignBuilder();
    build.campaignName = campaignName;
    build.designation = designation;
    build.invitationContent = invitationContent;
    build.adminCompanyId = adminCompanyId;
    build.openDate = openDate;
    build.closeDate = closeDate;
    build.courseIds = courseIds;
    build.materialIds = materialIds;

    return build;
  }
}
