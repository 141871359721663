import { IAuditableEntity } from "./IAuditableEntry.interface";

export class AnswerValue implements IAuditableEntity {
  public answerValueId: number;
  public answerId: number;
  public code: string;
  public title: string;
  public pointValue: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public ordinal: number;
  public deleted: boolean;
}
