import { Component, OnInit } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { CourseMaterial } from 'src/app/models/course-material.model';
import { Course } from 'src/app/models/course.model';
import { CoursesService } from 'src/app/services/courses.service';
import { ActivatedRoute, Params } from '@angular/router';
import { AlertService, MessageSeverity, DialogType } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { MatSnackBar, MatSelectChange } from '@angular/material';
import { Utilities } from 'src/app/services/utilities';
import { Material } from 'src/app/models/material.model';
import { MaterialsService } from 'src/app/services/materials.service';

@Component({
  selector: 'app-course-editor',
  templateUrl: './course-editor.component.html',
  styleUrls: ['./course-editor.component.scss'],
  animations: [fadeInOut]
})
export class CourseEditorComponent implements OnInit {

  courseId: number;

  course: Course = new Course(-1, "");

  loadingIndicator: boolean;

  deletedOrds: CourseMaterial[] = [];

  materials: Material[] = [];

  selectedMaterial: Material;

  get designation(): string {
    return this.course.designation || "course";
  }

  get icon(): string {
    switch (this.designation) {
      case "course": {
        return "group_work";
        break;
      }
      default: {
        return "group_work";
        break;
      }
    }
  }

  get title(): string {
    if (this.course)
      return this.course.courseName;
    return "";
  }

  get jsonData(): string {
    if (!this.course) return "no data";
    return JSON.stringify(this.course, null, 2);
  }

  get noContent(): boolean {
    if (!this.course) return true;
    if (!this.course.courseMaterial) return true;
    return this.course.courseMaterial.filter(x => (x.materialId || 0) != 0).length <= 0;
  }

  get orders(): CourseMaterial[] {
    if (!this.course) return [];
    if (!this.course.courseMaterial || this.course.courseMaterial.length <= 0) {
      this.course.courseMaterial = [];
    }

    this.course.courseMaterial = this.course.courseMaterial.sort((a, b) => a.ordinal < b.ordinal ? -1 : 1);

    return this.course.courseMaterial;
  }

  get freeMaterials(): Material[] {
    if (!this.materials) return [];
    return this.materials;
  }

  constructor(
    private courseService: CoursesService,
    private materialService: MaterialsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private translateService: AppTranslationService,
    private snackBar: MatSnackBar
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.courseId = params['courseId'];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.courseService.getCourse(this.courseId, true).subscribe(result => this.onDataLoadSuccessful(result),
      error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(course: Course) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    if (this.course)
      Object.assign(this.course, course);
    else
      this.course = course;
    this.resetOrdinals();
       
    this.materialService.getMaterialsByCompanyId(this.course.companyId).subscribe(
      result => this.onDataLoadSuccessfulMaterials(result),
      error => this.onDataLoadFailed(error));

    window.scroll(0, 0);
  }

  private onDataLoadSuccessfulMaterials(materials: Material[]) {
    this.materials = materials;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private contentPosition(matord: CourseMaterial): string {
    var index = this.course.courseMaterial.indexOf(matord);

    var first: boolean = index == 0;
    var last: boolean = index == (this.course.courseMaterial.length - 1);

    return (first == true && last == true ? "single" : first == true ? "first" : last == true ? "last" : "");
  }

  private resetOrdinals() {
    this.course.courseMaterial.sort((a, b) => a.ordinal > b.ordinal ? 1 : -1);

    var ordinal: number = 0;
    this.course.courseMaterial.forEach(x => {
      ordinal += 100;
      x.ordinal = ordinal;
    });
  }

  private contentDelete(matcon: CourseMaterial) {
    var index = this.course.courseMaterial.findIndex(x => x.materialId == matcon.materialId);
    if (index >= 0)
      this.deletedOrds.push(this.course.courseMaterial.splice(index, 1)[0]);
    this.resetOrdinals();
  }

  private contentMoveUp(matcon: CourseMaterial) {
    var ord = this.course.courseMaterial.find(x => x.materialId == matcon.materialId);
    ord.ordinal -= 150;
    this.resetOrdinals();
  }

  private contentMoveDown(matcon: CourseMaterial) {
    var ord = this.course.courseMaterial.find(x => x.materialId == matcon.materialId);
    ord.ordinal += 150;
    this.resetOrdinals();
  }

  private addContentAt(ordinal: number, before: boolean, material: Material, event?: MatSelectChange) {
    var matcontent = this.course.courseMaterial.find(x => x.materialId == material.materialId);

    if (!matcontent) {
      matcontent = new CourseMaterial();
      matcontent.courseId = this.courseId;
      matcontent.materialId = material.materialId;
      matcontent.material = material;

      this.course.courseMaterial.push(matcontent);
    }

    matcontent.ordinal = ordinal + (before == true ? -50 : 50);
    this.resetOrdinals();

    this.selectedMaterial = null;
    if (event) {
      event.source.value = null;
    }
  }

  private reload() {
    this.snackBar.open(this.translateService.getTranslation("courseEditor.ConfirmReload"), this.translateService.getTranslation("commands.Reload"), { duration: 5000 })
      .onAction().subscribe(() => {
        window.scroll(0, 0);
        this.loadData();
      });
  }

  save() {
    var msg = this.translateService.getTranslation("courseEditor.ConfirmSave");
    var ttl = this.translateService.getTranslation("commands.Save");
    var yes = this.translateService.getTranslation("commands.Yes");
    var no = this.translateService.getTranslation("commands.No");
    this.alertService.showDialog(ttl, msg, DialogType.confirm,
      (val) => this.processSave(),
      () => {

      },
      yes, no);
  }

  private processSave() {
    this.loadingIndicator = true;
    this.alertService.startLoadingMessage("Saving...");
    //this.deletedOrds.forEach(x => {
    //  if (x.materialOrderId > 0) {
    //    x.deleted = true;
    //    this.material.materialOrder.push(x);
    //  }
    //});
    this.courseService.patchCourse(this.course).subscribe(result => this.onDataLoadSuccessful(result),
      error => this.onDataLoadFailed(error));
  }
}
