import { Injectable } from '@angular/core';

import { AppTheme } from '../../models/AppTheme';
import { AppLanguage } from '../../models/AppLanguage';
import { ConfigurationService } from '../../services/configuration.service';

@Injectable()
export class LanguageManager {
  languages: Array<AppLanguage> = [
    { name: 'English', locale: 'en', isDefault: true },
    { name: 'Español', locale: 'es', isDefault: false },
    { name: 'Français', locale: 'fr', isDefault: false },
    { name: 'Deutsche', locale: 'de', isDefault: false },
    { name: 'Português', locale: 'pt', isDefault: false },
    { name: 'العربية', locale: 'ar', isDefault: false },
    { name: '한국인', locale: 'ko', isDefault: false }
  ];

  constructor(
    private configuration: ConfigurationService
  ) {

  }

  public installLanguage(language: AppLanguage) {
    if (language == null) {
      this.configuration.language = 'en';
    } else {
      this.configuration.language = language.locale;
    }
  }

  public getLanguageByLocale(locale: string): AppLanguage {
    return this.languages.find(language => language.locale === locale);
  }
}
