import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { UserUploadControlComponent } from '../../controls/user-upload-control/user-upload-control.component';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-contact-upload-dialog',
  templateUrl: './contact-upload-dialog.component.html',
  styleUrls: ['./contact-upload-dialog.component.scss']
})
export class ContactUploadDialogComponent implements OnInit {

  @ViewChild('userUpload') userUpload: UserUploadControlComponent;

  contacts: InvitationContact[];

  get title(): string {
    var t: string = this.translationService.getTranslation("contactUpload.Title");

    if (this.data) {
      if (this.data.title) {
        if (this.data.title != "") {
          t = this.data.title;
        }
      }
    }

    return t;
  }

  constructor(
    private translationService: AppTranslationService,
    public dialogRef: MatDialogRef<ContactUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string }) {

  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.userUpload.onContactsReady.subscribe(results => { this.contactsReady(results); });
  }
  
  contactsReady(contacts: InvitationContact[]) {
    this.contacts = [];
    this.contacts = contacts.filter(c => c.email && c.email != "");
    this.dialogRef.close(this.contacts);
  }

  import() {
    this.userUpload.Upload();
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
