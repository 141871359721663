import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { Answer } from 'src/app/models/answer.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AnswerValue } from 'src/app/models/answer-value.model';

@Component({
  selector: 'app-answer-editor-dialog',
  templateUrl: './answer-editor-dialog.component.html',
  styleUrls: ['./answer-editor-dialog.component.scss']
})
export class AnswerEditorDialogComponent implements OnInit {

  get title(): string {
    if (!this.data) return "";
    return this.data.title;
  }

  get answer(): Answer {
    if (!this.data) return new Answer();
    return this.data.answer;
  }

  constructor(
    private dialogRef: MatDialogRef<AnswerEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      answer: Answer,
      onValueDelete: EventEmitter<AnswerValue>
    }) { }

  ngOnInit() {
  }
   
  valueDelete(av: AnswerValue) {
    this.data.onValueDelete.emit(av);
  }

  done() {
    this.dialogRef.close(this.answer);
  }
}
