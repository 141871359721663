import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Company } from 'src/app/models/company';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthService } from 'src/app/services/auth.service';
import { ImageUploadDialogComponent } from '../../dialogs/image-upload-dialog/image-upload-dialog.component';
import { MatDialog } from '@angular/material';
import { CompanyViewDialogComponent } from '../../companies/company-view-dialog/company-view-dialog.component';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { CompanyCreditDialogComponent } from '../../companies/dialogs/company-credit-dialog/company-credit-dialog.component';
import { CompanyCredit } from 'src/app/models/company-credit';

@Component({
  selector: 'app-company-card-control',
  templateUrl: './company-card-control.component.html',
  styleUrls: ['./company-card-control.component.scss']
})
export class CompanyCardControlComponent implements OnInit, OnChanges {
  @Input() companyId: number;
  @Input() getDefaultCompany: boolean = true;

  public company: Company;

  private cancelWait: boolean = false;

  get companyName(): string {
    if (!this.company) return "";
    return this.company.companyName;
  }
  get address(): string {
    if (!this.company) return "";
    var address: string = this.company.address;
    if (address != "" && (this.company.city || "") != "")
      address += ", ";
    address += this.company.city;
    if (address != "" && (this.company.state || "") != "")
      address += ", ";
    address += this.company.state;
    if (address != "" && (this.company.zipCode || "") != "")
      address += " ";
    address += this.company.zipCode;

    return address;
  }
  get companyPhoneUrl(): string {
    if (!this.company) return "";
    if (this.company.companyPhoneNumber == null) return "";
    return `tel:+${this.company.companyPhoneNumber.replace(/\D/g, '')}`;
  }
  get companyPhone(): string {
    if (!this.company) return "";
    if (this.company.companyPhoneNumber == null) return "";
    return this.company.companyPhoneNumber;
  }

  get logoSquare(): string {
    if (!this.company) return "";
    return this.company.logoSquareBase64;
  }
  get logoFullBase64(): string {
    if (!this.company) return "";
    return this.company.logoFullBase64;
  }

  get contact(): string {
    if (!this.company) return "";
    if (this.company.contactName == null) return "";
    return this.company.contactName;
  }

  get email(): string {
    if (!this.company) return "";
    return this.company.contactEmailAddress;
  }
  get emailurl(): string {
    if (!this.company) return "";
    if (this.company.contactEmailAddress == null) return "";
    return `mailto:${this.company.contactEmailAddress}`;
  }
  get phone(): string {
    if (!this.company) return "";
    return this.company.contactPhoneNumber || "";
  }
  get phoneurl(): string {
    if (!this.company) return "";
    if (this.company.contactPhoneNumber == null) return "";
    return `tel:+${this.company.contactPhoneNumber.replace(/\D/g, '')}`;
  }
  get website(): string {
    if (!this.company) return "";
    return this.company.website || "";  
  }

  private requestingCredits: boolean = false;
  private requestCreditCount: number = 0;
  get remainingCredits(): string {
    if (!this.company) return "0";
    if (this.company.unlimitedCredits == true) return this.translationService.getTranslation("companyCard.UnlimitedCredits");

    if (this.company.companyCredits && this.company.companyCredits.length > 0) {
      return this.company.companyCredits.filter(c => (c.userTestId || 0) <= 0).length.toString();
    }
    else {
      if (this.requestingCredits == false && this.requestCreditCount <= 3) {
        this.requestCreditCount += 1;
        this.requestingCredits = true;
        this.companyService.getCredits(this.companyId).subscribe(
          result => this.onCreditsSuccess(result),
          error => this.onCreditsFailed(error)
        );
      }
    }

    return "0";
  }


  get creditButtonColor(): string {
    if (this.remainingCredits == "0")
      return "warn";
    return "default";
  }


  get canChangePhoto(): boolean {
    if (!this.company || !this.authService) return false;
    if (this.authService.userIsAdmin || this.authService.userIsCompanyAdmin) return true;
    else return this.authService.userIsCompanyAgent;
  }

  get canEditCompany(): boolean {
    if (!this.company || !this.authService) return false;
    if (this.authService.userIsAdmin || this.authService.userIsCompanyAdmin) return true;
    else return this.authService.userIsCompanyAgent;
  }

  get canManageCredits(): boolean {
    if (!this.company || !this.authService) return false;
    return this.authService.userIsAdmin;
  }

  constructor(
    private companyService: CompaniesService,
    public authService: AuthService,
    private translationService: AppTranslationService,
    private alertService: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (this.getDefaultCompany == true) {
      if (this.authService.currentUserCompany) {
        this.companyFound(this.authService.currentUserCompany);
      }

      this.authService.companySelected$.subscribe(
        result => { this.companyFound(result); }
      );
    }
    this.waitForCompanyId(this.companyId);
  }

  ngOnChanges() {
    if (this.company)
      this.companyFound(this.company);
  }

  public companyFound(company: Company) {
    this.company = company;
    return this.makeCall(company.companyId);
  }

  waitForCompanyId(id: number) {
    if (this.waitCancelled() == true) {
      return;
    }

    if (!id) {
      setTimeout(() => { this.waitForCompanyId(id); }, 500);
      return;
    }

    if (id < 0) {
      setTimeout(() => { this.waitForCompanyId(id); }, 500);
      return;
    }

    this.makeCall(id);
  }

  waitCancelled(): boolean {
    if (this.cancelWait) {
      this.cancelWait = false;
      return true;
    }
    return false;
  }

  makeCall(id: number) {
    this.companyId = id;
    this.cancelWait = true;

    if (this.company && this.company.companyId != id)
      this.company = null;

    if (!this.company) {
      this.companyService.getCompany(this.companyId, true).subscribe(
        data => this.parseCompany(data),
        error => this.parseError(error)
      );
    }
  }

  parseCompany(data: Company) {
    this.company = data;
  }

  parseError(error: any) {

  }

  launchSquareImageImport() {
    if (!this.canChangePhoto) return;

    const dialogRef = this.dialog.open(ImageUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(base64Image => {
      if (base64Image) {
        this.updateSquareLogo(base64Image);
      }
    });
  }

  updateSquareLogo(image: string) {
    this.company.logoSquareBase64 = image;
    this.companyService.updateCompany(this.company).subscribe(
      () => this.companyUpdated(),
      error => this.parseError(error)
    );
  }



  launchFullImageImport() {
    if (!this.canChangePhoto) return;

    const dialogRef = this.dialog.open(ImageUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(base64Image => {
      if (base64Image) {
        this.updateFullLogo(base64Image);
      }
    });
  }

  updateFullLogo(image: string) {
    this.company.logoFullBase64 = image;
    this.companyService.updateCompany(this.company).subscribe(
      () => this.companyUpdated(),
      error => this.parseError(error)
    );
  }

  companyUpdated() {
    if (this.authService.companyId != this.company.companyId) return;
    this.authService.currentUserCompany = this.company;
  }

  launchCompanyEditor() {
    if (!this.canEditCompany) return;

    var editCompany: Company = new Company();
    Object.assign(editCompany, this.company);

    const dialogRef = this.dialog.open(CompanyViewDialogComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { company: editCompany }
      });
    dialogRef.afterClosed().subscribe(company => {
      if (company) {
        this.updateCompany(company);
      }
    });
  }

  updateCompany(company: Company) {
    Object.assign(this.company, company);
  }

  launchCreditEditor() {
    if (!this.canEditCompany) return;

    if (!this.canManageCredits) {
      this.alertService.showMessage(this.translationService.getTranslation("companyCard.CreditContact"));
      return;
    }

    var editCompany: Company = new Company();
    Object.assign(editCompany, this.company);

    const dialogRef = this.dialog.open(CompanyCreditDialogComponent,
      {
        panelClass: 'mat-dialog-sm',
        disableClose: true,
        data: { company: editCompany }
      });
    dialogRef.afterClosed().subscribe(company => {
      if (company) {
        this.updateCompany(company);
      }
    });

  }

  onCreditsSuccess(credits: CompanyCredit[]) {
    this.requestingCredits = false;
    this.company.companyCredits = credits;
  }
  onCreditsFailed(error: any) {
    this.requestingCredits = false;
  }
}
