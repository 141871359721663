<app-page-header title="SendInvitation" icon="mail_outline"></app-page-header>
<div [@fadeInOut] class="page-content">
  <mat-horizontal-stepper linear>
    <mat-step [completed]="validContacts">
      <!--Contacts-->
      <ng-template matStepLabel>{{'invitations.steps.Recipients' | translate}}</ng-template>
      <app-contact-table-control [contacts]="contactData"></app-contact-table-control>
      <div>
        <div fxFlex="auto"></div>
        <button mat-button matStepperNext type="button" *ngIf="validContacts">{{'invitations.commands.Next' | translate}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="validSubjectAndBody">
      <!--Subject and Body-->
      <ng-template matStepLabel>{{'invitations.steps.Subject' | translate}}</ng-template>
      <form #form="ngForm" [formGroup]="formSubject" autocomplete="off">
        <div fxLayout="row">
          <mat-form-field floatLabel="auto" fxFlex="100%">
            <mat-label>{{'invitations.preview.Subject' | translate}}</mat-label>
            <input matInput formControlName="subject" [readonly]="false" />
          </mat-form-field>
        </div>
        <div fxLayout="row">
          <mat-form-field floatLabel="auto" fxFlex="100%">
            <mat-label>{{'invitations.preview.Body' | translate}}</mat-label>
            <textarea matInput formControlName="body" [readonly]="false" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
          </mat-form-field>
        </div>
      </form>
      <div>
        <button mat-button matStepperPrevious type="button">{{'invitations.commands.Back' | translate}}</button>
        <div fxFlex="auto"></div>
        <button mat-button matStepperNext type="button" *ngIf="validSubjectAndBody">{{'invitations.commands.Next' | translate}}</button>
      </div>
    </mat-step>
    <mat-step [completed]="validOptions">
      <!--Options-->
      <ng-template matStepLabel>{{'invitations.steps.Options' | translate}}</ng-template>
      <form #form="ngForm" [formGroup]="formOptions" autocomplete="off">
        <div fxLayout="row">
          {{'invitations.preview.ExpirationDate' | translate}}
        </div>
        <div fxLayout="row" fxLayoutGap="10px grid">
          <div fxFlex="25" class="mat-small">
            {{'invitations.descriptions.ExpirationDate' | translate}}
          </div>
          <div>
            <mat-form-field floatLabel="auto" fxFlex="100%">
              <mat-label>{{'invitations.preview.ExpirationDate' | translate}}</mat-label>
              <input matInput formControlName="expirationDate" [matDatepicker]="myDatepicker" [min]="minExp">
              <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #myDatepicker></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <mat-divider class="options-divider"></mat-divider>
        <div fxLayout="row">
          {{'invitations.preview.Course' | translate}}
        </div>
        <div fxLayout="row" fxLayoutGap="10px grid">
          <div fxFlex="25" class="mat-small">
            {{'invitations.descriptions.Course' | translate}}
          </div>
          <div fxFlex="100%">
            <mat-form-field floatLabel="auto" fxFlex="100%">
              <mat-label>{{'invitations.preview.Course' | translate}}</mat-label>
              <mat-select formControlName="courseId" selectionChange="courseSelected">
                <mat-option *ngFor="let course of courseList" [value]="course.courseId">
                  <span matTooltip="{{course.courseName}}">{{course.courseName}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-divider class="options-divider"></mat-divider>
        <div fxLayout="row">
          {{'invitations.preview.Material' | translate}}
        </div>
        <div fxLayout="row" fxLayoutGap="10px grid">
          <div fxFlex="25" class="mat-small">
            {{'invitations.descriptions.Material' | translate}}
          </div>
          <div fxFlex="100%">
            <mat-form-field floatLabel="auto" fxFlex="100%">
              <mat-label>{{'invitations.preview.Material' | translate}}</mat-label>
              <mat-select formControlName="materialId" selectionChange="materialSelected">
                <mat-option *ngFor="let material of materialList" [value]="material.materialId">
                  <span matTooltip="{{material.materialName}}">{{material.materialName}}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div>
        <button mat-button matStepperPrevious type="button">{{'invitations.commands.Back' | translate}}</button>
        <div fxFlex="auto"></div>
        <button mat-button matStepperNext type="button" *ngIf="validOptions">{{'invitations.commands.Next' | translate}}</button>
      </div>
    </mat-step>
    <mat-step completed="false">
      <!--Preview-->
      <ng-template matStepLabel>{{'invitations.steps.Send' | translate}}</ng-template>
      <mat-card>
        <mat-card-content fxLayoutGap="10px grid">
          <div fxLayout="row">
            <div fxFlex="12">{{'invitations.preview.Recipients' | translate}}</div>
            <div fxFlex="100%" class="mat-small">
              {{ contactstring }}
            </div>
          </div>
          <div fxLayout="row">
            <div fxFlex="12">{{'invitations.preview.Subject' | translate}}</div>
            <div fxFlex="100%">
              <b>{{ subject.value }}</b>
            </div>
          </div>
          <div fxLayout="row">
            <div fxFlex="12">{{'invitations.preview.Body' | translate}}</div>
            <div fxFlex="100%">
              <pre>{{ body.value || ('invitations.preview.DefaultEmailTemplate' | translate) }}</pre>
            </div>
          </div>

          <div fxLayout="row" *ngIf="expirationDateValue != null">
            <div fxFlex="12">{{'invitations.preview.ExpirationDate' | translate}}</div>
            <div fxFlex="100%">
              <pre>{{ expirationDateValuePreview }}</pre>
            </div>
          </div>

          <div fxLayout="row" *ngIf="courseName != ''">
            <div fxFlex="12">{{'invitations.preview.Course' | translate}}</div>
            <div fxFlex="100%">
              <pre>{{ courseName }}</pre>
            </div>
          </div>

          <div fxLayout="row" *ngIf="materialName != ''">
            <div fxFlex="12">{{'invitations.preview.Material' | translate}}</div>
            <div fxFlex="100%">
              <pre>{{ materialName }}</pre>
            </div>
          </div>
        </mat-card-content>

      </mat-card>
      <div>
        <button mat-button matStepperPrevious type="button">{{'invitations.commands.Back' | translate}}</button>
        <div fxFlex="auto"></div>
        <button mat-raised-button (click)="send();" color="primary">{{'invitations.commands.Send' | translate}}</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  <!--</form>-->
</div>
