import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { KeyValuePair, KeyValueControlComponent } from '../controls/key-value-control/key-value-control.component';
import { Profile } from 'src/app/models/profile';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { ContactUploadDialogComponent } from '../dialogs/contact-upload-dialog/contact-upload-dialog.component';
import { MatDialog } from '@angular/material';
import { Company } from 'src/app/models/company';
import { LegacyService } from '../../services/legacy.service';
import { KeyValueObject } from '../../models/key-value-object.model';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    animations: [fadeInOut]
})
export class HomeComponent implements OnInit, AfterViewInit {
  @ViewChild(KeyValueControlComponent) kvpcontrol: KeyValueControlComponent;

  checkkeyvalue: string;

  private profile: Profile;
  private company: Company;

  get userId(): number {
    var userId: number = 0;
    if (this.profile)
      userId = this.profile.userId;
    return userId;
  }

  get htmlcontent(): string {
    return '<hr/><br/><h1>How About Now?</h1>';
  }

  get showCompanyDashboard(): boolean {
    if (!this.authService) return false;
    if (!this.authService.currentUserCompany) return false;
    return this.authService.userIsCompanyAgent;
  }

  constructor(
    public legacyService: LegacyService,
    public configurations: ConfigurationService,
    private authService: AuthService,
    private dialog: MatDialog) {
  }

  ngOnInit() {

    this.profile = this.authService.currentUserProfile;
    this.authService.profileSelected$.subscribe(
      result => { this.profileFound(result); }
    );


    this.company = this.authService.currentUserCompany;
    this.authService.companySelected$.subscribe(
      result => { this.companyFound(result); }
    );


    this.legacyService.getClients().subscribe(
      result => { this.legacyClientsFound(result); },
      error => { this.handleError(error); }
    );
  }

  legacyClientsFound(result: KeyValueObject[]) {

  }

  handleError(error: any) {

  }

  ngAfterViewInit(): void {
    /*
    this.kvpcontrol.pairChanged$.subscribe(
      kvp => this.handleKVPChange(kvp)
    );

    this.checkkeyvalue = this.kvpcontrol.value;
    */
  }
  handleKVPChange(pair: KeyValuePair) {

  }

  profileFound(profile: Profile) {
    this.profile = profile;
  }

  companyFound(company: Company) {
    this.company = company;
  }

}
