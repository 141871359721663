import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { observe, Observables } from 'rxjs-observe';

export interface Filter {
  name: string;
  from: number,
  to: number,
  active: boolean
}
//https://stackblitz.com/edit/angular-material-range-slider-kxqxmq
@Component({
  selector: 'app-benchmark-slider-control',
  templateUrl: './benchmark-slider-control.component.html',
  styleUrls: ['./benchmark-slider-control.component.scss']
})
export class BenchmarkSliderControlComponent implements OnInit, AfterViewInit {

  @Input() benchmarkName: string = '';
  @Input() benchmarkFromValue: number = 0;
  @Input() benchmarkToValue: number = 15;
  @Output() benchmarkChanged: EventEmitter<Filter> = new EventEmitter<Filter>();


  active: boolean = false;

  instance: Filter;
  benchmark: Filter;

  displayedColumns: string[] = ['name', 'from', 'range', 'to'];

  slidersRefresh: EventEmitter<void> = new EventEmitter<void>();

  constructor() {

  }

  ngOnInit() {
    this.loadBenchmark(this.benchmarkName, this.benchmarkFromValue, this.benchmarkToValue);
  }

  ngAfterViewInit(): void {
    const { observables, proxy } = observe(this.instance);

    this.benchmark = proxy;

    observables.active.subscribe(b => { this.emitBenchmark(); });
    observables.from.subscribe(b => { this.emitBenchmark(); });
    observables.to.subscribe(b => { this.emitBenchmark(); });
  }

  loadBenchmark(name: string, from:number, to:number) {
    this.benchmarkName = name;
    this.benchmarkFromValue = from;
    this.benchmarkToValue = to;

    this.active = !(this.benchmarkFromValue == 0 && this.benchmarkFromValue == this.benchmarkToValue);

    this.instance = { name: this.benchmarkName, from: this.benchmarkFromValue, to: this.benchmarkToValue, active: this.active };
    this.benchmark = this.instance;
  }

  emitBenchmark() {
    this.benchmarkChanged.emit(this.benchmark);
  }



}
