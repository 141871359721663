import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { Course, CourseDisplay } from 'src/app/models/course.model';
import { CoursesService } from 'src/app/services/courses.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/services/utilities';
import { Company } from 'src/app/models/company';
import { CourseNewDialogComponent } from '../../dialogs/course-new-dialog/course-new-dialog.component';

@Component({
  selector: 'app-course-list-control',
  templateUrl: './course-list-control.component.html',
  styleUrls: ['./course-list-control.component.scss']
})
export class CourseListControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() companyId: number;
  @Input() designation: string;

  get role(): string {
    if (!this.authService) return "User";
    if (!this.authService.companyRole) return "User";
    return this.authService.companyRole;
  }

  get canEdit(): boolean {
    return this.role != "User";
  }


  get isCreator(): boolean {
    if (!this.authService) return false;
    return this.authService.userIsCompanyCreator;
  }

  get displayedColumns(): string[] {
    if (this.canEdit) {
      return [
        "courseName",
        "companyName",
        "language",
        "template",
        "actions"
      ];
    }

    return [
      "courseName",
      "companyName",
      "template"
    ];
  }
  dataSource: MatTableDataSource<Course>;
  loadingIndicator: boolean;


  constructor(
    private courseService: CoursesService,
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public companyFound(company: Company) {
    this.companyId = company.companyId;
    this.loadData();
  }
  public companyIdFound(companyId: number) {
    this.companyId = companyId;
    this.loadData();
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.courseService.getCourses(this.companyId, this.designation).subscribe(entries => this.onDataLoadSuccessful(entries),
      error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: Course[]) {
    var data = entries.map(e => {
      var d = new CourseDisplay();
      Object.assign(d, e);
      if (d.company)
        d.companyName = d.company.companyName;
      return d;
    });

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = data;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private newCourse() {
    var copy: Course = new Course(0, `(new course)`);
    copy.companyId = this.authService.companyId;
    copy.template = false;
    copy.languageCode = "en";

    const dialogref = this.dialog.open(
      CourseNewDialogComponent,
      {
        panelClass: "mat-dialog-sm",
        data: { course: copy, companyId: this.companyId, designation: this.designation }
      });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource.data = this.dataSource.data.sort((a, b) => (a.courseName > b.courseName) ? 1 : -1);
        this.edit(result);
      }
    });
  }

  private copy(object: Course) {
    this.courseService.copyCourse(object.courseId).subscribe(
      result => this.edit(result),
      error => this.onDataLoadFailed(error)
    );
  }

  private edit(object: Course) {
    this.router.navigateByUrl(`/course/${object.courseId}`);
  }

  private confirmDelete(object: Course) {
    if (object.template) return;

    this.snackBar.open(`Delete ${object.courseName}?`, 'DELETE', { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;
        this.delete(object.courseId);
      });
  }

  delete(courseId: number) {
    this.courseService.deleteCourse(courseId)
      .subscribe(
        results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.loadData();
        },
        error => {
          this.checkError(error);
        });
  }

  checkError(error: string) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (error != "INVALID")
      return;

    this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }




}
