<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content fxLayout="column">

  <div fxFlex="100%">
    <mat-form-field floatLabel="auto" fxFlex="100%">
      <mat-label>{{'testNewDialog.Name' | translate}}</mat-label>
      <input matInput [(ngModel)]="material.materialName" />
    </mat-form-field>
  </div>

  <div fxFlex="100%">
    <mat-form-field floatLabel="auto" fxFlex="100%">
      <mat-label>{{'testNewDialog.Language' | translate}}</mat-label>
      <mat-select [(ngModel)]="material.languageCode">
        <mat-option *ngFor="let lc of languages" [value]="lc.code">
          <span matTooltip="{{lc.title}}">{{lc.title + ' (' + lc.code + ')'}}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="continue();" color="primary">{{'commands.Continue' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
