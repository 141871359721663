export class AnswerChangeEventArgs {
  public stepIndex: number;
  public taskItemId: number;
  public answerValue: string;
  public itemType: string;

  public constructor(stepIndex: number, taskItemId: number, answerValue: string, itemType: string) {
    this.stepIndex = stepIndex;
    this.taskItemId = taskItemId;
    this.answerValue = answerValue;
    this.itemType = itemType;
  }
}
