<div fxLayout="column" fxLayoutGap="1em" fxFlex="50">

    <mat-form-field>

        <mat-select [(ngModel)]="configurations.language" placeholder="{{'preferences.Language' | translate}}">
            <mat-option *ngFor="let language of languages" [value]="language.locale">
                <span class="vertical-center">{{'preferences.' + language.name | translate}}<i *ngIf="language.isDefault" style="font-size: 0.75em;"> (default)</i></span>
            </mat-option>
        </mat-select>

        <mat-hint>{{'preferences.LanguageHint' | translate}}</mat-hint>

    </mat-form-field>

    <mat-form-field>

        <mat-select [(ngModel)]="configurations.homeUrl" placeholder="{{'preferences.HomePage' | translate}}">
            <mat-select-trigger>
                <mat-icon class="vertical-center mat-button-icon">{{currentHomePage.icon}}</mat-icon><span class="vertical-center">{{currentHomePage.title}}</span>
            </mat-select-trigger>

            <mat-option *ngFor="let page of homePages" [value]="page.path">
                <mat-icon class="vertical-center">{{page.icon}}</mat-icon><span class="vertical-center">{{'preferences.' + page.title | translate}}<i *ngIf="page.isDefault" style="font-size: 0.75em;"> (default)</i></span>
            </mat-option>
        </mat-select>

        <mat-hint>{{'preferences.HomePageHint' | translate}}</mat-hint>

    </mat-form-field>

    <div fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="1em">

    <mat-slide-toggle [(ngModel)]="configurations.showDashboardStatistics"
                      matTooltip="{{'preferences.DashboardStatisticsHint' | translate}}"
                      matTooltipPosition="right">
        {{'preferences.DashboardStatistics' | translate}}
    </mat-slide-toggle>

    <mat-slide-toggle [(ngModel)]="configurations.showDashboardNotifications"
                      matTooltip="{{'preferences.DashboardNotificationsHint' | translate}}"
                      matTooltipPosition="right">
        {{'preferences.DashboardNotifications' | translate}}
    </mat-slide-toggle>

    <mat-slide-toggle [(ngModel)]="configurations.showDashboardTodo"
                      matTooltip="{{'preferences.DashboardStatisticsHint' | translate}}"
                      matTooltipPosition="right">
        {{'preferences.DashboardTodo' | translate}}
    </mat-slide-toggle>

    <mat-slide-toggle [(ngModel)]="configurations.showDashboardBanner"
                      matTooltip="{{'preferences.DashboardBannerHint' | translate}}"
                      matTooltipPosition="right">
        {{'preferences.DashboardBanner' | translate}}
    </mat-slide-toggle>

    </div>

</div>
