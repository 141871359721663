<div fxLayout="column" class="simple-border content-container">
  <div fxFlex="100%" fxLayout="row">
    <mat-icon>{{icon}}</mat-icon>
    <div fxFlex="100%" class="material-name">
      {{material.materialName}}
    </div>
    <div class="buttons" fxLayout="row">
      <button mat-icon-button *ngIf="!(position == 'first' || position == 'single')" (click)="moveUp()" matTooltip="{{'commands.Up' | translate}}">
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!(position == 'last' || position == 'single')" (click)="moveDown()" matTooltip="{{'commands.Down' | translate}}">
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="delete()" matTooltip="{{'commands.Delete' | translate}}">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>

  <div fxFlex="100%" fxLayout="row" fxLayoutGap="16px" class="content-area" *ngIf="material.intro">
    <div fxFlex="100%" class="simple-border content-container">
      {{material.intro}}
    </div>
  </div>
</div>
