import { Injectable, OnInit } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { XrGender } from '../models/xr-gender';
import { Observable } from 'rxjs';
import { XrAnswerType } from '../models/xr-answer-type.model';
import { XrLanguageCode } from '../models/xr-language-code.model';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class XrService {

  genders: XrGender[];
  languageCodes: XrLanguageCode[];

  constructor(
    private applicationEndpoint: ApplicationEndpoint,
    private configService: ConfigurationService,
  ) { }

  getGenders() {
    if (!this.genders) {
      //this.applicationEndpoint
      //  .getXrGendersEndpoint<XrGender[]>()
      //  .subscribe(data =>
      //    this.onGendersDataLoadSuccessful(data),
      //    error => this.onDataLoadFailed(error)
      //  );

      this.genders = [
        new XrGender(1, "U", "Undisclosed"),
        new XrGender(2, "M", "Male"),
        new XrGender(3, "F", "Female")
      ];
    }

    return this.genders;
  }

  private onGendersDataLoadSuccessful(genders: XrGender[]) {
    this.genders = genders;
  }
  private onDataLoadFailed(error: any) {

  }

  getGender(id: number) {
    const list: XrGender[] = this.getGenders().filter(e => e.genderId == id);
    
    return list.length > 0 ? list[0] : undefined;
  }

  private requestAnswerTypesPending: boolean = false;
  getAnswerTypes() {
    if (!this.configService.xrAnswerTypes || this.configService.xrAnswerTypes.length <= 0) {
      if (!this.requestAnswerTypesPending) {
        this.requestAnswerTypesPending = true;
        this.applicationEndpoint
          .getXrAnswerTypesEndpoint<XrAnswerType[]>()
          .subscribe(data =>
            this.onAnswerTypesDataLoadSuccessful(data),
            error => this.onDataLoadFailed(error)
          );
      }
    }

    return this.configService.xrAnswerTypes;
  }

  private onAnswerTypesDataLoadSuccessful(answerTypes: XrAnswerType[]) {
    this.configService.xrAnswerTypes = answerTypes;
    this.requestAnswerTypesPending = false;
  }
  private onAnswerTypesDataLoadFailed(error: any) {
    this.requestAnswerTypesPending = false;
    this.onDataLoadFailed(error);
  }

  getAnswerType(id: number) {
    const list: XrAnswerType[] = this.getAnswerTypes().filter(e => e.answerTypeId == id);

    return list.length > 0 ? list[0] : undefined;
  }

  private requestLanguageCodesPending: boolean = false;
  getLanguageCodes() {
    if (!this.configService.xrLanguageCodes || this.configService.xrLanguageCodes.length <= 0) {
      if (!this.requestLanguageCodesPending) {
        this.requestLanguageCodesPending = true;
          this.applicationEndpoint
            .getXrLanguageCodesEndpoint<XrLanguageCode[]>()
            .subscribe(data =>
              this.onLanguageCodesDataLoadSuccessful(data),
              error => this.onDataLoadFailed(error)
            );
      }
    }

    return this.configService.xrLanguageCodes;
  }

  private onLanguageCodesDataLoadSuccessful(languageCodes: XrLanguageCode[]) {
    this.configService.xrLanguageCodes = languageCodes;
    this.requestLanguageCodesPending = false;
  }
  private onLanguageCodesDataLoadFailed(error: any) {
    this.requestLanguageCodesPending = false;
    this.onDataLoadFailed(error);
  }

  getLanguageCode(id: number) {
    const list: XrLanguageCode[] = this.getLanguageCodes().filter(e => e.languageCodeId == id);

    return list.length > 0 ? list[0] : undefined;
  }

  getLanguageCodeByCode(code: string) {
    const list: XrLanguageCode[] = this.getLanguageCodes().filter(e => e.code == code);

    return list.length > 0 ? list[0] : undefined;
  }
}
