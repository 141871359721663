import { IAuditableEntity } from "./IAuditableEntry.interface";
import { Answer } from "./answer.model";

export class Question implements IAuditableEntity {
  public questionId: number;
  public testId: number;
  public code: string;
  public title: string;
  public weight: number;
  public pointValue: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public ordinal: number;
  public deleted: boolean;

  public answer: Answer[];
}
