<div fxLayout="column" class="simple-border content-container">
  <div fxFlex="100%" fxLayout="row">
    <div fxFlex="100%">
      <mat-form-field floatLabel="auto" fxFlex="100%">
        <mat-label>{{'questionControl.Title' | translate}}</mat-label>
        <textarea matInput [(ngModel)]="editObj.title" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
      </mat-form-field>
    </div>
    <div class="buttons" fxLayout="row">
      <button mat-icon-button *ngIf="!(position == 'first' || position == 'single')" (click)="moveUp()" matTooltip="{{'commands.Up' | translate}}">
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!(position == 'last' || position == 'single')" (click)="moveDown()" matTooltip="{{'commands.Down' | translate}}">
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <button mat-icon-button *ngIf="position != 'single'" color="warn" (click)="delete()" matTooltip="{{'commands.Delete' | translate}}">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div fxFlex="100%" fxLayout="column" fxLayoutGap="8px">
    <div fxFlex="100%" fxLayout="row" *ngIf="noAnswer">
      <div fxFlex="auto"></div>
      <button mat-icon-button color="primary" (click)="addAnswerAt(100, true)" matTooltip="{{'testEditor.NewAnswer' | translate}}">
        <mat-icon>add_comment</mat-icon>
      </button>
      <div fxFlex="auto"></div>
    </div>
    <div fxFlex="100%" *ngFor="let a of answers" fxLayout="column" fxLayoutGap="8px" class="answer">
      <app-answer-view-control [answer]="a"
                          [position]="answerPosition(a)"
                          (onDelete)="answerDelete($event)"
                          (onMoveUp)="answerMoveUp($event)"
                          (onMoveDown)="answerMoveDown($event)"
                          (onValueDelete)="valueDelete($event)">

      </app-answer-view-control>
      <div fxFlex="100%" fxLayout="row">
        <div fxFlex="auto"></div>
        <button mat-icon-button color="primary" (click)="addAnswerAt(a.ordinal)" matTooltip="{{'testEditor.NewResponse' | translate}}">
          <mat-icon>add_comment</mat-icon>
        </button>
        <div fxFlex="auto"></div>
      </div>
    </div>
  </div>
</div>
