<div fxLayout="column" class="simple-border content-container">
  <div fxFlex="100%" fxLayout="row">
    <div fxFlex="100%">
      <mat-form-field floatLabel="auto" fxFlex="100%">
        <mat-label>{{'materialContentControl.Title' | translate}}</mat-label>
        <input matInput [(ngModel)]="editObj.title" />
      </mat-form-field>
    </div>
    <div class="buttons" fxLayout="row">
      <button mat-icon-button *ngIf="!(position == 'first' || position == 'single')" (click)="moveUp()" matTooltip="{{'commands.Up' | translate}}">
        <mat-icon>arrow_upward</mat-icon>
      </button>
      <button mat-icon-button *ngIf="!(position == 'last' || position == 'single')" (click)="moveDown()" matTooltip="{{'commands.Down' | translate}}">
        <mat-icon>arrow_downward</mat-icon>
      </button>
      <button mat-icon-button color="warn" (click)="delete()" matTooltip="{{'commands.Delete' | translate}}">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div fxFlex="100%" fxLayout="row" fxLayoutGap="16px" class="content-area">
    <div class="buttons">
      <button mat-icon-button (click)="launchContentEditor()" matTooltip="{{'commands.Edit' | translate}}">
        <mat-icon>edit</mat-icon>
      </button>
    </div>
    <div fxFlex="100%" class="simple-border content-container" >
      <div [innerHtml]="editObj.content | dompurify"></div>
    </div>
  </div>
</div>
