
import { Component, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserEditorComponent } from 'src/app/admin/user-editor/user-editor.component';
import { User } from 'src/app/models/user.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';


@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss']
})
export class RegistrationDialogComponent {
  @ViewChild(UserEditorComponent)
  userEditor: UserEditorComponent;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    public dialogRef: MatDialogRef<RegistrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngAfterViewInit() {
    this.userEditor.userSaved$.subscribe(user => this.userSaved(user));
  }

  userSaved(user: User) {
    //locate or create other user account...
    var msgtitle = this.translationService.getTranslation('users.registration.MessageTitleUserCreated');
    var msgtext = this.translationService.getTranslation('users.registration.MessageTextUserCreated');

    this.alertService.resetStickyMessage();

    this.alertService.showStickyMessage(msgtitle, msgtext, MessageSeverity.success);

    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  enterKeyDown() {
    this.userEditor.save();
  }
}
