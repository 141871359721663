import { Component, OnInit, ViewChild } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { IndividualsControlComponent } from '../controls/individuals-control/individuals-control.component';
import { ActivatedRoute, Params } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-company-individuals',
  templateUrl: './company-individuals.component.html',
  styleUrls: ['./company-individuals.component.scss'],
  animations: [fadeInOut]
})
export class CompanyIndividualsComponent implements OnInit {
  @ViewChild(IndividualsControlComponent) list: IndividualsControlComponent;

  companyId: number;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

  ngOnInit() {
  }

}
