import { Component, ViewChild, Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';

import { User } from '../../models/user.model';
import { Role } from '../../models/role.model';
import { UserEditorComponent } from '../../admin/user-editor/user-editor.component';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AccountService } from 'src/app/services/account.service';

import { fadeInOut } from '../../services/animations';
import { LoginDialogComponent } from '../login/login-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  animations: [fadeInOut]
})
export class RegistrationComponent {
  @ViewChild(UserEditorComponent)
  editUser: UserEditorComponent;

  @Input() user: User = new User();
  @Input() roles: Role[] = [];

  @Output() enterKeyPress = new EventEmitter();

  constructor(
    private authService: AuthService,
    public router: Router,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) {
  }

  ngAfterViewInit() {
    this.editUser.userSaved$.subscribe(user => this.userSaved(user));
  }

  userSaved(user: User) {
    //locate or create other user account...
    var msgtitle = this.translationService.getTranslation('users.registration.MessageTitleUserCreated');
    var msgtext = this.translationService.getTranslation('users.registration.MessageTextUserCreated');

    this.alertService.showStickyMessage(msgtitle, msgtext, MessageSeverity.success);

    this.router.navigateByUrl('/login');
  }

  cancel(): void {
    
  }

  enterKeyDown() {
    this.editUser.save();
  }
}
