import { Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material';
import { P60ResultControlComponent } from '../p60-result-control/p60-result-control.component';
import { ProfileCardControlComponent } from 'src/app/components/controls/profile-card-control/profile-card-control.component';
import { TaskListControlComponent } from 'src/app/components/dashboard/controls/task-list-control/task-list-control.component';
import { ProfileOpenInvitationsControlComponent } from '../profile-open-invitations-control/profile-open-invitations-control.component';
import { ProfileReportListControlComponent } from '../profile-report-list-control/profile-report-list-control.component';

@Component({
  selector: 'app-profile-view-control',
  templateUrl: './profile-view-control.component.html',
  styleUrls: ['./profile-view-control.component.scss']
})
export class ProfileViewControlComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() useDefaultProfile: boolean = true;
  @Input() profile: Profile;

  //@ViewChild(P60ResultControlComponent) p60Result: P60ResultControlComponent;
  @ViewChild(ProfileCardControlComponent) profileCard: ProfileCardControlComponent;
  @ViewChild(TaskListControlComponent) taskList: TaskListControlComponent;
  @ViewChild(ProfileOpenInvitationsControlComponent) openInvites: ProfileOpenInvitationsControlComponent;
  @ViewChild(ProfileReportListControlComponent) reportList: ProfileReportListControlComponent;

  get userId(): number {
    var userId: number = 0;
    if (this.profile)
      userId = this.profile.userId;
    return userId;
  }

  constructor(
    private authService: AuthService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    if (this.useDefaultProfile == true) {
      this.profile = this.authService.currentUserProfile;
      if (!this.profile) {
        this.authService.profileSelected$.subscribe(
          result => { this.profileFound(result); }
        );
      }
    }
  }

  ngAfterViewInit() {

  }

  ngOnChanges() {
    this.profileFound(this.profile);
  }

  profileFound(profile: Profile) {
    this.profile = profile;

    if (this.profile) {
      //this.p60Result.refresh(this.profile.userId);
      this.profileCard.profileFound(this.profile);
      this.taskList.profileFound(this.profile);
      this.openInvites.profileFound(this.profile);
      this.reportList.profileFound(this.profile);
    }
  }


}
