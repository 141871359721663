import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Invitation } from 'src/app/models/invitation.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Utilities } from 'src/app/services/utilities';
import { InvitationsService } from 'src/app/services/invitations.service';
import { Task } from 'src/app/models/task.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-open-invitations-control',
  templateUrl: './profile-open-invitations-control.component.html',
  styleUrls: ['./profile-open-invitations-control.component.scss']
})
export class ProfileOpenInvitationsControlComponent implements OnInit, AfterViewInit {
  @Input() userId: number;
  @Input() getDefaultProfile: boolean = true;

  private profile: Profile;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'claim',
    'subject',
    'invited'
  ];
  dataSource: MatTableDataSource<Invitation>;
  loadingIndicator: boolean;

  get isUser(): boolean {
    if (!this.profile || !this.authService) return false;
    return this.profile.userId == this.authService.currentUserProfile.userId;
  }

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private profileService: ProfileService,
    private inviteService: InvitationsService,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    if (this.getDefaultProfile == true) {
      this.authService.profileSelected$.subscribe(
        result => { this.profileFound(result); }
      );
    }

    this.waitForUserId(this.userId);
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public profileFound(profile: Profile) {
    this.profile = profile;

    if (!this.isUser) {
      this.displayedColumns = [
        'subject',
        'invited'
      ];
    }

    return this.makeCall(profile.userId);
  }

  waitForUserId(id: number) {
    if (!id) {
      setTimeout(() => { this.waitForUserId(id); }, 500);
      return;
    }

    if (id <= 0) {
      setTimeout(() => { this.waitForUserId(id); }, 500);
      return;
    }

    this.makeCall(id);
  }

  makeCall(id: number) {
    this.userId = id;

    if (!this.profile && this.authService.currentUserProfile)
      this.profile = this.authService.currentUserProfile;

    if (this.profile) {
      this.loadData();
    }
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.profileService
      .getOpenInvitations(this.userId)
      .subscribe(entries =>
        this.onDataLoadSuccessful(entries),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: Invitation[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    entries.forEach(e => {
      e.invited = Utilities.utcDateToLocal(new Date(e.invited));
    });
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private claimInvitation(code: string) {
    this.inviteService.claimInvitation(code).subscribe(result => {
      this.invitationClaimed(result);
    });
  }

  invitationClaimed(task: Task) {
    var url = `/home`;
    if (task.taskId)
      url = `/task/${task.taskId}`;
    //TODO: Invitations - Claim - add other routes for task.

    this.router.navigateByUrl(url);
  }

}
