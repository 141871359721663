<h1 mat-dialog-title>
  {{ option.keyText }}
</h1>
<div mat-dialog-content fxLayout="row">
  <app-key-value-control (key)="option.keyText" (value)="option.valueText" fxFlex="100%"></app-key-value-control>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="save();" color="primary">{{'commands.Save' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
