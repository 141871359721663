import { Component, OnInit, ViewChild, AfterViewInit, Input } from '@angular/core';
import { MatSort, MatTableDataSource, MatSnackBar, MatDialog, MatPaginator } from '@angular/material';
import { InvitationContact } from 'src/app/models/invitation-sender.model';
import { AlertService } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Subject } from 'rxjs';
import { ContactEditorDialogComponent } from '../../dialogs/contact-editor-dialog/contact-editor-dialog.component';
import { ContactUploadDialogComponent } from 'src/app/components/dialogs/contact-upload-dialog/contact-upload-dialog.component';
import { TeamSelectionDialogComponent } from 'src/app/components/companies/dialogs/team-selection-dialog/team-selection-dialog.component';

import { Profile } from 'src/app/models/profile';

@Component({
  selector: 'app-contact-table-control',
  templateUrl: './contact-table-control.component.html',
  styleUrls: ['./contact-table-control.component.scss']
})
export class ContactTableControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  private _contacts: InvitationContact[] = [];

  get contacts(): InvitationContact[] {
    return this._contacts;
  }
  @Input() set contacts(contacts: InvitationContact[]) {
    this._contacts = contacts;
    this.loadData();
  }

  private onContactsChanged = new Subject<InvitationContact[]>();
  contactsChanged$ = this.onContactsChanged.asObservable();

  displayedColumns = [
    'email',
    'firstName',
    'lastName',
    'actions'];
  dataSource: MatTableDataSource<InvitationContact>;
  sourceContact: InvitationContact;
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog

  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    //Causes the filter to refresh thereby updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    //this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    //do other prepwork here...
    //this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = this.contacts;
  }

  private updateContacts(contact: InvitationContact) {
    var newcontact = false;

    if (!this.sourceContact) {
      newcontact = true;
    } else if ((this.sourceContact.email || '') == '') {
      newcontact = true;
    }

    if (!newcontact) {
      Object.assign(this.sourceContact, contact);
      //this.alertService.showMessage('Success', `Changes to the information for \"${contact.email}\" was saved successfully`, MessageSeverity.success);
      this.sourceContact = null;
    } else {
      this.dataSource.data.push(contact);
      this.refresh();
      //this.alertService.showMessage('Success', `\"${contact.contactName}\" was created successfully`, MessageSeverity.success);
    }

    this.sendContactChanges();
  }

  private editContact(contact?: InvitationContact) {
    if (!contact) {
      contact = new InvitationContact("","","");
    }

    this.sourceContact = contact;
    
    const dialogRef = this.dialog.open(ContactEditorDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { contact: contact }
      });
    dialogRef.afterClosed().subscribe(contact => {
      if (contact) {
        this.updateContacts(contact);
      }
    });
  }

  private confirmDelete(contact: InvitationContact) {
    this.snackBar.open(`Delete ${contact.email}?`, 'DELETE', { duration: 2000 })
      .onAction().subscribe(() => {
        //this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;
        //do other prepwork here...
        //this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;
        this.dataSource.data = this.dataSource.data.filter(item => item !== contact);

        this.sendContactChanges();
      });
  }

  private sendContactChanges() {
    this.contacts = this.dataSource.data;
    this.onContactsChanged.next(this.contacts);
  }

  private launchImport() {
    const dialogRef = this.dialog.open(ContactUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(contacts => {
      if (contacts) 
        this.addContacts(contacts);
    });
  }

  private addContacts(contacts: InvitationContact[]) {
    contacts = contacts.filter(c => this.dataSource.data.filter(d => c.email == d.email).length <= 0); //no duplicates
       
    this.dataSource.data = this.dataSource.data.concat(contacts);

    var addtext = this.translationService.getTranslation("invitations.contactTableControl.NoContacts");
    if (contacts.length > 1)
      addtext = contacts.length + " " + this.translationService.getTranslation("invitations.contactTableControl.ContactsAdded");
    else if (contacts.length == 1)
      addtext = this.translationService.getTranslation("invitations.contactTableControl.ContactAdded");

    this.alertService.showMessage(addtext);

    this.sendContactChanges();
  }

  private launchTeamSelect() {
    const dialogRef = this.dialog.open(TeamSelectionDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { title: '', companyId: -1 } 
      });
    dialogRef.afterClosed().subscribe((profiles: Profile[]) => {
      if (profiles) {
        if (profiles.length <= 0)
          this.addContacts([]);
        else {
          var contacts = profiles.map(p => new InvitationContact(p.email, p.firstName, p.lastName));
          this.addContacts(contacts);
        }
      }
    });
  }

}
