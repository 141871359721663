import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { FormGroup, NgForm, Validators, FormBuilder } from '@angular/forms';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AccountService } from 'src/app/services/account.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [fadeInOut]
})
export class ForgotPasswordComponent implements OnInit {
  isResetting = false;

  forgotForm: FormGroup;

  @ViewChild('form')
  private form: NgForm;

  @Output()
  enterKeyPress = new EventEmitter();

  get email() {
    return this.forgotForm.get('email');
  }

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private translationService: AppTranslationService,
    public router: Router,
    private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
    this.forgotForm.setValue({ email: '' });
  }

  buildForm(): any {
    this.forgotForm = this.formBuilder.group({ email: ['', Validators.required] });
  }

  enterKeyDown() {
    this.enterKeyPress.emit();
  }

  resetForm() {
    this.forgotForm.reset({ email: '' });
  }

  cancel() {
    this.resetForm();
    this.isResetting = false;
    this.alertService.resetStickyMessage();
  }

  resetPassword() {
    if (!this.form.submitted) {
      this.form.onSubmit(null);
      return;
    }

    if (!this.forgotForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isResetting = true;
    this.alertService.startLoadingMessage('', 'Resetting password...');

    this.accountService.forgotPassword(this.forgotForm.get('email').value).subscribe(
      email => this.resetCompleted(email),
      error => this.resetFailed(error)
    );
  }

  resetCompleted(email?: any) {
    this.isResetting = false;
    this.alertService.stopLoadingMessage();
    this.resetForm();

    var msgtitle = this.translationService.getTranslation('forgotPassword.MessageTitleResetSuccess');
    var msgtext = this.translationService.getTranslation('forgotPassword.MessageTextResetSuccess');

    this.alertService.showStickyMessage(msgtitle, msgtext, MessageSeverity.success);

    this.router.navigateByUrl('/login');
  }

  resetFailed(error?: any) {
    this.isResetting = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Reset Error', 'One or more errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
