import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { InvitationContact } from  '../../../../models/invitation-sender.model';
import { ContactEditorControlComponent } from '../../controls/contact-editor-control/contact-editor-control.component';


@Component({
  selector: 'app-contact-editor-dialog',
  templateUrl: './contact-editor-dialog.component.html',
  styleUrls: ['./contact-editor-dialog.component.scss']
})
export class ContactEditorDialogComponent {
  @ViewChild('coned') editContact: ContactEditorControlComponent;

  get contact(): InvitationContact {
    return this.data.contact;
  }

  constructor(
    public dialogRef: MatDialogRef<ContactEditorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: InvitationContact }) {
  }

  ngAfterViewInit() {
    this.editContact.contactSaved$.subscribe(contact => this.dialogRef.close(contact));
  }

  save(): void {
    this.editContact.save();
  }

  cancel(): void {
    this.dialogRef.close(null);
  }
}
