<div fxLayout="row" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="1em">
  <div fxLayout="column" class="photo simple-border" fxHide.lt-md (click)="launchSquareLogoImport()">
    <img src="{{ logoSquareBase64 || logoFullBase64 || 'logo-black.png'}}" />
  </div>

  <form #form="ngForm" [formGroup]="editorForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off" fxFlex="100%">

    <div fxLayout="column" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'companies.management.CompanyName' | translate}}</mat-label>
        <input matInput formControlName="companyName" [readonly]="!isEditMode" />
      </mat-form-field>

      <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="1em">
        <div fxLayout="column" fxLayoutGap="1em" fxFlex="60%" fxFlex.lt-md>
          <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
            <mat-label>{{'companies.management.Address' | translate}}</mat-label>
            <input matInput formControlName="address" [readonly]="!isEditMode" />
          </mat-form-field>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
            <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
              <mat-label>{{'companies.management.City' | translate}}</mat-label>
              <input matInput formControlName="city" [readonly]="!isEditMode" />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex="25%">
              <mat-label>{{'companies.management.State' | translate}}</mat-label>
              <input matInput formControlName="state" [readonly]="!isEditMode" />
            </mat-form-field>
            <mat-form-field [floatLabel]="floatLabels" fxFlex="25%">
              <mat-label>{{'companies.management.ZipCode' | translate}}</mat-label>
              <input matInput formControlName="zipCode" [readonly]="!isEditMode" />
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="1em" fxFlex="40%" fxFlex.lt-md>
          <mat-form-field [floatLabel]="floatLabels">
            <mat-label>{{'companies.management.WebSite' | translate}}</mat-label>
            <input matInput formControlName="website" [readonly]="!isEditMode" />
          </mat-form-field>
          <mat-form-field [floatLabel]="floatLabels">
            <mat-label>{{'companies.management.PhoneNumber' | translate}}</mat-label>
            <input matInput formControlName="companyPhoneNumber" [readonly]="!isEditMode" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="1em">
      <div fxLayout="column" fxLayoutGap="1em" fxFlex="60%" fxFlex.lt-md>
        <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
          <mat-label>{{'companies.management.ContactName' | translate}}</mat-label>
          <input matInput formControlName="contactName" [readonly]="!isEditMode" />
        </mat-form-field>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
          <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
            <mat-label>{{'companies.management.ContactPhone' | translate}}</mat-label>
            <input matInput formControlName="contactPhoneNumber" [readonly]="!isEditMode" />
          </mat-form-field>
          <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
            <mat-label>{{'companies.management.ContactEmail' | translate}}</mat-label>
            <input matInput formControlName="contactEmailAddress" [readonly]="!isEditMode" />
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="column" class="logo simple-border" fxFlex="40%" fxFlex.lt-md (click)="launchFullLogoImport()">
        <img src="{{ logoFullBase64 || '../../../assets/images/LoginLogo.png'}}" />
      </div>

    </div>
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'companies.management.NotifyEmails' | translate}}</mat-label>
        <input matInput formControlName="notifyEmailAddresses" [readonly]="!isEditMode" />
      </mat-form-field>
    </div>

    <!-- https://github.com/angular/material2/issues/8798 -->
    <div>&nbsp;</div>
  </form>
</div>
