import { Component, ViewChild, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { InvitationContact, InvitationSender } from '../../../../models/invitation-sender.model';
import { ContactEditorControlComponent } from '../../controls/contact-editor-control/contact-editor-control.component';
import { CampaignBuilder } from 'src/app/models/campaign-builder.model';
import { AuthService } from 'src/app/services/auth.service';
import { MaterialsService } from 'src/app/services/materials.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Material } from 'src/app/models/material.model';
import { CampaignService } from 'src/app/services/campaign.service';
import { Utilities } from 'src/app/services/utilities';
import { TranslateService } from '@ngx-translate/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { InvitationsService } from 'src/app/services/invitations.service';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-p60-invite',
  templateUrl: './p60-invite.component.html',
  styleUrls: ['./p60-invite.component.scss']
})
export class P60InviteComponent {
  @ViewChild(ContactEditorControlComponent)
  editContact: ContactEditorControlComponent;

  p60MaterialId: number;
  contact: InvitationContact;
  mats: Material[];

  floatLabels:string = "auto";

  get subject(): string {
    var p60Subject = this.translationService.getTranslation('invitations.p60Invite.EmailSubject');
    var mat = this.mats.find(x => x.materialId == this.p60MaterialId);

    return `${mat.materialName} - ${p60Subject}: ${this.contact.firstName} ${this.contact.lastName}`;
  }

  constructor(
    private inviteService: InvitationsService,
    private translationService: AppTranslationService,
    private campaignService: CampaignService,
    private materialService: MaterialsService,
    private alertService: AlertService,
    private authService: AuthService,
    public dialogRef: MatDialogRef<P60InviteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { contact: InvitationContact }) {
  }

  ngAfterViewInit() {
    this.editContact.contactSaved$.subscribe(contact => this.contactReturned(contact));

    this.materialService.getTests(this.authService.companyId, "").subscribe(mats => this.materialsReturned(mats));

    this.authService.companySelected$.subscribe(c => this.companySelected(c));
  }

  private companySelected(c: Company) {
    this.materialService.getTests(this.authService.companyId, "").subscribe(mats => this.materialsReturned(mats));
  }

  contactReturned(c: InvitationContact) {
    this.contact = c;
  }

  cancel(): void {
    this.dialogRef.close(null);
  }

  materialsReturned(mats: Material[]) {
    this.mats = mats;
    this.p60MaterialId = mats.find(m => m.languageCode === "en" && m.materialName === "P60 Assessment").materialId;
  }

  send() {

    this.alertService.startLoadingMessage('Sending...');

    this.editContact.save();

    var build = CampaignBuilder.Create(
      this.subject,
      'invitation',
      '',
      this.authService.companyId,
      null,
      null,
      [],
      [this.p60MaterialId]
    );

    this.campaignService.buildCampaign(build).subscribe(
      (newbuild: any) => this.sendInvitation(newbuild.campaignId),
      error => this.invitesFailed(error)
    )
  }

  sendInvitation(campaignId?: number) {
    var message = '';
    if (message != '')
      message = Utilities.convertStringToHTML(message);

    var link = Utilities.baseUrl() + '/invitation/claim/';

    var invite = InvitationSender.Create(
      this.authService.currentUserProfile.userId,
      this.authService.companyId,
      this.subject,
      message,
      null,
      this.p60MaterialId,
      this.translationService.getCurrentLanguage(),
      link,
      null,
      [this.contact]
    );

    if (campaignId)
      invite.campaignId = campaignId;

    this.alertService.startLoadingMessage('Sending...');

    this.inviteService.sendInvitations(invite).subscribe(
      () => this.invitesSent(invite),
      error => this.invitesFailed(error)
    );
  }

  invitesSent(invite: InvitationSender) {
    if (invite) {
      //add downstream event calls here, if needed.
      this.contact = invite.contacts[0];
    }

    //this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.alertService.showMessage('Success', `Invitation sent!`, MessageSeverity.success);

    this.dialogRef.close();

    //this.router.navigateByUrl('/invitations');

    //this.resetForm(true);
    //this.onCompanySaved.next(this.company);
  }

  invitesFailed(error: string) {
    //this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Sending Error', 'One or more errors occured whilst sending the invite:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
