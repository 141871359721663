// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { SettingsComponent } from './settings/settings.component';
import { AboutComponent } from './components/about/about.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';

import { CompaniesComponent } from './components/companies/companies.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SendInvitationComponent } from './components/invitation/send-invitation/send-invitation.component';
import { InvitationsComponent } from './components/invitation/invitations/invitations.component';
import { ClaimInvitationComponent } from './components/invitation/claim-invitation/claim-invitation.component';
import { StartMaterialComponent } from './components/materials/start-material/start-material.component';
import { TaskViewComponent } from './components/tasks/task-view/task-view.component';
import { CampaignAnalysisComponent } from './components/campaign/campaign-analysis/campaign-analysis.component';
import { P60Report } from './components/reports/p60/p60-report/p60-report.component';
import { CompanyReportListComponent } from './components/companies/company-report-list/company-report-list.component';
import { BenchmarkListComponent } from './components/companies/benchmark-list/benchmark-list.component';
import { P60OptionListComponent } from './components/companies/p60-option-list/p60-option-list.component';
import { P60OptionEditorComponent } from './components/companies/p60-option-editor/p60-option-editor.component';
import { CompanyEditorComponent } from './components/companies/company-editor/company-editor.component';
import { CompanyIndividualsComponent } from './components/companies/company-individuals/company-individuals.component';
import { ProfileViewComponent } from './components/profile/profile-view/profile-view.component';
import { CompanyTeamsComponent } from './components/companies/company-teams/company-teams.component';
import { CompanyViewComponent } from './components/companies/company-view/company-view.component';
import { AssessmentsComponent } from './components/materials/assessments/assessments.component';
import { SurveysComponent } from './components/materials/surveys/surveys.component';
import { TestEditorComponent } from './components/materials/test-editor/test-editor.component';
import { MaterialsComponent } from './components/materials/materials/materials.component';
import { CourseEditorComponent } from './components/courses/course-editor/course-editor.component';
import { CoursesComponent } from './components/courses/courses/courses.component';
import { LegacyClientsComponent } from './components/legacy/legacy-clients/legacy-clients.component';
import { AptitudesComponent } from './components/materials/aptitudes/aptitudes.component';


const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard], data: { title: 'Home' } },
  { path: 'campaign/analysis/:campaignId', component: CampaignAnalysisComponent, canActivate: [AuthGuard], data: { title: 'Campaign Analysis' } },
  { path: 'task/:taskId', component: TaskViewComponent, canActivate: [AuthGuard], data: { title: 'Task' } },
  { path: 'material/start/:materialId', component: StartMaterialComponent, canActivate: [AuthGuard], data: { title: 'Start Material' } },
  { path: 'invitation/claim/:code', component: ClaimInvitationComponent, data: { title: 'Claim Invitation', navigateOnCompanyChange: true } },
  { path: 'invitation/send', component: SendInvitationComponent, canActivate: [AuthGuard], data: { title: 'Send Invitation' } },
  { path: 'invitation/send/team/:teamId', component: SendInvitationComponent, canActivate: [AuthGuard], data: { title: 'Send Invitation' } },
  { path: 'invitations', component: InvitationsComponent, canActivate: [AuthGuard], data: { title: 'Invitations' } },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard], data: { title: 'Companies' } },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { title: 'Settings' } },
  { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
  { path: 'registration', component: RegistrationComponent, data: { title: 'Registration' } },
  { path: 'about', component: AboutComponent, data: { title: 'About Us' } },
  { path: 'home', redirectTo: '/', pathMatch: 'full' },
  { path: 'report/p60/:userTestId', component: P60Report, data: { title: 'P60' } },
  { path: 'company/reports', component: CompanyReportListComponent, canActivate: [AuthGuard], data: { title: 'Reports' } },
  { path: 'company/reports/:companyId', component: CompanyReportListComponent, canActivate: [AuthGuard], data: { title: 'Reports' } },
  { path: 'company/benchmarklist', component: BenchmarkListComponent, canActivate: [AuthGuard], data: { title: 'Benchmarks' } },
  { path: 'company/benchmarklist/:companyId', component: BenchmarkListComponent, canActivate: [AuthGuard], data: { title: 'Benchmarks' } },
  { path: 'company/p60optionlist', component: P60OptionListComponent, canActivate: [AuthGuard], data: { title: 'P60 Options' } },
  { path: 'company/p60optionlist/:companyId', component: P60OptionListComponent, canActivate: [AuthGuard], data: { title: 'P60 Options' } },
  { path: 'company/p60option/:optionId', component: P60OptionEditorComponent, canActivate: [AuthGuard], data: { title: 'P60 Option Editor' } },
  { path: 'company/individuals', component: CompanyIndividualsComponent, canActivate: [AuthGuard], data: { title: 'Individuals' } },
  { path: 'company/individuals/:companyId', component: CompanyIndividualsComponent, canActivate: [AuthGuard], data: { title: 'Individuals' } },
  { path: 'company/teams', component: CompanyTeamsComponent, canActivate: [AuthGuard], data: { title: 'Teams' } },
  { path: 'company/teams/:companyId', component: CompanyTeamsComponent, canActivate: [AuthGuard], data: { title: 'Teams' } },
  { path: 'company', component: CompanyViewComponent, canActivate: [AuthGuard], data: { title: 'Company' } },
  { path: 'company/:companyId', component: CompanyViewComponent, canActivate: [AuthGuard], data: { title: 'Company' } },
  { path: 'profile', component: ProfileViewComponent, canActivate: [AuthGuard], data: { title: 'Profile' } },
  { path: 'profile/:userId', component: ProfileViewComponent, canActivate: [AuthGuard], data: { title: 'Profile' } },
  { path: 'materials/aptitudes', component: AptitudesComponent, canActivate: [AuthGuard], data: { title: 'Aptitudes' } },
  { path: 'materials/aptitudes/:companyId', component: AptitudesComponent, canActivate: [AuthGuard], data: { title: 'Aptitudes' } },
  { path: 'materials/assessments', component: AssessmentsComponent, canActivate: [AuthGuard], data: { title: 'Assessments' } },
  { path: 'materials/assessments/:companyId', component: AssessmentsComponent, canActivate: [AuthGuard], data: { title: 'Assessments' } },
  { path: 'materials/surveys', component: SurveysComponent, canActivate: [AuthGuard], data: { title: 'Surveys' } },
  { path: 'materials/surveys/:companyId', component: SurveysComponent, canActivate: [AuthGuard], data: { title: 'Surveys' } },
  { path: 'materials/materials', component: MaterialsComponent, canActivate: [AuthGuard], data: { title: 'Materials' } },
  { path: 'materials/materials/:companyId', component: MaterialsComponent, canActivate: [AuthGuard], data: { title: 'Materials' } },
  { path: 'materials/test/:materialId', component: TestEditorComponent, canActivate: [AuthGuard], data: { title: 'Test' } },
  { path: 'courses', component: CoursesComponent, canActivate: [AuthGuard], data: { title: 'Courses' } },
  { path: 'courses/:companyId', component: CoursesComponent, canActivate: [AuthGuard], data: { title: 'Courses' } },
  { path: 'course/:courseId', component: CourseEditorComponent, canActivate: [AuthGuard], data: { title: 'Course' } },
  { path: 'legacy/clients', component: LegacyClientsComponent, canActivate: [AuthGuard], data: { title: 'LegacyClients' } },
  { path: '**', component: NotFoundComponent, data: { title: 'Page Not Found' } }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard]
})
export class AppRoutingModule { }
