<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <div>
    {{'contactUpload.Instructions' | translate}}
  </div>
  <div>
    <i>{{'contactUpload.Instructions2' | translate}}</i>
  </div>
  <app-user-upload-control #userUpload
                           fxFlex
                           [showButton]="false"
                           (onContactsReady)="contactsReady($event)">

  </app-user-upload-control>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="import();" color="primary">{{'commands.Import' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
