import { IAuditableEntity } from "./IAuditableEntry.interface";

export class XrGender implements IAuditableEntity {
  //constructor() { }
  constructor(genderId?: number, code?: string, title?: string) {
    this.genderId = genderId;
    this.code = code;
    this.title = title;
    this.createdBy = "";
    this.createdDate = new Date();
    this.updatedBy = "";
    this.updatedDate = new Date();
  }

  public genderId: number;
  public code: string;
  public title: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
}
