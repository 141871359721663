import { Company } from "./company";
import { Profile } from "./profile";

export class PersonalityScoring {
  private _constr: string;

  private _applicantid: number;
  private _clientid: number;
  private _testid: number;
  private _benchmarkid: number;

  private _company: Company;
  private _user: any;
  private _test: any;

  public constructor() {
    this.benchmarkName = "";
    this.currentStateOfMind_BenchmarkFrom = 0;
    this.currentStateOfMind_BenchmarkTo = 0;
    this.stressManagement_BenchmarkFrom = 0;
    this.stressManagement_BenchmarkTo = 0;
    this.sensitivityAwareness_BenchmarkTo = 0;
    this.sensitivityAwareness_BenchmarkFrom = 0;
    this.compassion_BenchmarkFrom = 0;
    this.compassion_BenchmarkTo = 0;
    this.patience_BenchmarkFrom = 0;
    this.patience_BenchmarkTo = 0;
    this.creativeAbilities_BenchmarkFrom = 0;
    this.creativeAbilities_BenchmarkTo = 0;
    this.artisticAbilities_BenchmarkFrom = 0;
    this.artisticAbilities_BenchmarkTo = 0;
    this.peopleOutgoingTraits_BenchmarkFrom = 0;
    this.peopleOutgoingTraits_BenchmarkTo = 0;
    this.interactionLevel_BenchmarkFrom = 0;
    this.interactionLevel_BenchmarkTo = 0;
    this.analyticalTraits_BenchmarkFrom = 0;
    this.analyticalTraits_BenchmarkTo = 0;
    this.detailTraits_BenchmarkFrom = 0;
    this.detailTraits_BenchmarkTo = 0;
    this.decisiveness_BenchmarkFrom = 0;
    this.decisiveness_BenchmarkTo = 0;
    this.independentTraits_BenchmarkFrom = 0;
    this.independentTraits_BenchmarkTo = 0;
    this.persuasiveness_BenchmarkFrom = 0;
    this.persuasiveness_BenchmarkTo = 0;
    this.aggressiveTraits_BenchmarkFrom = 0;
    this.aggressiveTraits_BenchmarkTo = 0;
    this.stubbornness_BenchmarkFrom = 0;
    this.stubbornness_BenchmarkTo = 0;
    this.goalSettingLevel_BenchmarkFrom = 0;
    this.goalSettingLevel_BenchmarkTo = 0;
    this.timeManagement_BenchmarkFrom = 0;
    this.timeManagement_BenchmarkTo = 0;
  }

  public get ReportName(): string {
    return "P60 Assessment";
  }

  public ApplicantName: string;
  public get ApplicantID(): number {
    return this._applicantid;
  }
  public JobTitle: string;
  public EmailAddress: string;

  private BAB: string;
  private heshe: string = "their";
  private UBOB: string = "Their";
  private UBOB1: string = "Their";
  private UBOB2: string = "Their";
  private UBOB3: string = "Theirself";
  private LBOB: string = "they";
  private LBOB1: string = "their";
  private LBOB2: string = "their";
  private LBOB3: string = "theirself";

  public benchmarkName: string;
  public currentStateOfMind_BenchmarkFrom: number;
  public currentStateOfMind_BenchmarkTo: number;
  public stressManagement_BenchmarkFrom: number;
  public stressManagement_BenchmarkTo: number;
  public sensitivityAwareness_BenchmarkTo: number;
  public sensitivityAwareness_BenchmarkFrom: number;
  public compassion_BenchmarkFrom: number;
  public compassion_BenchmarkTo: number;
  public patience_BenchmarkFrom: number;
  public patience_BenchmarkTo: number;
  public creativeAbilities_BenchmarkFrom: number;
  public creativeAbilities_BenchmarkTo: number;
  public artisticAbilities_BenchmarkFrom: number;
  public artisticAbilities_BenchmarkTo: number;
  public peopleOutgoingTraits_BenchmarkFrom: number;
  public peopleOutgoingTraits_BenchmarkTo: number;
  public interactionLevel_BenchmarkFrom: number;
  public interactionLevel_BenchmarkTo: number;
  public analyticalTraits_BenchmarkFrom: number;
  public analyticalTraits_BenchmarkTo: number;
  public detailTraits_BenchmarkFrom: number;
  public detailTraits_BenchmarkTo: number;
  public decisiveness_BenchmarkFrom: number;
  public decisiveness_BenchmarkTo: number;
  public independentTraits_BenchmarkFrom: number;
  public independentTraits_BenchmarkTo: number;
  public persuasiveness_BenchmarkFrom: number;
  public persuasiveness_BenchmarkTo: number;
  public aggressiveTraits_BenchmarkFrom: number;
  public aggressiveTraits_BenchmarkTo: number;
  public stubbornness_BenchmarkFrom: number;
  public stubbornness_BenchmarkTo: number;
  public goalSettingLevel_BenchmarkFrom: number;
  public goalSettingLevel_BenchmarkTo: number;
  public timeManagement_BenchmarkFrom: number;
  public timeManagement_BenchmarkTo: number;
  public testDate: Date;
  private PA: number[];
  private XTYPE: number[];
  private N: number[];
  private LED2: number;
  private DTYP: number;
  private DID16: number;
  private PATN: number;
  private PATNT: number;
  private RANS: number;
  private DID7: number;
  private DID23: number;
  private DID38: number;
  private DID2: number;
  private DID14: number;
  private DID40: number;
  private DID55: number;
  private LED1: number;
  private LED3: number;
  private TPTCount: number;
  private A: number;
  private B: number;
  private C: number;
  private D: number;
  private ANSV: number;
  private PTCount: number;
  private WASTE: number;
  private LA: number;
  private DIDLA: number;
  private ADIF: number;
  private XA: number;
  private XB: number;
  private XC: number;
  private DID: number;
  private XNUM: number;
  private HIGH: number;
  private DIF: number;
  private JSCORE: number;
  private PRT1: number;
  private PRT2: number;
  private XG: number;
  private XR: number;
  private FE1: number;
  private FE2: number;
  private FE3: number;
  private FE: number;
  private EMT: number;
  private LOW: number;
  private TCAL: number;
  private DXT: number;
  private XT: number;
  private MOST: number;
  private LEAST: number;
  private HON: number;
  public get consistencyLevel(): number {
    return this.HON;
  }
  private EGO: number;
  public get currentStateOfMind(): number {
    return this.EGO;
  }
  public mostLike: string;
  public secondLike: string;
  public leastLike: string;
  private ACNT: number;
  public get A_Utilization(): number {
    return this.ACNT;
  }
  private BCNT: number;
  public get B_Utilization(): number {
    return this.BCNT;
  }
  private CCNT: number;
  public get C_Utilization(): number {
    return this.CCNT;
  }
  private DCNT: number;
  public get D_Utilization(): number {
    return this.DCNT;
  }
  private EM: number;
  public get stressManagement(): number {
    return this.EM;
  }
  private SN: number;
  public get sensitivityAwareness(): number {
    return this.SN;
  }
  private CO: number;
  public get compassion(): number {
    return this.CO;
  }
  public patience: number;
  private CR: number;
  public get creativeAbilities(): number {
    return this.CR;
  }
  private AR: number;
  public get artisticAbilities(): number {
    return this.AR;
  }
  private POR: number;
  public get peopleOutgoingTraits(): number {
    return this.POR;
  }
  private IR: number;
  public get interactionLevel(): number {
    return this.IR;
  }
  private ANAL: number;
  public get analyticalTraits(): number {
    return this.ANAL;
  }
  private DT: number;
  public get detailTraits(): number {
    return this.DT;
  }
  private DE: number;
  public get decisiveness(): number {
    return this.DE;
  }
  private ID: number;
  public get independentTraits(): number {
    return this.ID;
  }
  private PR: number;
  public get persuasiveness(): number {
    return this.PR;
  }
  private AG: number;
  public get aggressiveTraits(): number {
    return this.AG;
  }
  private ST: number;
  public get stubbornness(): number {
    return this.ST;
  }
  private SG: number;
  public get goalSettingLevel(): number {
    return this.SG;
  }
  private RE: number;
  public get timeManagement(): number {
    return this.RE;
  }


  public headstyle: string = "";
  public body: string = "";

}

