import { Component, OnInit, ViewChild, Input, Inject, AfterViewInit } from '@angular/core';
import { BenchmarkSliderControlComponent, Filter } from '../../controls/benchmark-slider-control/benchmark-slider-control.component';
import { P60benchmark } from 'src/app/models/p60benchmark';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { CompaniesService } from 'src/app/services/companies.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-benchmark-dialog',
  templateUrl: './benchmark-dialog.component.html',
  styleUrls: ['./benchmark-dialog.component.scss']
})
export class BenchmarkDialogComponent implements OnInit, AfterViewInit {

  @Input() benchmark: P60benchmark;
  
  @ViewChild('sliderStress')      sliderStress: BenchmarkSliderControlComponent;
  @ViewChild('sliderSensitivity') sliderSensitivity: BenchmarkSliderControlComponent;
  @ViewChild('sliderCompassion')  sliderCompassion: BenchmarkSliderControlComponent;
  @ViewChild('sliderPatience')    sliderPatience: BenchmarkSliderControlComponent;
  @ViewChild('sliderCreative')    sliderCreative: BenchmarkSliderControlComponent;
  @ViewChild('sliderArtistic')    sliderArtistic: BenchmarkSliderControlComponent;
  @ViewChild('sliderOutgoing')    sliderOutgoing: BenchmarkSliderControlComponent;
  @ViewChild('sliderInteraction') sliderInteraction: BenchmarkSliderControlComponent;
  @ViewChild('sliderAnalytical')  sliderAnalytical: BenchmarkSliderControlComponent;
  @ViewChild('sliderDetailed')    sliderDetailed: BenchmarkSliderControlComponent;
  @ViewChild('sliderDecision')    sliderDecision: BenchmarkSliderControlComponent;
  @ViewChild('sliderIndependent') sliderIndependent: BenchmarkSliderControlComponent;
  @ViewChild('sliderPersuasive')  sliderPersuasive: BenchmarkSliderControlComponent;
  @ViewChild('sliderAggressive')  sliderAggressive: BenchmarkSliderControlComponent;
  @ViewChild('sliderStubborn')    sliderStubborn: BenchmarkSliderControlComponent;
  @ViewChild('sliderGoal')        sliderGoal: BenchmarkSliderControlComponent;
  @ViewChild('sliderTime')        sliderTime: BenchmarkSliderControlComponent;
  @ViewChild('sliderConfidence')  sliderConfidence: BenchmarkSliderControlComponent;

  get name() {
    if (this.benchmark)
      return this.benchmark.benchmarkName;

    return "(new benchmark)";
  }

  set name(value: string) {
    if (this.benchmark)
      this.benchmark.benchmarkName = value;
  }

  get template() {
    if (this.benchmark)
      return this.benchmark.template;

    return false;
  }

  set template(value: boolean) {
    if (this.benchmark)
      this.benchmark.template = value;
  }

  floatLabels: string = "auto";

  constructor(
    public authService: AuthService,
    public companyService: CompaniesService,
    public dialogRef: MatDialogRef<BenchmarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { benchmark: P60benchmark }) {

    this.benchmark = this.data.benchmark;
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    try {
      setTimeout(() => {
        this.sliderStress.loadBenchmark("Stress Management", this.benchmark.stressFrom, this.benchmark.stressTo);
        this.sliderSensitivity.loadBenchmark("Sensitivity", this.benchmark.sensitivityFrom, this.benchmark.sensitivityTo);
        this.sliderCompassion.loadBenchmark("Compassion Level", this.benchmark.compassionFrom, this.benchmark.compassionTo);
        this.sliderPatience.loadBenchmark("Patience Level", this.benchmark.patienceFrom, this.benchmark.patienceTo);
        this.sliderCreative.loadBenchmark("Creative Ability", this.benchmark.creativeFrom, this.benchmark.creativeTo);
        this.sliderArtistic.loadBenchmark("Artistic Ability", this.benchmark.artisticFrom, this.benchmark.artisticTo);
        this.sliderOutgoing.loadBenchmark("Outgoing Level", this.benchmark.outgoingFrom, this.benchmark.outgoingTo);
        this.sliderInteraction.loadBenchmark("Interaction Level", this.benchmark.interactionFrom, this.benchmark.interactionTo);
        this.sliderAnalytical.loadBenchmark("Analytical Level", this.benchmark.analyticalFrom, this.benchmark.analyticalTo);
        this.sliderDetailed.loadBenchmark("Detailed Level", this.benchmark.detailedFrom, this.benchmark.detailedTo);
        this.sliderDecision.loadBenchmark("Decision Level", this.benchmark.decisionFrom, this.benchmark.decisionTo);
        this.sliderIndependent.loadBenchmark("Independent Level", this.benchmark.independentFrom, this.benchmark.independentTo);
        this.sliderPersuasive.loadBenchmark("Persuasive Level", this.benchmark.persuasiveFrom, this.benchmark.persuasiveTo);
        this.sliderAggressive.loadBenchmark("Aggressive Level", this.benchmark.aggressiveFrom, this.benchmark.aggressiveTo);
        this.sliderStubborn.loadBenchmark("Stubborn Level", this.benchmark.stubbornFrom, this.benchmark.stubbornTo);
        this.sliderGoal.loadBenchmark("Goal Setting Level", this.benchmark.goalFrom, this.benchmark.goalTo);
        this.sliderTime.loadBenchmark("Time Management", this.benchmark.timeFrom, this.benchmark.timeTo);
        this.sliderConfidence.loadBenchmark("Confidence Level", this.benchmark.confidenceFrom, this.benchmark.confidenceTo);


        this.sliderStress.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderSensitivity.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderCompassion.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderPatience.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderCreative.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderArtistic.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderOutgoing.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderInteraction.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderAnalytical.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderDetailed.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderDecision.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderIndependent.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderPersuasive.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderAggressive.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderStubborn.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderGoal.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderTime.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
        this.sliderConfidence.benchmarkChanged.subscribe(b => this.changeBenchmark(b));
      }, 500);

    } catch (e) {
      if (e instanceof RangeError) {
        var x = 1;
      }
    }

  }

  prepSliders() {

  }

  changeBenchmark(f: Filter) {
    if (f.active == false) {
      f.from = 0;
      f.to = 0;
    }

    if (f.name == "Stress Management") {
      this.benchmark.stressFrom = f.from;
      this.benchmark.stressTo = f.to;
    }
    else if (f.name == "Sensitivity") {
      this.benchmark.sensitivityFrom = f.from;
      this.benchmark.sensitivityTo = f.to;
    }
    else if (f.name == "Compassion Level") {
      this.benchmark.compassionFrom = f.from;
      this.benchmark.compassionTo = f.to;
    }
    else if (f.name == "Patience Level") {
      this.benchmark.patienceFrom = f.from;
      this.benchmark.patienceTo = f.to;
    }
    else if (f.name == "Creative Ability") {
      this.benchmark.creativeFrom = f.from;
      this.benchmark.creativeTo = f.to;
    }
    else if (f.name == "Artistic Ability") {
      this.benchmark.artisticFrom = f.from;
      this.benchmark.artisticTo = f.to;
    }
    else if (f.name == "Outgoing Level") {
      this.benchmark.outgoingFrom = f.from;
      this.benchmark.outgoingTo = f.to;
    }
    else if (f.name == "Interaction Level") {
      this.benchmark.interactionFrom = f.from;
      this.benchmark.interactionTo = f.to;
    }
    else if (f.name == "Analytical Level") {
      this.benchmark.analyticalFrom = f.from;
      this.benchmark.analyticalTo = f.to;
    }
    else if (f.name == "Detailed Level") {
      this.benchmark.detailedFrom = f.from;
      this.benchmark.detailedTo = f.to;
    }
    else if (f.name == "Decision Level") {
      this.benchmark.decisionFrom = f.from;
      this.benchmark.decisionTo = f.to;
    }
    else if (f.name == "Independent Level") {
      this.benchmark.independentFrom = f.from;
      this.benchmark.independentTo = f.to;
    }
    else if (f.name == "Persuasive Level") {
      this.benchmark.persuasiveFrom = f.from;
      this.benchmark.persuasiveTo = f.to;
    }
    else if (f.name == "Aggressive Level") {
      this.benchmark.aggressiveFrom = f.from;
      this.benchmark.aggressiveTo = f.to;
    }
    else if (f.name == "Stubborn Level") {
      this.benchmark.stubbornFrom = f.from;
      this.benchmark.stubbornTo = f.to;
    }
    else if (f.name == "Goal Setting Level") {
      this.benchmark.goalFrom = f.from;
      this.benchmark.goalTo = f.to;
    }
    else if (f.name == "Time Management") {
      this.benchmark.timeFrom = f.from;
      this.benchmark.timeTo = f.to;
    }
    else if (f.name == "Confidence Level") {
      this.benchmark.confidenceFrom = f.from;
      this.benchmark.confidenceTo = f.to;
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  save() {
    this.sliderStress.emitBenchmark();
    this.sliderSensitivity.emitBenchmark();
    this.sliderCompassion.emitBenchmark();
    this.sliderPatience.emitBenchmark();
    this.sliderCreative.emitBenchmark();
    this.sliderArtistic.emitBenchmark();
    this.sliderOutgoing.emitBenchmark();
    this.sliderInteraction.emitBenchmark();
    this.sliderAnalytical.emitBenchmark();
    this.sliderDetailed.emitBenchmark();
    this.sliderDecision.emitBenchmark();
    this.sliderIndependent.emitBenchmark();
    this.sliderPersuasive.emitBenchmark();
    this.sliderAggressive.emitBenchmark();
    this.sliderStubborn.emitBenchmark();
    this.sliderGoal.emitBenchmark();
    this.sliderTime.emitBenchmark();
    this.sliderConfidence.emitBenchmark();

    this.benchmark.companyId = this.authService.companyId;
    this.companyService.patchRecordBenchmark(this.benchmark).subscribe(data => this.parseSaveResult(data), error => this.checkError(error));
  }

  parseSaveResult(data: P60benchmark) {
    this.dialogRef.close(data);
  }

  checkError(error: string) {
    if (error != "INVALID")
      return;

  }
}
