<app-page-header title="About" icon="info"></app-page-header>

<div class="page-content">
  <div [@fadeInOut]>
    <h4>Welcome to Assessment 360</h4>
    <p>The Assessment 360 platform provides a multi-fauceted toolbox for discovering your potential and improving those skills needed to succeed in business.</p>

    <hr />

    <mat-accordion>
      <mat-expansion-panel class="simple-border">
        <mat-expansion-panel-header color="primary">
          <mat-panel-title>
            Change Log
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card *ngFor="let log of logs">
          <mat-card-header>{{log.title}}</mat-card-header>
          <mat-card-content>
            <ul>
              <li *ngFor="let item of log.items">{{item}}</li>
            </ul>
          </mat-card-content>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
