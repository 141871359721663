import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { P60optionValue } from 'src/app/models/p60option-value.model';
import { AlertService } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { P60OptionValueEditorDialogComponent } from '../../dialogs/p60-option-value-editor-dialog/p60-option-value-editor-dialog.component';

@Component({
  selector: 'app-p60-option-value-set-control',
  templateUrl: './p60-option-value-set-control.component.html',
  styleUrls: ['./p60-option-value-set-control.component.scss']
})
export class P60OptionValueSetControlComponent implements OnInit, AfterViewInit {
  @Input() values: P60optionValue[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  dataSource: MatTableDataSource<P60optionValue>;
  sourceObject: P60optionValue;
  loadingIndicator: boolean;

  displayedColumns = [
    'keyText',
    'valueText',
    'actions'];

  public get optionValues() {
    return this.dataSource.data;
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private dialog: MatDialog) {

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);

  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.dataSource.data = this.values;
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  public assignValues(values: P60optionValue[]) {
    this.values = values;
    this.loadData();
  }



  private edit(object: P60optionValue) {

    const dialogref = this.dialog.open(P60OptionValueEditorDialogComponent, {
      panelClass: 'mat-dialog-md',
      data: { option: object }
    });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.values.filter(v => v.keyText == result.keyText)[0] = result;

        this.loadData();
      }
    });
  }

}
