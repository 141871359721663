<h1 *ngIf="canManageRoles" mat-dialog-title>{{roleName ? ('roles.management.EditRole' | translate:roleName) : ('roles.management.NewRole' | translate)}}</h1>
<h1 *ngIf="!canManageRoles" mat-dialog-title>{{'roles.management.RoleDetails' | translate:roleName}}</h1>
<div mat-dialog-content>
    <role-editor #roleEditor [role]="data.role" [allPermissions]="data.allPermissions"></role-editor>
</div>
<div mat-dialog-actions>
    <button *ngIf="canManageRoles" mat-raised-button (click)="roleEditor.save();" color="primary">Save</button>
    <button *ngIf="canManageRoles" mat-raised-button (click)="cancel()" color="warn">Cancel</button>
    <button *ngIf="!canManageRoles" mat-raised-button (click)="cancel()">Close</button>
</div>
