<div class="search-box" fxLayout="row" fxLayout.lt-sm="column" fxLayout.sm="column">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'individualsControl.Search' | translate}}">
  </mat-form-field>
  <div fxFlex="auto" fxHide.sm fxHide.lt-sm></div>
  <div fxLayoutGap="8px" fxLayout="column">
    <div fxFlex="auto" fxHide.lt-sm></div>
    <div fxLayoutGap="8px" fxLayout="row" fxLayout.sm="column" fxLayout.lt-sm="column">
      <button mat-raised-button matTooltip="{{'individualsControl.NewIndividual' | translate}}" (click)="newIndividual()">
        <mat-icon>person_add</mat-icon>&nbsp;&nbsp;{{'individualsControl.NewIndividual' | translate}}
      </button>
      <button mat-raised-button matTooltip="{{'individualsControl.Import' | translate}}" (click)="launchImport()">
        <mat-icon>cloud_upload</mat-icon>&nbsp;&nbsp;{{'individualsControl.Import' | translate}}
      </button>
    </div>
    <div fxFlex="auto"></div>
  </div>
</div>

<div class="simple-border">
  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="lastName">
      <mat-header-cell fxFlex="40%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matHeaderCellDef mat-sort-header>
        <div fxHide.sm fxHide.lt-sm>{{'individualsControl.LastName' | translate}}</div>
        <div fxHide.gt-sm>{{'individualsControl.Name' | translate}}</div>
      </mat-header-cell>
      <mat-cell fxFlex="40%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matCellDef="let row">
        <span fxHide.sm fxHide.lt-sm>{{row.lastName}}</span>
        <span fxHide.gt-sm>
          {{row.lastName}}, {{row.firstName}}
          <br />{{row.email}}
        </span>        
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <mat-header-cell fxFlex="35%" *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'individualsControl.FirstName' | translate}} </mat-header-cell>
      <mat-cell fxFlex="35%" *matCellDef="let row" fxHide.sm fxHide.lt-sm> {{row.firstName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'individualsControl.Email' | translate}} </mat-header-cell>
      <mat-cell fxFlex="25%" *matCellDef="let row" fxHide.sm fxHide.lt-sm> {{row.email}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">

      </mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
        <button mat-icon-button matTooltip="{{'individualsControl.DisplayProfile' | translate}}" (click)="launch(entry)">
          <mat-icon>visibility</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>

