import { Test } from "./test.model";
import { Question } from "./question.model";
import { MaterialContent } from "./material-content.model";
import { Material } from "./material.model";
import { Answer } from "./answer.model";
import { UserTestQuestionAnswer } from "./user-test-question-answer.model";
import { IAuditableEntity } from "./IAuditableEntry.interface";

export class TaskItem implements IAuditableEntity {
  public taskItemId: number;
  public taskId: number;
  public userId: number;
  public taskItemName: string;
  public designation: string;
  public ordinal: number;
  public materialId: number;
  public materialOrderId: number;
  public materialContentId: number;
  public testId: number;
  public questionId: number;
  public answerId: number;
  public userMaterialLog: number;
  public userTestId: number;
  public userTestQuestion: number;
  public userTestQuestionAnswerId: number;
  public began: Date;
  public lastAccess: Date;
  public completed: Date;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;

  public answer: Answer;
  public material: Material;
  public materialContent: MaterialContent;
  public question: Question;
  public test: Test;
  public userTestQuestionAnswer: UserTestQuestionAnswer;

  public hasError: boolean;
  public state: string;
  public isCompleted: boolean;
}
