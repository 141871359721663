<div class="search-box" fxLayout="row" fxLayout.lt-sm="column">
  <mat-form-field fxFlex="50%">
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'openInvitations.Search' | translate}}">
  </mat-form-field>
</div>

<div class="simple-border">
  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="claim" >
      <mat-header-cell *matHeaderCellDef fxFlex="60px" class="first-cell-button"></mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" class="first-cell-button">
        <button *ngIf="isUser" mat-icon-button matTooltip="{{'openInvitations.Claim' | translate}}" (click)="claimInvitation(entry.invitationCode)">
          <mat-icon>how_to_vote</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="subject">
      <mat-header-cell fxFlex="70%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matHeaderCellDef mat-sort-header> {{'openInvitations.Invitation' | translate}} </mat-header-cell>
      <mat-cell fxFlex="70%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matCellDef="let row">
        <div fxFlex="100%" fxLayout="column" fxLayoutGap="4px">
          <div fxFlex="100%">{{row.subject}}</div>
          <div fxFlex="100%" fxLayout="row" fxLayoutGap="4px" fxHide.md fxHide.gt-md>
            <div fxFlex="100%">{{'openInvitations.Sent' | translate}}: {{row.invited | date:'short'}}</div>
          </div>
        </div>
        
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="invited">
      <mat-header-cell fxFlex="30%" fxHide.sm fxHide.lt-sm *matHeaderCellDef mat-sort-header> {{'openInvitations.Sent' | translate}} </mat-header-cell>
      <mat-cell fxFlex="30%" fxHide.sm fxHide.lt-sm *matCellDef="let row"> {{row.invited | date:'short'}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100]"></mat-paginator>
</div>
