import { Component, OnInit } from '@angular/core';
import { P60benchmark } from 'src/app/models/p60benchmark';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { MessageSeverity, AlertService } from 'src/app/services/alert.service';
import { Utilities } from 'src/app/services/utilities';
import { Company } from 'src/app/models/company';

@Component({
  selector: 'app-company-p60-benchmark-selector-control',
  templateUrl: './company-p60-benchmark-selector-control.component.html',
  styleUrls: ['./company-p60-benchmark-selector-control.component.scss']
})
export class CompanyP60BenchmarkSelectorControlComponent implements OnInit {

  dataSource: P60benchmark[];
  selectedValue: P60benchmark;

  loadingIndicator: boolean = false;

  private onBenchmarkSelected = new Subject<P60benchmark>();
  benchmarkSelected$ = this.onBenchmarkSelected.asObservable();

  public nobenchmark = new P60benchmark(0, this.translateService.getTranslation("companyReports.benchmarks.NoBenchmark"));

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private translateService: AppTranslationService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.loadingIndicator = true;

    this.authService.companySelected$.subscribe(
      company => this.companyChanged(company)
    );

    if (this.authService.currentUserCompany && this.authService.currentUserCompany != null)
      this.makeCall(this.authService.companyId);
  }

  companyChanged(company: Company) {
    if (!company) return;
    this.makeCall(company.companyId);
  }

  makeCall(companyId: number) {
    if (companyId < 0) return;

    this.companiesService
      .getP60Benchmarks(companyId)
      .subscribe(benchmarks =>
        this.onDataLoadSuccessful(benchmarks),
        error => this.onDataLoadFailed(error)
      );
  }

  onDataLoadSuccessful(benchmarks: P60benchmark[]): void {
    this.loadingIndicator = false;
    this.dataSource = [this.nobenchmark];
    if (benchmarks)
      this.dataSource = this.dataSource.concat(benchmarks);
    this.selectedValue = this.dataSource[0];
  }

  onDataLoadFailed(error: any): void {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve benchmarks from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  selectionMade() {
    this.onBenchmarkSelected.next(this.selectedValue);
  }
}
