import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { InvitationsService } from 'src/app/services/invitations.service';
import { InvitationClaim } from 'src/app/models/invitation-claim.model';
import { fadeInOut } from 'src/app/services/animations';
import { LoginDialogComponent } from '../../login/login-dialog.component';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { Invitation } from 'src/app/models/invitation.model';
import { Task } from 'src/app/models/task.model';
import { RegistrationDialogComponent } from '../../registration/dialogs/registration-dialog/registration-dialog.component';

@Component({
  selector: 'app-claim-invitation',
  templateUrl: './claim-invitation.component.html',
  styleUrls: ['./claim-invitation.component.scss'],
  animations: [fadeInOut]
})
export class ClaimInvitationComponent implements OnInit {

  code: string = '';
  inviteClaim: InvitationClaim;

  get inviteClaimJSON() {
    return JSON.stringify(this.inviteClaim);
  }

  get claimExists() {
    if (!this.inviteClaim)
      return false;

    return this.inviteClaim.exists || false;
  }
  get claimExpired() {
    if (!this.inviteClaim)
      return false;

    return this.inviteClaim.expired || false;
  }
  get claimClaimed() {
    if (!this.inviteClaim)
      return false;
    else if (!this.inviteClaim.invite)
      return false;
    else if (this.inviteClaim.invite.claimed)
      return true;

    return false;
  }

  get showLoginOptions() {
    return (this.claimExists && !this.claimExpired && !this.claimClaimed && !this.authService.isLoggedIn);
  }
  get showLoginButton() {
    if (!this.showLoginOptions)
      return false;

    return ((this.inviteClaim.userId || '') != '');
  }
  get showRegistrationButton() {
    if (!this.showLoginOptions)
      return false;

    return ((this.inviteClaim.userId || '') == '');
  }

  get showClaimOptions() {
    return this.claimExists && !this.claimExpired && !this.claimClaimed && !this.showLoginOptions;
  }

  get showClaimExpired() {
    return this.claimExists && this.claimExpired && !this.claimClaimed;
  }

  get showClaimClaimed() {
    return this.claimExists && this.claimClaimed;
  }

  get showClaimDoesNotExist() {
    return !this.claimExists;
  }
  
  

  constructor(
    private route: ActivatedRoute,
    private inviteService: InvitationsService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
    this.inviteService.checkInvitation(this.code).subscribe(result => {
      this.parseClaim(result);
    });
  }

  parseParams(params: Params) {
    this.code = params['code'];
  }

  parseClaim(inviteClaim: InvitationClaim) {
    this.inviteClaim = inviteClaim;
  }


  showLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent, {  });

    dialogRef.afterClosed().subscribe(result => {
      this.alertService.resetStickyMessage();

      if (!result || this.authService.isSessionExpired) {
        this.authService.logout();
        //this.router.navigateByUrl('/login');
        this.alertService.showStickyMessage('Sign In', 'To continue, you must sign into your account.', MessageSeverity.warn);
      }
    });
  }

  showRegisterDialog(): void {
    const dialogRef = this.dialog.open(RegistrationDialogComponent, {});

    dialogRef.afterClosed().subscribe(result => {
      this.alertService.resetStickyMessage();

      if (!result || this.authService.isSessionExpired) {

      }

        this.inviteService.checkInvitation(this.code).subscribe(result => {
          this.parseClaim(result);
        });

    });
  }

  claimInvitation(): void {
    this.inviteService.claimInvitation(this.code).subscribe(
      result => {
        this.invitationClaimed(result);
      },
      error => {
        this.claimFailed(error);
      }
    );
  }

  invitationClaimed(task: Task) {
    var url = `/home`;
    if (task.taskId)
      url = `/task/${task.taskId}`;
    //TODO: Invitations - Claim - add other routes for task.

    this.router.navigateByUrl(url);
  }


  claimFailed(error: string) {
    //this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Claim Error', 'One or more errors occured whilst claiming the invitation:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }
}
