  <canvas class="simple-border" fxFlex="100%"
          baseChart
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [options]="barChartOptions"
          [colors]="barChartColors"
          [legend]="barChartLegend"
          [chartType]="barChartType"
          >
  </canvas>
