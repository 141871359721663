<app-page-header title="{{title}}" icon="{{icon}}"></app-page-header>
<div [@fadeInOut] class="page-content" fxLayout="column">
  <app-course-header-control [course]="course" designation="{{designation}}"></app-course-header-control>
  <div fxLayout="column" fxLayoutGap="8px">
    <div fxFlex="100%" fxLayout="row" *ngIf="noContent">
      <div fxFlex="auto"></div>
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'courseEditor.SelectContent' | translate}}</mat-label>
        <mat-select [(ngModel)]="selectedMaterial" (selectionChange)="addContentAt(100, true, selectedMaterial, $event)">
          <mat-option *ngFor="let lc of materials" [value]="lc">
            <span matTooltip="{{lc.materialName}}">{{lc.materialName + ' (' + lc.languageCode + ')'}}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div fxFlex="auto"></div>
    </div>
    <div fxFlex="100%" *ngFor="let o of orders">
      <div fxFlex="100%" *ngIf="o.material" fxLayout="column" fxLayoutGap="8px">
        <app-course-material-control [courseMaterial]="o"
                                     [position]="contentPosition(o)"
                                     (onDelete)="contentDelete($event)"
                                     (onMoveUp)="contentMoveUp($event)"
                                     (onMoveDown)="contentMoveDown($event)">
        </app-course-material-control>
        <div fxFlex="100%" fxLayout="row">
          <div fxFlex="auto"></div>
          <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
            <mat-label>{{'courseEditor.SelectContent' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedMaterial" (selectionChange)="addContentAt(o.ordinal, false, selectedMaterial, $event)">
              <mat-option *ngFor="let lc of freeMaterials" [value]="lc">
                <span matTooltip="{{lc.materialName}}">{{lc.materialName + ' (' + lc.languageCode + ')'}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div fxFlex="auto"></div>
        </div>
      </div>
    </div>

  </div>
  <mat-divider></mat-divider>

  <!--<div>

    <pre fxFlex="100%">
      {{jsonData}}
    </pre>

  </div>-->

  <div class="fab-container" *ngIf="!(loadingIndicator == true)">
    <button mat-fab color="primary" class="fab-action" (click)="save()" matTooltip="{{'commands.Save'|translate}}">
      <mat-icon>save</mat-icon>
    </button>
    <button mat-mini-fab color="warn" class="fab-action" (click)="reload()" matTooltip="{{'commands.Reload'|translate}}">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>


</div>

<div class="{{loadingIndicator == true ? 'editor-hold' : ''}}"></div>
