<h1 mat-dialog-title fxLayoutGap="8px" fxLayout="row">
  <mat-icon class="mat-icon-md" >contact_mail</mat-icon>
  <span fxFlex="100%">{{'invitations.p60Invite.Title' | translate}}</span>
</h1>
<div mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="8px">
    <app-contact-editor-control [contact]="data.contact" isEditMode="true"></app-contact-editor-control>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'invitations.p60Invite.Assessment' | translate}}</mat-label>
      <mat-select [(ngModel)]="p60MaterialId">
        <mat-option *ngFor="let m of mats" [value]="m.materialId">
          <span>{{ m.materialName }}</span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="send();" color="primary">{{'invitations.p60Invite.SendInvite' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel'|translate}}</button>
</div>
