import { Inject, Input, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subscription } from 'rxjs';
import { PasswordResetModel } from '../../models/password-reset.model';
import { User } from '../../models/user.model';
import { AccountService } from '../../services/account.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { EqualValidator } from '../../shared/validators/equal.validator';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit, OnDestroy {
  isEditMode: boolean = true;
  isSaving: boolean = false;

  @ViewChild('form') private form: NgForm;

  passwordResetForm: FormGroup;

  private passwordWatcher: Subscription;

  get currentPassword() {
    return this.passwordResetForm.get('current_password');
  }
  get newPassword() {
    return this.passwordResetForm.get('new_password');
  }
  get confirmPassword() {
    return this.passwordResetForm.get('confirm_password');
  }

  get userName(): any {
    return this.data.user ? { name: this.data.user.userName } : null;
  }

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PasswordResetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: User }
  ) {
    this.buildForm();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.passwordWatcher.unsubscribe();
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  private buildForm() {
    this.passwordResetForm = this.formBuilder.group({
      current_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,}/)]],
      confirm_password: ['', [Validators.required, EqualValidator('new_password')]]
    });

    this.passwordWatcher = this.newPassword.valueChanges.subscribe(() => this.confirmPassword.updateValueAndValidity());
  }

  public resetForm(stopEditing: boolean = false) {
    this.newPassword.clearValidators();
    this.confirmPassword.clearValidators();
    this.currentPassword.clearValidators();

    this.passwordResetForm.reset({
      current_password: '',
      new_password: '',
      confirm_password: ''
    });
  }

  public save() {
    if (!this.form.submitted) {
      // Causes validation to update.
      this.form.onSubmit(null);
      return;
    }

    if (!this.passwordResetForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    this.alertService.startLoadingMessage('Resetting password...');

    var model: PasswordResetModel = {
      user_id: this.data.user.id,
      current_password: this.currentPassword.value,
      new_password: this.newPassword.value,
      confirm_password: this.confirmPassword.value
    };

    this.accountService.resetPassword(model).subscribe(
      () => this.saveCompleted(),
      error => this.saveFailed(error)
    );
  }

  public cancel() {
    this.resetForm();
    this.isEditMode = false;

    this.alertService.resetStickyMessage();
    this.dialogRef.close(null);
  }

  private saveCompleted() {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Password Changed', 'Your password has been changed.', MessageSeverity.success);

    this.resetForm(true);
    this.dialogRef.close(null);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

}
