import { Injectable, PACKAGE_ROOT_URL } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { Material } from '../models/material.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialsService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint,
    private authService: AuthService
  ) { }

  //getMaterials() {
  //  const companyId: number = this.authService.companyId;
  //  return this.getMaterialsByCompanyId(companyId);
  //}

  getMaterial(id: number, includeall: boolean = false) {
    return this.applicationEndpoint.getMaterialEndpoint<Material>(id, includeall);
  }

  getMaterialsByCompanyId(companyId: number) {
    return this.applicationEndpoint.getMaterialsByCompanyIdEndpoint<Material[]>(companyId);
  }

  getTests(companyId: number, designation: string = "") {
    return this.applicationEndpoint.getMaterialTestsEndpoint<Material[]>(companyId, designation);
  }
   
  getMaterials(companyId: number, designation: string = "") {
    return this.applicationEndpoint.getMaterialMaterialsEndpoint<Material[]>(companyId, designation);
  }

  copyMaterial(materialId: number, toCompanyId: number = -1) {
    return this.applicationEndpoint.copyMaterialEndpoint<Material>(materialId, toCompanyId);
  }

  deleteMaterial(materialId: number) {
    return this.applicationEndpoint.deleteMaterialEndpoint<Material>(materialId);
  }

  createTest(designation: string, material: Material) {
    return this.applicationEndpoint.createTestEndpoint<Material>(designation, material);
  }

  createMaterial(designation: string, material: Material) {
    return this.applicationEndpoint.createMaterialEndpoint<Material>(designation, material);
  }

  patchMaterial(material: Material) {
    return this.applicationEndpoint.patchMaterialEndpoint<Material>(material);
  }
}
