import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { AlertService } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { MatDialog } from '@angular/material';
import { ImageUploadDialogComponent } from '../../dialogs/image-upload-dialog/image-upload-dialog.component';
import { ProfileInitialDialogComponent } from '../../profile/profile-initial-dialog/profile-initial-dialog.component';
import { User } from 'src/app/models/user.model';

@Component({
  selector: 'app-profile-card-control',
  templateUrl: './profile-card-control.component.html',
  styleUrls: ['./profile-card-control.component.scss']
})
export class ProfileCardControlComponent implements OnInit, OnChanges {
  @Input() userId: number;
  @Input() getDefaultProfile: boolean = true;

  private profile: Profile;

  get name(): string {
    if (!this.profile) return "";
    return `${this.profile.firstName}${(this.profile.preferredName != "" && this.profile.preferredName != this.profile.firstName ? " '" + this.profile.preferredName + "'" : "")} ${this.profile.lastName}${(this.profile.educationCredentials != "" ? ", " + this.profile.educationCredentials : "")}`;
  }
  get email(): string {
    if (!this.profile) return "";
    return this.profile.email;
  }
  get emailurl(): string {
    if (!this.profile) return "";
    return `mailto:${this.profile.email}`;
  }
  get phone(): string {
    if (!this.profile) return "";
    return this.profile.phoneNumber || "";
  }
  get phoneurl(): string {
    if (!this.profile) return "";
    if (this.profile.phoneNumber == null) return "";
    return `tel:+${this.profile.phoneNumber.replace(/\D/g, '')}`;
  }
  get photoBase64(): string {
    if (!this.profile) return "";
    return this.profile.photoBase64 || "";
  }

  get isUser(): boolean {
    if (!this.profile || !this.authService) return false;
    return this.profile.userId == this.authService.currentUserProfile.userId;
  }

  get canChangePhoto(): boolean {
    if (!this.profile || !this.authService) return false;
    if (this.authService.userIsAdmin || this.authService.userIsCompanyAdmin) return true;
    else return this.profile.userId == this.authService.currentUserProfile.userId;
  }

  get canEditProfile(): boolean {
    if (!this.profile || !this.authService) return false;
    if (this.authService.userIsAdmin || this.authService.userIsCompanyAdmin) return true;
    else return this.profile.userId == this.authService.currentUserProfile.userId;
  }

  constructor(
    private alertService: AlertService,
    private translateService: AppTranslationService,
    private profileService: ProfileService,
    private authService: AuthService,
    private dialog: MatDialog

  ) {

  }

  ngOnInit() {
    if (this.getDefaultProfile == true) {
      this.authService.profileSelected$.subscribe(
        result => { this.profileFound(result); }
      );
    }

    this.waitForUserId(this.userId);
  }

  ngOnChanges() {
    if (this.profile)
      this.profileFound(this.profile);
  }

  public profileFound(profile: Profile) {
    this.profile = profile;
    return this.makeCall(profile.userId);
  }

  waitForUserId(id: number) {
    if (!id) {
      setTimeout(() => { this.waitForUserId(id); }, 500);
      return;
    }

    if (id <= 0) {
      setTimeout(() => { this.waitForUserId(id); }, 500);
      return;
    }

    this.makeCall(id);
  }

  makeCall(id: number) {
    this.userId = id;

    if (!this.profile && this.authService.currentUserProfile)
      this.profile = this.authService.currentUserProfile;

    if (this.profile) {
      this.profileService.getProfile(this.userId).subscribe(
        data => this.parseProfile(data),
        error => this.parseError(error)
      );
    }
  }

  parseProfile(data: Profile) {
    this.profile = data;
  }

  parseError(error: any) {

  }

  launchImageImport() {
    if (!this.canChangePhoto) return;

    const dialogRef = this.dialog.open(ImageUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(base64Image => {
      if (base64Image) {
        this.updateProfileImage(base64Image);
      }
    });
  }

  private updateProfileImage(image: string) {
    this.profile.photoBase64 = image;
    this.profileService.updateProfile(this.profile).subscribe(
      () => { },
      error => this.parseError(error)
    ); 
  }


  private launchProfileEditor() {
    if (!this.canEditProfile) return;

    var user: User = new User();
    user.fullName = `${this.profile.firstName} ${this.profile.lastName}`;
    user.email = this.profile.email;
    user.userName = this.profile.userName;

    const dialogRef = this.dialog.open(ProfileInitialDialogComponent,
      {
        //panelClass: 'mat-dialog-lg',
        data: { user: user, profile: this.profile, isInitial: false }
      });
    dialogRef.afterClosed().subscribe(profile => {
      if (profile) {
        this.profile = profile;
      }
    });
  }
}
