import { Component, OnInit, ViewChild, Input, OnDestroy, OnChanges } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AppTranslationService } from '../../../services/app-translation.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ProfileService } from '../../../services/profile.service';
import { XrService } from '../../../services/xr.service';

import { Profile } from '../../../models/profile';
import { User } from '../../../models/user.model';
import { XrGender } from '../../../models/xr-gender';
import { isNullOrUndefined } from 'util';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { ImageUploadDialogComponent } from '../../dialogs/image-upload-dialog/image-upload-dialog.component';
import { ProfileCompany } from 'src/app/models/profile-company';

@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.scss']
})
export class ProfileEditorComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('form')
  private form: NgForm;

  isNewProfile = false;
  private isSaving = false;

  @Input() user: User = new User();
  @Input() profile: Profile = new Profile();
  @Input() isEditMode = false;
  @Input() isInitial = false;

  private onProfileSaved = new Subject<Profile>();

  profileForm: FormGroup;
  profileSaved$ = this.onProfileSaved.asObservable();
  
  get photoBase64(): string {
    if (!this.profile) return "";
    return this.profile.photoBase64 || "";
  }

  get canChangePhoto(): boolean {
    return true;
    //if (!this.profile || !this.authService) return false;
    //if (this.authService.userIsAdmin) return true;
    //else return this.profile.userId == this.authService.currentUserProfile.userId;
  }

  get genders(): XrGender[] {
    //if (!this.xrService.genders) {
    //  var loader = new XrGender();
    //  loader.genderId = -1;
    //  loader.title = "loading";
    //  loader.code = "load";
    //  return [loader];
    //}
    return this.xrService.genders;
  }

  floatLabels: string = "auto";

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private profileService: ProfileService,
    private xrService: XrService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    this.xrService.getGenders(); //preps the data, just in case.
    this.buildForm();
  }

  ngOnInit() {
  }

  ngOnChanges() {
    this.isNewProfile = false;

    if (!this.profile) {
      this.isNewProfile = true;
    } else if (isNullOrUndefined(this.profile.userId)) {
      this.isNewProfile = true;
    }


    if (this.isNewProfile == true) {
      this.isNewProfile = true;
      if (this.isInitial)
        this.isNewProfile = false; //allow the creation of the profile object, but treat as an update...

      this.profile = new Profile();

      var namesplit = this.user.fullName.split(' ');
            
      this.profile.userName = this.user.userName;
      this.profile.email = this.user.email;
      this.profile.firstName = namesplit[0];
      this.profile.middleName = namesplit.length > 2 ? namesplit[1] : '';
      this.profile.lastName = namesplit[namesplit.length - 1];
      this.profile.preferredName = namesplit[0];
      this.profile.suffix = '';
      this.profile.salutation = '';
      this.profile.educationCredentials = '';
      this.profile.phoneNumber = '';
      this.profile.photoBase64 = '';
      this.profile.genderId = this.user.genderId; //undisclosed
            
    }
    this.resetForm();
  }

  ngOnDestroy() {
    
  }

  private buildForm() {
    this.profileForm = this.formBuilder.group({
      userId: 0,
      userName: ['', Validators.required],
      email: ['',Validators.required],
      firstName: ['', Validators.required],
      preferredName: ['', Validators.required],
      middleName: '',
      lastName: ['', Validators.required],
      suffix: '',
      salutation: '',
      educationCredentials: '',
      phoneNumber: '',
      photoBase64: '',
      genderId: 1 //undisclosed
    })
  }

  private resetForm(stopEditing: boolean = false) {
    if (stopEditing) {
      this.isEditMode = false;
      this.isInitial = false;
    }

    if (!this.profile || this.isNewProfile) {
      this.isNewProfile = true;
      this.profile = new Profile();
    }

    if (this.isNewProfile) {
      //no special logic, yet.
    }


    this.profileForm.reset({
      userId: this.profile.userId || 0,
      userName: this.profile.userName || '',
      email: this.profile.email || '',
      firstName: this.profile.firstName || '',
      preferredName: this.profile.preferredName || '',
      middleName: this.profile.middleName || '',
      lastName: this.profile.lastName || '',
      suffix: this.profile.suffix || '',
      salutation: this.profile.suffix || '',
      educationCredentials: this.profile.educationCredentials || '',
      phoneNumber: this.profile.phoneNumber || '',
      photoBase64: this.profile.photoBase64 || '',
      genderId: +this.profile.genderId || 1 //undisclosed
    });

  }

  public beginEdit() {
    this.isEditMode = true;
  }

  public save() {
    if (!this.form.submitted) {
      // causes validation to update.
      this.form.onSubmit(null);
      return;
    }

    if (!this.profileForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    const editedProfile = this.getEditedProfile();

    if (this.isNewProfile || this.isInitial) {
      this.profileService.newProfile(editedProfile).subscribe(
        profile => this.saveCompleted(profile),
        error => this.saveFailed(error)
      );
    } else {
      this.profileService.updateProfile(editedProfile).subscribe(
        () => this.saveCompleted(editedProfile),
        error => this.saveFailed(error)
      );
    }
  }

  public cancel() {
    this.resetForm();
    this.isEditMode = false;

    this.alertService.resetStickyMessage();
  }

  private getEditedProfile(): Profile {
    const formModel = this.profileForm.value;

    var editObj = new Profile();
    var values = {
      userId: this.profile.userId,
      userName: formModel.userName,
      email: formModel.email,
      firstName: formModel.firstName,
      preferredName: formModel.preferredName,
      middleName: formModel.middleName,
      lastName: formModel.lastName,
      suffix: formModel.suffix,
      salutation: formModel.salutation,
      educationCredentials: formModel.educationCredentials,
      genderId: formModel.genderId,
      phoneNumber: formModel.phoneNumber,
      photoBase64: formModel.photoBase64,
      createdDate: formModel.createdDate,
      createdBy: formModel.createdBy,
      updatedDate: formModel.updatedDate,
      updatedBy: formModel.updatedBy,

      gender: this.xrService.getGender(formModel.genderId)
    };

    Object.assign(editObj, values);

    return editObj;
  }

  private saveCompleted(profile?: Profile) {
    if (profile) {
      //add downstream event calls here, if needed
      this.profile = profile;
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.resetForm(true);
    this.onProfileSaved.next(this.profile);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);  
  }

  launchImageImport() {
    if (!this.canChangePhoto) return;

    const dialogRef = this.dialog.open(ImageUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(base64Image => {
      if (base64Image) {
        this.updateProfileImage(base64Image);
      }
    });
  }

  private updateProfileImage(image: string) {
    this.profile.photoBase64 = image;
    this.profileForm.get('photoBase64').setValue(image);
    //this.profileService.updateProfile(this.profile).subscribe(
    //  () => { },
    //  error => this.parseError(error)
    //);
  }

  parseError(error: any) {

  }

}
