<div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="20px">
  <div fxLayout="row" fxFlex="100%" fxLayoutGap="20px">
    <div class="photo simple-border">
      <img src="{{ photoBase64 || '../../../assets/images/usericon.png'}}" (click)="launchImageImport()" />
    </div>
    <div fxLayout="column" fxFlex="100%">
      <h3>{{name}}</h3>
      <a href="{{emailurl}}">{{email}}</a>
      <a href="{{phoneurl}}">{{phone}}</a>
    </div>
  </div>
  <div *ngIf="canEditProfile" fxLayout="column" fxFlex="auto">
    <button mat-raised-button (click)="launchProfileEditor();" color="primary">{{'profileCard.EditProfile' | translate}}</button>
  </div>
</div>
