<h1 mat-dialog-title>
  <input matInput [(ngModel)]="benchmark.benchmarkName" />
</h1>
<div mat-dialog-content fxLayout="column">

  <div>{{'benchmarkNew.Instructions' | translate}}</div>

  <div class="simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <mat-header-cell fxFlex="15%" *matHeaderCellDef mat-sort-header> {{'benchmarkNew.Select' | translate}} </mat-header-cell>
        <mat-cell fxFlex="15%" *matCellDef="let row">
          <mat-checkbox [(ngModel)]="row.select"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell fxFlex="85%" *matHeaderCellDef mat-sort-header> {{'benchmarkNew.Name' | translate}} </mat-header-cell>
        <mat-cell fxFlex="85%" *matCellDef="let row"> {{row.name}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="continue();" color="primary">{{'commands.Continue' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
