import { Component, OnInit, ViewChild } from '@angular/core';
import { TasksService } from 'src/app/services/tasks.service';
import { Task } from 'src/app/models/task.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Utilities } from 'src/app/services/utilities';
import { fadeInOut } from 'src/app/services/animations';
import { TaskItemControlComponent } from 'src/app/components/tasks/controls/task-item-control/task-item-control.component';
import { MatStepper } from '@angular/material';
import { UserTestQuestionAnswer } from 'src/app/models/user-test-question-answer.model';
import { timeout } from 'q';
import { AnswerChangeEventArgs } from 'src/app/models/answer-change-event-args.model';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ProgressSavedEventArgs } from 'src/app/models/progress-saved-event-args.model';
import { AlertService } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { XrService } from 'src/app/services/xr.service';
import { forEach } from '@angular/router/src/utils/collection';
import { TaskItem } from '../../../models/task-item.model';

@Component({
  selector: 'app-task-view',
  templateUrl: './task-view.component.html',
  styleUrls: ['./task-view.component.scss'],
  animations: [fadeInOut],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { showError: true }
  }]
})
export class TaskViewComponent implements OnInit {
 
  taskId: number;
  task: Task;

  get taskName() {
    if (!this.task)
      return '';

    return this.task.taskName;   
  }
  get percent() {
    if (!this.task)
      return 0;

    return this.task.completionValue * 100;  
  }
  get completionValue() {
    if (!this.task)
      return '0.00%';

    return this.formatPercent(this.task.completionValue);
  }
  get completed() {
    if (!this.task)
      return null;

    if (!this.task.completed)
      return null;

    const value = Utilities.utcDateToLocal(new Date(this.task.completed));

    return value.toLocaleDateString() + ' ' + value.toLocaleTimeString();
  }
  get dueDate() {
    if (!this.task)
      return null;

    if (!this.task.dueDate)
      return null;

    const value = Utilities.utcDateToLocal(new Date(this.task.dueDate));

    return (this.isToday(this.task.dueDate) ? value.toLocaleTimeString() : value.toLocaleDateString());
  }
  get created() {
    if (!this.task)
      return null;

    const value = Utilities.utcDateToLocal(new Date(this.task.createdDate));

    return value.toLocaleDateString() + ' ' + value.toLocaleTimeString();
  }

  get taskItem() {
    if (!this.task)
      return [];

    return this.task.taskItem;
  }

  lastAnsweredIndex: number = 0;

  indexValue: number = 0;

  get indexPosition(): number {
    return this.indexValue;
  }

  get itemCount(): number {
    if (!this.task) return 0;
    if (!this.task.taskItem.length) return 0;

    return this.task.taskItem.length;
  }

  get indexItem(): TaskItem {
    if (!this.task) return new TaskItem();
    if (!this.task.taskItem.length) return new TaskItem();

    return this.task.taskItem[this.indexPosition];
  }

  get indexItemJsonData(): string {
    return JSON.stringify(this.indexItem, null, 2);
  }

  get jsonData() {
    return JSON.stringify(this.task, null, 2);
  }

  get showBack(): boolean {
    return this.indexValue >= 1;
  }
  get showNext(): boolean {
    return this.indexValue < this.lastAnsweredIndex;
  }

  @ViewChild(MatStepper) stepper: MatStepper;


  constructor(
    private route: ActivatedRoute,
    private taskService: TasksService,
    private router: Router,
    private alertService: AlertService,
    private translateService: AppTranslationService,
    private xrService: XrService
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
    this.xrService.getGenders(); //preps the data, just in case.
    this.xrService.getAnswerTypes(); //preps the data, just in case.

    this.taskService.getTask(this.taskId).subscribe(result => {
      this.parseTask(result);
    });
  }
   
  parseParams(params: Params) {
    this.taskId = params['taskId'];
  }

  parseTask(task: Task) {
    this.task = task;
    setTimeout(() => {
      this.moveToLastAnswered();
    });
  }

  moveToLastAnswered() {
    var compindex = 0;
    for (var ti of this.task.taskItem) {
      if (ti.completed)
        compindex++;
      else {
        break;
      }
    }
    //Math.round(this.task.completionValue * (this.taskItem.length - 1) + .4);
    this.lastAnsweredIndex = compindex;
    this.moveForward(compindex);
  }

  moveForward(forwardindex?: number) {
    if (forwardindex == undefined)
      forwardindex = this.indexValue + 1;
    
    if (forwardindex >= this.taskItem.length)
      forwardindex = this.taskItem.length - 1;

    if (forwardindex > this.lastAnsweredIndex)
      this.lastAnsweredIndex = forwardindex;

    this.indexValue = forwardindex;
  }

  moveBack(backindex?: number) {
    if (backindex == undefined)
      backindex = this.indexValue - 1;

    if (backindex < 0)
      backindex = 0;

    this.indexValue = backindex;
  }

  formatPercent(value: number): string {
    const perc = (value * 100).toFixed(2);
    return `${perc}%`;
  }

  isToday(dateValue: Date) {
    if (!Utilities)
      return false;
    if (!Utilities.isToday)
      return false;
    if (!dateValue)
      return false;

    const testDate = Utilities.utcDateToLocal(new Date(dateValue));

    return Utilities.isToday(testDate);
  }

  selectionChanged(e) {
    
  }

  progressSaved(e: ProgressSavedEventArgs) {
    this.task.completionValue = e.task.completionValue;
    this.task.completed = e.task.completed;
    if (e.task.completionValue >= 1 && this.indexValue == (this.taskItem.length - 1)) {
      var errorIndex: number = -1;
      var changedIndex: number = -1;

      this.taskItem.forEach(function (item, index, steps) {
        if (index == e.stepIndex && item.state == "changed") {
          item.state = "saved";
          item.isCompleted = true;
        }

        if (errorIndex == -1 && item.hasError == true) {
          errorIndex = index;
        }
        else if (changedIndex == -1 && item.state == "changed") {
          changedIndex = index;
        }
      });

      if (errorIndex >= 0) {
        this.indexValue = errorIndex;
        this.alertService.showMessage(this.translateService.getTranslation('taskView.AnswerAllQuestions'));
      }
      else if (changedIndex >= 0) {
        this.indexValue = changedIndex;
        this.alertService.showMessage(this.translateService.getTranslation('taskView.SaveChanges'));
      }
      else {
        this.alertService.showMessage(this.translateService.getTranslation('taskView.TaskComplete'));
        this.router.navigateByUrl('/home');
      }
    }
    else {
      this.taskItem.forEach(function (item, index, steps) {
        if (index == e.stepIndex && item.state == "changed") {
          item.state = "saved";
        }
      });

      if (this.indexValue == (this.taskItem.length - 1)) {
        this.alertService.showMessage(this.translateService.getTranslation('taskView.AnswerAllQuestions'));
      }

      this.moveForward();
    }
  }

  answerChanged(e: AnswerChangeEventArgs) {

    this.taskItem.forEach(function (item, index, steps) {
      if (index == e.stepIndex) {
        if (e.itemType == "question") {
          item.hasError = (e.answerValue.toString().trim() == '');
          item.state = "changed";
        }

      }
    });
  }

}
