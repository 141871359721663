import { Injectable } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { AuthService } from './auth.service';
import { Task } from '../models/task.model';
import { TaskItem } from '../models/task-item.model';
import { TaskItemProgress } from '../models/task-item-progress.model';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint,
    private authService: AuthService
  ) { }

  getTasksForUser(id?: number) {
    var userId: number = 0;
    if (!id && this.authService.currentUserProfile)
      userId = this.authService.currentUserProfile.userId;
    else if (id > 0)
      userId = id;
    
    return this.applicationEndpoint.getTasksForUserEndpoint<Task[]>(userId);
  }

  getTask(id: number) {
    return this.applicationEndpoint.getTask<Task>(id);
  }

  getTaskItem(id: number) {
    return this.applicationEndpoint.getTaskItem<TaskItem>(id);
  }

  patchRecordTaskItemProgress(patch: TaskItemProgress) {
    return this.applicationEndpoint.patchRecordTaskItemProgress<Task>(patch);
  }
}
