export class InvitationSender {
  public campaignId: number;
  public senderUserId: number;
  public companyId: number;
  public subject: string;
  public body: string;
  public courseId: number;
  public materialId: number;
  public language: string;
  public linkBase: string;
  public expirationDate: Date;
  public contacts: InvitationContact[];

  public static Create(
    senderUserId: number,
    companyId: number,
    subject: string,
    body: string,
    courseId: number,
    materialId: number,
    language: string,
    linkBase: string,
    expirationDate: Date,
    contacts: InvitationContact[]
  ) {
    var invite = new InvitationSender();
    invite.senderUserId = senderUserId;
    invite.companyId = companyId;
    invite.subject = subject;
    invite.body = body;
    invite.courseId = courseId;
    invite.materialId = materialId;
    invite.language = language;
    invite.linkBase = linkBase;
    invite.expirationDate = expirationDate;
    invite.contacts = contacts;

    return invite;
  }


}

export class InvitationContact {
  constructor(email: string, firstName: string, lastName: string) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  public email: string;
  public firstName: string;
  public lastName: string;
  public success: boolean;
  public error: string;
}

