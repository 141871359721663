import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';

import { fadeInOut } from 'src/app/services/animations';
import { AlertService, DialogType, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from 'src/app/services/utilities';
import { CompaniesService } from 'src/app/services/companies.service';
import { P60benchmark } from 'src/app/models/p60benchmark';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';
import { BenchmarkSliderControlComponent, Filter } from '../controls/benchmark-slider-control/benchmark-slider-control.component';
import { BenchmarkDialogComponent } from '../dialogs/benchmark-dialog/benchmark-dialog.component';
import { BenchmarkNewDialogComponent } from '../dialogs/benchmark-new-dialog/benchmark-new-dialog.component';

@Component({
  selector: 'app-benchmark-list',
  templateUrl: './benchmark-list.component.html',
  styleUrls: ['./benchmark-list.component.scss'],
  animations: [fadeInOut]
})
export class BenchmarkListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(BenchmarkSliderControlComponent) testslider: BenchmarkSliderControlComponent;

  companyId: number;

  dataSource: MatTableDataSource<P60benchmark>;
  sourceObject: P60benchmark;
  loadingIndicator: boolean;

  get teststring() {
    if (!this.sourceObject)
      return "n/a";

    return JSON.stringify(this.sourceObject, null, 2);
  }

  get role(): string {
    if (!this.authService) return "User";
    if (!this.authService.companyRole) return "User";
    return this.authService.companyRole;
  }

  get canEdit(): boolean {
    return this.role != "User";
  }

  get displayedColumns(): string[] {
    if (this.canEdit) {
      return [
        'benchmarkName',
        'template',
        'actions'
      ];
    }

    return [
      'benchmarkName',
      'template'
    ];
  }

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute) {

    // Assign the data to the data source for the table to render
    this.dataSource = new MatTableDataSource();

    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService
      .getP60Benchmarks(this.companyId)
      .subscribe(list =>
        this.onDataLoadSuccessful(list),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(list: P60benchmark[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = list;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve benchmarks from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private newBenchmark() {
    var copy: P60benchmark = new P60benchmark(0, "(new benchmark)"); 
    copy.companyId = this.authService.companyId;
    copy.template = false;

    const dialogref = this.dialog.open(BenchmarkNewDialogComponent, { data: { benchmark: copy, companyId: this.companyId } });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource.data = this.dataSource.data.sort((a, b) => (a.benchmarkName > b.benchmarkName) ? 1 : -1);
        this.edit(result);
      }
    });
  }

  private copy(object: P60benchmark) {
    var copy: P60benchmark = (JSON.parse(JSON.stringify(object))); //the JSOn presents a clone...
    copy.benchmarkName += " (Copy)";
    copy.benchmarkId = 0;
    copy.template = false;

    const dialogref = this.dialog.open(BenchmarkDialogComponent, { data: { benchmark: copy } });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource.data = this.dataSource.data.sort((a, b) => (a.benchmarkName > b.benchmarkName) ? 1 : -1);
      }
    });
  }

  private edit(object: P60benchmark) {
    if (object.template && object.companyId != this.companyId) return;
    const dialogref = this.dialog.open(BenchmarkDialogComponent, { data: { benchmark: object } });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
      }
    });
  }


  private confirmDelete(object: P60benchmark) {
    if (object.template && object.companyId != this.companyId) return;

    this.snackBar.open(`Delete ${object.benchmarkName} benchmark?`, 'DELETE', { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;
        this.delete(object.benchmarkId);

      });
  }

  delete(benchmarkId: number) {
    this.companiesService.deleteBenchmark(benchmarkId)
      .subscribe(
        results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.loadData();
        },
        error => {
          this.checkError(error);
        });
  }

  checkError(error: string) {
    if (error != "INVALID")
      return;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting the benchmark.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


}
