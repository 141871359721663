<div fxLayout="row">
  <app-page-header title="{{optionTitle}}" icon="widgets" fxFlex="100%"></app-page-header>
  <div>
    <i>{{code}} ({{languageCode}})&nbsp;</i>
  </div>
</div>


<div [@fadeInOut] class="page-content">
  <app-p60-option-value-set-control (values)="option.p60optionValue"></app-p60-option-value-set-control>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</div>
