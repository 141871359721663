import { Component, OnInit, Inject } from '@angular/core';
import { Company } from 'src/app/models/company';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { CompanyCredit } from 'src/app/models/company-credit';

@Component({
  selector: 'app-company-credit-dialog',
  templateUrl: './company-credit-dialog.component.html',
  styleUrls: ['./company-credit-dialog.component.scss']
})
export class CompanyCreditDialogComponent implements OnInit {

  company: Company;
  credits: number = 0;

  newCredits: number = 0;

  get remainingCredits(): string {
    if (!this.company) return "0";
    if (this.company.unlimitedCredits == true) return this.translationService.getTranslation("companyCreditDialog.UnlimitedCredits");

    if (this.company.companyCredits) {
      return this.company.companyCredits.filter(c => (c.userTestId || 0) <= 0).length.toString();
    }

    return "0";
  }

  constructor(
    public companyService: CompaniesService,
    public translationService: AppTranslationService,
    public dialogRef: MatDialogRef<CompanyCreditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company }
  ) {
    this.company = data.company;
  }

  ngOnInit() {
  }

  setUnlimited() {
    this.companyService.setUnlimitedCreditStatus(this.company.companyId, true)
      .subscribe(
        data => this.parseUnlimitedResult(data),
        error => this.parseError(error)
      );
  }
  setCreditsOnly() {
    this.companyService.setUnlimitedCreditStatus(this.company.companyId, false)
      .subscribe(
        data => this.parseCreditsResult(data),
        error => this.parseError(error)
      );
  }
  addCredits(credits: number) {
    this.newCredits = credits;
    this.companyService.addCredits(this.company.companyId, credits)
      .subscribe(
        data => this.parseResult(data),
        error => this.parseError(error)
      );
  }

  parseUnlimitedResult(data: CompanyCredit[]) {
    this.company.unlimitedCredits = true;
    this.company.companyCredits = data;
  }

  parseCreditsResult(data: CompanyCredit[]) {
    this.company.unlimitedCredits = false;
    this.company.companyCredits = data;
  }

  parseResult(data: CompanyCredit[]) {
    this.credits += this.newCredits;
    if (this.credits < 0) this.credits = 0;
    this.newCredits = 0;
    this.company.companyCredits = data;
  }

  parseError(error: any) {

  }

  close() {
    this.dialogRef.close(this.company);
  }

}
