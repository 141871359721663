import { IAuditableEntity } from "./IAuditableEntry.interface";
import { Question } from "./question.model";

export class Test implements IAuditableEntity {
  public testId: number;
  public courseId: number;
  public materialId: number;
  public testName: string;
  public intro: string;
  public outtro: string;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public languageCode: string;
  public designation: string;

  public question: Question[];
}
