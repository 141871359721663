<h1 mat-dialog-title>
  <input matInput [(ngModel)]="name" />
</h1>
<div mat-dialog-content fxLayout="column">
  <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
    <mat-label>{{'materialHeaderControl.Template' | translate}}</mat-label>
    <mat-select [(ngModel)]="template">
      <mat-option [value]="false">{{'materialHeaderControl.TemplateNo' | translate}}</mat-option>
      <mat-option [value]="true">{{'materialHeaderControl.TemplateYes' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>
  <div fxLayout="column" fxFlex="100%">
    <app-benchmark-slider-control #sliderStress></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderSensitivity></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderCompassion></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderPatience></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderCreative></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderArtistic></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderOutgoing></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderInteraction></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderAnalytical></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderDetailed></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderDecision></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderIndependent></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderPersuasive></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderAggressive></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderStubborn></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderGoal></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderTime></app-benchmark-slider-control>
    <app-benchmark-slider-control #sliderConfidence></app-benchmark-slider-control>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="save();" color="primary">Save</button>
  <button mat-raised-button (click)="cancel()" color="warn">Cancel</button>
</div>
