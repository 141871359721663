<div class="search-box">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'commands.Search' | translate}}">
  </mat-form-field>
</div>

<div class="simple-border">

  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="keyText">
      <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header> {{'p60OptionValueSet.KeyText' | translate}} </mat-header-cell>
      <mat-cell fxFlex="25%" *matCellDef="let row"> {{row.keyText}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="valueText">
      <mat-header-cell fxFlex="75%" *matHeaderCellDef mat-sort-header> {{'p60OptionValueSet.ValueText' | translate}} </mat-header-cell>
      <mat-cell fxFlex="75%" *matCellDef="let row">{{row.valueText}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="100px">
        &nbsp;
      </mat-header-cell>
      <mat-cell *matCellDef="let option" fxFlex="70px" fxFlexOffset="30px">
        <button mat-icon-button matTooltip="{{'p60OptionValueSet.Edit' | translate}}" (click)="edit(option)">
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div>&nbsp;</div>
</div>
