<app-page-header title="P60Options" icon="widgets"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'commands.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="optionTitle">
        <mat-header-cell fxFlex="80%" *matHeaderCellDef mat-sort-header> {{'p60OptionList.Name' | translate}} </mat-header-cell>
        <mat-cell fxFlex="80%" *matCellDef="let row"> {{row.optionTitle}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="template">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header> {{'p60OptionList.Template' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row">
          <mat-icon *ngIf="row.template">check</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="download">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header> {{'p60OptionList.Download' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row">
          <button mat-icon-button matTooltip="{{'p60OptionList.Download' | translate}}" (click)="download(row)">
            <mat-icon>cloud_download</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="200px">
          <button mat-button color="primary" matTooltip="{{'p60OptionList.New' | translate}}" (click)="newOption()">
            <mat-icon>add_circle_outline</mat-icon>&nbsp;{{'p60OptionList.New' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let option" fxFlex="170px" fxFlexOffset="30px">
          <button mat-icon-button matTooltip="{{'p60OptionList.Copy' | translate}}" (click)="copy(option)">
            <mat-icon>file_copy</mat-icon>
          </button>
          <button *ngIf="option.companyId == companyId" mat-icon-button matTooltip="{{'p60OptionList.Edit' | translate}}" (click)="edit(option)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="option.companyId == companyId" mat-icon-button matTooltip="{{'p60OptionList.Delete' | translate}}" (click)="confirmDelete(option)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
  </div>
</div>
