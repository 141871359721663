// =============================
// Email: info@ebenmonney.com
// www.ebenmonney.com/templates
// =============================

import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule } from '@ngx-translate/core';

import { Assessment360ProMaterialModule } from '../modules/material.module';

import { PageHeaderComponent } from './page-header/page-header.component';
import { UserEditorComponent } from '../admin/user-editor/user-editor.component';
import { CompanyEditorComponent } from '../../app/components/companies/company-editor/company-editor.component';
import { AppDialogComponent } from './app-dialog/app-dialog.component';

import { GroupByPipe } from '../pipes/group-by.pipe';
import { NgDompurifyPipe } from '../pipes/ng-dompurify.pipe';

import { ChartsModule } from 'ng2-charts';
import { NguCarouselModule } from '@ngu/carousel';
import { Ng5SliderModule } from 'ng5-slider';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { StoreModule } from '@ngrx/store';
//import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
//import { AppEffects } from './app.effects';
import { reducers, metaReducers } from '../../app/store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { AppEffects } from '../../app/store/effects/app.effects';


import { HomeComponent } from '../../app/components/home/home.component';
import { CustomersComponent } from '../../app/components/customers/customers.component';
import { ProductsComponent } from '../../app/components/products/products.component';
import { OrdersComponent } from '../../app/components/orders/orders.component';
import { AboutComponent } from '../../app/components/about/about.component';
import { NotFoundComponent } from '../../app/components/not-found/not-found.component';

import { BannerDemoComponent } from '../../app/components/controls/banner-demo.component';
import { TodoDemoComponent } from '../../app/components/controls/todo-demo.component';
import { StatisticsDemoComponent } from '../../app/components/controls/statistics-demo.component';
import { NotificationsViewerComponent } from '../../app/components/controls/notifications-viewer.component';
import { AddTaskDialogComponent } from '../../app/components/controls/add-task-dialog.component';
import { CompaniesComponent } from '../../app/components/companies/companies.component';
import { CompanyViewDialogComponent } from '../../app/components/companies/company-view-dialog/company-view-dialog.component';
import { ProfileInitialDialogComponent } from '../../app/components/profile/profile-initial-dialog/profile-initial-dialog.component';
import { ProfileEditorComponent } from '../../app/components/profile/profile-editor/profile-editor.component';
import { CompanySelectorComponent } from '../../app/components/controls/company-selector/company-selector.component';
import { SendInvitationComponent } from '../../app/components/invitation/send-invitation/send-invitation.component';
import { ContactEditorControlComponent } from '../../app/components/invitation/controls/contact-editor-control/contact-editor-control.component';
import { ContactTableControlComponent } from '../../app/components/invitation/controls/contact-table-control/contact-table-control.component';
import { ContactEditorDialogComponent } from '../../app/components/invitation/dialogs/contact-editor-dialog/contact-editor-dialog.component';
import { InvitationsComponent } from '../../app/components/invitation/invitations/invitations.component';
import { ClaimInvitationComponent } from '../../app/components/invitation/claim-invitation/claim-invitation.component';
import { StartMaterialComponent } from '../../app/components/materials/start-material/start-material.component';
import { TaskListControlComponent } from '../../app/components/dashboard/controls/task-list-control/task-list-control.component';
import { TaskViewComponent } from '../../app/components/tasks/task-view/task-view.component';
import { TaskItemControlComponent } from '../../app/components/tasks/controls/task-item-control/task-item-control.component';
import { CampaignAnalysisComponent } from '../../app/components/campaign/campaign-analysis/campaign-analysis.component';
import { P60ResultControlComponent } from '../../app/components/profile/controls/p60-result-control/p60-result-control.component';
import { P60Report } from '../../app/components/reports/p60/p60-report/p60-report.component';
import { P60InviteComponent } from '../../app/components/invitation/dialogs/p60-invite/p60-invite.component';
import { RegistrationDialogComponent } from '../../app/components/registration/dialogs/registration-dialog/registration-dialog.component';
import { CompanyReportListControlComponent } from '../../app/components/companies/controls/company-report-list-control/company-report-list-control.component';
import { CompanyReportListComponent } from '../../app/components/companies/company-report-list/company-report-list.component';
import { CompanyP60BenchmarkSelectorControlComponent } from '../../app/components/companies/controls/company-p60-benchmark-selector-control/company-p60-benchmark-selector-control.component';
import { CompanyP60OptionSelectorControlComponent } from '../../app/components/companies/controls/company-p60-option-selector-control/company-p60-option-selector-control.component';
import { BenchmarkListComponent } from '../../app/components/companies/benchmark-list/benchmark-list.component';
import { BenchmarkSliderControlComponent } from '../../app/components/companies/controls/benchmark-slider-control/benchmark-slider-control.component';
import { BenchmarkDialogComponent } from '../../app/components/companies/dialogs/benchmark-dialog/benchmark-dialog.component';
import { BenchmarkNewDialogComponent } from '../../app/components/companies/dialogs/benchmark-new-dialog/benchmark-new-dialog.component';
import { P60OptionListComponent } from '../../app/components/companies/p60-option-list/p60-option-list.component';
import { P60OptionEditorDialogComponent } from '../../app/components/companies/dialogs/p60-option-editor-dialog/p60-option-editor-dialog.component';
import { KeyValueControlComponent } from '../../app/components/controls/key-value-control/key-value-control.component';
import { P60OptionValueSetControlComponent } from '../../app/components/companies/controls/p60-option-value-set-control/p60-option-value-set-control.component';
import { P60OptionValueEditorDialogComponent } from '../../app/components/companies/dialogs/p60-option-value-editor-dialog/p60-option-value-editor-dialog.component';
import { P60OptionEditorComponent } from '../../app/components/companies/p60-option-editor/p60-option-editor.component';
import { ProfileCardControlComponent } from '../../app/components/controls/profile-card-control/profile-card-control.component';
import { ProfileReportListControlComponent } from '../../app/components/profile/controls/profile-report-list-control/profile-report-list-control.component';
import { ProfileOpenInvitationsControlComponent } from '../../app/components/profile/controls/profile-open-invitations-control/profile-open-invitations-control.component';
import { UserUploadControlComponent } from '../../app/components/controls/user-upload-control/user-upload-control.component';
import { DragDropDirective } from '../../app/directives/drag-drop.directive';
import { UploadFileControlComponent } from '../../app/components/controls/upload-file-control/upload-file-control.component';
import { ContactUploadDialogComponent } from '../../app/components/dialogs/contact-upload-dialog/contact-upload-dialog.component';
import { IndividualsControlComponent } from '../../app/components/companies/controls/individuals-control/individuals-control.component';
import { CompanyIndividualsComponent } from '../../app/components/companies/company-individuals/company-individuals.component';
import { ImageUploadDialogComponent } from '../../app/components/dialogs/image-upload-dialog/image-upload-dialog.component';
import { ProfileViewControlComponent } from '../../app/components/profile/controls/profile-view-control/profile-view-control.component';
import { ProfileViewDialogComponent } from '../../app/components/profile/dialogs/profile-view-dialog/profile-view-dialog.component';
import { ProfileViewComponent } from '../../app/components/profile/profile-view/profile-view.component';
import { IndividualsSelectionControlComponent } from '../../app/components/companies/controls/individuals-selection-control/individuals-selection-control.component';
import { IndividualsSelectionDialogComponent } from '../../app/components/companies/dialogs/individuals-selection-dialog/individuals-selection-dialog.component';
import { CompanyTeamsComponent } from '../../app/components/companies/company-teams/company-teams.component';
import { TeamSelectionDialogComponent } from '../../app/components/companies/dialogs/team-selection-dialog/team-selection-dialog.component';
import { CompanyViewComponent } from '../../app/components/companies/company-view/company-view.component';
import { CompanyViewControlComponent } from '../../app/components/companies/controls/company-view-control/company-view-control.component';
import { CompanyCardControlComponent } from '../../app/components/controls/company-card-control/company-card-control.component';
import { CompanyCreditDialogComponent } from '../../app/components/companies/dialogs/company-credit-dialog/company-credit-dialog.component';
import { CompanyPrivilegedUsersControlComponent } from '../../app/components/companies/controls/company-privileged-users-control/company-privileged-users-control.component';
import { CompanyRoleAssignmentDialogComponent } from '../../app/components/companies/dialogs/company-role-assignment-dialog/company-role-assignment-dialog.component';
import { CompanyClientsControlComponent } from '../../app/components/companies/controls/company-clients-control/company-clients-control.component';
import { MaterialListControlComponent } from '../../app/components/materials/controls/material-list-control/material-list-control.component';
import { AssessmentsComponent } from '../../app/components/materials/assessments/assessments.component';
import { SurveysComponent } from '../../app/components/materials/surveys/surveys.component';
import { TestNewDialogComponent } from '../../app/components/materials/dialogs/test-new-dialog/test-new-dialog.component';
import { TestEditorComponent } from '../../app/components/materials/test-editor/test-editor.component';
import { MaterialHeaderControlComponent } from '../../app/components/materials/controls/material-header-control/material-header-control.component';
import { MaterialContentControlComponent } from '../../app/components/materials/controls/material-content-control/material-content-control.component';
import { RichTextDialogComponent } from '../../app/components/dialogs/rich-text-dialog/rich-text-dialog.component';
import { QuestionControlComponent } from '../../app/components/materials/controls/question-control/question-control.component';
import { AnswerControlComponent } from '../../app/components/materials/controls/answer-control/answer-control.component';
import { AnswerValueControlComponent } from '../../app/components/materials/controls/answer-value-control/answer-value-control.component';
import { AnswerViewControlComponent } from '../../app/components/materials/controls/answer-view-control/answer-view-control.component';
import { AnswerEditorDialogComponent } from '../../app/components/materials/dialogs/answer-editor-dialog/answer-editor-dialog.component';
import { MaterialsComponent } from '../../app/components/materials/materials/materials.component';
import { CourseListControlComponent } from '../../app/components/courses/controls/course-list-control/course-list-control.component';
import { CourseEditorComponent } from '../../app/components/courses/course-editor/course-editor.component';
import { CoursesComponent } from '../../app/components/courses/courses/courses.component';
import { CourseNewDialogComponent } from '../../app/components/courses/dialogs/course-new-dialog/course-new-dialog.component';
import { CourseHeaderControlComponent } from '../../app/components/courses/controls/course-header-control/course-header-control.component';
import { CourseMaterialControlComponent } from '../../app/components/courses/controls/course-material-control/course-material-control.component';
import { CampaignReportsControlComponent } from '../components/campaign/controls/campaign-reports-control/campaign-reports-control.component';
import { LegacyClientsComponent } from '../components/legacy/legacy-clients/legacy-clients.component';
import { AptitudesComponent } from '../components/materials/aptitudes/aptitudes.component';
import { MaterialShareDialogComponent } from '../components/materials/dialogs/material-share-dialog/material-share-dialog.component';
import { CompanyUsageControlComponent } from '../components/companies/controls/company-usage-control/company-usage-control.component';


@NgModule({
  imports: [
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule,
    Assessment360ProMaterialModule,
    TranslateModule,
    ChartsModule,
    NguCarouselModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300
    }),
    Ng5SliderModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects]),
    //StoreModule.forRoot(reducers, {
    //  metaReducers
    //  //, 
    //  //runtimeChecks: {
    //  //  strictStateImmutability: true,
    //  //  strictActionImmutability: true,
    //  //}
    //}),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CKEditorModule
  ],
  exports: [
    FlexLayoutModule,
    FormsModule, ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule,
    Assessment360ProMaterialModule,
    TranslateModule,
    PageHeaderComponent,
    GroupByPipe,
    NgDompurifyPipe,
    UserEditorComponent,
    CompanyEditorComponent,
    AppDialogComponent
  ],
  declarations: [
    PageHeaderComponent,
    GroupByPipe,
    NgDompurifyPipe,
    UserEditorComponent,
    CompanyEditorComponent,
    AppDialogComponent,


    CustomersComponent,
    ProductsComponent,
    OrdersComponent,
    AboutComponent,
    NotFoundComponent,
    NotificationsViewerComponent,
    AddTaskDialogComponent,
    StatisticsDemoComponent, TodoDemoComponent, BannerDemoComponent,
    HomeComponent,
    CompaniesComponent,
    CompanyViewDialogComponent,
    ProfileInitialDialogComponent,
    ProfileEditorComponent,
    SendInvitationComponent,
    ContactEditorControlComponent,
    ContactTableControlComponent,
    ContactEditorDialogComponent,
    InvitationsComponent,
    ClaimInvitationComponent,
    StartMaterialComponent,
    TaskListControlComponent,
    TaskViewComponent,
    TaskItemControlComponent,
    CampaignAnalysisComponent,
    P60ResultControlComponent,
    P60Report,
    P60InviteComponent,
    RegistrationDialogComponent,
    CompanyReportListControlComponent,
    CompanyReportListComponent,
    CompanyP60BenchmarkSelectorControlComponent,
    CompanyP60OptionSelectorControlComponent,
    BenchmarkListComponent,
    BenchmarkSliderControlComponent,
    BenchmarkDialogComponent,
    BenchmarkNewDialogComponent,
    P60OptionListComponent,
    P60OptionEditorDialogComponent,
    KeyValueControlComponent,
    P60OptionValueSetControlComponent,
    P60OptionValueEditorDialogComponent,
    P60OptionEditorComponent,
    ProfileCardControlComponent,
    ProfileReportListControlComponent,
    ProfileOpenInvitationsControlComponent,
    UserUploadControlComponent,
    DragDropDirective,
    UploadFileControlComponent,
    ContactUploadDialogComponent,
    IndividualsControlComponent,
    CompanyIndividualsComponent,
    ImageUploadDialogComponent,
    ProfileViewControlComponent,
    ProfileViewDialogComponent,
    ProfileViewComponent,
    IndividualsSelectionControlComponent,
    IndividualsSelectionDialogComponent,
    CompanyTeamsComponent,
    TeamSelectionDialogComponent,
    CompanyViewComponent,
    CompanyViewControlComponent,
    CompanyCardControlComponent,
    CompanyCreditDialogComponent,
    CompanyPrivilegedUsersControlComponent,
    CompanyRoleAssignmentDialogComponent,
    CompanyClientsControlComponent,
    MaterialListControlComponent,
    AssessmentsComponent,
    SurveysComponent,
    TestNewDialogComponent,
    TestEditorComponent,
    MaterialHeaderControlComponent,
    MaterialContentControlComponent,
    RichTextDialogComponent,
    QuestionControlComponent,
    AnswerControlComponent,
    AnswerValueControlComponent,
    AnswerViewControlComponent,
    AnswerEditorDialogComponent,
    MaterialsComponent,
    CourseListControlComponent,
    CourseEditorComponent,
    CoursesComponent,
    CourseNewDialogComponent,
    CourseHeaderControlComponent,
    CourseMaterialControlComponent,
    CampaignReportsControlComponent,
    LegacyClientsComponent,
    AptitudesComponent,
    MaterialShareDialogComponent,
    CompanyUsageControlComponent
  ],
  entryComponents: [
    AppDialogComponent,
    AddTaskDialogComponent,
    CompanyViewDialogComponent,
    ProfileInitialDialogComponent,
    ContactEditorDialogComponent,
    P60InviteComponent,
    RegistrationDialogComponent,
    BenchmarkDialogComponent,
    BenchmarkNewDialogComponent,
    P60OptionEditorDialogComponent,
    P60OptionValueEditorDialogComponent,
    ContactUploadDialogComponent,
    ImageUploadDialogComponent,
    IndividualsSelectionDialogComponent,
    TeamSelectionDialogComponent,
    CompanyCreditDialogComponent,
    CompanyRoleAssignmentDialogComponent,
    TestNewDialogComponent,
    RichTextDialogComponent,
    AnswerEditorDialogComponent,
    CourseNewDialogComponent,
    MaterialShareDialogComponent
  ]
})
export class SharedModule {

}
