<div class="search-box" fxLayout="row" fxLayout.lt-sm="column">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'clientsControl.Search' | translate}}">
  </mat-form-field>
  <div fxFlex="auto" fxHide.lt-sm></div>
  <div *ngIf="canEditClients" fxLayoutGap="8px" fxLayout="column">
    <div fxFlex="auto" fxHide.lt-sm></div>
    <button mat-raised-button matTooltip="{{'clientsControl.NewClient' | translate}}" (click)="newClient()">
      <mat-icon>add_circle_outline</mat-icon>&nbsp;&nbsp;{{'clientsControl.NewClient' | translate}}
    </button>
    <div fxFlex="auto"></div>
  </div>
</div>

<div class="simple-border">
  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="companyName">
      <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'clientsControl.Client' | translate}} </mat-header-cell>
      <mat-cell fxFlex="40%" fxFlex.lt-sm="100%" *matCellDef="let row">
        <div fxFlex="100%" fxLayout="row" fxLayoutGap="4px">
          <div fxFlex="50%" fxFlex.gt-xs="100%" fxLayout="column" fxLayoutGap="4px">
            <div>{{row.companyName}}</div>
            <div fxHide.gt-xs class="contact-info"><a href="{{telLink(row.contactPhoneNumber)}}">{{row.contactPhoneNumber}}</a></div>
          </div>
          <div fxFlex="50%" fxHide.gt-xs fxLayout="column" fxLayoutGap="4px">
            <div>{{row.contactName}}</div>
            <div fxHide.gt-xs class="contact-info"><a href="{{mailLink(row.contactEmailAddress)}}">{{row.contactEmailAddress}}</a></div>
          </div>
        </div>
        
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="contactName">
      <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header fxHide.lt-sm> {{'clientsControl.Contact' | translate}} </mat-header-cell>
      <mat-cell fxFlex="25%" *matCellDef="let row" fxHide.lt-sm> {{row.contactName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="contactEmailAddress">
      <mat-header-cell fxFlex="35%" *matHeaderCellDef mat-sort-header fxHide.lt-sm> {{'clientsControl.Phone' | translate}}/{{'clientsControl.Email' | translate}} </mat-header-cell>
      <mat-cell fxFlex="35%" *matCellDef="let row" fxHide.lt-sm class="contact-info">
        <div fxLayout="column">
          <a href="{{telLink(row.contactPhoneNumber)}}">{{row.contactPhoneNumber}}</a>
          <a href="{{mailLink(row.contactEmailAddress)}}">{{row.contactEmailAddress}}</a>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.xs="40px" fxFlexOffset="30px" fxFlexOffset.xs="2px">

      </mat-header-cell>
      <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.xs="40px" fxFlexOffset="30px" fxFlexOffset.xs="2px">
        <button mat-icon-button matTooltip="{{'clientsControl.Display' | translate}}" (click)="launch(entry)">
          <mat-icon>visibility</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
