import { IAuditableEntity } from "./IAuditableEntry.interface";
import { Material } from "./material.model";

export class CourseMaterial implements IAuditableEntity {
  public courseId: number;
  public materialId: number;
  public createdDate: Date;
  public createdBy: string;
  public updatedDate: Date;
  public updatedBy: string;
  public ordinal: number;

  public material: Material;
}
