import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MaterialListControlComponent } from '../controls/material-list-control/material-list-control.component';
import { fadeInOut } from '../../../services/animations';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-aptitudes',
  templateUrl: './aptitudes.component.html',
  styleUrls: ['./aptitudes.component.css'],
  animations: [fadeInOut]
})
export class AptitudesComponent implements OnInit, AfterViewInit {
  @ViewChild(MaterialListControlComponent) list: MaterialListControlComponent;

  companyId: number;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.list.companyIdFound(this.companyId);
  }

  parseParams(params: Params) {
    this.companyId = this.authService.companyId;

    if (params)
      if (params['companyId'])
        this.companyId = params['companyId'];
  }
}
