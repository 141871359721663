import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { EndpointFactory } from './endpoint-factory.service';
import { ConfigurationService } from './configuration.service';
import { InvitationSender } from '../models/invitation-sender.model';
import { TaskItemProgress } from '../models/task-item-progress.model';
import { CampaignBuilder } from '../models/campaign-builder.model';
import { P60benchmark } from '../models/p60benchmark';
import { P60option } from '../models/p60option.model';
import { P60optionValue } from '../models/p60option-value.model';
import { Team } from '../models/team.model';
import { Material } from '../models/material.model';
import { Course } from '../models/course.model';
import { KeyValueObject } from '../models/key-value-object.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationEndpoint extends EndpointFactory {
  private readonly _campaignsUrl: string = '/api/campaigns';
  private readonly _companiesUrl: string = '/api/companies';
  private readonly _coursesUrl: string = '/api/courses';
  private readonly _invitationsUrl: string = '/api/invitations';
  private readonly _materialsUrl: string = '/api/materials';
  private readonly _tasksUrl: string = '/api/tasks';
  private readonly _usersUrl: string = '/api/users';
  private readonly _xrUrl: string = '/api/xr';
  private readonly _legacyUrl: string = '/api/legacy';

  get campaignsUrl() { return this.configurations.baseUrl + this._campaignsUrl; }
  get companiesUrl() { return this.configurations.baseUrl + this._companiesUrl; }
  get coursesUrl() { return this.configurations.baseUrl + this._coursesUrl; }
  get invitationsUrl() { return this.configurations.baseUrl + this._invitationsUrl; }
  get materialsUrl() { return this.configurations.baseUrl + this._materialsUrl; }
  get tasksUrl() { return this.configurations.baseUrl + this._tasksUrl; }
  get usersUrl() { return this.configurations.baseUrl + this._usersUrl; }
  get xrUrl() { return this.configurations.baseUrl + this._xrUrl; }
  get legacyUrl() { return this.configurations.baseUrl + this._legacyUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {
    super(http, configurations, injector);
  }

  // #region Companies
  getCompaniesEndpoint<T>(): Observable<T> {
    const endpointUrl = this.companiesUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompaniesEndpoint());
      }));
  }

  getCompanyEndpoint<T>(companyId: number, includeCredits: boolean = false): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/${companyId}/${includeCredits}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyEndpoint(companyId, includeCredits));
      }));
  }

  getNewCompanyEndpoint<T>(company: any): Observable<T> {
    const endpointUrl = this.companiesUrl;

    return this.http.post<T>(endpointUrl, JSON.stringify(company), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCompanyEndpoint(company));
      }));
  }

  getUpdateCompanyEndpoint<T>(company: any, companyId?: number): Observable<T> {
    const endpointUrl = companyId >= 0 ? `${this.companiesUrl}/${companyId}` : this.companiesUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(company), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateCompanyEndpoint(company, companyId));
      }));
  }

  getDeleteCompanyEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/${companyId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteCompanyEndpoint(companyId));
      }));
  }

  getCompanyReportsEndpoint<T>(companyId: number, days?: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/getreports/${companyId}/${days == null ? 0 : days}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyReportsEndpoint(companyId, days));
      }));
  }

  putReleaseReportEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/releasereport/${id}`;

    return this.http.put<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putReleaseReportEndpoint(id));
      }));
  }

  getP60BenchmarksEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/getp60benchmarks/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getP60BenchmarksEndpoint(companyId));
      }));
  }

  getP60OptionsEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/getp60options/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getP60OptionsEndpoint(companyId));
      }));
  }

  getP60OptionEndpoint<T>(p60OptionId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/getp60option/${p60OptionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getP60OptionEndpoint(p60OptionId));
      }));
  }
  
  patchRecordBenchmarkEndpoint<T>(patch: P60benchmark): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/recordbenchmark`;

    return this.http.patch<T>(endpointUrl, patch, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchRecordBenchmarkEndpoint(patch));
      }));
  }

  patchRecordP60OptionEndpoint<T>(patch: P60option): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/recordp60option`;

    return this.http.patch<T>(endpointUrl, patch, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchRecordP60OptionEndpoint(patch));
      }));
  }

  patchRecordP60OptionValueEndpoint<T>(patch: P60optionValue): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/recordp60optionvalue`;

    return this.http.patch<T>(endpointUrl, patch, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchRecordP60OptionValueEndpoint(patch));
      }));
  }

  deleteBenchmarkEndpoint<T>(benchmarkId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/deletebenchmark/${benchmarkId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteBenchmarkEndpoint(benchmarkId));
      }));
  }

  deleteP60OptionEndpoint<T>(p60OptionId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/deletep60option/${p60OptionId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteP60OptionEndpoint(p60OptionId));
      }));
  }

  deleteP60OptionValueEndpoint<T>(p60OptionValueId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/deletep60optionvalue/${p60OptionValueId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteP60OptionValueEndpoint(p60OptionValueId));
      }));
  }

  copyP60OptionValuesEndpoint<T>(fromId: number, toId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/copyp60optionvalues/${fromId}/${toId}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.copyP60OptionValuesEndpoint(fromId, toId));
      }));
  }

  getUsersByCompany<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getusersbycompany/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUsersByCompany(companyId));
      }));

  }

  addCreditsEndpoint<T>(companyId: number, credits: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/addcredits/${companyId}/${credits}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.addCreditsEndpoint(companyId, credits));
      }));
  }

  setUnlimitedCreditStatusEndpoint<T>(companyId: number, unlimited: boolean) {
    const endpointUrl = `${this.companiesUrl}/setunlimitedcreditstatus/${companyId}/${unlimited}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.setUnlimitedCreditStatusEndpoint(companyId, unlimited));
      }));   
  }

  getClientCompaniesEndpoint<T>(companyId: number) {
    const endpointUrl = `${this.companiesUrl}/getclientcompanies/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getClientCompaniesEndpoint(companyId));
      }));  
  }

  connectCompanyEndpoint<T>(companyId: number, clientCompanyId: number, partner: boolean = false): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/connectcompany/${companyId}/${clientCompanyId}${partner ? "/" + partner : ""}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.connectCompanyEndpoint(companyId, clientCompanyId, partner));
      }));
  }

  disconnectCompanyEndpoint<T>(companyId: number, clientCompanyId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/disconnectcompany/${companyId}/${clientCompanyId}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.disconnectCompanyEndpoint(companyId, clientCompanyId));
      }));
  }
   
  privilegedUsers<T>(companyId: number) {
    const endpointUrl = `${this.companiesUrl}/privilegedusers/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.privilegedUsers(companyId));
      }));
  }

  getCreditsByCompany<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/credits/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCreditsByCompany(companyId));
      }));

  }

  getCompanyUsage<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/usage/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyUsage(companyId));
      }));

  }

  // #endregion

  // #region Teams
  getTeamsByCompanyEndpoint<T>(companyId: number, includeUsers: boolean): Observable<T> {
    const endpointUrl = `${this.companiesUrl}/teams/${companyId}/${includeUsers}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTeamsByCompanyEndpoint(companyId, includeUsers));
      }));
  }

  getTeamEndpoint<T>(teamId: number, includeUsers: boolean) {
    const endpointUrl = `${this.companiesUrl}/team/${teamId}/${includeUsers}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTeamEndpoint(teamId, includeUsers));
      }));
  }

  postTeamEndpoint<T>(team: Team) {
    const endpointUrl = `${this.companiesUrl}/team`;

    return this.http.post<T>(endpointUrl, JSON.stringify(team), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postTeamEndpoint(team));
      }));
  }

  putTeamEndpoint<T>(team: Team) {
    const endpointUrl = `${this.companiesUrl}/team`;

    return this.http.put<T>(endpointUrl, JSON.stringify(team), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putTeamEndpoint(team));
      }));
  }

  deleteTeamEndpoint<T>(teamId: number) {
    const endpointUrl = `${this.companiesUrl}/team/${teamId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteTeamEndpoint(teamId));
      }));
  }

  connectUserTeamEndpoint<T>(userId: number, teamId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/connectuserteam/${userId}/${teamId}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.connectUserTeamEndpoint(userId, teamId));
      }));
  }

  disconnectUserTeamEndpoint<T>(userId: number, teamId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/disconnectuserteam/${userId}/${teamId}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.disconnectUserTeamEndpoint(userId, teamId));
      }));
  }
  // #endregion

  // #region Profiles (Users)
  getUsersEndpoint<T>(): Observable<T> {
    const endpointUrl = this.usersUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUsersEndpoint());
      }));
  }

  getUserByIdEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/${userId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserByIdEndpoint(userId));
      }));
  }

  getP60ResultEndpoint<T>(userId: number, companyId: number, language: string, userTestId?: number, benchmarkId?: number, optionId?: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getp60result/${userId}/${companyId}/${language}${userTestId == null ? "" : "/" + userTestId}${benchmarkId == null ? "" : "/" + benchmarkId}${optionId == null ? "" : "/" + optionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getP60ResultEndpoint(userId, companyId, language, userTestId, benchmarkId, optionId));
      }));
  }

  getP60DownloadEndpoint<T>(userId: number, companyId: number, language: string, userTestId?: number, benchmarkId?: number, optionId?: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getp60download/${userId}/${companyId}/${language}${userTestId == null ? "" : "/" + userTestId}${benchmarkId == null ? "" : "/" + benchmarkId}${optionId == null ? "" : "/" + optionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getP60DownloadEndpoint(userId, companyId, language, userTestId, benchmarkId, optionId));
      }));
  }

  getIQResultEndpoint<T>(userId: number, companyId: number, language: string, userTestId?: number, optionId?: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getiqresult/${userId}/${companyId}/${language}${userTestId == null ? "" : "/" + userTestId}${optionId == null ? "" : "/" + optionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getIQResultEndpoint(userId, companyId, language, userTestId, optionId));
      }));
  }

  getIQDownloadEndpoint<T>(userId: number, companyId: number, language: string, userTestId?: number, optionId?: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getiqdownload/${userId}/${companyId}/${language}${userTestId == null ? "" : "/" + userTestId}${optionId == null ? "" : "/" + optionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getIQDownloadEndpoint(userId, companyId, language, userTestId, optionId));
      }));
  }

  getCTResultEndpoint<T>(userId: number, companyId: number, language: string, userTestId: number, optionId?: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getctresult/${userId}/${companyId}/${language}/${userTestId}${optionId == null ? "" : "/" + optionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCTResultEndpoint(userId, companyId, language, userTestId, optionId));
      }));
  }

  getCTDownloadEndpoint<T>(userId: number, companyId: number, language: string, userTestId: number, optionId?: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getctdownload/${userId}/${companyId}/${language}/${userTestId}${optionId == null ? "" : "/" + optionId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCTDownloadEndpoint(userId, companyId, language, userTestId, optionId));
      }));
  }

  getCSResultEndpoint<T>(userId: number, companyId: number, language: string, userTestId: number, optionId?: number, summary?: boolean): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getcsresult/${userId}/${companyId}/${language}/${userTestId}${optionId == null ? "/0" : "/" + optionId}${summary == null ? "/false" : "/" + summary}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCSResultEndpoint(userId, companyId, language, userTestId, optionId, summary));
      }));
  }

  getCSDownloadEndpoint<T>(userId: number, companyId: number, language: string, userTestId: number, optionId?: number, summary?: boolean): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getcsdownload/${userId}/${companyId}/${language}/${userTestId}${optionId == null ? "/0" : "/" + optionId}${summary == null ? "/false" : "/" + summary}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCSDownloadEndpoint(userId, companyId, language, userTestId, optionId, summary));
      }));
  }

  getUserByEmailEndpoint<T>(email: string): Observable<T> {
    const endpointUrl = `${this.usersUrl}/findbyemail/${email.replace("@", "%40")}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserByEmailEndpoint(email));
      }));
  }

  getNewUserEndpoint<T>(user: any): Observable<T> {
    const endpointUrl = this.usersUrl;

    return this.http.post<T>(endpointUrl, JSON.stringify(user), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewUserEndpoint(user));
      }));
  }

  getUpdateUserEndpoint<T>(user: any, userId?: number): Observable<T> {
    const endpointUrl = userId ? `${this.usersUrl}/${userId}` : this.usersUrl;

    return this.http.put<T>(endpointUrl, JSON.stringify(user), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateUserEndpoint(user, userId));
      }));
  }

  getDeleteUserEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/${userId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteUserEndpoint(userId));
      }));
  }

  getConnectUserCompanyEndpoint<T>(userId: number, companyId: number, role: string = ""): Observable<T> {
    const endpointUrl = `${this.usersUrl}/connectusercompany/${userId}/${companyId}${role != "" ? "/" + role : ""}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getConnectUserCompanyEndpoint(userId, companyId, role));
      }));
  }

  getDisconnectUserCompanyEndpoint<T>(userId: number, companyId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/disconnectusercompany/${userId}/${companyId}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDisconnectUserCompanyEndpoint(userId, companyId));
      }));
  }
   
  getUserReportsEndpoint<T>(userId: number, days?: number, all?: boolean): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getreports/${userId}/${days == null ? 0 : days}/${all == null ? false : all}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUserReportsEndpoint(userId, days, all));
      }));
  }

  getOpenInvitationsEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/getopeninvitations/${userId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getOpenInvitationsEndpoint(userId));
      }));
  }

  companyRoleEndpoint<T>(userId: number, companyId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/companyrole/${userId}/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.companyRoleEndpoint(userId, companyId));
      }));
  }

  companySelectionEndpoint<T>(userId: number): Observable<T> {
    const endpointUrl = `${this.usersUrl}/companyselection/${userId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.companySelectionEndpoint(userId));
      }));
  }

  // #endregion

  // #region XR
  getXrGendersEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.xrUrl}/genders`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getXrGendersEndpoint());
      }));
  }

  getXrAnswerTypesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.xrUrl}/answertypes`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getXrAnswerTypesEndpoint());
      }));
  }

  getXrAnswerTypeEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.xrUrl}/answertype/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getXrAnswerTypeEndpoint(id));
      }));
  }

  getXrLanguageCodesEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.xrUrl}/languagecodes`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getXrLanguageCodesEndpoint());
      }));
  }
  // #endregion

  // #region Invitations
  sendInvitations<T>(info: InvitationSender): Observable<T> {
    const endpointUrl = `${this.invitationsUrl}/sendinvite`;

    info.campaignId = info.campaignId | 0;
    info.courseId = info.courseId | 0;
    info.materialId = info.materialId | 0;

    return this.http.post<T>(endpointUrl, JSON.stringify(info), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.sendInvitations(info));
      }));
  }

  checkInvitationClaim<T>(code: string): Observable<T> {
    const endpointUrl = `${this.invitationsUrl}/checkclaim/${code}`;

    return this.http.get<T>(endpointUrl, this.getRegisterHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.checkInvitationClaim(code));
      })
    );
  }

  claimInvitation<T>(userId: number, code: string): Observable<T> {
    const endpointUrl = `${this.invitationsUrl}/claim/${userId}/${code}`;

    return this.http.patch<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.claimInvitation(userId, code));
      })
    );
  }
// #endregion

  //#region Courses
  getCoursesByCompanyIdEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.coursesUrl}/bycompanyid/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCoursesByCompanyIdEndpoint(companyId));
      }));
  }
  
  getCourseEndpoint<T>(id: number, includeall: boolean = false): Observable<T> {
    const endpointUrl = `${this.coursesUrl}/${id}/${includeall}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCourseEndpoint(id, includeall));
      }));
  }

  getCoursesEndpoint<T>(companyId: number, designation: string = ""): Observable<T> {
    const endpointUrl = `${this.coursesUrl}/courses/${companyId}${designation ? "/" + designation : ""}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCoursesEndpoint(companyId, designation));
      }));
  }

  copyCourseEndpoint<T>(courseId: number, toCompanyId: number = -1) {
    const endpointUrl = `${this.coursesUrl}/copy/${courseId}/${toCompanyId}`;

    return this.http.post<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.copyCourseEndpoint(courseId, toCompanyId));
      }));
  }

  deleteCourseEndpoint<T>(courseId: number): Observable<T> {
    const endpointUrl = `${this.coursesUrl}/${courseId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteCourseEndpoint(courseId));
      }));
  }

  createCourseEndpoint<T>(designation: string, course: Course): Observable<T> {
    const endpointUrl = `${this.coursesUrl}/createcourse/${designation}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(course), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.createCourseEndpoint(designation, course));
      }));
  }

  patchCourseEndpoint<T>(course: Course): Observable<T> {
    const endpointUrl = `${this.coursesUrl}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(course), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchCourseEndpoint(course));
      }));
  }
  //#endregion

  //#region Materials
  getMaterialsByCompanyIdEndpoint<T>(companyId: number): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/bycompanyid/${companyId}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMaterialsByCompanyIdEndpoint(companyId));
      }));
  }

  getMaterialEndpoint<T>(id: number, includeall: boolean = false): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/${id}/${includeall}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMaterialEndpoint(id, includeall));
      }));
  }

  getMaterialTestsEndpoint<T>(companyId: number, designation: string = ""): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/tests/${companyId}${designation ? "/" + designation : ""}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMaterialTestsEndpoint(companyId, designation));
      }));
  }

  getMaterialMaterialsEndpoint<T>(companyId: number, designation: string = ""): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/materials/${companyId}${designation ? "/" + designation : ""}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMaterialMaterialsEndpoint(companyId, designation));
      }));
  }

  copyMaterialEndpoint<T>(materialId: number, toCompanyId: number = -1) {
    const endpointUrl = `${this.materialsUrl}/copy/${materialId}/${toCompanyId}`;

    return this.http.post<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.copyMaterialEndpoint(materialId, toCompanyId));
      }));
  }

  deleteMaterialEndpoint<T>(materialId: number): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/${materialId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteMaterialEndpoint(materialId));
      }));
  }

  createTestEndpoint<T>(designation: string, material: Material): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/createtest/${designation}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(material), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.createTestEndpoint(designation, material));
      }));
  }

  createMaterialEndpoint<T>(designation: string, material: Material): Observable<T> {
    const endpointUrl = `${this.materialsUrl}/creatematerial/${designation}`;

    return this.http.post<T>(endpointUrl, JSON.stringify(material), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.createMaterialEndpoint(designation, material));
      }));
  }

  patchMaterialEndpoint<T>(material: Material): Observable<T> {
    const endpointUrl = `${this.materialsUrl}`;

    return this.http.patch<T>(endpointUrl, JSON.stringify(material), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchMaterialEndpoint(material));
      }));
  }
  //#endregion

  //#region Tasks
  getTasksForUserEndpoint<T>(id: number): Observable<T> {
    const endpointUrl = `${this.tasksUrl}/user/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTasksForUserEndpoint(id));
      }));
  }

  getTask<T>(id: number): Observable<T> {
    const endpointUrl = `${this.tasksUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTask(id));
      }));
  }

  getTaskItem<T>(id: number): Observable<T> {
    const endpointUrl = `${this.tasksUrl}/item/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getTaskItem(id));
      }));   
  }

  patchRecordTaskItemProgress<T>(patch: TaskItemProgress): Observable<T> {
    const endpointUrl = `${this.tasksUrl}/recordtaskitemprogress`;

    return this.http.patch<T>(endpointUrl, patch, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchRecordTaskItemProgress(patch));
      })); 
  }
  //#endregion

  //#region Campaign
  buildCampaign<T>(info: CampaignBuilder): Observable<T> {
    const endpointUrl = `${this.campaignsUrl}/buildcampaign`;

    return this.http.post<T>(endpointUrl, JSON.stringify(info), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.buildCampaign(info));
      }));
  };
  analyzeCampaign<T>(id: number): Observable<T> {
    const endpointUrl = `${this.campaignsUrl}/analyzecampaign/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.analyzeCampaign(id));
      }));
  }
  getCompanyCampaigns<T>(id: number): Observable<T> {
    const endpointUrl = `${this.campaignsUrl}/getcompanycampaigns/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyCampaigns(id));
      }));
  }

  getCampaignReportsEndpoint<T>(campaignId: number, days?: number): Observable<T> {
    const endpointUrl = `${this.campaignsUrl}/getreports/${campaignId}/${days == null ? 0 : days}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCampaignReportsEndpoint(campaignId, days));
      }));
  }
  //#endregion

  //#region Legacy
  getLegacyClientsEndpoint<T>(): Observable<T> {
    const endpointUrl = `${this.legacyUrl}/getclients`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getLegacyClientsEndpoint());
      }));
  }
  patchTransferLegacyClientEndpoint<T>(kvo: KeyValueObject): Observable<T> {
    const endpointUrl = `${this.legacyUrl}/transferclient`;

    return this.http.patch<T>(endpointUrl, kvo, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.patchTransferLegacyClientEndpoint(kvo));
      }));
  } 
  //#endregion
}
