import { Injectable } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { KeyValueObject } from '../models/key-value-object.model';
import { Company } from '../models/company';

@Injectable({
  providedIn: 'root'
})
export class LegacyService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint
  ) { }

  getClients() {
    return this.applicationEndpoint.getLegacyClientsEndpoint<KeyValueObject[]>();
  }

  transferClient(kvo: KeyValueObject) {
    return this.applicationEndpoint.patchTransferLegacyClientEndpoint<Company>(kvo);
  }
}
