import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { UploadFileControlComponent } from '../../controls/upload-file-control/upload-file-control.component';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-image-upload-dialog',
  templateUrl: './image-upload-dialog.component.html',
  styleUrls: ['./image-upload-dialog.component.scss']
})
export class ImageUploadDialogComponent implements OnInit {

  @ViewChild('imageUpload') imageUpload: UploadFileControlComponent;

  imageBase64Selected: EventEmitter<string> = new EventEmitter<string>();

  image: string;

  constructor(
    public dialogRef: MatDialogRef<ImageUploadDialogComponent>
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.imageUpload.onBase64ImageReady.subscribe(results => { this.ready(results); });
  }


  ready(event) {
    this.image = event;
  }

  import() {
    this.imageBase64Selected.emit(this.image);
    this.dialogRef.close(this.image);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
