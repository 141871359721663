import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { LanguageManager } from './language-manager';
import { ConfigurationService } from '../../services/configuration.service';
import { AppLanguage } from '../../models/AppLanguage';
import { CommonModule } from '@angular/common';

import { MatButtonModule, MatListModule, MatIconModule, MatMenuModule, MatTooltipModule } from '@angular/material';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {'aria-hidden': 'true'}
})
export class LanguagePicker implements OnInit {

  constructor(
    public languageManager: LanguageManager,
    private configuration: ConfigurationService
  ) {
    configuration.configurationImported$.subscribe(() => this.setLanguage(this.currentLanguage));
    //this.setLanguage(this.currentLanguage);
  }

  ngOnInit() {
  }

  get currentLanguage(): AppLanguage {
    return this.languageManager.getLanguageByLocale(this.configuration.language);
  }

  setLanguage(language: AppLanguage) {
    if (language) {
      this.languageManager.installLanguage(language);
      this.configuration.language = language.locale;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatTooltipModule,
  ],
  exports: [LanguagePicker],
  declarations: [LanguagePicker],
  providers: [LanguageManager, ConfigurationService],
})
export class LanguagePickerModule { }
