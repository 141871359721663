import { InviteeProgress } from "./invitee-progress.model";
import { Campaign } from "./campaign.model";

export class CampaignAnalysis {
  public campaign: Campaign;
  public campaignId: number;
  public participation: number;
  public overallProgress: number;
  public inviteeCount: number;
  public invitationsClaimed: number;
  public daysInProgress: number;
  public daysToGo: number;
  public invitees: InviteeProgress[];
}
