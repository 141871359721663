<h1 mat-dialog-title>{{'users.management.ResetPassword' | translate:userName}}</h1>
<div mat-dialog-content>
  <form #form="ngForm" [formGroup]="passwordResetForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'users.editor.CurrentPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="current_password" autocomplete="off" />
        <mat-error *ngIf="currentPassword.hasError('required')">
          {{'users.editor.CurrentPasswordRequired' | translate}}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'users.editor.NewPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="new_password" autocomplete="off" />
        <mat-error *ngIf="newPassword.hasError('required')">
          {{'users.editor.NewPasswordRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="!newPassword.hasError('required') && newPassword.hasError('pattern')">
          {{'users.editor.NewPasswordRules' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'users.editor.ConfirmPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="confirm_password" />
        <mat-error *ngIf="confirmPassword.hasError('required')">
          {{'users.editor.ConfirmationPasswordRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="!confirmPassword.hasError('required') && confirmPassword.hasError('notEqual')">
          {{'users.editor.PasswordMismatch' | translate}}
        </mat-error>
      </mat-form-field>
    </div>
    <div fxFlex="auto">&nbsp;<br />&nbsp;</div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="save()" color="primary">{{'users.editor.Reset' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'users.editor.Cancel' | translate}}</button>
</div>
