import { Component, OnInit } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { Material } from 'src/app/models/material.model';
import { MaterialsService } from 'src/app/services/materials.service';
import { AlertService } from 'src/app/services/alert.service';
import { MatDialog } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { Router, Params, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-start-material',
  templateUrl: './start-material.component.html',
  styleUrls: ['./start-material.component.scss'],
  animations: [fadeInOut]
})
export class StartMaterialComponent implements OnInit {

  materialId: number;
  material: Material;

  get materialName() {
    if (!this.material)
      return '';

    return this.material.materialName;
  }
  
  get intro() {
    if (!this.material)
      return '';

    return this.material.intro;
  }

  constructor(
    private route: ActivatedRoute,
    private materialService: MaterialsService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) {
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  ngOnInit() {
    this.materialService.getMaterial(this.materialId).subscribe(result => {
      this.parseMaterial(result);
    });
  }

  parseParams(params: Params) {
    this.materialId = params['materialId'];
  }

  parseMaterial(material: Material) {
    this.material = material;
  }

}
