<div fxLayout="row">
  <app-page-header title="Teams" icon="people_outline"></app-page-header>
  <div fxFlex="auto"></div>
  <div class="header-button-container">
    <button mat-raised-button (click)="newTeam();" color="primary">{{'companyTeams.NewTeam' | translate}}</button>
  </div>
</div>
<div [@fadeInOut] class="page-content">
  <div>
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let team of teams" class="simple-border" expanded="expandedTeamId === {{team.teamId}}" (opened)="setExpandedTeamId(team.teamId)">
        <mat-expansion-panel-header color="primary">
          <mat-panel-title>
            {{team.teamName}}<div fxFlex="auto"></div>({{team.userTeam.length}} {{ (team.userTeam.length == 1 ? 'companyTeams.Member' : 'companyTeams.Members') | translate }})
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-card fxLayout="column">
          <div fxLayout="row" fxLayout.lt-lg="column" fxFlex="auto">
            <mat-form-field [floatLabel]="always" fxFlex="50%">
              <mat-label>{{'companyTeams.TeamName' | translate}}</mat-label>
              <input matInput [(ngModel)]="team.teamName" (blur)="updateTeam(team)" />
            </mat-form-field>
            <div fxFlex="auto" fxHide.lt-lg></div>
            <div fxLayout="column">
              <button mat-raised-button (click)="compose(team);" color="default" fxFlex.lt-lg="auto">{{'companyTeams.Compose' | translate}}</button>
              <div fxFlex="auto" fxHide.lt-lg></div>
            </div>
            <div fxLayout="column">
              <button mat-raised-button (click)="select(team);" color="primary" fxFlex.lt-lg="auto">{{'companyTeams.Select' | translate}}</button>
              <div fxFlex="auto" fxHide.lt-lg></div>
            </div>
          </div>
          <mat-card-content>
            <app-individuals-selection-control id="teamlist{{team.teamId}}" #teamList teamId="{{team.teamId}}" [displayProfiles]="getTeamProfiles(team)" companyId="{{team.companyId}}" ></app-individuals-selection-control>
          </mat-card-content>
          <mat-card-actions fxLayout="row" fxLayout.sm="column" fxLayout.lt-sm="column">
            <button mat-button color="warn" matTooltip="{{'companyTeams.DeleteUsers' | translate}}" (click)="deleteUsers(team)">
              {{'companyTeams.DeleteUsers' | translate}}
            </button>
            <div fxFlex="auto"></div>
            <button mat-button color="warn" matTooltip="{{'companyTeams.DeleteTeam' | translate}}" (click)="deleteTeam(team)">
              {{'companyTeams.DeleteTeam' | translate}}
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
