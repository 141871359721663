import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { CompaniesService } from 'src/app/services/companies.service';
import { MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { P60benchmark } from 'src/app/models/p60benchmark';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { ReportEntry } from 'src/app/models/report-entry.model';
import { Utilities } from 'src/app/services/utilities';
import { ProfileService } from 'src/app/services/profile.service';
import { PersonalityScoring } from 'src/app/models/personality-scoring.model';

export class Individual {

  name: string;
  select: boolean;
}

@Component({
  selector: 'app-benchmark-new-dialog',
  templateUrl: './benchmark-new-dialog.component.html',
  styleUrls: ['./benchmark-new-dialog.component.scss']
})
export class BenchmarkNewDialogComponent implements OnInit {
  @Input() benchmark: P60benchmark;
  @Input() companyId: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = [
    'select',
    'name'];
  dataSource: MatTableDataSource<Individual>;
  loadingIndicator: boolean;

  reportList: ReportEntry[];
  scoringList: PersonalityScoring[];

  get name() {
    if (this.benchmark)
      return this.benchmark.benchmarkName;
    return "(new benchmark)";
  }

  set name(value: string) {
    if (this.benchmark)
      this.benchmark.benchmarkName = value;
  }

  constructor(
    public companiesService: CompaniesService,
    public profileService: ProfileService,
    public alertService: AlertService,
    public dialogRef: MatDialogRef<BenchmarkNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { benchmark: P60benchmark, companyId: number }) {

    this.benchmark = this.data.benchmark;
    this.companyId = this.data.companyId;
    
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService
      .getReports(this.companyId)
      .subscribe(list =>
        this.onReportEntryLoadSuccessful(list),
        error => this.onDataLoadFailed(error));
  }

  private onReportEntryLoadSuccessful(list: ReportEntry[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.reportList = list;

    var individuals = [];

    this.reportList.forEach(function (e) {
      if (individuals.filter(d => e.userId == d.userId).length <= 0) {
        individuals.push({ name: e.userFullName, select: false });
      }
    });

    this.dataSource.data = individuals;
    this.dataSource.data.sort();
  }

  callFailed(error: any) {
    this.onDataLoadFailed(error);
  }

  public onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  onP60LoadSuccessful(ps: PersonalityScoring) {
    this.scoringList.push(ps);
    const testcount = this.dataSource.data.filter(i => i.select == true).length;
    if (!(this.scoringList.length == testcount))
      return;
    
    var totalscore = new PersonalityScoring();
    this.scoringList.forEach(v => {
      totalscore.aggressiveTraits_BenchmarkFrom += v.aggressiveTraits - 2;
      totalscore.aggressiveTraits_BenchmarkTo += v.aggressiveTraits + 2;
      totalscore.analyticalTraits_BenchmarkFrom += v.analyticalTraits - 2;
      totalscore.analyticalTraits_BenchmarkTo += v.analyticalTraits + 2;
      totalscore.artisticAbilities_BenchmarkFrom += v.artisticAbilities - 2;
      totalscore.artisticAbilities_BenchmarkTo += v.artisticAbilities + 2;
      totalscore.compassion_BenchmarkFrom += v.compassion - 2;
      totalscore.compassion_BenchmarkTo += v.compassion + 2;
      totalscore.creativeAbilities_BenchmarkFrom += v.creativeAbilities - 2;
      totalscore.creativeAbilities_BenchmarkTo += v.creativeAbilities + 2;
      totalscore.currentStateOfMind_BenchmarkFrom += v.currentStateOfMind - 2;
      totalscore.currentStateOfMind_BenchmarkTo += v.currentStateOfMind + 2;
      totalscore.decisiveness_BenchmarkFrom += v.decisiveness - 2;
      totalscore.decisiveness_BenchmarkTo += v.decisiveness + 2;
      totalscore.detailTraits_BenchmarkFrom += v.detailTraits - 2;
      totalscore.detailTraits_BenchmarkTo += v.detailTraits + 2;
      totalscore.goalSettingLevel_BenchmarkFrom += v.goalSettingLevel - 2;
      totalscore.goalSettingLevel_BenchmarkTo += v.goalSettingLevel + 2;
      totalscore.independentTraits_BenchmarkFrom += v.independentTraits - 2;
      totalscore.independentTraits_BenchmarkTo += v.independentTraits + 2;
      totalscore.interactionLevel_BenchmarkFrom += v.interactionLevel - 2;
      totalscore.interactionLevel_BenchmarkTo += v.interactionLevel + 2;
      totalscore.patience_BenchmarkFrom += v.patience - 2;
      totalscore.patience_BenchmarkTo += v.patience + 2;
      totalscore.peopleOutgoingTraits_BenchmarkFrom += v.peopleOutgoingTraits - 2;
      totalscore.peopleOutgoingTraits_BenchmarkTo += v.peopleOutgoingTraits + 2;
      totalscore.persuasiveness_BenchmarkFrom += v.persuasiveness - 2;
      totalscore.persuasiveness_BenchmarkTo += v.persuasiveness + 2;
      totalscore.sensitivityAwareness_BenchmarkFrom += v.sensitivityAwareness - 2;
      totalscore.sensitivityAwareness_BenchmarkTo += v.sensitivityAwareness + 2;
      totalscore.stressManagement_BenchmarkFrom += v.stressManagement - 2;
      totalscore.stressManagement_BenchmarkTo += v.stressManagement + 2;
      totalscore.stubbornness_BenchmarkFrom += v.stubbornness - 2;
      totalscore.stubbornness_BenchmarkTo += v.stubbornness + 2;
      totalscore.timeManagement_BenchmarkFrom += v.timeManagement - 2;
      totalscore.timeManagement_BenchmarkTo += v.timeManagement + 2;
    });

    this.benchmark.aggressiveFrom = Math.floor(totalscore.aggressiveTraits_BenchmarkFrom / testcount);
    this.benchmark.aggressiveTo = Math.ceil(totalscore.aggressiveTraits_BenchmarkTo / testcount);
    this.benchmark.analyticalFrom = Math.floor(totalscore.analyticalTraits_BenchmarkFrom / testcount);
    this.benchmark.analyticalTo = Math.ceil(totalscore.analyticalTraits_BenchmarkTo / testcount);
    this.benchmark.artisticFrom = Math.floor(totalscore.artisticAbilities_BenchmarkFrom / testcount);
    this.benchmark.artisticTo = Math.ceil(totalscore.artisticAbilities_BenchmarkTo / testcount);
    this.benchmark.compassionFrom = Math.floor(totalscore.compassion_BenchmarkFrom / testcount);
    this.benchmark.compassionTo = Math.ceil(totalscore.compassion_BenchmarkTo / testcount);
    this.benchmark.creativeFrom = Math.floor(totalscore.creativeAbilities_BenchmarkFrom / testcount);
    this.benchmark.creativeTo = Math.ceil(totalscore.creativeAbilities_BenchmarkTo / testcount);
    this.benchmark.consistencyFrom = Math.floor(totalscore.currentStateOfMind_BenchmarkFrom / testcount);
    this.benchmark.consistencyTo = Math.ceil(totalscore.currentStateOfMind_BenchmarkTo / testcount);
    this.benchmark.decisionFrom = Math.floor(totalscore.decisiveness_BenchmarkFrom / testcount);
    this.benchmark.decisionTo = Math.ceil(totalscore.decisiveness_BenchmarkTo / testcount);
    this.benchmark.detailedFrom = Math.floor(totalscore.detailTraits_BenchmarkFrom / testcount);
    this.benchmark.detailedTo = Math.ceil(totalscore.detailTraits_BenchmarkTo / testcount);
    this.benchmark.goalFrom = Math.floor(totalscore.goalSettingLevel_BenchmarkFrom / testcount);
    this.benchmark.goalTo = Math.ceil(totalscore.goalSettingLevel_BenchmarkTo / testcount);
    this.benchmark.independentFrom = Math.floor(totalscore.independentTraits_BenchmarkFrom / testcount);
    this.benchmark.independentTo = Math.ceil(totalscore.independentTraits_BenchmarkTo / testcount);
    this.benchmark.interactionFrom = Math.floor(totalscore.interactionLevel_BenchmarkFrom / testcount);
    this.benchmark.interactionTo = Math.ceil(totalscore.interactionLevel_BenchmarkTo / testcount);
    this.benchmark.patienceFrom = Math.floor(totalscore.patience_BenchmarkFrom / testcount);
    this.benchmark.patienceTo = Math.ceil(totalscore.patience_BenchmarkTo / testcount);
    this.benchmark.outgoingFrom = Math.floor(totalscore.peopleOutgoingTraits_BenchmarkFrom / testcount);
    this.benchmark.outgoingTo = Math.ceil(totalscore.peopleOutgoingTraits_BenchmarkTo / testcount);
    this.benchmark.persuasiveFrom = Math.floor(totalscore.persuasiveness_BenchmarkFrom / testcount);
    this.benchmark.persuasiveTo = Math.ceil(totalscore.persuasiveness_BenchmarkTo / testcount);
    this.benchmark.sensitivityFrom = Math.floor(totalscore.sensitivityAwareness_BenchmarkFrom / testcount);
    this.benchmark.sensitivityTo = Math.ceil(totalscore.sensitivityAwareness_BenchmarkTo / testcount);
    this.benchmark.stressFrom = Math.floor(totalscore.stressManagement_BenchmarkFrom / testcount);
    this.benchmark.stressTo = Math.ceil(totalscore.stressManagement_BenchmarkTo / testcount);
    this.benchmark.stubbornFrom = Math.floor(totalscore.stubbornness_BenchmarkFrom / testcount);
    this.benchmark.stubbornTo = Math.ceil(totalscore.stubbornness_BenchmarkTo / testcount);
    this.benchmark.timeFrom = Math.floor(totalscore.timeManagement_BenchmarkFrom / testcount);
    this.benchmark.timeTo = Math.ceil(totalscore.timeManagement_BenchmarkTo / testcount);

    this.dialogRef.close(this.benchmark);
  }

  continue() {
    if (this.dataSource.data.filter(d => d.select == true).length == 0) {
      this.dialogRef.close(this.benchmark);
      return;
    }

    var reports = [];
    const rl = this.reportList;

    var checktests = [];

    this.scoringList = [];


    this.dataSource.data.forEach(e => {
      if (e.select) {
        var tests = rl.filter(r => r.userFullName == e.name);
        var test = tests[tests.length - 1];
        checktests.push(test);

      }
    });

    for (let test of checktests) {
      this.profileService.getP60Result(test.userId, test.companyId, test.language, test.userTestId)
        .subscribe(
          ps => this.onP60LoadSuccessful(ps),
          error => this.onDataLoadFailed(error));  
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
