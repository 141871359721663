import { Component, OnInit, OnChanges, OnDestroy, ViewChild, Input } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject, Subscription, Observable } from 'rxjs';

import { CompaniesService } from 'src/app/services/companies.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { Utilities } from '../../../services/utilities';
import { Company } from 'src/app/models/company';
import { CompanyEdit } from 'src/app/models/company-edit.model';
import { EqualValidator } from '../../../shared/validators/equal.validator';
import { AppDialogComponent } from 'src/app/shared/app-dialog/app-dialog.component';
import { ImageUploadDialogComponent } from '../../dialogs/image-upload-dialog/image-upload-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'company-editor',
  templateUrl: './company-editor.component.html',
  styleUrls: ['./company-editor.component.scss']
})
export class CompanyEditorComponent implements OnChanges {

  @Input() company: Company = new Company();
  @Input() isEditMode: boolean = false;

  @ViewChild('form')
  private form: NgForm;

  isNew = false;
  private isSaving = false;
  private onCompanySaved = new Subject<Company>();

  editorForm: FormGroup;
  public companySaved$ = this.onCompanySaved.asObservable();

  get companyName() {
    return this.editorForm.get('companyName');
  }
  get contactName() {
    return this.editorForm.get('contactName');
  }

  get logoSquareBase64(): string {
    if (!this.company) return "";
    return this.company.logoSquareBase64;
  }

  get logoFullBase64(): string {
    if (!this.company) return "";
    return this.company.logoFullBase64;
  }

  get floatLabels(): string {
    return this.isEditMode ? 'auto' : 'always';
  }

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    this.buildForm();
  }
  ngOnChanges() {
    if (this.company) {
      if (this.company.companyId >= 0)
        this.isNew = false;
      else
        this.isNew = true;
    } else {
      this.isNew = true;
      this.createNewCompanyObject();
    }

    this.resetForm();
  }

  private buildForm() {
    this.editorForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companyPhoneNumber: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      website: '',
      contactName: ['', Validators.required],
      contactPhoneNumber: '',
      contactEmailAddress: '',
      logoFullBase64: '',
      logoSquareBase64: '',
      notifyEmailAddresses: '',
      isNew: false
    });
  }

  private createNewCompanyObject() {
    if (this.company)
      return;

    this.company = new Company();
  }

  private resetForm(stopEditing: boolean = false) {
    if (stopEditing) {
      this.isEditMode = false;
    }

    if (!this.company || this.isNew) {
      this.isNew = true;
      this.createNewCompanyObject();
    }

    if (this.isNew) {
      //no special logic, yet.
    } 

    this.editorForm.reset({
      companyName: this.company.companyName || '',
      companyPhoneNumber: this.company.companyPhoneNumber || '',
      address: this.company.address || '',
      city: this.company.city || '',
      state: this.company.state || '',
      zipCode: this.company.zipCode || '',
      website: this.company.website || '',
      contactName: this.company.contactName || '',
      contactPhoneNumber: this.company.contactPhoneNumber || '',
      contactEmailAddress: this.company.contactEmailAddress || '',
      logoFullBase64: this.company.logoFullBase64 || '',
      logoSquareBase64: this.company.logoSquareBase64 || '',
      notifyEmailAddresses: this.company.notifyEmailAddresses || '',
      isNew: this.isNew
    });
    
  }

  public beginEdit() {
    this.isEditMode = true;
  }

  public save() {
    if (!this.form.submitted) {
      // Causes validation to update.
      this.form.onSubmit(null);
      return;
    }

    if (!this.editorForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.isSaving = true;
    this.alertService.startLoadingMessage('Saving changes...');

    const editedCompany = this.getEditedCompany();

    if (this.isNew) {
      this.companiesService.newCompany(editedCompany).subscribe(
        company => this.saveCompleted(company),
        error => this.saveFailed(error));
    } else {
      this.companiesService.updateCompany(editedCompany).subscribe(
        () => this.saveCompleted(editedCompany),
        error => this.saveFailed(error));
    }
  }

  public cancel() {
    this.resetForm();
    this.isEditMode = false;

    this.alertService.resetStickyMessage();
  }

  private getEditedCompany(): CompanyEdit {
    const formModel = this.editorForm.value;

    var editObj = new CompanyEdit();
    var values = {
      companyId: this.company.companyId,
      companyName: formModel.companyName,
      companyPhoneNumber: formModel.companyPhoneNumber,
      address: formModel.address,
      city: formModel.city,
      state: formModel.state,
      zipCode: formModel.zipCode,
      website: formModel.website,
      contactName: formModel.contactName,
      contactPhoneNumber: formModel.contactPhoneNumber,
      contactEmailAddress: formModel.contactEmailAddress,
      logoFullBase64: formModel.logoFullBase64,
      logoSquareBase64: formModel.logoSquareBase64,
      unlimitedCredits: this.company.unlimitedCredits,
      createdDate: formModel.createdDate,
      createdBy: formModel.createdBy,
      updatedDate: formModel.updatedDate,
      updatedBy: formModel.updatedBy,
      notifyEmailAddresses: formModel.notifyEmailAddresses, 

      companyCredits: this.company.companyCredits
    };

    Object.assign(editObj, values);

    return editObj;
  }

  private saveCompleted(company?: Company) {
    if (company) {
      //add downstream event calls here, if needed.
      this.company = company;
    }

    this.isSaving = false;
    this.alertService.stopLoadingMessage();

    this.resetForm(true);
    this.onCompanySaved.next(this.company);
  }

  private saveFailed(error: any) {
    this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Save Error', 'One or more errors occured whilst saving your changes:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);   
  }
   
  launchSquareLogoImport() {
    const dialogRef = this.dialog.open(ImageUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(base64Image => {
      if (base64Image) {
        this.updateSquareLogoImage(base64Image);
      }
    });
  }

  launchFullLogoImport() {
    const dialogRef = this.dialog.open(ImageUploadDialogComponent,
      {
        panelClass: 'mat-dialog-md'
      });
    dialogRef.afterClosed().subscribe(base64Image => {
      if (base64Image) {
        this.updateFullLogoImage(base64Image);
      }
    });
  }

  private updateSquareLogoImage(image: string) {
    this.company.logoSquareBase64 = image;
    this.editorForm.get('logoSquareBase64').setValue(image);
  }

  private updateFullLogoImage(image: string) {
    this.company.logoFullBase64 = image;
    this.editorForm.get('logoFullBase64').setValue(image);
  }
}
