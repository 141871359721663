<div fxLayout="column" fxFlex="100%">

  <div fxLayout="column" fxFlex="100%" class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
    <i class="material-icons">cloud_upload</i>
    <input hidden type="file" #fileInput (change)="incomingFile($event)" accept="{{accept}}">
  </div>
  <div fxFlex="100%" class="files-list" *ngFor="let file of files;let i= index">
    <p> {{ file.name }} </p>
    <button mat-icon-button class="delete-file" (click)="deleteAttachment(i)">
      <mat-icon color="warn">delete</mat-icon>
    </button>
  </div>
</div>
