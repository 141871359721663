import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { fadeInOut } from 'src/app/services/animations';
import { CampaignService } from 'src/app/services/campaign.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { CampaignAnalysis } from 'src/app/models/campaign-analysis.model';
import { Campaign } from 'src/app/models/campaign.model';
import { InviteeProgress } from 'src/app/models/invitee-progress.model';
import { Utilities } from 'src/app/services/utilities';
import { InvitationSender, InvitationContact } from '../../../models/invitation-sender.model';
import { AppRoutingModule } from '../../../app-routing.module';
import { InvitationsService } from '../../../services/invitations.service';
import { AuthService } from '../../../services/auth.service';
import { ContactEditorDialogComponent } from '../../invitation/dialogs/contact-editor-dialog/contact-editor-dialog.component';

@Component({
  selector: 'app-campaign-analysis',
  templateUrl: './campaign-analysis.component.html',
  styleUrls: ['./campaign-analysis.component.scss'],
  animations: [fadeInOut]
})
export class CampaignAnalysisComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  campaignId: number;
  analysis: CampaignAnalysis;

  displayedColumns = [
    'lastName',
    //'firstName',
    'email',
    'status',
    'progress'
  ];

  dsParticipants: MatTableDataSource<InviteeProgress>;
  loadingIndicator: boolean;

  cirpro: any = {
    radius: 36,
    outerStrokeWidth: 4,
    innerStrokeWidth: 2,
    outerStrokeColor: "#78C000",
    innerStrokeColor: "#C7E596",
    animation: true,
    animationDuration: 300,
    space: 0,
    showTitle: true,
    titleFontSize: 16,
    unitsFontSize: 16,
    showSubtitle: true,
    hideSubtitle: false,
    startFromZero: false,
    responsive: false
  };

  get campaignName() {
    if (!this.analysis) return '';
    return this.analysis.campaign.campaignName;
  }
  get designation() {
    if (!this.analysis) return '';
    return this.analysis.campaign.designation;
  }
  get participationPercent() {
    if (!this.analysis) return 0;
    return this.analysis.participation * 100;
  }
  get participationValue() {
    if (!this.analysis) return '0.00';
    if (isNaN(this.analysis.participation)) return '0.00';
    return this.formatPercent(this.analysis.participation);
  }
  get participationText() {
    if (!this.analysis) return '0 of 0';
    return `${this.analysis.invitationsClaimed} of ${this.analysis.inviteeCount}`;
  }
  get progressPercent() {
    if (!this.analysis) return 0;
    return this.analysis.overallProgress * 100;
  }
  get progressValue() {
    if (!this.analysis) return '0.00';
    if (isNaN(this.analysis.overallProgress)) return '0.00';
    return this.formatPercent(this.analysis.overallProgress);
  }
  get invitationTemplate() {
    if (!this.analysis) return "";
    return this.analysis.campaign.invitationContent;
  }
  get openDate() {
    if (!this.analysis) return null;
    if (!this.analysis.campaign.openDate) return null;
    return Utilities.utcDateToLocal(new Date(this.analysis.campaign.openDate)).toLocaleDateString();
  }
  get closeDate() {
    if (!this.analysis) return null;
    if (!this.analysis.campaign.closeDate) return null;
    return Utilities.utcDateToLocal(new Date(this.analysis.campaign.closeDate)).toLocaleDateString();
  }

  constructor(
    private route: ActivatedRoute,
    private campaignService: CampaignService,
    private inviteService: InvitationsService,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.dsParticipants = new MatTableDataSource();
    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.campaignId = params['campaignId'];
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.campaignService.analyzeCampaign(this.campaignId).subscribe(
      result => {
        this.parseAnalysis(result);
      });
  }

  ngAfterViewInit(): void {
    this.dsParticipants.paginator = this.paginator;
    this.dsParticipants.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dsParticipants.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dsParticipants.filter);
  }

  parseAnalysis(analysis: CampaignAnalysis) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.analysis = analysis;
    this.dsParticipants.data = analysis.invitees;
    //setTimeout(() => {
    //  //performed any delayed actions here.
    //});
  }


  formatPercent(value: number): string {
    const perc = (value * 100).toFixed(2);
    return `${perc}`;
  }

  resendInvite(progress: InviteeProgress) {
    var i = progress.invitation;
    var ic = new InvitationContact(progress.email, progress.firstName, progress.lastName);
    this.sendInvitation(i.body, i.subject, i.courseId, i.materialId, this.translationService.getCurrentLanguage(), i.expirationDate, [ic]);
  }

  sendInvitation(body: string, subject: string, courseId: number, materialId: number, language: string, expirationDate: Date, contactData: InvitationContact[]) {
    var message = body;
    //if (message != '')
    //  message = Utilities.convertStringToHTML(message);

    var link = Utilities.baseUrl() + '/invitation/claim/';

    var invite = InvitationSender.Create(
      this.authService.currentUserProfile.userId,
      this.authService.companyId,
      subject,
      message,
      courseId,
      materialId,
      language,
      link,
      expirationDate,
      contactData
    );

    if (this.campaignId)
      invite.campaignId = this.campaignId;

    this.alertService.startLoadingMessage('Sending...');

    this.inviteService.sendInvitations(invite).subscribe(
      () => this.invitesSent(invite),
      error => this.invitesFailed(error)
    );
  }

  invitesSent(invite: InvitationSender) {
    this.alertService.stopLoadingMessage();
    this.alertService.showMessage('Success', `Invitation sent!`, MessageSeverity.success);
    this.loadData();
  }

  invitesFailed(error: string) {
    //this.isSaving = false;
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage('Sending Error', 'One or more errors occured whilst sending the invites:', MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  newIndividual() {
    const contact = new InvitationContact("", "", "");

    const dialogRef = this.dialog.open(ContactEditorDialogComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { contact: contact }
      });
    dialogRef.afterClosed().subscribe(contact => {
      if (contact) {
        this.addContact(contact);
      }
    });
  }

  private addContact(contact: InvitationContact) {
    var i = this.dsParticipants.data[0].invitation; //only acting as an information source; there should always be at least one.
    var ic = contact;
    this.sendInvitation(i.body, i.subject, i.courseId, i.materialId, this.translationService.getCurrentLanguage(), i.expirationDate, [ic]);
  }
}
