<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content fxLayout="column" fxLayoutGap="8px">
  <ckeditor [editor]="Editor" [config]="config" [(ngModel)]="content"></ckeditor>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="import();" color="primary">{{'commands.Continue' | translate}}</button>
  <button mat-raised-button (click)="cancel()" color="warn">{{'commands.Cancel' | translate}}</button>
</div>
