import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { Material, MaterialDisplay } from 'src/app/models/material.model';
import { MaterialsService } from 'src/app/services/materials.service';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { Company } from 'src/app/models/company';
import { Utilities } from 'src/app/services/utilities';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { TestNewDialogComponent } from '../../dialogs/test-new-dialog/test-new-dialog.component';
import { Router } from '@angular/router';
import { MaterialShareDialogComponent } from '../../dialogs/material-share-dialog/material-share-dialog.component';

@Component({
  selector: 'app-material-list-control',
  templateUrl: './material-list-control.component.html',
  styleUrls: ['./material-list-control.component.scss']
})
export class MaterialListControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
   
  @Input() companyId: number;
  @Input() designation: string;

  get role(): string {
    if (!this.authService) return "User";
    if (!this.authService.companyRole) return "User";
    return this.authService.companyRole;
  }

  get canEdit(): boolean {
    return this.role != "User";
  }

  get isCreator(): boolean {
    if (!this.authService) return false;
    return this.authService.userIsCompanyCreator;
  }

  get displayedColumns(): string[] {
    if (this.canEdit) {
      return [
        "materialName",
        "companyName",
        "language",
        "template",
        "actions"
      ];
    }

    return [
      "materialName",
      "companyName",
      "template"
    ];
  }
  dataSource: MatTableDataSource<MaterialDisplay>;
  loadingIndicator: boolean;

  get searchText(): string {
    if (this.designation == "aptitude")
      return this.translationService.getTranslation("materialListControl.SearchAptitudes");
    else if (this.designation == "assessment")
      return this.translationService.getTranslation("materialListControl.SearchAssessments");
    else if (this.designation == "survey")
      return this.translationService.getTranslation("materialListControl.SearchSurveys");
    else if (this.designation == "material")
      return this.translationService.getTranslation("materialListControl.SearchMaterials");
    else
      return this.translationService.getTranslation("materialListControl.Search");
  }

  get newText(): string {
    if (this.designation == "aptitude")
      return this.translationService.getTranslation("materialListControl.NewAptitude");
    else if (this.designation == "assessment")
      return this.translationService.getTranslation("materialListControl.NewAssessment");
    else if (this.designation == "survey")
      return this.translationService.getTranslation("materialListControl.NewSurvey");
    else if (this.designation == "material")
      return this.translationService.getTranslation("materialListControl.NewMaterial");
    else
      return this.translationService.getTranslation("materialListControl.New");
  }

  get nameText(): string {
    if (this.designation == "aptitude")
      return this.translationService.getTranslation("materialListControl.MaterialAptitude");
    else if (this.designation == "assessment")
      return this.translationService.getTranslation("materialListControl.MaterialAssessment");
    else if (this.designation == "survey")
      return this.translationService.getTranslation("materialListControl.MaterialSurvey");
    else if (this.designation == "material")
      return this.translationService.getTranslation("materialListControl.MaterialMaterial");
    else
      return this.translationService.getTranslation("materialListControl.MaterialName");
  }

  constructor(
    private materialService: MaterialsService,
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public companyFound(company: Company) {
    this.companyId = company.companyId;
    this.loadData();
  }
  public companyIdFound(companyId: number) {
    this.companyId = companyId;
    this.loadData();
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    if (["aptitude","assessment","survey"].indexOf(this.designation) >= 0) {
      this.materialService
        .getTests(this.companyId, this.designation)
        .subscribe(
          entries => this.onDataLoadSuccessful(entries),
          error => this.onDataLoadFailed(error)
        );
    }
    else {
      this.materialService
        .getMaterials(this.companyId, this.designation)
        .subscribe(
          entries => this.onDataLoadSuccessful(entries),
          error => this.onDataLoadFailed(error)
        );
    }
   
  }

  private onDataLoadSuccessful(entries: Material[]) {
    var data = entries.map(e => {
      var d = new MaterialDisplay();
      Object.assign(d, e);
      if (d.company)
        d.companyName = d.company.companyName;
      return d;
    });

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource.data = data;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private newMaterial() {
    var copy: Material = new Material(0, `(new ${this.designation})`);
    copy.companyId = this.authService.companyId;
    copy.template = false;
    copy.languageCode = "en";

    const dialogref = this.dialog.open(
      TestNewDialogComponent,
      {
        panelClass: "mat-dialog-sm",
        data: { material: copy, companyId: this.companyId, designation: this.designation }
      });
    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.dataSource.data.push(result);
        this.dataSource.data = this.dataSource.data.sort((a, b) => (a.materialName > b.materialName) ? 1 : -1);
        this.edit(result);
      }
    });
  }

  canCopy(object: Material): boolean {
    if (!object) return false;
    if (object.companyId == 0 && this.companyId != object.companyId) return false;
    return true;
  }

  private share(object: Material) {
    const dialogref = this.dialog.open(
      MaterialShareDialogComponent,
      {
        panelClass: "mat-dialog-sm",
        data: { material: object, companyId: this.companyId }
      });
    dialogref.afterClosed().subscribe(result => {
      //do nothing, for now...
    });
  }

  private copy(object: Material, toCompanyId: number = -1) {
    this.materialService.copyMaterial(object.materialId, toCompanyId).subscribe(
      result => this.edit(result),
      error => this.onDataLoadFailed(error)
    );
  }

  private edit(object: Material) {
    this.router.navigateByUrl(`/materials/test/${object.materialId}`);
  }

  private confirmDelete(object: Material) {
    if (object.template) return;

    this.snackBar.open(`Delete ${object.materialName}?`, 'DELETE', { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage('Deleting...');
        this.loadingIndicator = true;
        this.delete(object.materialId);

      });
  }

  delete(materialId: number) {
    this.materialService.deleteMaterial(materialId)
      .subscribe(
        results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;
          this.loadData();
        },
        error => {
          this.checkError(error);
        });
  }

  checkError(error: string) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    if (error != "INVALID")
      return;

    this.alertService.showStickyMessage('Delete Error', `An error occured whilst deleting.\r\nError: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }


}
