import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { fadeInOut } from 'src/app/services/animations';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import { P60option } from 'src/app/models/p60option.model';
import { P60optionValue } from 'src/app/models/p60option-value.model';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AuthService } from 'src/app/services/auth.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { CompaniesService } from 'src/app/services/companies.service';
import { ActivatedRoute, Params } from '@angular/router';
import { P60OptionValueSetControlComponent } from '../controls/p60-option-value-set-control/p60-option-value-set-control.component';
import { Utilities } from 'src/app/services/utilities';

@Component({
  selector: 'app-p60-option-editor',
  templateUrl: './p60-option-editor.component.html',
  styleUrls: ['./p60-option-editor.component.scss'],
  animations: [fadeInOut]
})
export class P60OptionEditorComponent implements OnInit, AfterViewInit {
  @ViewChild(P60OptionValueSetControlComponent) valueset: P60OptionValueSetControlComponent;

  optionId: number;
  option: P60option;

  get optionTitle() {
    if (!this.option)
      return "";
    return this.option.optionTitle;
  }
  get code() {
    if (!this.option)
      return "";
    return this.option.code;
  }
  get languageCode() {
    if (!this.option)
      return "";
    return this.option.languageCode;
  }

  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    private companiesService: CompaniesService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute) {

    route.params
      .subscribe(params => {
        this.parseParams(params);
      });
  }

  parseParams(params: Params) {
    this.optionId = 0;

    if (params)
      if (params['optionId'])
        this.optionId = params['optionId'];
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit(): void {

  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.companiesService.getP60Option(this.optionId).subscribe(
      option => this.onDataLoadSuccessful(option),
      error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(option: P60option) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.option = option;
    this.valueset.assignValues(this.option.p60optionValue);
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve the option set from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  
}
