 <div>
  <div class="search-box" fxLayout="row" fxLayout.lt-sm="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'taskListControl.Search' | translate}}">
    </mat-form-field>
    <!--<mat-slide-toggle class="mat-small" (change)="$event? toggleCompletedTasks() : null" [checked]="hideCompletedTasks" fxFlex="initial">{{'taskListControl.HideCompleted' | translate}}</mat-slide-toggle>-->
  </div>

  <div class="task-list-control-container simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <mat-header-cell fxFlex="60px" *matHeaderCellDef class="first-cell-button"></mat-header-cell>
        <mat-cell fxFlex="60px" *matCellDef="let task" class="first-cell-button">
          <button mat-icon-button matTooltip="{{'taskListControl.Launch' | translate}}" (click)="launch(task)" *ngIf="canLaunch(task)">
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="taskName">
        <mat-header-cell fxFlex="50%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matHeaderCellDef mat-sort-header> {{'taskListControl.Description' | translate}} </mat-header-cell>
        <mat-cell fxFlex="50%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matCellDef="let task" [class.completed]="task.completionValue == 1.0">
          <div fxFlex="100%" fxLayout="column" fxLayoutGap="4px">
            <div fxFlex="100%">{{task.taskName}}</div>
            <div fxFlex="100%" fxLayout="row" fxLayoutGap="4px" fxHide.md fxHide.gt-md>
              <div fxFlex="20%">{{formatPercent(task.completionValue)}}</div>
              <div fxFlex="40%">{{'taskListControl.Created' | translate}} {{task.createdDate | date: (this.isToday(task.createdDate) ? 'shortTime' : 'shortDate')}}</div>
              <div fxFlex="40%" *ngIf="task.dueDate">{{'taskListControl.DueDate' | translate}} {{task.dueDate | date: 'shortDate'}}</div>
            </div>
          </div>
          
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="completionValue">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'taskListControl.Task' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let task" [class.completed]="task.completionValue == 1.0" fxHide.sm fxHide.lt-sm>
          {{formatPercent(task.completionValue)}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'taskListControl.Created' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let task" [class.completed]="task.completionValue == 1.0" fxHide.sm fxHide.lt-sm> {{task.createdDate | date: (this.isToday(task.createdDate) ? 'shortTime' : 'shortDate')}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'taskListControl.DueDate' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let task" [class.completed]="task.completionValue == 1.0" fxHide.sm fxHide.lt-sm> {{task.dueDate | date:'shortDate'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>


</div>
