<app-page-header title="NotFound" icon="error"></app-page-header>
<div [@fadeInOut] class="page-content" fxLayout="column" fxLayoutAlign="center center">
    <div>
        <mat-icon class="mat-icon-lg vertical-center">map</mat-icon>
        <h1 class="vertical-center" style="margin: 0;">{{'notFound.404' | translate}}</h1>
    </div>
    <p>{{'notFound.pageNotFound' | translate}}</p>
    <button mat-raised-button color="primary" routerLink="/">
        <mat-icon>home</mat-icon>
        <span>{{'notFound.backToHome' | translate}}</span>
    </button>
</div>
