import { Component, ViewChild, Inject, Input } from '@angular/core';
import { ProfileEditorComponent } from '../profile-editor/profile-editor.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from 'src/app/models/user.model';
import { Profile } from 'src/app/models/profile';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-profile-initial-dialog',
  templateUrl: './profile-initial-dialog.component.html',
  styleUrls: ['./profile-initial-dialog.component.scss']
})
export class ProfileInitialDialogComponent {

  @ViewChild(ProfileEditorComponent)
  editProfile: ProfileEditorComponent;
  
  constructor(
    private authService: AuthService,
    public dialogRef: MatDialogRef<ProfileInitialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: User, profile: Profile, isInitial: boolean
    }) {

  }


  ngAfterViewInit() {
    this.editProfile.profileSaved$.subscribe(profile => this.dialogRef.close(profile));
  }

  profileSaved(profile: Profile) {
    if (!this.authService.currentUserProfile || profile.userId == this.authService.currentUserProfile.userId) {
      this.authService.currentUserProfile = profile;
    }
    this.dialogRef.close(profile);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
