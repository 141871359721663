<div class="search-box" fxLayout="row" fxLayout.lt-sm="column">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'courseListControl.SearchCourses' | translate}}">
  </mat-form-field>
  <div fxFlex="auto"></div>
  <div *ngIf="isCreator" fxLayout="column" fxLayoutAlign="center center">
    <button mat-raised-button matTooltip="{{'courseListControl.NewCourse' | translate}}" (click)="newCourse()">
      <mat-icon>add_circle_outline</mat-icon>&nbsp;&nbsp;{{'courseListControl.NewCourse' | translate}}
    </button>
  </div>
</div>

<div class="simple-border">
  <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
  <mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="courseName">
      <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header> {{'courseListControl.Name' | translate}} </mat-header-cell>
      <mat-cell fxFlex="40%" fxFlex.lt-sm="100%" *matCellDef="let row"> {{row.courseName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="companyName">
      <mat-header-cell fxFlex="40%" *matHeaderCellDef mat-sort-header fxHide.lt-sm> {{'courseListControl.CompanyName' | translate}} </mat-header-cell>
      <mat-cell fxFlex="40%" *matCellDef="let row" fxHide.lt-sm> {{row.companyName}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="language">
      <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header fxHide.lt-sm> {{'courseListControl.Language' | translate}} </mat-header-cell>
      <mat-cell fxFlex="10%" *matCellDef="let row" fxHide.lt-sm> {{row.languageCode}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="template">
      <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header> {{'courseListControl.Template' | translate}} </mat-header-cell>
      <mat-cell fxFlex="10%" *matCellDef="let row">
        <mat-icon *ngIf="row.template">check</mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="200px">
        <button mat-button color="primary" matTooltip="{{'courseListControl.NewCourse' | translate}}" (click)="newCourse()">
          <mat-icon>add_circle_outline</mat-icon>&nbsp;{{'courseListControl.NewCourse' | translate}}
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let course" fxFlex="170px" fxFlexOffset="30px">
        <button mat-icon-button matTooltip="{{'commands.Copy' | translate}}" (click)="copy(course)">
          <mat-icon>file_copy</mat-icon>
        </button>
        <button *ngIf="course.companyId == companyId" mat-icon-button matTooltip="{{'commands.Edit' | translate}}" (click)="edit(course)">
          <mat-icon>edit</mat-icon>
        </button>
        <button *ngIf="course.companyId == companyId" mat-icon-button matTooltip="{{'commands.Delete' | translate}}" (click)="confirmDelete(course)">
          <mat-icon color="warn">delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;">
    </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
</div>
