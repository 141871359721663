import { Task } from "./task.model";

export class ProgressSavedEventArgs {
  public task: Task;
  public stepIndex: number;
  public taskItemId: number;

  public constructor(stepIndex: number, taskItemId: number, task: Task) {
    this.stepIndex = stepIndex;
    this.taskItemId = taskItemId;
    this.task = task;
  }
}
