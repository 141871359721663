import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/profile.service';
import { ProfileCompany } from 'src/app/models/profile-company';
import { AppTranslationService } from 'src/app/services/app-translation.service';

@Component({
  selector: 'app-company-role-assignment-dialog',
  templateUrl: './company-role-assignment-dialog.component.html',
  styleUrls: ['./company-role-assignment-dialog.component.scss']
})
export class CompanyRoleAssignmentDialogComponent implements OnInit {

  roles: [
    "Administrator",
    "Agent",
    "Creator",
    "User"
  ];

  constructor(
    private profileService: ProfileService,
    private translationService: AppTranslationService,
    private dialogRef: MatDialogRef<CompanyRoleAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      profile: Profile,
      companyId: number
    }) {  }

  ngOnInit() {
  }

  applyAdministrator() {
    this.apply("Administrator");
  }
  applyAgent() {
    this.apply("Agent");
  }
  applyCreator() {
    this.apply("Creator");
  }
  applyUser() {
    this.apply("User");
  }

  apply(role: string) {
    this.profileService.connectToCompany(this.data.profile.userId, this.data.companyId, role)
      .subscribe(uc => {
        this.roleApplied(uc);
      });
  }

  roleApplied(uc: ProfileCompany) {
    if (this.data.profile.userCompany.filter(x => x.companyId == this.data.companyId).length > 0)
      Object.assign(this.data.profile.userCompany.filter(x => x.companyId == this.data.companyId)[0],uc);
    else
      this.data.profile.userCompany.push(uc);

    this.dialogRef.close(this.data.profile);
  }

  cancel() {
    this.dialogRef.close(null);
  }


  roleName(role: string): string {
    return this.translationService.getTranslation(`roles.labels.${role}`);;
  }
  roleDescription(role: string): string {
    return this.translationService.getTranslation(`roles.labels.${role}Description`);
  }
}
