<app-page-header title="LegacyClients" icon="eco"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div class="search-box" fxLayout="row" fxLayout.lt-sm="column" fxLayout.sm="column">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'legacyClients.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="value">
        <mat-header-cell fxFlex="40%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matHeaderCellDef mat-sort-header>
          {{'legacyClients.ValueTitle' | translate}}
        </mat-header-cell>
        <mat-cell fxFlex="40%" fxFlex.sm="100%" fxFlex.lt-sm="100%" *matCellDef="let row">
          <span fxHide.sm fxHide.lt-sm>{{row.value}}</span>
          <span fxHide.gt-sm>
            {{row.value}}
            <br />{{row.note}}
            <br />{{row.key}}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="note">
        <mat-header-cell fxFlex="50%" *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'legacyClients.NoteTitle' | translate}} </mat-header-cell>
        <mat-cell fxFlex="50%" *matCellDef="let row" fxHide.sm fxHide.lt-sm><span>{{row.note}}</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="key">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header fxHide.sm fxHide.lt-sm> {{'legacyClients.KeyTitle' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row" fxHide.sm fxHide.lt-sm> {{row.key}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">

        </mat-header-cell>
        <mat-cell *matCellDef="let entry" fxFlex="60px" fxFlex.sm="40px" fxFlex.lt-sm="40px" fxFlexOffset="4px" fxFlexOffset.sm="2px" fxFlexOffset.lt-sm="2px">
          <button mat-icon-button matTooltip="{{'legacyClients.TransferHint' | translate}}" (click)="transfer(entry)">
            <mat-icon>repeat</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </div>


</div>
