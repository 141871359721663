import { IAuditableEntity } from "./IAuditableEntry.interface";

export class P60benchmark implements IAuditableEntity {
  constructor(id: number, name: string) {
    this.benchmarkId = id;
    this.benchmarkName = name;
    this.companyId = 0;
    this.template = false;
    this.code = "";
    this.stressFrom = 0;
    this.stressTo = 0;
    this.sensitivityFrom = 0;
    this.sensitivityTo = 0;
    this.compassionFrom = 0;
    this.compassionTo = 0;
    this.patienceFrom = 0;
    this.patienceTo = 0;
    this.creativeFrom = 0;
    this.creativeTo = 0;
    this.artisticFrom = 0;
    this.artisticTo = 0;
    this.outgoingFrom = 0;
    this.outgoingTo = 0;
    this.interactionFrom = 0;
    this.interactionTo = 0;
    this.analyticalFrom = 0;
    this.analyticalTo = 0;
    this.detailedFrom = 0;
    this.detailedTo = 0;
    this.decisionFrom = 0;
    this.decisionTo = 0;
    this.independentFrom = 0;
    this.independentTo = 0;
    this.persuasiveFrom = 0;
    this.persuasiveTo = 0;
    this.aggressiveFrom = 0;
    this.aggressiveTo = 0;
    this.stubbornFrom = 0;
    this.stubbornTo = 0;
    this.goalFrom = 0;
    this.goalTo = 0;
    this.timeFrom = 0;
    this.timeTo = 0;
    this.confidenceFrom = 0;
    this.confidenceTo = 0;
    this.consistencyFrom = 0;
    this.consistencyTo = 0;
    this.personalityFrom = 0;
    this.personalityTo = 0;
  }

  public benchmarkId: number;
  public companyId: number;
  public template: boolean;
  public code: string;
  public benchmarkName: string;
  public stressFrom: number;
  public stressTo: number;
  public sensitivityFrom: number;
  public sensitivityTo: number;
  public compassionFrom: number;
  public compassionTo: number;
  public patienceFrom: number;
  public patienceTo: number;
  public creativeFrom: number;
  public creativeTo: number;
  public artisticFrom: number;
  public artisticTo: number;
  public outgoingFrom: number;
  public outgoingTo: number;
  public interactionFrom: number;
  public interactionTo: number;
  public analyticalFrom: number;
  public analyticalTo: number;
  public detailedFrom: number;
  public detailedTo: number;
  public decisionFrom: number;
  public decisionTo: number;
  public independentFrom: number;
  public independentTo: number;
  public persuasiveFrom: number;
  public persuasiveTo: number;
  public aggressiveFrom: number;
  public aggressiveTo: number;
  public stubbornFrom: number;
  public stubbornTo: number;
  public goalFrom: number;
  public goalTo: number;
  public timeFrom: number;
  public timeTo: number;
  public confidenceFrom: number;
  public confidenceTo: number;
  public consistencyFrom: number;
  public consistencyTo: number;
  public personalityFrom: number;
  public personalityTo: number;
  public createdBy: string;
  public updatedBy: string;
  public createdDate: Date;
  public updatedDate: Date;
}
