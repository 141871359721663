import { Injectable } from '@angular/core';
import { ApplicationEndpoint } from './application-endpoint.service';
import { CampaignBuilder } from '../models/campaign-builder.model';
import { CampaignAnalysis } from '../models/campaign-analysis.model';
import { Campaign } from '../models/campaign.model';
import { ReportEntry } from '../models/report-entry.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  constructor(
    private applicationEndpoint: ApplicationEndpoint
  ) { }

  buildCampaign(info: CampaignBuilder) {
    if (info) {
      var retval = this.applicationEndpoint.buildCampaign(info);

      return retval;
    }
  }

  analyzeCampaign(id: number) {
    if (id) {
      var retval = this.applicationEndpoint.analyzeCampaign<CampaignAnalysis>(id);

      return retval;
    }
  }

  getCompanyCampaigns(id: number) {
    return this.applicationEndpoint.getCompanyCampaigns<CampaignAnalysis[]>(id);
  }

  getReports(campaignId: number, days?: number) {
    return this.applicationEndpoint.getCampaignReportsEndpoint<ReportEntry[]>(campaignId, days);
  }
}
