<div [@fadeInOut] class="page-content" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
  <div>
    <mat-card class="simple-border">
      <mat-card-header>
        <mat-icon mat-card-avatar>perm_identity</mat-icon>
        <mat-card-title>{{'registration.Title' | translate}}</mat-card-title>
        <mat-card-subtitle>{{'registration.SubTitle' | translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <user-editor [user]="" [roles]="" isEditMode="true" isRegisterUser="true" (keydown.enter)="enterKeyDown()"></user-editor>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button [routerLink]="'/login'">{{'commands.Cancel' | translate }}</button>
        <div fxFlex="auto"></div>
        <button mat-raised-button color="primary" (click)="editUser.save()">{{'users.editor.Save' | translate}}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
