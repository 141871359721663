<app-page-header title="Companies" icon="business_center"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div class="search-box">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'companies.management.Search' | translate}}">
    </mat-form-field>
  </div>

  <div class="simple-border">
    <mat-progress-bar *ngIf="loadingIndicator" mode="query"></mat-progress-bar>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="companyName">
        <mat-header-cell fxFlex="30%" *matHeaderCellDef mat-sort-header> {{'companies.management.CompanyName' | translate}} </mat-header-cell>
        <mat-cell fxFlex="30%" *matCellDef="let row"> {{row.companyName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contactName">
        <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header> {{'companies.management.CompanyName' | translate}} </mat-header-cell>
        <mat-cell fxFlex="25%" *matCellDef="let row"> {{row.contactName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contactEmailAddress">
        <mat-header-cell fxFlex="25%" *matHeaderCellDef mat-sort-header> {{'companies.management.Email' | translate}} </mat-header-cell>
        <mat-cell fxFlex="25%" *matCellDef="let row"> {{row.contactEmailAddress}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contactPhoneNumber">
        <mat-header-cell fxFlex="10%" *matHeaderCellDef mat-sort-header> {{'companies.management.PhoneNumber' | translate}} </mat-header-cell>
        <mat-cell fxFlex="10%" *matCellDef="let row"> {{row.contactPhoneNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef fxFlex="170px">
          <button mat-button color="primary" matTooltip="{{'companies.management.NewCompany' | translate}}" (click)="editCompany()">
            <mat-icon>add_circle_outline</mat-icon>&nbsp;{{'companies.management.NewCompany' | translate}}
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let company" fxFlex="140px" fxFlexOffset="30px">
          <button mat-icon-button matTooltip="{{'companies.management.Edit' | translate}}" (click)="editCompany(company)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="!company.deleted" mat-icon-button matTooltip="{{'companies.management.Delete' | translate}}" (click)="confirmDelete(company)">
            <mat-icon color="warn">delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;">
      </mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" [pageSize]="25"></mat-paginator>
  </div>
</div>
