import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AnswerValue } from 'src/app/models/answer-value.model';
import { XrAnswerType } from 'src/app/models/xr-answer-type.model';

@Component({
  selector: 'app-answer-value-control',
  templateUrl: './answer-value-control.component.html',
  styleUrls: ['./answer-value-control.component.scss']
})
export class AnswerValueControlComponent implements OnInit {
  @Input() answerValue: AnswerValue = new AnswerValue;
  @Input() position: string = "";
  @Input() answerType: XrAnswerType = new XrAnswerType;

  @Output() onDelete = new EventEmitter<AnswerValue>();
  @Output() onMoveUp = new EventEmitter<AnswerValue>();
  @Output() onMoveDown = new EventEmitter<AnswerValue>();


  get editObj(): AnswerValue {
    if (this.answerValue)
      return this.answerValue;
    return new AnswerValue;
  }

  get canEditCode(): boolean {
    if (!this.answerType) return false;
    return this.answerType.code != 'T/F';
  }
  get canEditTitle(): boolean {
    if (!this.answerType) return false;
    return true;
  }

  constructor() { }

  ngOnInit() {
  }

  delete() {
    this.onDelete.emit(this.answerValue);
  }
  moveUp() {
    this.onMoveUp.emit(this.answerValue);
  }
  moveDown() {
    this.onMoveDown.emit(this.answerValue);
  }

}
