import { Component, OnInit, Input } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../../services/alert.service';
import { CompaniesService } from '../../../../services/companies.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AppTranslationService } from '../../../../services/app-translation.service';
import { ProfileService } from '../../../../services/profile.service';
import { AuthService } from '../../../../services/auth.service';
import { CompanyUsage } from '../../../../models/company-usage.model';
import { Utilities } from '../../../../services/utilities';
import { Company } from '../../../../models/company';

@Component({
  selector: 'app-company-usage-control',
  templateUrl: './company-usage-control.component.html',
  styleUrls: ['./company-usage-control.component.scss']
})
export class CompanyUsageControlComponent implements OnInit {
  loadingIndicator: boolean;

  @Input() companyId; number;

  dataSource: CompanyUsage[];

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public barChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    { data: [], label: 'New Credits' },
    { data: [], label: 'New Users' },
    { data: [], label: 'Invitations' },
    { data: [], label: 'Reports' }
  ];

  public barChartColors = [
    { // first color
      backgroundColor: '#679dcb',
      //borderColor: 'rgba(225,10,24,0.2)',
      //pointBackgroundColor: 'rgba(225,10,24,0.2)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: '#32486e',
      //borderColor: 'rgba(225,10,24,0.2)',
      //pointBackgroundColor: 'rgba(225,10,24,0.2)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // third color
      backgroundColor: '#b4b4b4',
      //borderColor: 'rgba(225,10,24,0.2)',
      //pointBackgroundColor: 'rgba(225,10,24,0.2)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // fourth color
      backgroundColor: '#dfab48',
      //borderColor: 'rgba(225,10,24,0.2)',
      //pointBackgroundColor: 'rgba(225,10,24,0.2)',
      //pointBorderColor: '#fff',
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    }
  ];

  constructor(
    private alertService: AlertService,
    private companiesService: CompaniesService,
    private router: Router,
    private dialog: MatDialog,
    private translationService: AppTranslationService,
    private profileService: ProfileService,
    private authService: AuthService) { }

  ngOnInit() {
    this.loadData();
    this.authService.companySelected$.subscribe(
      result => { this.companyFound(result); }
    );
  }

  public companyFound(company: Company) {
    this.companyId = company.companyId;
    this.loadData();
  }


  private loadData() {
    this.alertService.startLoadingMessage();
    this.barChartData[0].data = [];
    this.barChartData[1].data = [];
    this.barChartData[2].data = [];
    this.barChartData[3].data = [];

    this.loadingIndicator = true;
    this.companiesService
      .getCompanyUsage(this.companyId)
      .subscribe(entries =>
        this.onDataLoadSuccessful(entries),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: CompanyUsage[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    this.dataSource = entries;

    this.barChartLabels = entries.map(x => x.my);
    this.barChartData[0].data = entries.map(x => x.credits_added);
    this.barChartData[1].data = entries.map(x => x.users_added);
    this.barChartData[2].data = entries.map(x => x.invitations_sent);
    this.barChartData[3].data = entries.map(x => x.reports_created);
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }
}
