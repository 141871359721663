import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';

import { ReportEntry } from 'src/app/models/report-entry.model';
import { AlertService, MessageSeverity, DialogType } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AuthService } from 'src/app/services/auth.service';
import { Utilities } from 'src/app/services/utilities';
import { ProfileService } from 'src/app/services/profile.service';
import { PersonalityScoring } from 'src/app/models/personality-scoring.model';
import { RenderRequest } from 'src/app/models/render-request.model';
import { P60benchmark } from 'src/app/models/p60benchmark';
import { P60option } from 'src/app/models/p60option.model';
import { CampaignService } from '../../../../services/campaign.service';
import { CompanyP60BenchmarkSelectorControlComponent } from '../../../companies/controls/company-p60-benchmark-selector-control/company-p60-benchmark-selector-control.component';
import { CompanyP60OptionSelectorControlComponent } from '../../../companies/controls/company-p60-option-selector-control/company-p60-option-selector-control.component';
import { CompaniesService } from '../../../../services/companies.service';

@Component({
  selector: 'app-campaign-reports-control',
  templateUrl: './campaign-reports-control.component.html',
  styleUrls: ['./campaign-reports-control.component.scss']
})
export class CampaignReportsControlComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(CompanyP60BenchmarkSelectorControlComponent) benchmarkSelector: CompanyP60BenchmarkSelectorControlComponent;
  @ViewChild(CompanyP60OptionSelectorControlComponent) optionSelector: CompanyP60OptionSelectorControlComponent;

  displayedColumns = [
    'userFullName',
    'testName',
    'completed',
    'release',
    'download',
    'launch'
  ];
  dataSource: MatTableDataSource<ReportEntry>;
  loadingIndicator: boolean;

  @Input() campaignId: number;

  selectedBenchmark: P60benchmark;
  selectedOption: P60option;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private campaignService: CampaignService,
    private profileService: ProfileService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
    this.selectedBenchmark = this.benchmarkSelector.nobenchmark;
    this.benchmarkSelector.benchmarkSelected$.subscribe(benchmark => { this.selectedBenchmark = benchmark; })
    this.selectedOption = this.optionSelector.nooption;
    this.optionSelector.optionSelected$.subscribe(option => { this.selectedOption = option; })
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;
    this.campaignService
      .getReports(this.campaignId)
      .subscribe(entries =>
        this.onDataLoadSuccessful(entries),
        error => this.onDataLoadFailed(error));
  }

  private onDataLoadSuccessful(entries: ReportEntry[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
    entries.forEach(e => {
      e.started = Utilities.utcDateToLocal(new Date(e.started));
      e.completed = Utilities.utcDateToLocal(new Date(e.completed));
    });
    this.dataSource.data = entries;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.alertService.showStickyMessage('Load Error', `Unable to retrieve data from the server.\r\nErrors: "${Utilities.getHttpResponseMessages(error)}"`,
      MessageSeverity.error, error);
  }

  private releaseAll() {
    const dataset = this.dataSource.filteredData.filter(d => d.hasCredit == true && d.userFullName);
    if (dataset.length > 0) {
      this.alertService.showDialog("Confirm Release", "Are you sure you want to release these reports?", DialogType.confirm, (val) => this.processReleaseAll(), null, "Yes", "No", "No");
    }
  }
  private processReleaseAll(val?: any): any {
    const dataset = this.dataSource.filteredData.filter(d => d.hasCredit == true && d.userFullName);
    if (dataset.length > 0) {
      this.alertService.startLoadingMessage("Releasing...");
      this.loadingIndicator = true;
      dataset.forEach(entry => setTimeout(() => { this.release(entry); }, 100));
    }
  }

  private release(entry: ReportEntry) {
    if (this.loadingIndicator != true) {
      if (entry.hasCredit)
        this.alertService.showDialog("Confirm Release", "Are you sure you want to release this report?", DialogType.confirm, (val) => this.processRelease(entry), null, "Yes", "No", "No");
      else
        this.alertService.showMessage("This report requires a credit before it can be released.");
    }
    else {
      this.processRelease(entry);
    }
  }
  private processRelease(entry: ReportEntry): any {
    if (!entry.hasCredit) return;
    if (this.loadingIndicator != true) {
      this.alertService.startLoadingMessage("Releasing...");
      this.loadingIndicator = true;
    }
    this.companiesService.releaseReport(entry.userTestId).subscribe(
      data => this.entryReleased(entry),
      error => this.onDataLoadFailed(error)
    );
  }

  private entryReleased(entry: ReportEntry) {
    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();

    entry.released = true;
  }

  private launchAll() {
    const dataset = this.dataSource.filteredData.filter(d => d.hasCredit == true);
    if (dataset.length > 0) {
      this.alertService.startLoadingMessage("Launching...");
      this.loadingIndicator = true;
      dataset.forEach(entry => setTimeout(() => { this.launch(entry); }, 1000));
    }
  }

  private launch(entry: ReportEntry) {
    if (this.loadingIndicator != true) {
      if (!entry.hasCredit) {
        this.alertService.showMessage("This report requires a credit before it can be launched.");
        return;
      }
      this.alertService.startLoadingMessage("Launching...");
      this.loadingIndicator = true;
    }
    if (entry.detailPath == "P60") {
      this.profileService.getP60Result(entry.userId, entry.companyId, entry.language, entry.userTestId, this.selectedBenchmark.benchmarkId, this.selectedOption.p60optionId).subscribe(
        data => this.parseReportResult(data),
        error => this.onDataLoadFailed(error)
      );
    }
    if (entry.detailPath == "IQ") {
      this.profileService.getIQResult(entry.userId, entry.companyId, entry.language, entry.userTestId).subscribe(
        data => this.parseReportResult(data),
        error => this.onDataLoadFailed(error)
      );
    }
    if (entry.detailPath == "CT") {
      this.profileService.getCTResult(entry.userId, entry.companyId, entry.language, entry.userTestId).subscribe(
        data => this.parseReportResult(data),
        error => this.onDataLoadFailed(error)
      );
    }
    if (entry.detailPath == "CS") {
      this.profileService.getCSResult(entry.userId, entry.companyId, entry.language, entry.userTestId, 0, (entry.userFullName == "")).subscribe(
        data => this.parseReportResult(data),
        error => this.onDataLoadFailed(error)
      );
    }
  }

  parseReportResult(data: any) {
    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();

    try {
      var newWindow = window.open("about:blank", "_blank");
      newWindow.document.write("<!DOCTYPE html><head>" + data.headstyle + "</head><body>" + data.body + "</body></html>");
    }
    catch {
      this.alertService.showMessage("Please enable pop-ups to continue.");
    }
  }

  private downloadAll() {
    const dataset = this.dataSource.filteredData.filter(d => d.hasCredit == true);
    if (dataset.length > 0) {
      this.alertService.startLoadingMessage("Downloading...");
      this.loadingIndicator = true;
      dataset.forEach(entry => setTimeout(() => { this.download(entry); }, 1000));
    }
  }

  private download(entry: ReportEntry) {
    if (this.loadingIndicator != true) {
      if (!entry.hasCredit) {
        this.alertService.showMessage("This report requires a credit before it can be downloaded.");
        return;
      }
      this.alertService.startLoadingMessage("Downloading...");
      this.loadingIndicator = true;
    }
    if (entry.detailPath == "P60") {
      this.profileService.getP60Download(entry.userId, entry.companyId, entry.language, entry.userTestId, this.selectedBenchmark.benchmarkId, this.selectedOption.p60optionId).subscribe(
        data => this.parseReportDownload(data),
        error => this.onDataLoadFailed(error)
      );
    }
    if (entry.detailPath == "IQ") {
      this.profileService.getIQDownload(entry.userId, entry.companyId, entry.language, entry.userTestId).subscribe(
        data => this.parseReportDownload(data),
        error => this.onDataLoadFailed(error)
      );
    }
    if (entry.detailPath == "CT") {
      this.profileService.getCTDownload(entry.userId, entry.companyId, entry.language, entry.userTestId).subscribe(
        data => this.parseReportDownload(data),
        error => this.onDataLoadFailed(error)
      );
    }
    if (entry.detailPath == "CS") {
      this.profileService.getCSDownload(entry.userId, entry.companyId, entry.language, entry.userTestId, 0, (entry.userFullName == "")).subscribe(
        data => this.parseReportDownload(data),
        error => this.onDataLoadFailed(error)
      );
    }
  }

  parseReportDownload(request: RenderRequest) {
    this.loadingIndicator = false;
    this.alertService.stopLoadingMessage();

    const pdf = request.filedata;
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = request.fileName;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

}
