<form #form="ngForm" [formGroup]="userProfileForm" novalidate (ngSubmit)="save()" fxLayout="column" [fxLayoutGap]="isEditMode ? '1em' : '0'" autocomplete="off">

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <!--<mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'users.editor.JobTitle' | translate}}</mat-label>
      <input matInput formControlName="jobTitle" [readonly]="!isEditMode" />
    </mat-form-field>-->

    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'users.editor.FullName' | translate}}</mat-label>
      <input matInput formControlName="fullName" [readonly]="!isEditMode" />
    </mat-form-field>
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'users.editor.Gender' | translate}}</mat-label>
      <mat-select formControlName="genderId" [disabled]="!isEditMode">
        <mat-option *ngFor="let g of genders" [value]="g.genderId">
          {{g.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'users.editor.Email' | translate}}</mat-label>
      <input matInput formControlName="email" [readonly]="!isEditMode" />
      <mat-error *ngIf="email.hasError('required')">
        {{'users.editor.EmailRequired' | translate}}
      </mat-error>
      <mat-error *ngIf="!email.hasError('required') && email.hasError('email')">
        {{'users.editor.InvalidEmail' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
      <mat-label>{{'users.editor.PhoneNumber' | translate}}</mat-label>
      <input matInput formControlName="phoneNumber" [readonly]="!isEditMode" />
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">
    <mat-form-field [floatLabel]="floatLabels" fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'users.editor.UserName' | translate}}</mat-label>
      <input matInput formControlName="userName" [readonly]="!isEditMode" autocomplete="off" />
      <mat-error *ngIf="userName.hasError('required')">
        {{'users.editor.UserNameRequired' | translate}}
      </mat-error>
    </mat-form-field>

    <mat-form-field [floatLabel]="floatLabels" formGroupName="password" *ngIf="(isEditMode && !isNewUser && userName.value != user.userName) || (isChangePassword && isEditingSelf)" fxFlex="100%">
      <mat-label>{{'users.editor.CurrentPassword' | translate}}</mat-label>
      <input matInput type="password" formControlName="currentPassword" />
      <mat-error *ngIf="currentPassword.hasError('required')">
        {{'users.editor.CurrentPasswordRequired' | translate}}
      </mat-error>
    </mat-form-field>

  </div>

  <div *ngIf="isEditMode">

    <button mat-raised-button (click)="changePassword()" *ngIf="!(isChangePassword || isNewUser || isRegisterUser)" color="primary">{{'users.editor.ChangePassword' | translate}}</button>

    <div *ngIf="isChangePassword || isNewUser || isRegisterUser" formGroupName="password" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em">

      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'users.editor.NewPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="newPassword" autocomplete="off" />
        <mat-error *ngIf="newPassword.hasError('required')">
          {{'users.editor.NewPasswordRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="!newPassword.hasError('required') && newPassword.hasError('pattern')">
          {{'users.editor.NewPasswordRules' | translate}}
        </mat-error>
      </mat-form-field>

      <mat-form-field [floatLabel]="floatLabels" fxFlex="100%">
        <mat-label>{{'users.editor.ConfirmPassword' | translate}}</mat-label>
        <input matInput type="password" formControlName="confirmPassword" />
        <mat-error *ngIf="confirmPassword.hasError('required')">
          {{'users.editor.ConfirmationPasswordRequired' | translate}}
        </mat-error>
        <mat-error *ngIf="!confirmPassword.hasError('required') && confirmPassword.hasError('notEqual')">
          {{'users.editor.PasswordMismatch' | translate}}
        </mat-error>
      </mat-form-field>

    </div>

  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em" *ngIf="!isRegisterUser">

    <mat-form-field fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-label>{{'users.editor.Roles' | translate}}</mat-label>
      <mat-select formControlName="roles" multiple [disabled]="!isEditMode || !canAssignRoles">
        <mat-option *ngFor="let role of assignableRoles" [value]="role.name">
          <span matTooltip="{{role.description}}">{{role.name}}</span>
        </mat-option>
      </mat-select>
      <mat-error *ngIf="assignedRoles.hasError('required')">
        {{'users.editor.RoleRequired' | translate}}
      </mat-error>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="1em" *ngIf="!isRegisterUser">

    <div fxFlex.gt-sm="0 1 calc(50% - 0.5em)">
      <mat-checkbox *ngIf="isEditMode" formControlName="isEnabled">{{'users.editor.Enabled' | translate}}</mat-checkbox>
    </div>

    <div>
      <button mat-button *ngIf="user.isLockedOut" (click)="unlockUser()" [disabled]="isSaving" color="accent"><mat-icon class="mat-button-icon">lock_open</mat-icon>{{'users.editor.Unblock' | translate}}</button>
    </div>

  </div>

  <!-- https://github.com/angular/material2/issues/8798 -->
  <div *ngIf="!isRegisterUser">&nbsp;</div>
</form>
