<h1 mat-dialog-title>
  {{data.company.companyName}}
</h1>
<div mat-dialog-content>
  <company-editor #cec [company]="company" isEditMode="true"></company-editor>
</div>
<div mat-dialog-actions>
  <div fxFlex="auto"></div>
  <button mat-raised-button (click)="cancel()" color="warn">Cancel</button>
  <button mat-raised-button (click)="save()" color="primary">Save</button>
</div>
