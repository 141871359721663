<div [@fadeInOut] class="page-content" fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
  <div>
    <mat-card class="simple-border">
      <mat-card-header>
        <mat-card-title>{{'mainMenu.ForgotPassword' | translate }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form #form="ngForm" [formGroup]="forgotForm" (ngSubmit)="resetPassword()" novalidate class="app-login-form">
          <div class="container" style="text-align: center"
               fxLayout
               fxLayout.xs="column"
               fxLayoutAlign="center"
               fxLayoutGap="10px"
               fxLayoutGap.xs="0" layout-align="center center">
            {{'forgotPassword.Message01' | translate}}
          </div>
          <br />
          <div class="container" style="text-align: center"
               fxLayout
               fxLayout.xs="column"
               fxLayoutAlign="center"
               fxLayoutGap="10px"
               fxLayoutGap.xs="0" layout-align="center center">
            {{'forgotPassword.Message02' | translate}}
          </div>
          <br />
          <div class="container" style="text-align: center"
               fxLayout
               fxLayout.xs="column"
               fxLayoutAlign="center"
               fxLayoutGap="10px"
               fxLayoutGap.xs="0">
            {{'forgotPassword.Instruction' | translate }}
          </div>
          <br />
          <mat-form-field class="app-validate"
                          fxLayout
                          fxLayout.xs="column"
                          fxLayoutAlign="center"
                          fxLayoutGap="10px"
                          fxLayoutGap.xs="0">
            <input matInput formControlName="email" placeholder="email address" autocomplete="email" (keydown.enter)="enterKeyDown()" type="email">
            <mat-error *ngIf="email.hasError('required')">
              Email is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </form>


      </mat-card-content>
      <mat-card-actions>
        <button mat-button [routerLink]="'/login'">{{'commands.Cancel' | translate }}</button>
        <div fxFlex="auto"></div>
        <div>&nbsp;</div>
        <button mat-raised-button color="primary" [disabled]="isResetting" (click)="resetPassword()">
          {{'commands.Reset' | translate | uppercase}}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
