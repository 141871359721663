<div fxFlex="100%" fxLayout="row" fxLayoutGap="16px" class="content-area">
  <div fxFlex="100%" fxLayout="column" class="simple-border content-container">
    <div fxFlex="100%" fxLayout="row">
      <div fxFlex="100%">
        <mat-form-field floatLabel="auto" fxFlex="100%">
          <mat-label>{{'answerControl.Title' | translate}}</mat-label>
          <textarea matInput [(ngModel)]="editObj.title" matTextareaAutosize matAutosizeMinRows=1 matAutosizeMaxRows=5></textarea>
        </mat-form-field>
      </div>
      <div class="buttons" fxLayout="row">
        <button mat-icon-button *ngIf="!(positionValue == 'first' || positionValue == 'single')" (click)="moveUp()" matTooltip="{{'commands.Up' | translate}}">
          <mat-icon>arrow_upward</mat-icon>
        </button>
        <button mat-icon-button *ngIf="!(positionValue == 'last' || positionValue == 'single')" (click)="moveDown()" matTooltip="{{'commands.Down' | translate}}">
          <mat-icon>arrow_downward</mat-icon>
        </button>
        <button mat-icon-button *ngIf="positionValue != 'single'" color="warn" (click)="delete()" matTooltip="{{'commands.Delete' | translate}}">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout="column" fxLayout.lt-md="column" fxFlex="100%" fxLayoutGap="8px" class="keep-top">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxFlex="100%">
        <mat-form-field fxFlex="50%">
          <mat-label>{{'answerControl.Type' | translate}}</mat-label>
          <mat-select [(ngModel)]="editObj.answerTypeId">
            <mat-option *ngFor="let t of answerTypes" [value]="t.answerTypeId">
              <span>{{t.title}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="useFreeTextLimit" [floatLabel]="auto" fxFlex="50%">
          <mat-label>{{'answerControl.TextLimit' | translate}}</mat-label>
          <input matInput [(ngModel)]="editObj.freeTextCharacterLimit" type="number" min="1" max="5000" />
        </mat-form-field>
      </div>
      <div fxLayout="column" fxLayoutGap="8px" *ngIf="useAnswerValues" fxFlex="100%">
        <div fxFlex="100%" *ngFor="let av of answerValues">
          <app-answer-value-control [answerValue]="av"
                                    [position]="valuePosition(av)"
                                    [answerType]="answerType"
                                    (onDelete)="valueDelete($event)"
                                    (onMoveUp)="valueMoveUp($event)"
                                    (onMoveDown)="valueMoveDown($event)">
          </app-answer-value-control>
        </div>
        <div *ngIf="answerType.code != 'T/F'" fxFlex="100%" fxLayout="row">
          <div fxFlex="auto"></div>
          <button mat-icon-button color="primary" (click)="addValueAt(1000000)" matTooltip="{{'testEditor.NewAnswer' | translate}}">
            <mat-icon>playlist_add</mat-icon>
          </button>
          <div fxFlex="auto"></div>
        </div>
      </div>
    </div>
  </div>
</div>
