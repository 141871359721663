<app-page-header title="StartMaterial" icon="play_arrow"></app-page-header>
<div [@fadeInOut] class="page-content">
  <div fxLayout="row">
    {{ materialName }}
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row">
    {{ intro }}
  </div>
</div>

